import { FileDarkIcon, FileLightIcon } from 'images/icons';
import React, { FC, useCallback } from 'react';
import s from './style.module.scss';
import { paths } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { buildInitials } from './user';
import { IconButton, Tooltip } from '@mui/material';
import { NotificationImportant as NotificationImportantIcon } from '@mui/icons-material';
import { orange } from '@mui/material/colors';
import { startCase, toLower } from 'lodash';
import { changeReportStatusName, statusVariant } from './formats';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { compare } from './functions';
import { DocumentCancelOrDeleteIcon } from 'template/LegalFolderDocument/components/DocumentCancelOrDeleteIcon/DocumentCancelOrDeleteIcon';

export const transformUserText = (data: any) => {
  if (!data) {
    return '--';
  }

  return data.name;
};

export const TransformUser: FC<{ data: any; row: any; order?: SortOrder; sorted?: boolean }> = ({
  data,
}) => {
  if (!data) {
    return (
      <div className="focal_point_user_column">
        <div>-</div>
      </div>
    );
  }

  const item: INameInitials = {
    name: data.name,
    initials: buildInitials(data.name),
  };

  const userText = (
    <div key={item.name}>
      <Tooltip title={item.name}>
        <span>{item.initials}</span>
      </Tooltip>
    </div>
  );

  return (
    <div className="focal_point_user_column">
      <div>{userText}</div>
    </div>
  );
};

export const TransformDocumentsNumber: FC<{ data: number; row: any }> = ({ data, row }) => {
  const history = useHistory();

  const handleSetPage = useCallback(
    (e: any) => {
      // const viewDocumentsPath = paths.client.PROJECT_FOLDER_ID_DOCUMENTS.replace(':id', row['id']);
      e.preventDefault();
      e.cancelBubble = true;
      e.stopPropagation();
      const viewDocumentsPath = paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB.replace(
        ':id',
        row['id']
      ).replace(':tab', 'D');
      history.push(viewDocumentsPath);
    },
    [history, row]
  );

  if (data > 0) {
    return (
      <div onClick={handleSetPage} style={{ cursor: 'pointer' }}>
        <FileDarkIcon className={s.documentIcon}></FileDarkIcon>
      </div>
    );
  }
  return (
    <div onClick={handleSetPage} style={{ cursor: 'pointer' }}>
      <FileLightIcon className={s.documentIcon}></FileLightIcon>
    </div>
  );
};

interface INameInitials {
  name: string;
  initials: string;
}

export const TransformCurrentlyReviewingBellIcon: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data, row, order, sorted }) => {
  const history = useHistory();
  const { currentUserHasActionPending } = data;

  return (
    <div className="currently_reviewing_column">
      {currentUserHasActionPending ? (
        <Tooltip
          title={
            <div>
              <div>Review Requested!</div>
              <div>Open File Management and Approval</div>
            </div>
          }
        >
          <IconButton
            size="small"
            style={{ color: orange[400], marginLeft: '0.5rem' }}
            onClick={(e: any) => {
              e.preventDefault();
              e.cancelBubble = true;
              e.stopPropagation();
              history.push(paths.treeViewClient.TREEVIEW_DOCUMENT_FM.replace(':id', row.id));
            }}
          >
            <NotificationImportantIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : undefined}
    </div>
  );
};

export const TransformCancelOrDeleteIcon: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data, row, order, sorted }) => {
  if (!data) {
    return <></>;
  }

  const { status, id } = row;
  return <DocumentCancelOrDeleteIcon status={status} id={id}></DocumentCancelOrDeleteIcon>;
};

export const TransformCurrentlyReviewing: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data, row, order, sorted }) => {
  const history = useHistory();
  const { reviewers, currentUserHasActionPending } = data;

  let orderedReviewers: INameInitials[] = reviewers.map((name: string) => ({
    name,
    initials: buildInitials(name),
  }));

  if (!!sorted) {
    orderedReviewers = orderedReviewers.sort((a: INameInitials, b: INameInitials) =>
      compare(a.initials, b.initials)
    );
    if (order === SortOrder.DESC) {
      orderedReviewers = orderedReviewers.reverse();
    }
  }

  const reviewersText = reviewers.length
    ? orderedReviewers.map((item: INameInitials) => (
        <div key={item.name}>
          <Tooltip title={item.name}>
            <span>{item.initials}</span>
          </Tooltip>
        </div>
      ))
    : '-';
  return (
    <div className="currently_reviewing_column">
      <div>{reviewersText}</div>

      {currentUserHasActionPending ? (
        <Tooltip
          title={
            <div>
              <div>Review Requested!</div>
              <div>Open File Management and Approval</div>
            </div>
          }
        >
          <IconButton
            size="small"
            style={{ color: orange[400], marginLeft: '0.5rem' }}
            onClick={(e: any) => {
              e.preventDefault();
              e.cancelBubble = true;
              e.stopPropagation();
              history.push(paths.treeViewClient.TREEVIEW_DOCUMENT_FM.replace(':id', row.id));
            }}
          >
            <NotificationImportantIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : undefined}
    </div>
  );
};

export const TransformOwners: FC<{ data: any; row: any; order?: SortOrder; sorted?: boolean }> = ({
  data,
  row,
  order,
  sorted,
}) => {
  let ordered: INameInitials[] = data.map((name: string) => ({
    name,
    initials: buildInitials(name),
  }));

  if (!!sorted) {
    ordered = ordered.sort((a: INameInitials, b: INameInitials) => compare(a.initials, b.initials));
    if (order === SortOrder.DESC) {
      ordered = ordered.reverse();
    }
  }

  const reviewersText = data.length
    ? ordered.map((item: INameInitials) => (
        <div key={item.name}>
          <Tooltip title={item.name}>
            <span>{item.initials}</span>
          </Tooltip>
        </div>
      ))
    : '-';
  return (
    <div className="currently_reviewing_column">
      <div>{reviewersText}</div>
    </div>
  );
};

export const TransformOwnersWithEmail: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data, row, order, sorted }) => {
  return (
    <>
      {row.owners.map((owner: any) => (
        <div>
          <Tooltip title={owner?.user?.email}>
            <a href={'mailto:' + owner?.user?.email}>{owner?.user?.name}</a>
          </Tooltip>
        </div>
      ))}
    </>
  );
};

export const TransformDocumentStatus: FC<{ data: any; row: any }> = ({ data, row }) => {
  const statusName = changeReportStatusName(startCase(toLower(data)));
  return <div style={{ color: statusVariant(data) }}>{statusName}</div>;
};

export const TransformDocumentStatusText = ({ data, row }: { data: any; row: any }) => {
  const statusName = changeReportStatusName(startCase(toLower(data)));
  return statusName;
};

export const TransformStringArray = ({ data, row }: { data: any; row: any }) => {
  return (
    <>
      {data.map((item: { id: string; name: string }) => (
        <div key={item.id}>{item.name}</div>
      ))}
    </>
  );
};
