import { useQuery } from '@apollo/client';
import { GET_DIVISION_MANAGERS } from 'graphql/legalFolders/divisions';
import { divisionManagers } from 'graphql/legalFolders/types/divisionManagers';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { IUsersArrayItem } from './interfaces';

export const useDivisionManagers = () => {
  const [divisionManagers, setDivisionManagers] = useState<IUsersArrayItem[]>();
  const { data, loading, error } = useQuery<divisionManagers>(GET_DIVISION_MANAGERS);

  useEffect(() => {
    if (!error && !loading && data?.divisions) {
      const managers = uniqBy(
        data.divisions
          .filter((division) => !!division.manager)
          .map((division) => {
            const { id, name, email } = division.manager!;
            return {
              discipline: 'DIVISION_MANAGER',
              division: null,
              id,
              name,
              email: email || '',
            };
          }),
        (manager) => manager.id
      );
      setDivisionManagers(managers);
    }
  }, [data, loading, error]);

  return { divisionManagers };
};
