import React, { FC, useCallback, useMemo } from 'react';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { ChevronRight as ColapsedIcon, ExpandMore as ExpandedIcon } from '@mui/icons-material';
import { ProjectFolders } from '../ProjectFolders/ProjectFolders';
import s from './style.module.scss';
import LoadingOverlay from 'react-loading-overlay-ts';
import cn from 'classnames';
import { NewLegalFolderButton } from '../TableSection/components/LegalFolderView/NewLegalFolderButton';
import { NewProjectFolderButton } from '../TableSection/components/ProjectFolderView/NewProjectFolderButton';
import { NewDocumentButton } from '../TableSection/components/DocumentsView/NewDocumentButton';
import { markSearchString } from 'utils/reformat';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NotificationImportant as NotificationImportantIcon } from '@mui/icons-material';
import { orange } from '@mui/material/colors';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useApolloUtil } from 'hooks/apolloUtilHook';
import { useHistory } from 'react-router';
import paths from 'constants/paths';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { LegalFolderSortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { useComponentContext as useLegalFoldersTreeContext } from 'contexts/LegalFoldersTree';

export const LegalFolders: FC = () => {
  const { confirmRequest } = useFormChangedDialogContext();
  const { getSingleActionPendingDocumentId } = useApolloUtil();
  const history = useHistory();
  const {
    legalFoldersOrderBy,
    setLegalFoldersOrderBy,
    legalFoldersOrderDirection,
    setLegalFoldersOrderDirection,
  } = useLegalFoldersTreeContext();

  const {
    tree,
    onToggleLegalFolder,
    onSelectLegalFolder,
    search,
    loadMore,
    loading,
    selectedFolder,
    initTab,
    myTask,
  } = useComponentContext();

  const onToggle = (id: string) => () => {
    onToggleLegalFolder!(id);
  };

  const onSelect = (id: string) => () => {
    confirmRequest!((confirmed) => {
      confirmed && onSelectLegalFolder!(id);
    });
  };

  const bellProcess = useCallback(
    (id: string) => async () => {
      const { documentId, error, multipleFound } = await getSingleActionPendingDocumentId!({
        legalFolderIds: [id],
      });
      if (!error && !multipleFound && documentId) {
        confirmRequest!((confirmed) => {
          confirmed &&
            history.push(paths.treeViewClient.TREEVIEW_DOCUMENT_FM.replace(':id', documentId));
        });
      }
      if (multipleFound) {
        confirmRequest!((confirmed) => {
          confirmed && history.push(paths.client.DOCUMENTS + '?lf=' + id);
        });
      }
    },
    [confirmRequest, getSingleActionPendingDocumentId, history]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: Record<string, any>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const orderByLabel = useMemo(() => {
    switch (legalFoldersOrderBy) {
      case LegalFolderSortableColumn.CREATED_AT:
        return 'Create Date';
      case LegalFolderSortableColumn.UPDATED_AT:
        return 'Update Date';

      case LegalFolderSortableColumn.NAME:
        return 'Legal Folder Name';
      case LegalFolderSortableColumn.CURRENT_USER_ACTION_PENDING:
        return 'Action Pending';
      default:
        return 'Action Pending';
    }
  }, [legalFoldersOrderBy]);

  const _onSortChange = useCallback(() => {
    const changeTo = legalFoldersOrderDirection === 'ASC' ? 'DESC' : 'ASC';
    setLegalFoldersOrderDirection(changeTo);
  }, [setLegalFoldersOrderDirection, legalFoldersOrderDirection]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div className={s.legalFolders}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          selected={legalFoldersOrderBy === LegalFolderSortableColumn.CURRENT_USER_ACTION_PENDING}
          onClick={() => {
            setLegalFoldersOrderBy(LegalFolderSortableColumn.CURRENT_USER_ACTION_PENDING);
            handleClose();
          }}
        >
          Action Pending
        </MenuItem>
        <MenuItem
          selected={legalFoldersOrderBy === LegalFolderSortableColumn.NAME}
          onClick={() => {
            setLegalFoldersOrderBy(LegalFolderSortableColumn.NAME);
            handleClose();
          }}
        >
          Legal Folder Name
        </MenuItem>
        <MenuItem
          selected={legalFoldersOrderBy === LegalFolderSortableColumn.CREATED_AT}
          onClick={() => {
            setLegalFoldersOrderBy(LegalFolderSortableColumn.CREATED_AT);
            handleClose();
          }}
        >
          Create Date
        </MenuItem>
        <MenuItem
          selected={legalFoldersOrderBy === LegalFolderSortableColumn.UPDATED_AT}
          onClick={() => {
            setLegalFoldersOrderBy(LegalFolderSortableColumn.UPDATED_AT);
            handleClose();
          }}
        >
          Update Date
        </MenuItem>
      </Menu>

      <Box mt={2} />

      <div>
        <div className={s.newLegalFolderButtonRow}>
          <NewLegalFolderButton
            variant="outlined"
            color="primary"
            className={s.treeSideButton}
            disabled={!!selectedFolder?.legalFolderId}
          />
        </div>
        <div className={s.newProjectFolderButtonRow}>
          <NewProjectFolderButton
            variant="outlined"
            color="primary"
            className={s.treeSideButton}
            disabled={initTab !== 'PF' || !selectedFolder?.legalFolderId}
          />
        </div>
        <div className={s.newProjectDocumentButtonRow}>
          <NewDocumentButton
            variant="outlined"
            color="primary"
            className={s.treeSideButton}
            disabled={
              initTab !== 'D' || !selectedFolder?.legalFolderId || !selectedFolder?.projectFolderId
            }
          />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: matches ? 'end' : 'start',
            padding: '10px 5px 10px 0',
          }}
        >
          Order by:{' '}
          <div
            style={{
              paddingLeft: '5px',
              display: 'inline-block',
            }}
            onClick={handleClick}
            className={s.link}
          >
            {orderByLabel || '...'}
          </div>
          <TableSortLabel
            active={Boolean(legalFoldersOrderDirection)}
            direction={
              legalFoldersOrderDirection
                ? (legalFoldersOrderDirection.toLowerCase() as 'asc' | 'desc')
                : undefined
            }
            onClick={debounce(_onSortChange, DEBOUNCE_TIMEOUT)}
          ></TableSortLabel>
        </div>
        <div key="LF" className={cn(s.legalFolderLabel)}>
          Legal Folders:
        </div>
        <div key={tree?.ts || 'empty-tree'}>
          <LoadingOverlay spinner active={loading} text="Loading your content...">
            {tree?.items.map((node) => {
              return (
                <div
                  key={node.id}
                  className={cn(s.legalFolderBox, {
                    [s.showBoxActive]: selectedFolder?.legalFolderId === node.id,
                    [s.extraLoaded]: node?.extraLoaded,
                  })}
                >
                  <div className={s.titleRow}>
                    <div
                      onClick={debounce(onToggle(node.id), DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      {node.expanded ? (
                        <ExpandedIcon fontSize={'small'}></ExpandedIcon>
                      ) : (
                        <ColapsedIcon fontSize={'small'}></ColapsedIcon>
                      )}
                    </div>
                    <div
                      className={cn({
                        [s.showActive]: selectedFolder?.legalFolderId === node.id,
                      })}
                      key={node.id}
                      onClick={debounce(onSelect(node.id), DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer', wordBreak: 'break-all', wordWrap: 'break-word' }}
                    >
                      {markSearchString(node.name, search)}
                    </div>
                    {node.currentUserHasActionPending ? (
                      <Tooltip title="Review Requested">
                        <IconButton
                          size="small"
                          style={{ color: orange[400], marginLeft: '2rem' }}
                          onClick={debounce(bellProcess(node.id), DEBOUNCE_TIMEOUT)}
                        >
                          <NotificationImportantIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ) : undefined}
                  </div>
                  <div
                    className={cn(s.contentRow, { [s.contentRowExpanded]: node.expanded })}
                    style={{ display: node.expanded ? undefined : 'none' }}
                  >
                    <ProjectFolders
                      node={node}
                      search={search || ''}
                      parentLoading={loading}
                      myTask={myTask}
                    ></ProjectFolders>
                  </div>
                </div>
              );
            })}
            {tree?.totalItems && tree?.totalItems > tree?.items.length ? (
              <div
                className={s.loadMore}
                key="loadMore"
                onClick={debounce(loadMore, DEBOUNCE_TIMEOUT)}
                style={{ cursor: 'pointer' }}
              >
                Load more...
              </div>
            ) : undefined}
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
};
