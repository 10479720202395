import {
  Badge,
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import './index.scss';

import { useComponentContext } from '../../../../LegalFolderDocumentContext';

import { useState, useCallback, useMemo, useEffect } from 'react';

import { PartyReviewStatus } from './../../components/PartyReviewStatus/PartyReviewStatus';
import { NewDocumentVersion } from './../../components/NewDocumentVersion';
import { DocumentApprovalReviewer } from './../../components/DocumentApprovalReviewer';
import { FileLogs } from './../../components/FileLogs/FileLogs';
import { SupportFiles } from './../../components/SupportFiles/SupportFiles';
import DocumentApprovalSection from './../../components/DocumentApprovalSection';

import { IReviewer } from '../../../Reviewers/interfaces';
import { DocumentSignSection } from '../DocumentSignSection/DocumentSignSection';
import { EventsLog } from './components/EventsLog/EventsLog';
import { DocuSignSection } from '../DocuSignSection/DocuSignSection';
import { Docusigns } from '../Docusigns/Docusigns';
import { DOCUSIGN_ENABLED } from 'constants/config';
import { useMsalAccount } from 'hooks/msalAccount';
import { DocumentObserversNotification } from '../DocumentObserversNotification';

const isCurrentUser = (account: Record<string, any>, reportUser: IReviewer): boolean => {
  return (
    account?.idToken?.email === reportUser.employeeEmail ||
    account?.idToken?.preferred_username === reportUser.employeeEmail
  );
};

const tabHeight = '5rem'; // default: '48px'

const useStylesScrollable = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    overflow: 'visible',
  },
}));

const useStylesFullWidth = makeStyles((theme) => ({
  tabsRoot: {
    overflow: 'visible !important',
    '& .MuiTabs-scroller': { overflow: 'visible !important' },
  },
  tabRoot: {
    overflow: 'visible',
  },
}));

export const FileManagementForm = () => {
  const { msalAccount: currentAccount } = useMsalAccount();

  const [rightPartTabsValue, setRightPartTabsValue] = useState('0');

  const { projectFolderDocument, projectFolderDocumentRefetch, refetchEventsList } =
    useComponentContext();

  const {
    id: documentId,
    partyReviewStatus,
    events,
    isPartyReviewRequired,
    versions,
    docusigns,
    supportFiles,
    statusFolder,
    canDocumentBeSigned,
    owners,
    containerOwners,
    legalFolderOwners,
  } = projectFolderDocument;

  const isReviewer = useCallback((): boolean => {
    return (
      !!currentAccount &&
      !!projectFolderDocument?.reviewers.find((user) => isCurrentUser(currentAccount, user))
    );
  }, [projectFolderDocument?.reviewers, currentAccount]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));

  const classesFullWidth = useStylesFullWidth(theme);
  const classesScrollable = useStylesScrollable(theme);

  const classes = matchesSm ? classesFullWidth : classesScrollable;

  const reviewers = useMemo(() => {
    if (projectFolderDocument?.reviewers.length > 0) {
      const onlyActive = projectFolderDocument?.reviewers.filter((e) => e.disabled === false);

      return onlyActive.map((item) => ({
        ...item,
        // reviewer: `${item?.type?.replace(/_/g, ' ') || ''} - ${item?.employeeName}`,
        reviewer: `${item?.employeeName}`,
      }));
    }
    return [];
  }, [projectFolderDocument?.reviewers]);

  const personas = useMemo(
    () => [
      ...(owners?.map(({ id, name }) => ({ id, persona: 'OWNER', name })) ?? []),
      ...(containerOwners?.map(({ id, name }) => ({
        id,
        persona: 'PROJECT FOLDER OWNER',
        name,
      })) ?? []),
      ...(legalFolderOwners?.map(({ id, name }) => ({
        id,
        persona: 'LEGAL FOLDER OWNER',
        name,
      })) ?? []),
    ],
    [containerOwners, legalFolderOwners, owners]
  );

  const docusignProps = useMemo(() => {
    return {
      documentId: parseInt(projectFolderDocument.id),
      versions,
      docusigns,
      refetchDocument: projectFolderDocumentRefetch,
      projectFolderDocument: projectFolderDocument,
      personas,
    };
  }, [docusigns, projectFolderDocument, projectFolderDocumentRefetch, versions, personas]);

  const activeDocuSign = useMemo(() => {
    const { docusigns } = projectFolderDocument;
    return docusigns.find(
      (docusign) =>
        docusign?.envelope?.status &&
        !['completed', 'voided', 'declined', 'deleted'].includes(docusign?.envelope?.status)
    );
  }, [projectFolderDocument]);

  const onDocusignCloseEvent = useCallback(() => {
    console.log('REFETCH DOCUMENT!!!');

    projectFolderDocumentRefetch();
  }, [projectFolderDocumentRefetch]);

  useEffect(() => {
    const messageListener = (event: any) => {
      if (
        event.origin === 'https://demo.docusign.net' ||
        event.origin === 'https://www.docusign.net'
      ) {
        if (
          event.data === 'signing_complete' ||
          event.data === 'decline' ||
          event.data === 'cancel'
        ) {
          onDocusignCloseEvent();
        }
      }
      if (event.data === 'docusign_complete' || event.data === 'x30674_end') {
        onDocusignCloseEvent();
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [onDocusignCloseEvent]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box m={5} />
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7} xl={8}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={5} style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" className="label-title">
                        Status folder:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      style={{
                        textTransform: 'capitalize',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div className={`status-folder ${statusFolder}`} />
                      <div style={{ paddingLeft: '20px' }}>{statusFolder?.toLowerCase()}</div>
                    </Grid>

                    {statusFolder === 'APPROVED' ? (
                      <Grid item xs={12}>
                        {currentAccount && (
                          <DocumentSignSection
                            account={currentAccount}
                            isSigned={false}
                            projectFolderDocument={projectFolderDocument}
                            refetchDocument={projectFolderDocumentRefetch}
                            disabled={!canDocumentBeSigned}
                          ></DocumentSignSection>
                        )}
                      </Grid>
                    ) : undefined}

                    <Grid item xs={12}>
                      <NewDocumentVersion
                        document={projectFolderDocument}
                        refetchDocument={projectFolderDocumentRefetch}
                      />
                    </Grid>

                    {isReviewer() && projectFolderDocument.canReview ? (
                      <Grid item xs={12}>
                        {currentAccount && (
                          <DocumentApprovalSection
                            account={currentAccount}
                            projectFolderDocument={projectFolderDocument}
                            refetchDocument={projectFolderDocumentRefetch}
                          />
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" className="label-title">
                    SELECT NEXT REVIEWER FOR DOCUMENT APPROVAL
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DocumentApprovalReviewer
                    projectFolderDocument={projectFolderDocument}
                    refetchDocument={projectFolderDocumentRefetch}
                    enableSubmit
                  ></DocumentApprovalReviewer>
                </Grid>

                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" className="label-title">
                    SELECT OBSERVERS TO NOTIFY
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DocumentObserversNotification
                    projectFolderDocument={projectFolderDocument}
                    refetchDocument={projectFolderDocumentRefetch}
                    enableSubmit
                  ></DocumentObserversNotification>
                </Grid>

                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>

                {!isPartyReviewRequired ? undefined : (
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className="label-title">
                          OTHER PARTY APPROVAL
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <PartyReviewStatus
                          documentId={documentId}
                          initialStatus={partyReviewStatus}
                          events={events}
                          refresh={refetchEventsList}
                        ></PartyReviewStatus>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {DOCUSIGN_ENABLED &&
                projectFolderDocument?.versions.length &&
                projectFolderDocument.versions[0].fileId ? (
                  <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <DocuSignSection
                        reviewers={reviewers}
                        documentId={projectFolderDocument.id}
                        documentVersionId={projectFolderDocument.versions[0].id}
                        documentFileId={projectFolderDocument.versions[0].fileId}
                        // account={currentAccount}
                        // isSigned={false}
                        // projectFolderDocument={projectFolderDocument}
                        refetchDocument={projectFolderDocumentRefetch}
                        // disabled={!canDocumentBeSigned}
                        documentDocusign={activeDocuSign}
                        versions={versions}
                      ></DocuSignSection>
                      <Grid item xs={12}>
                        <Box mt={2} />
                        <Divider />
                      </Grid>
                    </Grid>
                  </>
                ) : undefined}

                {matches ? (
                  <EventsLog
                    documentId={projectFolderDocument.id}
                    refetchDocument={projectFolderDocumentRefetch}
                    events={events}
                    name={projectFolderDocument.name}
                    docusignProps={docusignProps}
                    personas={personas}
                  ></EventsLog>
                ) : (
                  <Grid item xs={12}>
                    <Divider />
                    <Box m={2} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Tabs
                    value={rightPartTabsValue}
                    onChange={(e, val) => {
                      setRightPartTabsValue(val);
                    }}
                    TabIndicatorProps={{ style: { background: '#85A278' } }}
                    classes={{
                      root: classes.tabsRoot,
                    }}
                    variant={matchesSm ? 'fullWidth' : 'scrollable'}
                    scrollButtons={false}
                  >
                    <Tab
                      label={
                        <Badge
                          badgeContent={versions.length}
                          color="primary"
                          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                        >
                          <div style={{ margin: '5px 10px' }}>
                            Document {matches || !matchesSm ? <br /> : undefined} Versions
                          </div>
                        </Badge>
                      }
                      value="0"
                      classes={{
                        root: classes.tabRoot,
                      }}
                    />
                    <Tab
                      label={
                        <Badge
                          badgeContent={supportFiles.length}
                          color="primary"
                          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                        >
                          <div style={{ padding: '5px 10px' }}>
                            Support {!matchesSm ? <br /> : undefined} Docs
                          </div>
                        </Badge>
                      }
                      value="1"
                      classes={{
                        root: classes.tabRoot,
                      }}
                    />
                    {DOCUSIGN_ENABLED ? (
                      <Tab
                        label={
                          <Badge
                            badgeContent={docusigns.length}
                            color="primary"
                            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                          >
                            <div style={{ padding: '5px 10px' }}>Docusign</div>
                          </Badge>
                        }
                        value="2"
                        classes={{
                          root: classes.tabRoot,
                        }}
                      />
                    ) : undefined}
                  </Tabs>
                  <div style={{ display: rightPartTabsValue === '0' ? '' : 'none' }}>
                    <Box m={2} />
                    <FileLogs
                      documentId={parseInt(projectFolderDocument.id)}
                      versions={versions}
                      docusigns={docusigns}
                      refetchDocument={projectFolderDocumentRefetch}
                      projectFolderDocument={projectFolderDocument}
                      personas={personas}
                    />
                  </div>

                  <div style={{ display: rightPartTabsValue === '1' ? '' : 'none' }}>
                    {/* Placeholder for support documents */}
                    <Box m={2} />
                    <SupportFiles
                      documentId={parseInt(projectFolderDocument.id)}
                      supportFiles={supportFiles}
                      refetchDocument={projectFolderDocumentRefetch}
                    />
                  </div>

                  {DOCUSIGN_ENABLED ? (
                    <div style={{ display: rightPartTabsValue === '2' ? '' : 'none' }}>
                      <Box m={2} />
                      <Docusigns {...docusignProps} />
                    </div>
                  ) : undefined}
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </Grid>
            {!matches ? (
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <EventsLog
                    documentId={projectFolderDocument.id}
                    refetchDocument={projectFolderDocumentRefetch}
                    events={events}
                    docusignProps={docusignProps}
                    personas={personas}
                  ></EventsLog>
                </Grid>
              </Grid>
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>
      <Box m={5} />
    </Container>
  );
};
