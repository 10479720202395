import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';
import { Provider as TreeTableProvider } from 'template/TreeTable/TreeTableContext';
export interface IProps {
  routes: Array<any>;
}

export const TreeTableRoute: FC<IProps> = ({ routes }) => {
  return (
    <TreeTableProvider>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </TreeTableProvider>
  );
};
