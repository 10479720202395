import paths from './paths';

import {
  Login,
  LegalFolders,
  Parties,
  Documents as DocumentsComponent,
  LegalFolderId,
  LegalFolderDocumentId,
  LegalFolder,
  TreeTableRoute,
} from '../pages';

import { LegalFolderTemplate as LegalFolderComponent } from 'template/ProjectFolder/ProjectFolder';
import { NewPartie as NewPartyComponent } from 'pages/NewPartie/NewPartie';
import { LegalFolderDocumentsTemplate as LegalFolderDocumentsComponent } from 'template/LegalFolderDocuments/LegalFolderDocuments';
import { NewLegalFolderDocumentTemplate as NewLegalFolderDocumentComponent } from 'template/LegalFolderDocument/NewLegalFolderDocument';
import { DocumentView as LegalFolderDocumentComponent } from 'template/LegalFolderDocument/components/DocumentView/DocumentView';
import { FileManagement as DocumentFileManagementComponent } from 'template/LegalFolderDocument/components/FileManagement/FileManagement';

import { TreeTableHome as TreeTableHomeComponent } from 'pages/TreeTableRoute/components/TreeTableHome/TreeTableHome';
import { TreeTableNewLegalFolder as TreeTableNewLegalFolderComponent } from 'pages/TreeTableRoute/components/TreeTableNewLegalFolder/TreeTableNewLegalFolder';
import { TreeTableLegalFolder as TreeTableLegalFolderComponent } from 'pages/TreeTableRoute/components/TreeTableLegalFolder/TreeTableLegalFolder';
import { TreeTableLegalFolderProjectFolders as TreeTableLegalFolderProjectFoldersComponent } from 'pages/TreeTableRoute/components/TreeTableLegalFolderProjectFolders/TreeTableLegalFolderProjectFolders';
import { TreeTableNewProjectFolder as TreeTableNewProjectFolderComponent } from 'pages/TreeTableRoute/components/TreeTableNewProjectFolder/TreeTableNewProjectFolder';
import { TreeTableProjectFolder as TreeTableProjectFolderComponent } from 'pages/TreeTableRoute/components/TreeTableProjectFolder/TreeTableProjectFolder';
import { TreeTableNewDocument as TreeTableNewDocumentComponent } from 'pages/TreeTableRoute/components/TreeTableNewDocument/TreeTableNewDocument';
import { TreeTableDocument as TreeTableDocumentComponent } from 'pages/TreeTableRoute/components/TreeTableDocument/TreeTableDocument';
import { TreeTableDocumentFileManager as TreeTableDocumentFileManagerComponent } from 'pages/TreeTableRoute/components/TreeTableDocumentFileManager/TreeTableDocumentFileManager';

import { ClientPage as ClientPageComponent } from 'pages/Client/Client';
import { ClientDetails as ClientDetailsComponent } from 'pages/Client/components/ClientDetails/ClientDetails';
import { ClientEvents as ClientEventsComponent } from 'pages/Client/components/ClientEvents/ClientEvents';

import { ReportsPage as ReportPageComponent } from 'pages/Reports/Reports';
import { DocumentsNotSignedByAllParties as ReportDocumentsNotSignedByAllPartiesComponent } from 'pages/Reports/DocumentsNotSignedByAllParties/DocumentsNotSignedByAllParties';
import { ProjectFoldersWoProject as ReportProjectFoldersWoProjectComponent } from 'pages/Reports/ProjectFoldersWoProject/ProjectFoldersWoProject';
import { DocusignCompletePage } from 'pages/DocusignComplete/DocusignComplete';

const routes = [
  {
    path: paths.client.DOCUSIGN_COMPLETE,
    exact: true,
    component: DocusignCompletePage,
    private: true,
    guest: false,
  },
  {
    path: paths.client.PROJECT_FOLDERS,
    exact: true,
    component: LegalFolders,
    private: true,
    guest: false,
  },
  {
    path: paths.client.PROJECT_FOLDER,
    exact: false,
    component: LegalFolder,
    private: true,
    routes: [
      {
        path: paths.client.PROJECT_FOLDER,
        exact: true,
        component: LegalFolderComponent,
        private: true,
      },
      {
        path: paths.client.PROJECT_FOLDER_PARTY,
        exact: true,
        component: NewPartyComponent,
        private: true,
      },
    ],
  },
  {
    path: paths.client.PROJECT_FOLDER_ID,
    exact: false,
    component: LegalFolderId,
    private: true,
    routes: [
      {
        path: paths.client.PROJECT_FOLDER_ID,
        exact: true,
        component: LegalFolderComponent,
        private: true,
      },
      {
        path: paths.client.PROJECT_FOLDER_ID_PARTY,
        exact: true,
        component: NewPartyComponent,
        private: true,
      },
      {
        path: paths.client.PROJECT_FOLDER_ID_DOCUMENTS,
        exact: true,
        component: LegalFolderDocumentsComponent,
        private: true,
      },
      {
        path: paths.client.PROJECT_FOLDER_ID_DOCUMENT,
        exact: true,
        component: NewLegalFolderDocumentComponent,
        private: true,
      },
    ],
  },
  {
    path: paths.client.PROJECT_FOLDER_DOCUMENT_ID,
    exact: false,
    component: LegalFolderDocumentId,
    private: true,
    routes: [
      {
        path: paths.client.PROJECT_FOLDER_DOCUMENT_ID,
        exact: true,
        component: LegalFolderDocumentComponent,
        private: true,
      },
      {
        path: paths.client.PROJECT_FOLDER_DOCUMENT_ID_FILE_MANAGEMENT,
        exact: true,
        component: DocumentFileManagementComponent,
        private: true,
      },
    ],
  },
  {
    path: paths.client.PARTIES,
    exact: true,
    component: Parties,
    private: true,
  },
  {
    path: paths.client.DOCUMENTS,
    exact: true,
    component: DocumentsComponent,
    private: true,
  },
  {
    path: paths.client.NEW_PARTY,
    exact: true,
    component: NewPartyComponent,
    private: true,
  },
  {
    path: paths.client.CLIENT_PAGES,
    exact: false,
    component: ClientPageComponent,
    private: true,
    guest: false,
    routes: [
      {
        path: paths.client.CLIENT_DETAILS,
        exact: true,
        component: ClientDetailsComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.client.CLIENT_EVENTS,
        exact: true,
        component: ClientEventsComponent,
        private: true,
        guest: false,
      },
    ],
  },
  {
    path: paths.client.LOGIN,
    exact: true,
    component: Login,
    guest: true,
  },

  {
    path: paths.treeViewClient.TREEVIEW_HOME,
    exact: false,
    component: TreeTableRoute,
    private: true,
    guest: false,
    routes: [
      {
        path: paths.treeViewClient.TREEVIEW_HOME,
        exact: true,
        component: TreeTableHomeComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_NEW,
        exact: true,
        component: TreeTableNewLegalFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDER_NEW,
        exact: true,
        component: TreeTableNewProjectFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_TAB,
        exact: true,
        component: TreeTableLegalFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS,
        exact: true,
        component: TreeTableLegalFolderProjectFoldersComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER,
        exact: true,
        component: TreeTableLegalFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_DOCUMENT_NEW,
        exact: true,
        component: TreeTableNewDocumentComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB,
        exact: true,
        component: TreeTableProjectFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_PROJECT_FOLDER,
        exact: true,
        component: TreeTableProjectFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_DOCUMENT,
        exact: true,
        component: TreeTableDocumentComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_DOCUMENT_FM,
        exact: true,
        component: TreeTableDocumentFileManagerComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.client.REPORTS,
        exact: false,
        component: ReportPageComponent,
        private: true,
        guest: false,
        routes: [
          {
            path: paths.client.REPORT_DOCUMENTS_NOT_SIGNED_BY_ALL_PARTIES,
            exact: true,
            component: ReportDocumentsNotSignedByAllPartiesComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.REPORT_PROJECT_FOLDERS_WO_PROJECTS,
            exact: true,
            component: ReportProjectFoldersWoProjectComponent,
            private: true,
            guest: false,
          },
        ],
      },
    ],
  },
];

export default routes;
