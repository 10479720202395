import { Grid, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { Delete, DragIndicator, ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { FC, useMemo, useState } from 'react';
import { IProposal } from '../../interface';
import s from './style.module.scss';
import { EditDocument } from 'components/ui/EditDocument/EditDocument';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export interface IProposalTileProps {
  proposal: IProposal;
  index: number;
  onRemoveProposal: () => void;
  onViewProposal: () => void;
}
export const ProposalTile: FC<IProposalTileProps> = ({
  proposal,
  index,
  onRemoveProposal,
  onViewProposal,
}) => {
  const { name } = proposal;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const nameWithoutMultipleSpaces = useMemo(() => name.replace(/\s+/g, ' '), [name]);
  const edit = {
    onClick: () => {
      onViewProposal && onViewProposal();
    },
    tooltip: 'View Proposal in Proposals & Opportunities Application',
  };

  return (
    <Grid item xs={12} className={s.contactBox}>
      <Grid container spacing={2} alignContent="center" alignItems="center">
        {matches ? (
          <Grid item xs={1}>
            <DragIndicator fontSize="small" style={{ display: 'none' }} />
          </Grid>
        ) : undefined}

        <Grid item xs={matches ? 8 : 8} md={matches ? 8 : 6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={nameWithoutMultipleSpaces}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={matches ? 2 : 3}
          style={{
            display: 'flex',
          }}
        >
          <div style={{ paddingRight: '5px' }}>
            <EditDocument {...edit} />
          </div>
          <div style={{ paddingRight: '5px' }}>
            <Tooltip title="Remove Proposal">
              <IconButton
                size="small"
                onClick={debounce(onRemoveProposal, DEBOUNCE_TIMEOUT)}
                className={classes.hoverWarning}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div
            onClick={debounce(() => setExpanded(!expanded), DEBOUNCE_TIMEOUT)}
            style={{ cursor: 'pointer' }}
          >
            {expanded ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
          </div>
        </Grid>

        {expanded ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {matches ? <Grid item xs={1}></Grid> : undefined}
              <Grid xs={3} md={2} lg={2} item>
                {proposal.owners?.length && proposal.owners?.length > 1 ? 'Owners' : 'Owner'}:
              </Grid>
              <Grid item xs={7} md={7} lg={8}>
                {proposal.owners?.length ? (
                  proposal.owners?.map((owner) => <div>{owner.user?.name}</div>)
                ) : (
                  <div>--</div>
                )}
              </Grid>
              <Grid item xs={2} md={2} lg={1}></Grid>
              {matches ? <Grid item xs={1}></Grid> : undefined}
              <Grid xs={3} md={2} lg={2} item>
                Lead:
              </Grid>
              <Grid item xs={7} md={7} lg={8}>
                {proposal.leadUser ? proposal.leadUser.user?.name : '--'}
              </Grid>
              <Grid item xs={2} md={2} lg={1}></Grid>
            </Grid>
          </Grid>
        ) : undefined}
      </Grid>
    </Grid>
  );
};
