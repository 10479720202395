import React, { FC } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Provider as LegalFolderDocumentContextProvider } from 'template/LegalFolderDocument/LegalFolderDocumentContext';

import { useQuery } from '@apollo/client';
import { GET_PROJECT_FOLDER } from 'graphql/legalFolders/legalFolders';
import { projectFolder } from 'graphql/legalFolders/types/projectFolder';
import { useComponentContext } from '../../DocumentsContext';
import { DocumentViewForm } from 'template/LegalFolderDocument/components/DocumentView/components/DocumentViewForm/DocumentViewForm';
import { useComponentContext as useTreeComponentContext } from '../../../../../../TreeTableContext';

export const NewDocument: FC = () => {
  const { projectFolderId } = useComponentContext();
  const { onNewProjectDocumentCreated, onSelectProjectDocument } = useTreeComponentContext();

  const { data, loading } = useQuery<projectFolder>(GET_PROJECT_FOLDER, {
    variables: {
      id: projectFolderId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <LegalFolderDocumentContextProvider
        loadedLegalFolder={data?.contract_container}
        onCreated={onNewProjectDocumentCreated}
        onSelectDocument={onSelectProjectDocument}
      >
        <DocumentViewForm></DocumentViewForm>
      </LegalFolderDocumentContextProvider>
    </LoadingOverlay>
  );
};
