import { paths } from 'constants/index';
import React, { createContext, FC, useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ILegalFoldersList } from './components/interface';
import { DocumentViewTab } from './components/TableSection/components/DocumentsView/DocumentsContext';
import { ActiveTab } from './components/TableSection/TableSectionContext';
import { useLegalFolderTree } from './treeHooks';
import { IProjectFoldersList } from './components/interface';
import { ProjectFoldersViewTab } from './components/TableSection/components/ProjectFoldersView/ProjectFoldersContext';
import { useComponentContext as useMyTask } from 'contexts/MyTask';
import { useComponentContext as useLegalFoldersTreeContext } from 'contexts/LegalFoldersTree';

export interface IContextState {
  search?: string;
  myTask: boolean;
  tree?: ILegalFoldersList;
  selectedFolder?: ISelectedFolder;
  loading?: boolean;
  initTab?: ActiveTab;
  initDocumentTab?: DocumentViewTab;
  initProjectFoldersTab?: ProjectFoldersViewTab;
  initLegalFolderTab?: LegalFolderViewTab;
  showWellcome: boolean;
  treeProjectFolders?: ITreeProjectFoldersLists;
}

export interface IContextActions {
  setSearch: any;
  setMyTask: (value: boolean) => void;
  onToggleLegalFolder: (legalFolderId: string) => void;
  onSelectLegalFolder: (legalFolderId: string) => void;
  onGoHome: () => void;
  onUnselectLegalFolder: () => void;
  onUnselectProjectFolder: () => void;
  onNewProjectFolder: () => void;
  onSelectProjectFolder: (legalFolderId: string, projectFolderId: string) => void;
  onViewProjectFolder: (legalFolderId: string, projectFolderId: string) => void;
  loadMore: any;
  setActiveTab: (newTab: ActiveTab) => void;
  setDocumentViewTab: (newTab: DocumentViewTab) => void;
  setProjectFoldersViewTab: (newTab: ProjectFoldersViewTab) => void;
  onNewLegalFolderCreated: (legalFolderId: string) => void;
  onNewProjectFolderCreated: (projectFolderId: string) => void;
  onProjectFolderUpdated: (projectFolderId: string) => void;
  onNewProjectDocumentCreated: (projectDocumentId: string) => void;
  onNewProjectDocument: () => void;
  onSelectProjectDocument: (projectDocumentId: string | undefined) => void;
  initPage: (props: IProviderInitPage) => void;
  setTreeProjectFolders: React.Dispatch<React.SetStateAction<ITreeProjectFoldersLists | undefined>>;
}

export interface ISelectedFolder {
  legalFolderId: string;
  projectFolderId?: string;
  projectDocumentId?: string;
  newProjectDocumentTs?: number;
}

const initialState = {
  search: '',
  showWellcome: true,
  myTask: true,
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface ITreeProjectFoldersLists {
  [id: string]: IProjectFoldersList;
}

export type LegalFolderViewTab = 'new' | 'edit-info';
interface IProviderInitPage {
  wellcomeProp?: boolean;
  initTabProp?: ActiveTab;
  selectedFolderProp?: ISelectedFolder;
  documentTabProp?: DocumentViewTab;
  projectFoldersTabProp?: ProjectFoldersViewTab;
  legalFolderTabProp?: LegalFolderViewTab;
}

interface ITreeTableContextProps {
  children: any;
}

export const Provider: FC<ITreeTableContextProps> = ({ children }) => {
  const { myTask, setMyTask } = useMyTask();
  const { legalFoldersOrderBy, legalFoldersOrderDirection } = useLegalFoldersTreeContext();
  const [search, setSearch] = useState('');
  const [selectedFolder, setSelectedFolder] = useState<ISelectedFolder | undefined>();
  const [initTab, setInitTab] = useState<ActiveTab>();
  const [initDocumentTab, setInitDocumentTab] = useState<DocumentViewTab>();
  const [initProjectFoldersTab, setInitProjectFoldersTab] = useState<ProjectFoldersViewTab>();

  const [initLegalFolderTab, setInitLegalFolderTab] = useState<LegalFolderViewTab | undefined>();
  const [showWellcome, setShowWellcome] = useState(true);
  const [treeProjectFolders, setTreeProjectFolders] = useState<ITreeProjectFoldersLists>();

  const history = useHistory();

  const { tree, toggle, toggleTo, loadMore, loading, initTree } = useLegalFolderTree({
    search,
    myTask,
    selectedLegalFolderId: selectedFolder?.legalFolderId,
    orderBy: legalFoldersOrderBy,
    orderDirection: legalFoldersOrderDirection,
  });

  useEffect(() => {
    setTreeProjectFolders({});
  }, [tree.ts]);

  useEffect(() => {
    if (selectedFolder?.legalFolderId) {
      setShowWellcome(false);
    }
  }, [selectedFolder?.legalFolderId]);

  const onGoHome = useCallback(() => {
    // setSelectedFolder(undefined);
    // setInitTab('D');
    setSearch('');
    initTree();
    // setShowWellcome(true);
    history.push(paths.treeViewClient.TREEVIEW_HOME);
  }, [initTree, history]);

  const onToggleLegalFolder = useCallback(
    (legalFolderId: string) => {
      toggle(legalFolderId);
    },
    [toggle]
  );

  const onSelectLegalFolder = useCallback(
    (legalFolderId: string) => {
      toggleTo(legalFolderId, true);
      history.push(
        paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_TAB.replace(':id', legalFolderId).replace(
          ':tab',
          'lf'
        )
      );
      // setSelectedFolder({
      //   legalFolderId: legalFolderId,
      //   projectFolderId: undefined,
      // });
      // setInitTab('LF');
      // toggleTo(legalFolderId, true);
    },
    [toggleTo, history]
  );

  const onUnselectLegalFolder = useCallback(() => {
    history.push(paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_NEW);
    // setSelectedFolder(undefined);
    // setInitTab('LF');
    // setShowWellcome(false);
  }, [history]);

  const onUnselectProjectFolder = useCallback(() => {
    if (selectedFolder) {
      const { legalFolderId } = selectedFolder;
      history.push(
        paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS.replace(':id', legalFolderId)
      );
    }
    // setSelectedFolder((selectedFolder) => {
    //   if (!selectedFolder) return undefined;
    //   const { legalFolderId } = selectedFolder;
    //   return { legalFolderId, projectFolderId: undefined };
    // });
    // setInitTab('PF');
  }, [history, selectedFolder]);

  const onNewProjectFolder = useCallback(() => {
    if (selectedFolder) {
      const { legalFolderId } = selectedFolder;
      history.push(
        paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDER_NEW.replace(':id', legalFolderId)
      );
    }
  }, [history, selectedFolder]);

  const onSelectProjectFolder = useCallback(
    (legalFolderId: string, projectFolderId: string) => {
      history.push(
        paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB.replace(':id', projectFolderId).replace(
          ':tab',
          'd'
        )
      );
    },
    [history]
  );

  const onNewProjectDocument = useCallback(() => {
    if (selectedFolder?.projectFolderId) {
      const { projectFolderId } = selectedFolder;
      const url = paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_DOCUMENT_NEW.replace(
        ':id',
        projectFolderId
      );
      history.push(url);
    }
  }, [history, selectedFolder]);

  const onSelectProjectDocument = useCallback(
    (projectDocumentId: string | undefined) => {
      if (projectDocumentId) {
        const url = paths.treeViewClient.TREEVIEW_DOCUMENT.replace(':id', projectDocumentId);
        history.push(url);
      } else {
        onNewProjectDocument();
      }
    },
    [history, onNewProjectDocument]
  );

  const onViewProjectFolder = useCallback(
    (legalFolderId: string, projectFolderId: string) => {
      // setSelectedFolder({
      //   legalFolderId: legalFolderId,
      //   projectFolderId: projectFolderId,
      // });
      setInitTab('PF');
      history.push(paths.treeViewClient.TREEVIEW_PROJECT_FOLDER.replace(':id', projectFolderId));
    },
    [history]
  );

  const onNewLegalFolderCreated = useCallback(
    (legalFolderId: string) => {
      onSelectLegalFolder(legalFolderId);
    },
    [onSelectLegalFolder]
  );

  // const onProjectFolderUpdated = useCallback(
  //   (projectFolderId: string) => {
  //     if (selectedFolder) {
  //       const { legalFolderId } = selectedFolder;
  //       setTreeProjectFolders((pfLists) => omit(pfLists, legalFolderId));
  //     }
  //   },
  //   [selectedFolder]
  // );

  const onNewProjectFolderCreated = useCallback(
    (projectFolderId: string) => {
      if (selectedFolder) {
        const { legalFolderId } = selectedFolder;
        // setTreeProjectFolders((pfLists) => omit(pfLists, legalFolderId));
        onViewProjectFolder(legalFolderId, projectFolderId);
      }
    },
    [selectedFolder, onViewProjectFolder]
  );

  const onNewProjectDocumentCreated = useCallback(
    (projectDocumentId: string) => {
      onSelectProjectDocument(projectDocumentId);
    },
    [onSelectProjectDocument]
  );

  const setActiveTab = useCallback(
    (newTab: ActiveTab) => {
      if (initTab === newTab && newTab === 'PF') {
        setInitProjectFoldersTab((old) => {
          if (old === 'new') {
            return 'list-pf';
          }
          return old;
        });
      }

      if (initTab !== newTab) {
        // setInitTab(newTab);
        if (newTab === 'D') {
          setInitDocumentTab((old) => {
            if (old === 'new') {
              return 'list';
            }
            return old;
          });
        }

        if (!selectedFolder || !selectedFolder.legalFolderId) {
          history.push(paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_NEW);
          return;
        }

        const { legalFolderId, projectFolderId } = selectedFolder;
        if (!projectFolderId) {
          if (newTab === 'PF') {
            history.push(
              paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS.replace(
                ':id',
                legalFolderId
              )
            );
          } else {
            const url = paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_TAB.replace(
              ':id',
              legalFolderId
            ).replace(':tab', newTab.toLowerCase());
            history.push(url);
          }
        } else {
          history.push(
            paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB.replace(
              ':id',
              projectFolderId
            ).replace(':tab', newTab.toLowerCase())
          );
        }
      }
    },
    [history, selectedFolder, initTab]
  );

  const setDocumentViewTab = useCallback((newTab: DocumentViewTab) => {
    setInitDocumentTab(newTab);
  }, []);

  const setProjectFoldersViewTab = useCallback((newTab: ProjectFoldersViewTab) => {
    setInitProjectFoldersTab(newTab);
  }, []);

  const setLegalFolderViewTab = useCallback((newTab: LegalFolderViewTab | undefined) => {
    setInitLegalFolderTab(newTab);
  }, []);

  const initPage = useCallback(
    ({
      wellcomeProp,
      initTabProp,
      selectedFolderProp,
      documentTabProp,
      projectFoldersTabProp,
      legalFolderTabProp,
    }: IProviderInitPage) => {
      if (selectedFolderProp) {
        if (
          !selectedFolder ||
          JSON.stringify(selectedFolder) !== JSON.stringify(selectedFolderProp)
        ) {
          setSelectedFolder(selectedFolderProp);
        }
      } else {
        if (selectedFolder) {
          setSelectedFolder(undefined);
          setSearch('');
          initTree();
        }
      }
      if (wellcomeProp !== undefined) {
        setShowWellcome(wellcomeProp);
      }
      setInitTab(initTabProp);
      if (documentTabProp) {
        setDocumentViewTab(documentTabProp);
      }
      if (projectFoldersTabProp) {
        setProjectFoldersViewTab(projectFoldersTabProp);
      }
      if (legalFolderTabProp) {
        setLegalFolderViewTab(legalFolderTabProp);
      } else {
        setLegalFolderViewTab(undefined);
      }
    },
    [selectedFolder, initTree, setDocumentViewTab, setProjectFoldersViewTab, setLegalFolderViewTab]
  );

  return (
    <ComponentContext.Provider
      value={{
        tree,
        search,
        setSearch,
        onToggleLegalFolder,
        onSelectLegalFolder,
        onUnselectLegalFolder,
        onSelectProjectFolder,
        onUnselectProjectFolder,
        onNewProjectFolder,
        onSelectProjectDocument,
        onViewProjectFolder,
        onNewProjectDocument,
        loadMore,
        selectedFolder,
        loading,
        initTab,
        initDocumentTab,
        initProjectFoldersTab,
        initLegalFolderTab,
        setActiveTab,
        setDocumentViewTab,
        setProjectFoldersViewTab,
        onNewLegalFolderCreated,
        onNewProjectFolderCreated,
        onNewProjectDocumentCreated,
        // onProjectFolderUpdated,
        onGoHome,
        showWellcome,
        initPage,
        treeProjectFolders,
        setTreeProjectFolders,
        myTask,
        setMyTask,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
