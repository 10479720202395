import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { projectFolder } from 'graphql/legalFolders/types/projectFolder';
import LoadingOverlay from 'react-loading-overlay-ts';
import { GET_PROJECT_FOLDER } from 'graphql/legalFolders/legalFolders';
import { Provider as LegalFolderProvider } from 'template/ProjectFolder/ProjectFolderContext';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';
interface ILegalFolderUrlParams {
  id: string;
}

export interface ILegalFolderIdProps {
  routes: Array<any>;
}

export const LegalFolderId: FC<ILegalFolderIdProps> = ({ routes }) => {
  const { id } = useParams<ILegalFolderUrlParams>();

  const { data, loading, refetch } = useQuery<projectFolder>(GET_PROJECT_FOLDER, {
    variables: {
      id,
    },
  });

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <LegalFolderProvider
        loadedProjectFolder={data?.contract_container}
        refetch={refetch}
        legalFolderId={id}
      >
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} projectFolderId={id} />
          ))}
        </Switch>
      </LegalFolderProvider>
    </LoadingOverlay>
  );
};
