import { useEffect } from 'react';
import { FC } from 'react';

export interface IAllDocumentsTablePrintingMetaProps {
  onReady: () => void;
}
export const AllDocumentsTablePrintingMeta: FC<IAllDocumentsTablePrintingMetaProps> = ({
  onReady,
}) => {
  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <div>
      <div>{window.location.href}</div>
    </div>
  );
};
