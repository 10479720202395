import { Grid, Link, Typography } from '@mui/material';
import { WaterfallChart as WaterfallChartIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { FC, useState } from 'react';
import { EventCommentLog } from '../../../EventCommentLogs';
import { EventCommentSubmit } from '../../../EventCommentSubmit/EventCommentSubmit';
import { DialogForm } from 'components/DialogForm/DialogForm';
import { GanttChart } from 'components/GanttChart/GanttChart';
import { useGanttEvents } from './hooks/ganttEvents';
import { DocusignsProps } from '../../../Docusigns/interfaces';

const useStyles = makeStyles({
  iconRotate: {
    transform: 'rotate(-90deg)',
  },
});

export interface IEventsLog {
  documentId: string;
  refetchDocument: any;
  events: any;
  name?: string | null;
  docusignProps: DocusignsProps;
  personas: { id: string; name: string; persona: string }[];
}

export const EventsLog: FC<IEventsLog> = ({
  documentId,
  refetchDocument,
  events,
  name,
  docusignProps,
  personas,
}) => {
  const classes = useStyles();
  const [openGantt, setOpenGantt] = useState(false);
  const { tasks } = useGanttEvents(events, docusignProps, personas);

  return (
    <>
      <DialogForm
        dialogTitle={`Document #ID${documentId} - ${name} - Progress Chart`}
        onClose={() => setOpenGantt(false)}
        open={openGantt}
        maxWidth="xl"
      >
        <div className="dialogGanttChart" style={{ padding: '0 10px 0 10px' }}>
          <GanttChart tasks={tasks} />
        </div>
      </DialogForm>
      <Grid item xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            component="button"
            onClick={() => {
              setOpenGantt(true);
            }}
          >
            <div style={{ border: '1px solid lightblue', marginRight: '9px' }}>
              <WaterfallChartIcon className={classes.iconRotate} />
            </div>
          </Link>
          Document Progress Chart
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className="label-title-nocase">
          Events/comments log
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: 'flex', gap: '2em', width: '85%' }}>
          <EventCommentSubmit
            documentId={documentId}
            refetchDocument={refetchDocument}
          ></EventCommentSubmit>
        </div>
      </Grid>
      <Grid item xs={12}>
        <EventCommentLog
          events={events}
          documentArchived={false}
          documentId={documentId}
          refetchDocument={refetchDocument}
          personas={personas}
        ></EventCommentLog>
      </Grid>
    </>
  );
};
