import { gql } from '@apollo/client';

const user = `
  id
  name
  email
  discipline
`;

const division = `
  id
  name
  manager {
    ${user}
  }
`;

const project = `
id
key
name
predecessor
countries {
  id
  code
  name
}
divisions {
  ${division}
}
owner {
  ${division}
}
leader {
  ${user}
}
coordinator {
  ${user}
}
technicianCoordinator {
  ${user}
}
secondaryTechnicianCoordinator {
  ${user}
}
`;

const queries = {
  GET_PROJECTS_USERS: gql`
    query GetProjectUsers($take: Int, $filter: ProjectFilter, $userFilter: UserFilter) {
      projects(take: $take, filter: $filter) {
        ${project}
      }
      
      users(take: $take, filter: $userFilter) {
        id
        name
        email
        discipline
        division {
          id
          name
        }
      }

      personas {
        name
        isUsersFiltered
      }

    }
  `,
};

const componentsGql = { queries };

export default componentsGql;
