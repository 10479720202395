import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId, startCase, toLower } from 'lodash';

import { EventCommentLogProps } from './interfaces';

import { IEvent } from 'template/Party/PartyContext';
import { PartyEventType } from 'constants/enums';
import { UserInitials } from 'components';
import { documentStatusColor } from 'utils/colors';
import { formatTime } from 'utils/time';
import s from './style.module.scss';

import { usePayloadConvert } from './hook/payloadConvertHook';
import LoadingOverlay from 'react-loading-overlay-ts';
import { EventCommentReplySubmit } from 'template/Party/components/EventCommentReplySubmit/EventCommentReplySubmit';

const changeReportStatusName = (status: string): string => {
  return status
    .replace(/Archived/gi, 'Retired')
    .replace(/Archive/gi, 'Retire')
    .replace(/Report/gi, 'Document')
    .replace(/Party/gi, 'Client');
};

export const EventCommentLog: FC<EventCommentLogProps> = ({
  events = [],
  documentArchived,
  partyId,
  refetchParty,
}: EventCommentLogProps) => {
  const { payloadConvert, loading: convertDataLoading } = usePayloadConvert({ events });
  const showCommentRef = useRef<any>();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const commentByEvent = (val: IEvent): JSX.Element => {
    let text;
    if (val?.payload) {
      try {
        text = JSON.parse(val?.payload).comment;
      } catch {
        console.log('Payload is not in JSON format');
      }
    }

    return (
      <>
        {text && '.'}
        {text && (
          <>
            <br />
            {text}
          </>
        )}
      </>
    );
  };

  const eventType = (val: IEvent): JSX.Element => (
    <span style={{ color: documentStatusColor(val) }}>
      {changeReportStatusName(startCase(toLower(val?.eventType)))}
    </span>
  );

  // const content = (val: IEvent): JSX.Element => {
  //   return <div>{val.payload}</div>;
  // };

  const partyCreated = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const parsedPayload = payloadConvert(payload);

      return (
        <div>
          <div>Party Created</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvert]
  );

  const partyUpdated = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const parsedPayload = payloadConvert(payload);

      return (
        <div>
          <div>Party Information Updated</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvert]
  );

  const partyCommented: (val: IEvent) => JSX.Element = useCallback((val: IEvent): JSX.Element => {
    let text;
    let quoteEventId;
    if (val.payload) {
      try {
        text = JSON.parse(val.payload).comment;
        quoteEventId = JSON.parse(val.payload).refEventId;
      } catch {}
    }
    return showCommentRef.current && showCommentRef.current(val, text, quoteEventId);
  }, []);

  const partyContactCreated = (val: IEvent): JSX.Element => {
    const payload = val.payload ? JSON.parse(val.payload) : {};

    return (
      <div>
        <div>Party Contact Created</div>
        <div style={{ width: '85%' }}>
          {payload.name ? (
            <div style={{ wordBreak: 'keep-all' }}>Name: {payload.name || '--'}</div>
          ) : undefined}
          {payload.lastName ? (
            <div style={{ wordBreak: 'keep-all' }}>First Name: {payload.lastName || '--'}</div>
          ) : undefined}
          {payload.firstName ? (
            <div style={{ wordBreak: 'keep-all' }}>Last Name: {payload.firstName || '--'}</div>
          ) : undefined}
          {payload.jobTitle ? (
            <div style={{ wordBreak: 'keep-all' }}>Job Title: {payload.jobTitle || '--'}</div>
          ) : undefined}
          {payload.email ? (
            <div style={{ wordBreak: 'keep-all' }}>Email: {payload.email || '--'}</div>
          ) : undefined}
          {payload.phone ? (
            <div style={{ wordBreak: 'keep-all' }}>Phone Number: {payload.phone || '--'}</div>
          ) : undefined}
          {payload.linkedInUrl ? (
            <div style={{ wordBreak: 'keep-all' }}>LinkedIn URL: {payload.linkedInUrl || '--'}</div>
          ) : undefined}
          <div style={{ wordBreak: 'keep-all' }}>
            Active Contact: {!!payload.isActive ? 'true' : 'false'}
          </div>
        </div>
      </div>
    );
  };

  const partyContactUpdated = useCallback((val: IEvent): JSX.Element => {
    const payload = val.payload ? JSON.parse(val.payload) : {};

    return (
      <div>
        <div>Party Contact Updated</div>
        <div style={{ width: '85%' }}>
          <div style={{ wordBreak: 'keep-all' }}>Name: {payload.name}</div>
          <div style={{ wordBreak: 'keep-all' }}>Job Title: {payload.jobTitle}</div>
          <div style={{ wordBreak: 'keep-all' }}>Email: {payload.email}</div>
          <div style={{ wordBreak: 'keep-all' }}>Phone Number: {payload.phone}</div>
          <div style={{ wordBreak: 'keep-all' }}>LinkedIn URL: {payload.linkedInUrl}</div>
          <div style={{ wordBreak: 'keep-all' }}>
            Active Contact: {!!payload.isActive ? 'true' : 'false'}
          </div>
        </div>
      </div>
    );
  }, []);

  const partyContactDeleted = useCallback((val: IEvent): JSX.Element => {
    const payload = val.payload ? JSON.parse(val.payload) : {};

    return (
      <div>
        <div>Client Contact Deleted: #ID{payload.id}</div>
      </div>
    );
  }, []);

  const partyStockExchangesChanged = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const parsedPayload = payloadConvert(payload);

      return (
        <div>
          <div>Stock Exchanges Changed</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvert]
  );

  const showDefaultLog = useCallback(
    (val: IEvent): JSX.Element => (
      <div className={s.eventLogItem}>
        {eventType(val)}
        {commentByEvent(val)}
      </div>
    ),
    []
  );

  const content = useCallback(
    (val: IEvent): JSX.Element => {
      switch (val?.eventType) {
        case PartyEventType.PARTY_COMMENTED:
        case PartyEventType.PARTY_COMMENT_REPLIED:
        case PartyEventType.PARTY_COMMENT:
          return partyCommented(val);

        case PartyEventType.PARTY_CREATED:
          return partyCreated(val);

        case PartyEventType.PARTY_UPDATED:
          return partyUpdated(val);

        case PartyEventType.PARTY_CONTACT_DELETED:
          return partyContactDeleted(val);

        case PartyEventType.PARTY_CONTACT_UPDATED:
          return partyContactUpdated(val);

        case PartyEventType.PARTY_CONTACT_CREATED:
          return partyContactCreated(val);

        case PartyEventType.PARTY_STOCK_EXCHANGES_CHANGED:
          return partyStockExchangesChanged(val);

        default:
          return showDefaultLog(val);
      }
    },
    [
      partyContactDeleted,
      partyContactUpdated,
      partyCommented,
      partyCreated,
      partyStockExchangesChanged,
      partyUpdated,
      showDefaultLog,
    ]
  );

  const filterValues = useCallback((): IEvent[] => {
    const result = events;
    return result;
  }, [events]);

  const showEvent = useCallback(
    (data: any, allowComment: boolean) => {
      // let jsonPayload;
      // if (data?.payload) {
      //   try {
      //     jsonPayload = JSON.parse(data?.payload);
      //   } catch {}
      // }

      return (
        <Grid xs={12} key={uniqueId()}>
          <div style={{ display: 'flex', gap: '2em', width: '85%' }}>
            <div>
              <UserInitials {...data?.author} />
            </div>
            <div style={{ flexGrow: 2, display: 'flex', gap: '0.5em', flexDirection: 'column' }}>
              <div className="event-comment-log-header">
                <span className="event-comment-log-header-title">
                  <strong>
                    {user(data)}
                    {/* &nbsp;-&nbsp;
                      {getVersion(data)} */}
                  </strong>
                </span>
                <span className="event-comment-log-header-date">
                  {isSmall ? <br /> : undefined}
                  {formatTime(data?.createdAt)}
                </span>
              </div>

              <div>{content(data)}</div>

              {allowComment && (
                <div style={{ padding: '15px 0' }}>
                  <EventCommentReplySubmit
                    partyId={partyId}
                    refetchParty={refetchParty}
                    commentOnEventId={data.id}
                  ></EventCommentReplySubmit>
                </div>
              )}
            </div>
          </div>
        </Grid>
      );
    },
    [content, isSmall, partyId, refetchParty]
  );

  const showComment = useCallback(
    (val: IEvent, text: string, quoteEventId?: string) => {
      return (
        <div>
          <div
            className={s.eventLogItem}
            style={{
              border: '1px solid #ccc',
              padding: '11px 10px',
              borderRadius: '3px',
              width: '100%',
            }}
          >
            {quoteEventId ? (
              <>
                Quote:
                <div
                  className={s.eventLogItem}
                  style={{
                    border: '1px solid #ccc',
                    padding: '11px 10px',
                    borderRadius: '3px',
                    width: '100%',
                    backgroundColor: '#EEEEEE',
                    margin: '0 0 1em 0',
                  }}
                >
                  {showEvent(
                    events.find((event) => event.id === quoteEventId),
                    false
                  )}
                </div>
              </>
            ) : undefined}
            {text}
          </div>
        </div>
      );
    },
    [events, showEvent]
  );

  useEffect(() => {
    showCommentRef.current = showComment;
  }, [showComment]);

  const user = (data: any): string => (data?.author ? `${data?.author?.name}` : 'Undefined Author');

  return (
    <LoadingOverlay
      spinner
      active={convertDataLoading && !!events.length}
      text="Loading your content..."
    >
      <Grid container padding={0} spacing={2}>
        {filterValues()
          .slice()
          .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
          .map((event) =>
            showEvent(
              event,
              [PartyEventType.PARTY_COMMENTED, PartyEventType.PARTY_COMMENT_REPLIED].includes(
                event?.eventType as PartyEventType
              )
            )
          )}
      </Grid>
    </LoadingOverlay>
  );
};
