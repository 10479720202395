import { Grid, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Delete, DragIndicator } from '@mui/icons-material';
import React, { FC } from 'react';
import { SelectOneParty } from 'template/LegalFolder/components/SelectParty/SelectOneParty';
import s from './style.module.scss';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export interface ISelectPartyTileProps {
  onAddParty: (id: string) => void;
  onRemoveParty: () => void;
  error?: boolean;
  usedPartyIds: string[];
}

export const SelectPartyTile: FC<ISelectPartyTileProps> = ({
  onAddParty,
  onRemoveParty,
  error,
  usedPartyIds,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid item xs={12} className={s.contactBox}>
      <Grid container spacing={2} alignContent="center" alignItems="center">
        <Grid item xs={1}>
          <DragIndicator fontSize="small" style={{ display: 'none' }} />
        </Grid>
        {matches ? (
          <Grid xs={3} md={2} item>
            Name:
          </Grid>
        ) : undefined}
        <Grid item xs={matches ? 6 : 8} md={7}>
          <SelectOneParty
            onSelectChange={onAddParty}
            error={error}
            usedPartyIds={usedPartyIds}
          ></SelectOneParty>
        </Grid>
        <Grid
          item
          xs={matches ? 2 : 3}
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}
        >
          <Tooltip title="Remove Other Party">
            <IconButton
              size="small"
              onClick={debounce(onRemoveParty, DEBOUNCE_TIMEOUT)}
              className={classes.hoverWarning}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
