import { useLazyQuery } from '@apollo/client';
import { useMsalAccount } from 'hooks/msalAccount';
import { usersList } from 'graphql/legalFolders/types/usersList';
import { GET_USERS } from 'graphql/legalFolders/users';
import { useCallback, useEffect, useState } from 'react';

export interface ICurrentUser {
  id: string;
  oid: string;
  email: string;
  name: string;
}

const maxItems = 10;
export const useCurrentUser = () => {
  const [users, setUsers] = useState<ICurrentUser[]>();
  const { msalAccount: currentAccount } = useMsalAccount();

  const [
    loadUsers,
    {
      data: dataUsers,
      loading: loadingUsers,
      refetch: refetchUsers,
      called: calledUsers,
      error: errorUsers,
    },
  ] = useLazyQuery<usersList>(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (dataUsers?.users && !loadingUsers && currentAccount) {
      const { accountIdentifier: oid, userName: email } = currentAccount;
      setUsers(dataUsers.users.map(({ name, id }) => ({ id, name, oid, email })));
    }
  }, [dataUsers, loadingUsers, currentAccount]);

  const load = useCallback(() => {
    const { accountIdentifier: oid, userName: email } = currentAccount || {};
    const variables = {
      take: maxItems,
      skip: 0,
      filter: {
        oid,
        emailContains: email,
        isActive: true,
      },
    };

    if (calledUsers) {
      refetchUsers!(variables);
    } else {
      loadUsers({ variables });
    }
  }, [loadUsers, refetchUsers, calledUsers, currentAccount]);

  useEffect(() => {
    if (!!currentAccount?.userName) {
      load();
    }
  }, [currentAccount, load]);

  return {
    users,
    loadingUsers,
    errorUsers,
    load,
    currentAccount,
  };
};
