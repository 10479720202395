import { useLazyQuery } from '@apollo/client';
import { GET_LEGAL_FOLDER_ATTRIBUTES } from 'graphql/legalFolders/legalFolders';
import { legalFolderAttributes } from 'graphql/legalFolders/types/legalFolderAttributes';
import { useCallback, useEffect, useState } from 'react';

export interface IUseLegalFolderProps {
  id?: string;
}
export const useLegalFolder = ({ id }: IUseLegalFolderProps) => {
  const [legalFolderName, setLegalFolderName] = useState<string>();

  const [getLegalFolderName, { data, loading, called, refetch, error }] =
    useLazyQuery<legalFolderAttributes>(GET_LEGAL_FOLDER_ATTRIBUTES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    });

  const getData = useCallback(
    (id: string) => {
      const variables = {
        id,
      };

      if (called) {
        refetch!(variables);
      } else {
        getLegalFolderName({ variables });
      }
    },
    [refetch, called, getLegalFolderName]
  );

  useEffect(() => {
    if (data && !loading) {
      setLegalFolderName(data.contract_legalFolder.name);
    }
  }, [data, loading]);

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [getData, id]);

  return {
    legalFolderName,
    loading,
    error,
  };
};
