import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { ContainerSortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { TransformDocumentsNumber, TransformOwners } from 'utils/TableTransformFunctions';

export const demacProjectFoldersTableHead: IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder Name',
    sortable: true,
    sortBy: ContainerSortableColumn.NAME,
    filter: 'text',
    dataCell: {
      shrink: 'content',
    },
  },
  {
    id: 'documentCount',
    numeric: true,
    disablePadding: false,
    label: 'Documents',
    sortable: false,
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
      align: 'center',
    },
    transformDataCell: TransformDocumentsNumber,
  },
  // {
  //   id: 'expirationDate',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Expiration Date',
  //   sortable: true,
  //   sortBy: ContainerSortableColumn.END_DATE,
  //   filter: 'date',
  //   dataCell: {
  //     shrink: 'content',
  //   },
  //   transformFunction: (date) =>
  //     date
  //       ? new Date(date).toLocaleDateString('en-EN', {
  //           year: 'numeric',
  //           month: 'long',
  //           day: 'numeric',
  //         })
  //       : '',
  // },
  {
    id: 'documentsDivisions',
    numeric: false,
    disablePadding: false,
    label: 'Divisions',
    sortable: false,
    // filter: 'dropdown',
    dataCell: {
      shrink: 'content',
      align: 'left',
      wrap: 'box',
    },
    transformFunction: (data, row, order, sorted) => {
      if (!data?.length) return '-';
      if (!sorted) return data.map((item: { name: string }) => item.name).join(', ');
      if (order === SortOrder.ASC) {
        return data
          .map((item: { name: string }) => item.name)
          .sort()
          .join(', ');
      }
      return data
        .map((item: { name: string }) => item.name)
        .sort()
        .reverse()
        .join(', ');
    },
  },
  {
    id: 'projects',
    numeric: false,
    disablePadding: false,
    label: 'Projects Assigned',
    sortable: false,
    // filter: 'text',
    dataCell: {
      shrink: 'content',
      align: 'left',
      wrap: 'box',
    },
    transformFunction: (data, row, order, sorted) => {
      if (!data?.length) return '-';
      if (!sorted) return data.map((item: { key: string }) => item.key).join(', ');
      if (order === SortOrder.ASC) {
        return data
          .map((item: { key: string }) => item.key)
          .sort()
          .join(', ');
      }
      return data
        .map((item: { key: string }) => item.key)
        .sort()
        .reverse()
        .join(', ');
    },
  },
  {
    id: 'ownerNames',
    numeric: false,
    disablePadding: false,
    label: 'Owners',

    dataCell: {
      shrink: 'content',
      align: 'left',
    },

    transformDataCell: TransformOwners,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created',
    sortable: true,
    sortBy: ContainerSortableColumn.CREATED_AT,
    filter: 'date',
    dataCell: {
      shrink: 'content',
      align: 'left',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'lastModifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified',
    sortable: true,
    sortBy: ContainerSortableColumn.UPDATED_AT,
    filter: 'date',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      align: 'left',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
];
