import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  GET_LEGAL_FOLDERS_NAMES,
  GET_LEGAL_FOLDERS_COUNT,
} from 'graphql/legalFolders/legalFolders';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ILegalFolderItem, ILegalFoldersList } from './components/interface';
import { getLegalFolders } from 'graphql/legalFolders/types/getLegalFolders';
import { getLegalFoldersCount } from 'graphql/legalFolders/types/getLegalFoldersCount';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { useLegalFolderName } from 'hooks/legalFolderNameHook';
import { omit } from 'lodash';
import { LegalFolderSortableColumn } from 'graphql/legalFolders/types/graphql-types';

export interface ILegalFolderTreeProps {
  search: string;
  myTask: boolean;
  orderBy: LegalFolderSortableColumn;
  selectedLegalFolderId?: string;
  orderDirection: 'ASC' | 'DESC' | undefined;
}

export const useLegalFolderTree = ({
  search,
  myTask,
  selectedLegalFolderId,
  orderBy,
  orderDirection,
}: ILegalFolderTreeProps) => {
  const [skip, setSkip] = useState<number>(0);
  // const [take, setTake] = useState<number>(0);

  const client = useApolloClient();
  const {
    legalFolder: selectedLegalFolderName,
    loading,
    load,
  } = useLegalFolderName({ id: selectedLegalFolderId });

  const [tree, setTree] = useState<ILegalFoldersList>({
    totalItems: 0,
    items: [],
    search: '',
    myTask: myTask,
    ts: Date.now(),
  });
  const [expanded, setExpanded] = useState<any>(
    JSON.parse(sessionStorage.getItem('ContractsTreeViewExpanded') || '{}')
  );

  const expandedRef = useRef(expanded);
  const skipRef = useRef(skip);
  const reloadTreeRef = useRef<any>(null);
  const treeRef = useRef<any>(null);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    sessionStorage.setItem('ContractsTreeViewExpanded', JSON.stringify(expanded));
    expandedRef.current = expanded;
  }, [expanded]);

  useEffect(() => {
    skipRef.current = skip;
  }, [skip]);

  const [
    getLegalFolders,
    {
      data: getLegalFoldersData,
      loading: getLegalFoldersLoading,
      refetch: getLegalFoldersRefetch,
      called: getLegalFoldersCalled,
    },
  ] = useLazyQuery<getLegalFolders>(GET_LEGAL_FOLDERS_NAMES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getLegalFoldersCount,
    {
      data: getLegalFoldersCountData,
      loading: getLegalFoldersCountLoading,
      refetch: getLegalFoldersCountRefetch,
      called: getLegalFoldersCountCalled,
    },
  ] = useLazyQuery<getLegalFoldersCount>(GET_LEGAL_FOLDERS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadLegalFoldersPage = useCallback(
    (variables: any) => {
      setSkip(variables.skip);
      // setTake(variables.take);

      if (getLegalFoldersCalled) {
        getLegalFoldersRefetch!(variables);
      } else {
        getLegalFolders({ variables });
      }
    },
    [getLegalFoldersCalled, getLegalFoldersRefetch, getLegalFolders]
  );

  const loadLegalFoldersCount = useCallback(
    (variables: any) => {
      if (getLegalFoldersCountCalled) {
        getLegalFoldersCountRefetch!(variables);
      } else {
        getLegalFoldersCount({ variables });
      }
    },
    [getLegalFoldersCountCalled, getLegalFoldersCountRefetch, getLegalFoldersCount]
  );

  const firstPageLoad = useCallback(() => {
    setTree({ totalItems: 0, items: [], search, myTask, ts: Date.now() });
    loadLegalFoldersPage({
      filter: {
        nameOrContainerNameContains: search,
        onlyCurrentUserDocuments: myTask,
      },
      sort:
        orderBy === LegalFolderSortableColumn.UPDATED_AT
          ? [{ column: 'UPDATED_AT', order: orderDirection || 'DESC' }]
          : [
              { column: orderBy, order: orderDirection || 'ASC' },
              { column: 'UPDATED_AT', order: 'DESC' },
            ],
      take: DEFAULT_ROWS_PER_PAGE,
      skip: 0,
    });
    loadLegalFoldersCount({
      filter: {
        nameOrContainerNameContains: search,
        onlyCurrentUserDocuments: myTask,
      },
    });
  }, [search, myTask, loadLegalFoldersPage, loadLegalFoldersCount, orderBy, orderDirection]);

  useEffect(() => {
    firstPageLoad();
  }, [firstPageLoad]);

  useEffect(() => {
    if (selectedLegalFolderName && !loading && !search && !myTask) {
      setTree((oldTree) => {
        const { items } = oldTree;

        const { id, name, currentUserHasActionPending } = selectedLegalFolderName;
        const index = items.findIndex((item) => item.id === id);
        if (index >= 0) {
          items[index] = {
            ...items[index],
            id,
            name,
            currentUserHasActionPending: !!currentUserHasActionPending,
          };
        } else {
          items.push({
            id,
            name,
            expanded: !!expandedRef.current[id],
            currentUserHasActionPending: !!currentUserHasActionPending,
            extraLoaded: true,
          });
        }

        return { ...oldTree };
      });
    }
  }, [selectedLegalFolderName, loading, search, myTask]);

  useEffect(() => {
    if (getLegalFoldersData && !getLegalFoldersLoading) {
      setTree((oldTree) => {
        const { items } = oldTree;
        let extraItem: ILegalFolderItem | undefined;
        if (items.length && items[items.length - 1].extraLoaded) {
          extraItem = items.pop();
        }

        let count = 0;
        getLegalFoldersData.contract_legalFolders?.forEach((legalFolder) => {
          const position = count + skipRef.current;
          const { id, name, currentUserHasActionPending } = legalFolder;
          const index = items.findIndex((item) => item.id === id);
          if (index >= 0) {
            items[index] = {
              ...items[index],
              id,
              name,
              currentUserHasActionPending: !!currentUserHasActionPending,
            };
            if (index !== position) {
              const element = items[index];
              items.splice(index, 1);
              items.splice(position, 0, element);
            }
          } else {
            const element = {
              id,
              name,
              expanded: !!expandedRef.current[id],
              currentUserHasActionPending: !!currentUserHasActionPending,
              extraLoaded: false,
            };
            if (items.length <= position) {
              items.push(element);
            } else {
              items.splice(position, 0, element);
            }
          }
          count++;
        });

        if (extraItem) {
          const index = items.findIndex((item) => item.id === extraItem!.id);
          if (index >= 0) {
            items[index] = { ...items[index], ...omit(extraItem, ['name', 'id', 'extraLoaded']) };
          } else {
            items.push(extraItem);
          }
        }
        return { ...oldTree };
      });
    }
  }, [getLegalFoldersData, getLegalFoldersLoading, expandedRef, skipRef]);

  useEffect(() => {
    if (getLegalFoldersCountData && !getLegalFoldersCountLoading) {
      const count = getLegalFoldersCountData.contract_legalFolderCount;
      setTree((oldTree) => {
        return { ...oldTree, totalItems: count };
      });
    }
  }, [getLegalFoldersCountData, getLegalFoldersCountLoading]);

  const toggle = (id: string) => {
    const node = tree.items.find((item) => item.id === id);
    if (node) {
      node.expanded = !node?.expanded;
      setTree({ ...tree });
      setExpanded((old: any) => ({ ...old, [id]: node?.expanded }));
    }
  };

  const toggleTo = (id: string, expanded: boolean) => {
    const node = tree.items.find((item) => item.id === id);
    if (node) {
      node.expanded = expanded;
      setTree({ ...tree });
      setExpanded((old: any) => ({ ...old, [id]: expanded }));
    }
  };

  const loadMore = () => {
    loadLegalFoldersPage({
      filter: {
        nameOrContainerNameContains: search,
        onlyCurrentUserDocuments: myTask,
      },
      sort: [
        { column: 'CURRENT_USER_ACTION_PENDING', order: 'DESC' },
        { column: 'UPDATED_AT', order: 'DESC' },
      ],
      take: DEFAULT_ROWS_PER_PAGE,
      skip: tree.items.length,
    });
  };

  const initTree = () => {
    setExpanded({});
    firstPageLoad();
  };

  const reloadTree = useCallback(async () => {
    const { search, items, myTask } = treeRef.current;

    loadLegalFoldersCount({
      filter: {
        nameOrContainerNameContains: search,
        onlyCurrentUserDocuments: myTask,
      },
    });

    loadLegalFoldersPage({
      filter: {
        nameOrContainerNameContains: search,
        onlyCurrentUserDocuments: myTask,
      },
      sort: [
        { column: 'CURRENT_USER_ACTION_PENDING', order: 'DESC' },
        { column: 'UPDATED_AT', order: 'DESC' },
      ],
      take: items.length || DEFAULT_ROWS_PER_PAGE,
      skip: 0,
    });
  }, [loadLegalFoldersCount, loadLegalFoldersPage]);

  useEffect(() => {
    reloadTreeRef.current = reloadTree;
  }, [reloadTree]);

  useEffect(() => {
    client.onResetStore(reloadTreeRef.current);
  }, [client]);

  useEffect(() => {
    treeRef.current = tree;
  }, [tree]);

  return {
    tree,
    toggle,
    toggleTo,
    loadMore,
    loading: !!getLegalFoldersLoading || !!getLegalFoldersCountLoading,
    initTree,
  };
};
