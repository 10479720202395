import { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { Container, Paper, Box, Button, Grid, useTheme, useMediaQuery } from '@mui/material';

// API
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import { getLegalFolderDocuments } from 'graphql/legalFolders/types/getLegalFolderDocuments';
import { getLegalFolderDocumentsCount } from 'graphql/legalFolders/types/getLegalFolderDocumentsCount';

// Icons
import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';

// Utilities
import { useHistory, useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';

// Specific styles
import './index.scss';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';

import { paths } from 'constants/index';

import { IPageLoadParams } from './interface';
import {
  GET_DOCUMENT_TYPES,
  GET_PROJECT_FOLDER_DOCUMENTS,
  GET_PROJECT_FOLDER_DOCUMENTS_COUNT,
} from 'graphql/legalFolders/documents';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { DocumentFilter } from 'graphql/legalFolders/types/graphql-types';
import { documentTypes } from 'graphql/legalFolders/types/documentTypes';

import { Table } from 'components/ui/Table/Table';
import { demacProjectFolderDocumentsTableHead } from 'constants/projectFolderDocumentsTable';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useUI } from 'contexts/UiContext';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { GET_PROJECT_FOLDER_INFO } from 'graphql/legalFolders/legalFolders';
import {
  projectFolderInfo,
  projectFolderInfo_contract_container,
} from 'graphql/legalFolders/types/projectFolderInfo';

import { omit } from 'lodash';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

interface ILegalFolderUrlProps {
  projectFolderId?: string;
}
interface ILegalFolderUrlParams {
  id: string;
}

export const LegalFolderDocumentsTemplate: FC<ILegalFolderUrlProps> = (props) => {
  const { id: projectFolderIdFromUrl } = useParams<ILegalFolderUrlParams>();
  const projectFolderId = projectFolderIdFromUrl || props.projectFolderId!;

  const history = useHistory();
  const { addSnackbar } = useUI();

  const [projectFolderDocuments, setLegalFolderDocuments] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [resetFilter, setResetFilter] = useState(new Date());

  const [projectFolderInfo, setLegalFolderInfo] = useState<projectFolderInfo_contract_container>();

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    filter: {
      containerIds: [projectFolderId],
    },
  });

  const [
    loadLegalFolderDocumentsCount,
    {
      data: dataLegalFolderDocumentsCount,
      loading: loadingLegalFolderDocumentsCount,
      refetch: refetchLegalFolderDocumentsCount,
      called: calledLegalFolderDocumentsCount,
      error: errorLegalFolderDocumentsCount,
    },
  ] = useLazyQuery<getLegalFolderDocumentsCount>(GET_PROJECT_FOLDER_DOCUMENTS_COUNT);

  const [loadLegalFolderDocuments, { called, data, loading, refetch, error }] =
    useLazyQuery<getLegalFolderDocuments>(GET_PROJECT_FOLDER_DOCUMENTS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const { data: documentTypesData } = useQuery<documentTypes>(GET_DOCUMENT_TYPES);

  const { data: projectFolderInfoData, loading: projectFolderInfoLoading } =
    useQuery<projectFolderInfo>(GET_PROJECT_FOLDER_INFO, { variables: { id: projectFolderId } });

  useEffect(() => {
    if (projectFolderInfoData && !projectFolderInfoLoading) {
      setLegalFolderInfo({ ...projectFolderInfoData.contract_container });
    }
  }, [projectFolderInfoData, projectFolderInfoLoading]);

  useEffect(() => {
    if (errorLegalFolderDocumentsCount) {
      apolloErrorHandler(addSnackbar!)(errorLegalFolderDocumentsCount);
    }
  }, [loadingLegalFolderDocumentsCount, errorLegalFolderDocumentsCount, addSnackbar]);

  useEffect(() => {
    if (!loadingLegalFolderDocumentsCount && dataLegalFolderDocumentsCount) {
      setTotalItems(dataLegalFolderDocumentsCount.contract_documentCount);
    }
  }, [loadingLegalFolderDocumentsCount, dataLegalFolderDocumentsCount]);

  useEffect(() => {
    if (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
  }, [loading, error, addSnackbar]);

  useEffect(() => {
    if (!loading && data) {
      setLegalFolderDocuments(
        data.contract_documents?.map((projectFolderDocument) => {
          const {
            id,
            name,
            documentType,
            startDate,
            updatedAt,
            createdAt,
            status,
            endDate: expirationDate,
          } = projectFolderDocument;
          return {
            id,
            name,
            documentType: documentType?.name || '',
            startDate,
            expirationDate,
            lastModifiedDate: updatedAt,
            createdAt,
            currentlyReviewing: 'missing',
            lastActionBy: 'missing',
            status,
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    const { rowsPerPage, page, orderBy, order, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter,
    };

    if (called) {
      refetch!(variables);
    } else {
      loadLegalFolderDocuments({ variables });
    }
  }, [loadLegalFolderDocuments, refetch, called, pageLoadParams]);

  useEffect(() => {
    const { filter } = pageLoadParams;
    const variables = {
      filter,
    };
    if (calledLegalFolderDocumentsCount) {
      refetchLegalFolderDocumentsCount!(variables);
    } else {
      loadLegalFolderDocumentsCount({ variables });
    }
  }, [
    loadLegalFolderDocumentsCount,
    calledLegalFolderDocumentsCount,
    refetchLegalFolderDocumentsCount,
    pageLoadParams,
  ]);

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onNewDocumentClick = useCallback(() => {
    history.push(paths.client.PROJECT_FOLDER_ID_DOCUMENT.replace(':id', projectFolderId));
  }, [history, projectFolderId]);

  const onLegalFolderDocumentSelect = useCallback(
    (id: string) => {
      const newUrl = paths.client.PROJECT_FOLDER_DOCUMENT_ID.replace(':documentId', id);
      history.push(newUrl);
      return true;
    },
    [history]
  );

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const newFilter: DocumentFilter = {
          containerIds: [projectFolderId],
          nameContains: filterValues.name || undefined,
          statuses: filterValues.statuses,
          indemnities: filterValues.indemnities,
          startDate: filterValues.startDate || undefined,
          endDate: filterValues.expirationDate || undefined,
          documentTypeIds:
            filterValues.documentType.id !== 'All' ? [filterValues.documentType.id] : undefined,
          updatedAt: filterValues.lastModifiedDate,
          createdAt: filterValues.createdAt,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams, projectFolderId]
  );

  const filterApplied = useMemo(() => {
    const { filter } = pageLoadParams;
    return JSON.stringify(omit(filter, ['containerId'])) !== '{}';
  }, [pageLoadParams]);

  const filterOptions = useMemo(() => {
    const getDocumentTypesPairs = () => {
      return (
        documentTypesData?.contract_documentTypes.map(({ id, name }) => {
          return {
            id,
            name,
          };
        }) || []
      );
    };

    return {
      documentType: [{ id: 'All', name: 'All' }, ...getDocumentTypesPairs()],
    };
  }, [documentTypesData]);

  const filterValues = useMemo(
    () => ({
      documentType: { id: 'All', name: 'All' },
      resetFilter,
    }),
    [resetFilter]
  );

  const clearAllFilters = () => {
    setResetFilter(new Date());
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '40px', paddingRight: '40px', paddingTop: '24px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '24px 1.5rem 16px 1.5rem', margin: '6px' }
            : { padding: '5px .5rem 5px .5rem', margin: '6px' }
        }
        id="main-paper"
      >
        <Box marginBottom={2} marginTop={1} style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }}>
          <Breadcrumbs
            links={[
              {
                url: paths.client.PROJECT_FOLDER_ID.replace(':id', projectFolderId),
                title: projectFolderInfo ? projectFolderInfo.name : 'loading...',
              },
            ]}
            pageTitle="Documents"
          ></Breadcrumbs>
          <Box mt={3} />
          <Grid container spacing={0} alignContent="center" alignItems="center">
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={debounce(onNewDocumentClick, DEBOUNCE_TIMEOUT)}
              >
                NEW DOCUMENT
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                {filterApplied ? (
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                ) : undefined}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end"></Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          style={{
            marginTop: '15px',
            borderRadius: '1px',
            boxShadow: '0px 2px 5px #00000030',
            border: '1px solid #00000030',
          }}
        >
          <LoadingOverlay spinner active={loading} text="Loading your content...">
            <Table
              totalItems={totalItems}
              dataCells={projectFolderDocuments}
              headCells={demacProjectFolderDocumentsTableHead}
              loadPage={loadPage}
              handleSelect={onLegalFolderDocumentSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
            ></Table>
          </LoadingOverlay>
          <Box marginBottom={1} />
        </Box>
      </Paper>
    </Container>
  );
};
