import {
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Delete, DragIndicator } from '@mui/icons-material';
import React, { FC, useMemo, useState } from 'react';
import { SelectOneProposal } from 'template/LegalFolder/components/SelectProposal/SelectOneProposal';
import s from './style.module.scss';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export interface ISelectProposalTileProps {
  onAddProposal: (id: string) => void;
  onRemoveProposal: () => void;
  error?: boolean;
  usedProposalIds: string[];
  legalFolderPartyIds?: string[];
}

export const SelectProposalTile: FC<ISelectProposalTileProps> = ({
  onAddProposal,
  onRemoveProposal,
  error,
  usedProposalIds,
  legalFolderPartyIds,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [limitProposalsSearch, setLimitProposalsSearch] = useState(1);

  const partyIds = useMemo(
    () => (!limitProposalsSearch ? undefined : legalFolderPartyIds),
    [limitProposalsSearch, legalFolderPartyIds]
  );

  return (
    <Grid item xs={12} className={s.contactBox}>
      <Grid container spacing={0} alignContent="center" alignItems="center">
        <Grid item xs={1}></Grid>
        <Grid item xs={matches ? 9 : 8} md={matches ? 9 : 7}>
          <Grid container spacing={0}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!limitProposalsSearch}
                    onChange={() => {
                      setLimitProposalsSearch!(limitProposalsSearch ? 0 : 1);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Party's Proposals"
              />
              {/* <RadioGroup
                row
                aria-label="PartyProposalsSeatch"
                name="position"
                value={limitProposalsSearch ? '1' : '0'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLimitProposalsSearch!(e.target.value === '1' ? 1 : 0);
                }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" size="small" />}
                  label="Legal Folder's Parties"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" size="small" />}
                  label="All"
                />
              </RadioGroup> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={matches ? 2 : 3}></Grid>
        <Grid item xs={1}>
          <DragIndicator fontSize="small" style={{ display: 'none' }} />
        </Grid>

        <Grid item xs={matches ? 9 : 8} md={matches ? 9 : 7}>
          <SelectOneProposal
            onSelectChange={onAddProposal}
            error={error}
            usedProposalIds={usedProposalIds}
            partyIds={partyIds}
          ></SelectOneProposal>
        </Grid>
        <Grid
          item
          xs={matches ? 2 : 3}
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}
        >
          <Tooltip title="Remove Proposal">
            <IconButton
              size="small"
              onClick={debounce(onRemoveProposal, DEBOUNCE_TIMEOUT)}
              className={classes.hoverWarning}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
