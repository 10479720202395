import { IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material';
import { Clear as ClearIcon, Undo as UndoIcon } from '@mui/icons-material';
import { FC, Fragment } from 'react';
import s from './style.module.scss';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
export interface ITextInputFieldProps extends StandardTextFieldProps {
  onClear?: () => void;
  onUndo?: () => void;
}

export const TextInputField: FC<ITextInputFieldProps> = ({ onClear, onUndo, ...props }) => {
  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      className={s.textField}
      {...props}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <Fragment>
            {onUndo ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear content"
                  onClick={debounce(onUndo, DEBOUNCE_TIMEOUT)}
                  edge="end"
                  className={s.button}
                >
                  <UndoIcon fontSize="small" />{' '}
                </IconButton>
              </InputAdornment>
            ) : undefined}
            {onClear && props.value ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear content"
                  onClick={debounce(onClear, DEBOUNCE_TIMEOUT)}
                  edge="end"
                  className={s.button}
                >
                  <ClearIcon fontSize="small" />{' '}
                </IconButton>
              </InputAdornment>
            ) : undefined}
            {props.InputProps?.endAdornment}
          </Fragment>
        ),
      }}
    />
  );
};
