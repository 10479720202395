import { gql } from '@apollo/client';
import { report, dashboardReport, reportTable } from '../fragments';

const reportQueries = {
  GET_REPORT: gql`
  query($id: ID!) {
    report(id: $id) {
      ${report}
    }
  }
`,

  GET_REPORT_COUNT: gql`
    query GetReportCount($filter: ReportFilter) {
      reportCount(filter: $filter)
    }
  `,

  GET_REPORTS: gql`
  query GET_REPORTS($filter: ReportFilter, $take: Int = 100, $skip: Int = 0, $sort: [ReportSort!] = []) {
    reports(filter: $filter, take: $take, skip: $skip, sort: $sort) {
      ${dashboardReport}
    }
  }
`,

  GET_REPORTS_FOR_TABLE: gql`
  query GET_REPORTS($filter: ReportFilter, $take: Int = 100, $skip: Int = 0, $sort: [ReportSort!] = []) {
    reports(filter: $filter, take: $take, skip: $skip, sort: $sort) {
      ${reportTable}
    }
  }
`,

  GET_REPORTS_FILES: gql`
    query($id: ID!) {
      getProjectArchivedFileList(id: $id) {
        name
      }
    }
  `,
};

export default reportQueries;
