const TREEVIEW_HOME = '/';
const TREEVIEW_LEGAL_FOLDER = '/legal-folder/:id';
const TREEVIEW_LEGAL_FOLDER_TAB = '/legal-folder/:id/:tab';
const TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS = '/legal-folder-content/:id';
const TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDER_NEW = '/legal-folder/:id/project-folder-new';
const TREEVIEW_LEGAL_FOLDER_NEW = '/legal-folder-new';

const TREEVIEW_PROJECT_FOLDER = '/project-folder/:id';
const TREEVIEW_PROJECT_FOLDER_TAB = '/project-folder/:id/:tab';
const TREEVIEW_PROJECT_FOLDER_DOCUMENT_NEW = '/project-folder/:id/document-new';
const TREEVIEW_PROJECT_FOLDER_DOCUMENTS = '/project-folder/:id/documents';

const TREEVIEW_DOCUMENT = '/document/:id';
const TREEVIEW_DOCUMENT_FM = '/document/:id/files';

const DOCUSIGN_COMPLETE = '/docusign-complete';

export const treeViewClientPaths = {
  TREEVIEW_HOME,
  TREEVIEW_LEGAL_FOLDER,
  TREEVIEW_LEGAL_FOLDER_TAB,
  TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS,
  TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDER_NEW,
  TREEVIEW_LEGAL_FOLDER_NEW,

  TREEVIEW_PROJECT_FOLDER,
  TREEVIEW_PROJECT_FOLDER_TAB,
  TREEVIEW_PROJECT_FOLDER_DOCUMENT_NEW,
  TREEVIEW_PROJECT_FOLDER_DOCUMENTS,

  TREEVIEW_DOCUMENT,
  TREEVIEW_DOCUMENT_FM,

  DOCUSIGN_COMPLETE,
};
