import { gql } from '@apollo/client';
import { IProjectWithUsers } from './fragments';
import { IProjectListItem } from './projectsFragments';

export const GET_PROJECT_WITH_USERS = gql`
  query projectWithUsers($id: ID!) {
    project(id: $id) {
      ...IProjectWithUsers
    }
  }
  ${IProjectWithUsers}
`;

export const GET_PROJECT_NAMES = gql`
  query projectNames(
    $sort: [ProjectSort!] = []
    $filter: ProjectFilter
    $take: Int
    $skip: Int = 0
  ) {
    projects(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      key
      name
    }
  }
`;

export const GET_PARTY_PROJECTS = gql`
  query projects(
    $sort: [ProjectSort!] = []
    $filter: ProjectFilter
    $take: Int = 100
    $skip: Int = 0
  ) {
    projects(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IProjectListItem
    }
  }
  ${IProjectListItem}
`;

export const GET_PROJECT_COUNT = gql`
  query projectCount($filter: ProjectFilter) {
    projectCount(filter: $filter)
  }
`;
