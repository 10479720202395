import { useAutoCompleteDataSource } from './autoCompleteDataSource';

import { PartyFilterInput } from 'graphql/legalFolders/types/graphql-types';

import { GET_PARTY_COUNT, GET_PARTY_NAMES } from 'graphql/legalFolders/parties';
import { partyNames, partyNames_parties } from 'graphql/legalFolders/types/partyNames';
import { partyCount } from 'graphql/legalFolders/types/partyCount';

const getCount = (count: partyCount) => count.partyCount;
const getItems = (data: partyNames) => data.parties;
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ nameContains: localSearch });

export interface IPartyNamesAutocompleteDataHook {
  search: string;
}

export const usePartyNamesAutocompleteDataHook = ({ search }: IPartyNamesAutocompleteDataHook) => {
  const resp = useAutoCompleteDataSource<
    partyNames,
    partyCount,
    partyNames_parties,
    PartyFilterInput
  >({
    GET_QUERY: GET_PARTY_NAMES,
    GET_COUNT_QUERY: GET_PARTY_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};
