import React, { FC, useMemo } from 'react';
import { FolderTypeTabs } from './components/FolderTypeTabs/FolderTypeTabs';
import { useComponentContext } from '../TableSection/TableSectionContext';
import { useComponentContext as useTreeTableComponentContext } from 'template/TreeTable/TreeTableContext';
import { NotAvailable } from './components/NotAvailable/NotAvailable';
import { DocumentsView } from './components/DocumentsView/DocumentsView';
import { ProjectFolderView } from './components/ProjectFolderView/ProjectFolderView';
import { LegalFolderView } from './components/LegalFolderView/LegalFolderView';
import { NewLegalFolderView } from './components/LegalFolderView/NewLegalFolderView';
import { Provider as DocumentsContextProvider } from './components/DocumentsView/DocumentsContext';
import { Provider as ProjectFoldersContextProvider } from './components/ProjectFoldersView/ProjectFoldersContext';
import s from './style.module.scss';
import { DocumentViewTabs } from './components/DocumentsView/components/DocumentViewTabs/DocumentViewTabs';
import { LegalFolderProjectFoldersList } from './components/LegalFolderProjectFoldersList/LegalFolderProjectFoldersList';
import { ProjectFoldersView } from './components/ProjectFoldersView/ProjectFoldersView';
import { ProjectFoldersViewTabs } from './components/ProjectFoldersView/components/ProjectFoldersViewTabs/ProjectFoldersViewTabs';

export const TableSection: FC = () => {
  const {
    activeTab,
    setActiveTab,
    legalFolderId,
    projectFolderId,
    projectDocumentId,
    newProjectDocumentTs,
    setDocumentViewTab,
    setProjectFoldersViewTab,
    activeDocumentTab,
    activeProjectFoldersTab,
  } = useComponentContext();

  const { initLegalFolderTab } = useTreeTableComponentContext();

  const contentPane = useMemo(() => {
    if (!legalFolderId && activeTab === 'LF') {
      return <NewLegalFolderView key="newLegalFolder"></NewLegalFolderView>;
    }

    if (legalFolderId && activeTab === 'PF') {
      return (
        <ProjectFoldersContextProvider
          legalFolderId={legalFolderId}
          projectFolderId={projectFolderId}
          newProjectFolderTs={newProjectDocumentTs}
          setProjectFoldersView={setProjectFoldersViewTab}
          activeProjectFoldersViewTab={activeProjectFoldersTab}
        >
          {projectFolderId ? <ProjectFoldersViewTabs></ProjectFoldersViewTabs> : undefined}
          <ProjectFoldersView></ProjectFoldersView>
        </ProjectFoldersContextProvider>
      );
    }

    return !legalFolderId || (!projectFolderId && activeTab !== 'LF') ? (
      <NotAvailable></NotAvailable>
    ) : activeTab === 'D' ? (
      <DocumentsContextProvider
        legalFolderId={legalFolderId}
        projectFolderId={projectFolderId}
        projectDocumentId={projectDocumentId}
        newProjectDocumentTs={newProjectDocumentTs}
        setDocumentView={setDocumentViewTab}
        activeDocumentViewTab={activeDocumentTab}
      >
        {projectDocumentId ? <DocumentViewTabs></DocumentViewTabs> : undefined}
        <DocumentsView key={projectFolderId}></DocumentsView>
      </DocumentsContextProvider>
    ) : activeTab === 'PF' ? (
      <ProjectFolderView key={projectFolderId}></ProjectFolderView>
    ) : !initLegalFolderTab ? (
      <LegalFolderView key={legalFolderId}></LegalFolderView>
    ) : (
      <LegalFolderProjectFoldersList key={legalFolderId}></LegalFolderProjectFoldersList>
    );
  }, [
    activeTab,
    legalFolderId,
    projectFolderId,
    projectDocumentId,
    newProjectDocumentTs,
    setDocumentViewTab,
    setProjectFoldersViewTab,
    activeDocumentTab,
    activeProjectFoldersTab,
    initLegalFolderTab,
  ]);

  return (
    <div>
      <FolderTypeTabs
        maxLevel={!legalFolderId ? 1 : !projectFolderId ? 2 : 3}
        selectedView={activeTab}
        setSelectedView={setActiveTab}
      ></FolderTypeTabs>
      <div className={s.contentPane}>{contentPane}</div>
    </div>
  );
};
