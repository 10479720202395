import { IconButton, TextField, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { Delete } from '@mui/icons-material';
import { MonetaryTransactionType } from 'graphql/legalFolders/types/graphql-types';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { CurrencyInputField } from 'components/CurrencyInputField/CurrencyInputField';
export interface IMonetaryTransaction {
  startTimestamp: any;
  monetaryTransactionValue: string;
}

export interface IMonetaryTransactionsProps {
  monetaryTransactionBreakdowns: IMonetaryTransaction[];
  onRemoveMonetaryTransaction: (index: number) => void;
  onChangeMonetaryTransaction: (index: number, transaction: IMonetaryTransaction) => void;
  monetaryTransactionType?: MonetaryTransactionType;
}

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export const MonetaryTransactions: FC<IMonetaryTransactionsProps> = ({
  monetaryTransactionBreakdowns,
  onRemoveMonetaryTransaction,
  onChangeMonetaryTransaction,
  monetaryTransactionType,
}) => {
  const classes = useStyles();
  let index = -1;

  const updateValue = (index: number, startTimestamp: any) => (e: any, value: string) => {
    onChangeMonetaryTransaction!(index, {
      monetaryTransactionValue: value,
      startTimestamp,
    });
  };

  const updateTimestamp =
    (index: number, monetaryTransactionValue: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (/^\d*$/.test(e.target.value)) {
        onChangeMonetaryTransaction!(index, {
          monetaryTransactionValue,
          startTimestamp: e.target.value,
        });
      }
    };

  const remove = (index: number) => () => {
    onRemoveMonetaryTransaction!(index);
  };

  return (
    <Grid container padding={0} spacing={2}>
      {monetaryTransactionBreakdowns.map((monetaryTransaction) => {
        const { monetaryTransactionValue, startTimestamp } = monetaryTransaction;
        index++;
        return (
          <Grid xs={12} key={index}>
            <Grid container padding={0} spacing={2}>
              <Grid xs={2}>
                <Typography variant="body2" className="label-title">
                  Year:
                </Typography>
              </Grid>
              <Grid xs={3}>
                <div style={{ width: '60px' }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={startTimestamp}
                    onChange={updateTimestamp(index, monetaryTransactionValue)}
                    error={false}
                  />
                </div>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid xs={2}>
                <Typography variant="body2" className="label-title">
                  {monetaryTransactionType === MonetaryTransactionType.COST ? 'Cost:' : 'Revenue:'}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <CurrencyInputField
                  size="small"
                  value={parseFloat(monetaryTransactionValue)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateValue(index, startTimestamp)(e, e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid xs={1}>
                <Tooltip title="Delete Year">
                  <IconButton
                    size="small"
                    onClick={debounce(remove(index), DEBOUNCE_TIMEOUT)}
                    className={classes.hoverWarning}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
