import { LegalFolderSortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { useLegalFoldersTree } from 'hooks/legalFoldersTreeProps';
import React, { createContext, FC, useContext } from 'react';

export interface IContextState {
  legalFoldersOrderBy: LegalFolderSortableColumn;
  legalFoldersOrderDirection: 'ASC' | 'DESC' | undefined;
}

export interface IContextActions {
  setLegalFoldersOrderBy: any;
  setLegalFoldersOrderDirection: any;
}

const initialState: IContextState = {
  legalFoldersOrderBy: LegalFolderSortableColumn.CURRENT_USER_ACTION_PENDING,
  legalFoldersOrderDirection: 'ASC',
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  children: any;
}

export const Provider: FC<IProviderProps> = ({ children }) => {
  const {
    legalFoldersOrderBy,
    setLegalFoldersOrderBy,
    legalFoldersOrderDirection,
    setLegalFoldersOrderDirection,
  } = useLegalFoldersTree();

  return (
    <ComponentContext.Provider
      value={{
        legalFoldersOrderBy,
        setLegalFoldersOrderBy,
        legalFoldersOrderDirection,
        setLegalFoldersOrderDirection,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
