import { useAutoCompleteDataSource } from './autoCompleteDataSource';

import {
  getProjectFolders,
  getProjectFolders_contract_containers,
} from 'graphql/legalFolders/types/getProjectFolders';
import { getProjectFoldersCount } from 'graphql/legalFolders/types/getProjectFoldersCount';
import { ContainerFilter } from 'graphql/legalFolders/types/graphql-types';

import {
  GET_PROJECT_FOLDERS_COUNT,
  GET_PROJECT_FOLDERS_NAMES,
} from 'graphql/legalFolders/legalFolders';

const getCount = (count: getProjectFoldersCount) => count.contract_containerCount;
const getItems = (data: getProjectFolders) => data.contract_containers;
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ nameContains: localSearch });

export interface IProjectFoldersNamesAutocompleteDataHook {
  search: string;
}

export const useProjectFoldersNamesAutocompleteDataHook = ({
  search,
}: IProjectFoldersNamesAutocompleteDataHook) => {
  const resp = useAutoCompleteDataSource<
    getProjectFolders,
    getProjectFoldersCount,
    getProjectFolders_contract_containers,
    ContainerFilter
  >({
    GET_QUERY: GET_PROJECT_FOLDERS_NAMES,
    GET_COUNT_QUERY: GET_PROJECT_FOLDERS_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};
