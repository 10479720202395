import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { FC } from 'react';
import s from './style.module.scss';
import { useComponentContext } from '../../DocumentsContext';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

export interface IDocumentViewTabsProps {}

const visibleTabs = [
  {
    label: 'Document information',
    value: ['edit-info', 'new'],
  },
  {
    label: 'File Management and Approval',
    value: ['edit-fm'],
  },
];
export const DocumentViewTabs: FC<IDocumentViewTabsProps> = () => {
  const { confirmRequest } = useFormChangedDialogContext();
  const { currentView, changeView } = useComponentContext();

  return (
    <Tabs
      value={currentView}
      onChange={(e, val) => {
        confirmRequest!((confirmed) => {
          confirmed && changeView!(val);
        });
      }}
      variant="fullWidth"
      className={s.folderTypeTabs}
      TabIndicatorProps={{ style: { background: '#85A278' } }}
    >
      {visibleTabs.map((tab) => {
        return (
          <Tab
            label={tab.label}
            value={tab.value[0]}
            className={tab.value.includes(currentView) ? s.activeTab : undefined}
            key={tab.value[0]}
          />
        );
      })}
    </Tabs>
  );
};
