import { FC, useState } from 'react';
import {
  Container,
  Paper,
  useTheme,
  useMediaQuery,
  // Grid,
  // Typography,
  // Button,
  // Box,
} from '@mui/material';

// Specific styles
import './index.scss';
import { AllDocumentsTableView } from 'template/TreeTable/components/AllDocumentsTable/AllDocumentsTableView';
// import { SaveAlt } from '@mui/icons-material';
import { Print } from 'template/Print/Print';
import { AllDocumentsTablePrintingMeta } from './AllDocumentsTablePrintingMeta';
import { Provider as AllDocumentsTableContextProvider } from 'template/TreeTable/components/AllDocumentsTable/AllDocumentsTableContext';
import { IDocumentsTableHookLoadParams } from 'template/TreeTable/components/AllDocumentsTable/documentsTableHook';
// import { DEBOUNCE_TIMEOUT } from 'constants/config';
// import { debounce } from 'lodash';

export const DocumentsTemplate: FC<{
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IDocumentsTableHookLoadParams>;
  tableStorageKey?: string;
  title?: string;
}> = ({ overridePageLoadParams, initPageLoadParams, tableStorageKey, title }) => {
  const [printing, setPrinting] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '4px 4px 16px 4px' }
            : { padding: '4px 4px 16px 4px', marginTop: '6px' }
        }
        id="main-paper"
      >
        <AllDocumentsTableContextProvider
          overridePageLoadParams={overridePageLoadParams}
          initPageLoadParams={initPageLoadParams}
          tableStorageKey={tableStorageKey}
        >
          <AllDocumentsTableView title={title}></AllDocumentsTableView>

          <Print
            printing={printing}
            setPrinting={setPrinting}
            PrintingMeta={AllDocumentsTablePrintingMeta}
            PrintingData={AllDocumentsTableView}
            landscape
            subtitle="Documents List"
          ></Print>
        </AllDocumentsTableContextProvider>
      </Paper>
    </Container>
  );
};
