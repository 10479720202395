import { LegalFolderSortableColumn, Order } from 'graphql/legalFolders/types/graphql-types';
import { useEffect, useState } from 'react';

export const useLegalFoldersTree = () => {
  const [legalFoldersOrderBy, setLegalFoldersOrderBy] = useState<LegalFolderSortableColumn>(
    (sessionStorage.getItem('legalFoldersTreeOrderBy') as LegalFolderSortableColumn | undefined) ||
      LegalFolderSortableColumn.CURRENT_USER_ACTION_PENDING
  );
  const [legalFoldersOrderDirection, setLegalFoldersOrderDirection] = useState<
    'ASC' | 'DESC' | undefined
  >(
    (sessionStorage.getItem('legalFoldersOrderDirection') as 'ASC' | 'DESC' | undefined) ||
      Order.DESC
  );

  useEffect(() => {
    sessionStorage.setItem('legalFoldersTreeOrderBy', legalFoldersOrderBy);
  }, [legalFoldersOrderBy]);

  return {
    legalFoldersOrderBy,
    setLegalFoldersOrderBy,
    legalFoldersOrderDirection,
    setLegalFoldersOrderDirection,
  };
};
