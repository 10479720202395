import { useLazyQuery } from '@apollo/client';
import { GET_PROJECT_FOLDER_NAME } from 'graphql/legalFolders/legalFolders';
import { projectFolderName } from 'graphql/legalFolders/types/projectFolderName';
import { useCallback, useEffect, useState } from 'react';

export interface IUseProjectFolderProps {
  id?: string;
}
export const useProjectFolder = ({ id }: IUseProjectFolderProps) => {
  const [projectFolderName, setProjectFolderName] = useState<string>();

  const [getProjectFolderName, { data, loading, called, refetch }] =
    useLazyQuery<projectFolderName>(GET_PROJECT_FOLDER_NAME, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    });

  const getData = useCallback(
    (id: string) => {
      const variables = {
        id,
      };

      if (called) {
        refetch!(variables);
      } else {
        getProjectFolderName({ variables });
      }
    },
    [refetch, called, getProjectFolderName]
  );

  useEffect(() => {
    if (data && !loading) {
      setProjectFolderName(data.contract_container.name);
    }
  }, [data, loading]);

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [getData, id]);

  return {
    projectFolderName,
    loading,
  };
};
