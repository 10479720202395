import {
  Box,
  Button,
  Container,
  Grid,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import './index.scss';

import { useComponentContext } from '../../ProjectFolderContext';

import LoadingOverlay from 'react-loading-overlay-ts';

import { ProjectFolderFormFields } from '../ProjectFolderFormFields/ProjectFolderFormFields';
import { Print } from 'template/Print/Print';
import { useCallback, useState } from 'react';
import { ProjectFolderPrintingMeta } from '../ProjectFolderPrintingMeta/ProjectFolderPrintingMeta';
import { SaveAlt } from '@mui/icons-material';
import { useLegalFolder } from 'template/TreeTable/components/Breadcrumbs/legalFolderHook';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

import { useHistory } from 'react-router-dom';
import { paths } from 'constants/index';
import { ConfirmationDialog } from 'components';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const ProjectFolderForm = () => {
  const { checkAndProceed, resetChanged } = useFormChangedDialogContext();
  const history = useHistory();
  const { onSubmit, onCancel, loading, legalFolderId, projectFolder, onDeleteProcess } =
    useComponentContext();
  const { legalFolderName } = useLegalFolder({ id: legalFolderId });

  const [printing, setPrinting] = useState(false);
  const [displayDeleteWarning, showDeleteWarning] = useState(false);

  const handleDeleteProjectFolder = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        showDeleteWarning(() => false);
        if (await onDeleteProcess!()) {
          resetChanged && resetChanged();
          history.push(
            paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS.replace(
              ':id',
              legalFolderId!
            )
          );
        }
      }
    },
    [onDeleteProcess, history, legalFolderId, resetChanged]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      {!projectFolder.id ? (
        <div style={{ padding: '10px' }}>
          {projectFolder.id ? 'Viewing' : 'Creating new'} Project Folder in{' '}
          <strong>{legalFolderName}</strong> Legal Folder
        </div>
      ) : undefined}
      <ProjectFolderFormFields></ProjectFolderFormFields>

      <Print printing={printing} setPrinting={setPrinting} PrintingMeta={ProjectFolderPrintingMeta}>
        <ProjectFolderFormFields></ProjectFolderFormFields>
      </Print>

      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Project Folder deletion"
        message={'Selected Project Folder will be deleted! The action is irreversible!'}
        onClose={handleDeleteProjectFolder}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <Container maxWidth="md" style={!matches ? { padding: '0px' } : undefined}>
        <Box m={3} />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Divider />
              <Box m={3} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <Grid item xs={12} md={4} lg={3}>
                <Typography variant="body2" className="label-title">
                  Cover sheet:
                </Typography>
              </Grid>
              <Grid item xs={11} md={4} lg={6}>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1em',
                    cursor: 'pointer',
                  }}
                  onClick={debounce(() => {
                    setPrinting(true);
                  }, DEBOUNCE_TIMEOUT)}
                >
                  <SaveAlt></SaveAlt>
                  <Button variant="outlined" color="primary">
                    Download
                  </Button>
                </div>
              </Grid>
              <Grid item xs={1} md={4} lg={3}></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box m={5} />

        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid container justifyContent="center" spacing={3}>
              {projectFolder.id ? (
                <Grid item xs={12} sm={3} order={{ xs: 4, sm: 1 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={debounce(() => {
                      showDeleteWarning(true);
                    }, DEBOUNCE_TIMEOUT)}
                    style={{ background: 'red' }}
                  >
                    Delete
                  </Button>
                </Grid>
              ) : undefined}

              <Grid item style={{ width: '50px' }} xs={12} sm={1} order={{ xs: 3, sm: 2 }}></Grid>

              <Grid item xs={12} sm={3} order={{ xs: 2, sm: 3 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={debounce(checkAndProceed!(onCancel), DEBOUNCE_TIMEOUT)}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={12} sm={3} order={{ xs: 1, sm: 4 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onSubmit && debounce(onSubmit, DEBOUNCE_TIMEOUT)}
                >
                  Submit{/* {id ? 'Submit' : 'Create new'} */}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Container>
    </LoadingOverlay>
  );
};
