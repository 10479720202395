const AccountIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        d="M3,5V19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5a2.006,2.006,0,0,0-2-2H5A2,2,0,0,0,3,5ZM15,9a3,3,0,1,1-3-3A3,3,0,0,1,15,9ZM6,17c0-2,4-3.1,6-3.1S18,15,18,17v1H6Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export default AccountIcon;
