// #region React Core

import React, { FC, useState, useEffect } from 'react';

// #endregion

// #region Material-UI Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
  Box,
  Container,
  Grid,
} from '@mui/material';
// #endregion

// #region Styles

import './index.scss';

// #endregion

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

//
// Displays generic Alert Dialog with title and message
//
const AlertDialog: FC<any> = ({
  open,
  title,
  message,
  onClose,
  style,
  maxWidth,
  fullWidth,
}: any) => {
  const [dialogTitle, setTitle] = useState('');
  const [dialogMessage, setMessage] = useState('');
  const [dialogOpen, setOpen] = useState(false);

  useEffect((): void => {
    setOpen(open);
    setTitle(title);
    setMessage(message);
  }, [open, title, message]);

  const closeHandler = (): void => {
    setOpen(false);

    onClose();
  };

  return (
    <Dialog maxWidth={maxWidth || 'sm'} fullWidth={fullWidth} open={dialogOpen}>
      <Container>
        <DialogTitle>
          <Typography>
            <span className="alert-dialog-title">{dialogTitle}</span>
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText className="alert-dialog-message" style={{ ...style }}>
            {dialogMessage}
          </DialogContentText>
        </DialogContent>

        <Box marginBottom={3} />
        <DialogActions>
          <Grid style={{ margin: 'auto' }}>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              onClick={debounce((): void => {
                closeHandler();
              }, DEBOUNCE_TIMEOUT)}
            >
              Close
            </Button>
          </Grid>
        </DialogActions>
        <Box marginBottom={2} />
      </Container>
    </Dialog>
  );
};

export default AlertDialog;
