import {
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import EasyAutoComplete from '../../components/EasyAutoComplete';

import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { IPartyDesignation } from 'graphql/legalFolders/types/IPartyDesignation';
import { partyDesignations } from 'graphql/legalFolders/types/partyDesignations';
import { countries } from 'graphql/legalFolders/types/countries';
import { partyUpdateVariables } from 'graphql/legalFolders/types/partyUpdate';

import './index.scss';
import {
  GET_PARTY_DESIGNATIONS,
  PARTY_SOFT_DELETE,
  PARTY_UPDATE_MUTATION,
} from 'graphql/legalFolders/parties';
import { GET_COUNTRIES } from 'graphql/legalFolders/countries';
import { PartyTypeEnum } from 'graphql/legalFolders/types/graphql-types';

import { pick } from 'lodash';

import { validators } from 'constants/validators';
import validate from 'validate.js';
import { useHistory } from 'react-router-dom';
import { party_party, party_party_events } from 'graphql/legalFolders/types/party';
import { useUI } from 'contexts/UiContext';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { LegalFoldersList } from './components/LegalFoldersList/LegalFoldersList';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { Asterisks } from 'components/Asterisks/Asterisks';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

enum clientTypeDbMap {
  'CLIENT' = 'CLIENT',
  'NON-CLIENT' = 'NONCLIENT',
}

interface ICountryListItem {
  id: string;
  name: string;
}

interface IPartyData extends partyUpdateVariables {
  selectedClientType: { key: string; name: string };
  selectedClientDesignation?: { id: string; name: string };
  selectedCountry?: { id: string; name: string };
  isApplicationControlled: boolean;
  projectSetupClientCode?: string | null;
  isValid: boolean;
  showValidator: boolean;
  errors?: any;
  createEvent?: party_party_events;
}

const partyValidators = {
  name: validators.simpleText,
  designationId: validators.simpleText,
  type: validators.simpleText,
};

const newParty: IPartyData = {
  id: '',
  name: '',
  designationId: '',
  type: PartyTypeEnum.CLIENT,
  isActive: true,
  selectedClientType: { key: PartyTypeEnum.CLIENT, name: 'Client' },
  isValid: true,
  showValidator: false,
  isApplicationControlled: true,
};

export interface IPartyTemplate {
  loadedParty?: party_party | null;
  refetch?: any;
}

export const PartyTemplate: FC<IPartyTemplate> = ({ loadedParty, refetch }) => {
  const { formChanged, resetChanged, checkAndProceed } = useFormChangedDialogContext();
  const history = useHistory();
  const { addSnackbar } = useUI();
  const [displayDeleteWarning, showDeleteWarning] = useState(false);

  const { data: designationsData, loading: designationsLoading } =
    useQuery<partyDesignations>(GET_PARTY_DESIGNATIONS);

  const { data: countriesData, loading: countriesLoading } = useQuery<countries>(GET_COUNTRIES);

  const [updatePartyMutation] = useMutation(PARTY_UPDATE_MUTATION);
  const [deletePartyMutation] = useMutation(PARTY_SOFT_DELETE);

  const [designations, setDesignations] = useState<Array<IPartyDesignation>>();
  const [countries, setCountries] = useState<Array<ICountryListItem>>();
  const [party, setParty] = useState<IPartyData>(newParty);
  const [partyDialogOpen, setPartyDialogOpen] = useState(false);

  const {
    name,
    streetAddress,
    streetAddress2,
    addressCity,
    addressState,
    addressZip,
    isActive,
    selectedClientType,
    selectedClientDesignation,
    selectedCountry,
    projectSetupClientCode,
    type,
    designationId,
    isApplicationControlled,
    isValid,
    showValidator,
    errors,
    createEvent,
  } = party;

  const validateForm = (newState: any) => {
    const errors = validate(newState, partyValidators);
    return { ...newState, errors, isValid: errors ? false : true };
  };

  useEffect(() => {
    if (loadedParty) {
      const createEvent = loadedParty.events?.find((ev) => ev.eventType === 'PARTY_CREATED');

      setParty({
        ...pick(loadedParty, [
          'id',
          'name',
          'streetAddress',
          'streetAddress2',
          'addressCity',
          'addressState',
          'addressZip',
          'isActive',
          'isApplicationControlled',
          'projectSetupClientCode',
        ]),
        designationId: loadedParty.partyDesignation?.id || '',
        addressCountryId: loadedParty.country?.id,
        type: PartyTypeEnum[
          clientTypeDbMap[loadedParty.partyType?.name! as keyof typeof clientTypeDbMap]
        ],

        selectedClientType: {
          key: loadedParty.partyType?.name!,
          name: loadedParty.partyType?.name === 'CLIENT' ? 'Client' : 'Non-Client',
        },
        selectedClientDesignation: loadedParty.partyDesignation
          ? { id: loadedParty.partyDesignation.id, name: loadedParty.partyDesignation.name }
          : undefined,
        selectedCountry: loadedParty.country
          ? {
              id: loadedParty.country.id,
              name: `${loadedParty.country.name} (${loadedParty.country.code})`,
            }
          : undefined,

        isValid: true,
        showValidator: false,
        createEvent,
      });
    }
  }, [loadedParty]);

  useEffect(() => {
    if (designationsData && !designationsLoading) {
      setDesignations([
        ...designationsData.partyDesignations.filter((designation) => designation.isSelectable),
      ]);
    }
  }, [designationsData, designationsLoading]);

  useEffect(() => {
    if (countriesData && !countriesLoading) {
      setCountries(
        countriesData.countries.map((country) => {
          return {
            id: country.id,
            name: `${country.name} (${country.code})`,
          };
        })
      );
    }
  }, [countriesData, countriesLoading]);

  const updateProcedure = async ({
    mutation,
    parseResult,
  }: {
    mutation: any;
    parseResult: any;
  }) => {
    let result;
    try {
      // await apolloClient.resetStore();
      const { data } = await mutation();
      result = parseResult(data);
      if (result) {
        addSnackbar!({ text: 'Party is updated', severity: 'success' });
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    return result;
  };

  const onSubmitValidate = () => {
    if (!isValid || !name || !designationId || !type) {
      setParty(validateForm({ ...party, showValidator: true }));
      return false;
    }
    return true;
  };

  const onSubmitProcess = async () => {
    const variables = pick(party, [
      'id',
      'name',
      'type',
      'designationId',
      'addressCity',
      'addressState',
      'addressZip',
      'addressCountryId',
      'isActive',
      'streetAddress',
      'streetAddress2',
    ]);
    const success = await updateProcedure({
      mutation: () =>
        updatePartyMutation({
          variables,
        }),
      parseResult: (data: any) => {
        return data?.partyUpdate?.id;
      },
    });
    if (!!success) {
      // refetch && refetch();
      resetChanged && resetChanged();
      history.push('/parties-list');
    }
  };

  const onDeleteProcess = useCallback(async () => {
    const variables = pick(party, ['id']);
    try {
      const { data } = await deletePartyMutation({
        variables,
      });
      if (data.partySoftDelete) {
        resetChanged && resetChanged();
        addSnackbar!({ text: 'Party is deleted', severity: 'success' });
        history.push('/parties-list');
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
  }, [addSnackbar, deletePartyMutation, history, party, resetChanged]);

  const handleDeleteParty = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onDeleteProcess();
      }
      setPartyDialogOpen(() => false);
    },
    [onDeleteProcess]
  );

  const timestamp = useMemo(() => {
    if (!createEvent) return undefined;
    return new Date(createEvent.createdAt).toLocaleDateString('en-EN', {
      year: 'numeric',
      month: 'long', // 'numeric',
      day: 'numeric',
      // hour: '2-digit',
      // minute: '2-digit',
      // hourCycle: 'h24',
      // second: '2-digit',
    });
  }, [createEvent]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container
      maxWidth="md"
      style={!matches ? { padding: '0px 4px 0px 4px' } : { padding: '0px 24px 0px 24px' }}
    >
      <ConfirmationDialog
        title="Party update"
        message={'Please confirm Party update'}
        open={partyDialogOpen}
        onClose={(confirm: boolean) => {
          if (confirm) {
            onSubmitProcess();
          }
          setPartyDialogOpen(() => false);
        }}
      />
      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Party deletion"
        message={'Selected Party will be deleted! The action is irreversible!'}
        onClose={handleDeleteParty}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <Paper
        elevation={3}
        style={{
          padding: '0rem 28px 1rem 28px',
          marginTop: '40px',
          width: 'fit-content',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
        id="main-paper"
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" className="paper-title">
                Details
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Paper elevation={3} style={{ padding: '1rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box m={5} />

              <Grid container spacing={2} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      Other Party <Asterisks count={1} tooltipTitle="Other Party is required" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formChanged && formChanged();
                        setParty(validateForm({ ...party, name: e.target.value }));
                      }}
                      fullWidth
                      error={showValidator && errors?.name}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>
                  {projectSetupClientCode && projectSetupClientCode !== '' ? (
                    <>
                      <Grid item xs={12} sm={5}>
                        <Typography variant="body2" className="label-title">
                          Client Code:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7} style={{ opacity: '0.38' }}>
                        {projectSetupClientCode}
                      </Grid>
                    </>
                  ) : undefined}
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      Client / Non-Client :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <EasyAutoComplete
                      key={`type-${selectedClientType?.key}`}
                      items={[
                        { key: 'CLIENT', name: 'Client' },
                        { key: 'NONCLIENT', name: 'Non-Client' },
                      ]}
                      selected={selectedClientType}
                      label=""
                      textFieldStyle="outlined"
                      optionsLabel="name"
                      selectedChanged={(value: any): void => {
                        formChanged && formChanged();
                        setParty(
                          validateForm({ ...party, type: value.key, selectedClientType: value })
                        );
                      }}
                      error={showValidator && errors?.type}
                      getOptionSelected={(option: any, value: any) => {
                        return option.key === value.key;
                      }}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      Party type <Asterisks count={1} tooltipTitle="Party Type is required" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <EasyAutoComplete
                      key={`designation-${selectedClientDesignation?.id}`}
                      items={designations || []}
                      label=""
                      textFieldStyle="outlined"
                      optionsLabel="name"
                      selected={selectedClientDesignation}
                      selectedChanged={(value: any): void => {
                        formChanged && formChanged();
                        setParty(
                          validateForm({
                            ...party,
                            designationId: value.id,
                            selectedClientDesignation: value,
                          })
                        );
                      }}
                      error={showValidator && errors?.designationId}
                      getOptionSelected={(option: any, value: any) => {
                        return option.id === value.id;
                      }}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>
                  {party.id ? (
                    <>
                      <Grid item xs={12} sm={5}>
                        <div style={{ height: '100%' }}>
                          <Typography variant="body2" className="label-title">
                            Legal Folders:
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <LegalFoldersList filter={{ partyIds: [party.id] }}></LegalFoldersList>
                      </Grid>
                    </>
                  ) : undefined}
                  {party.id && createEvent ? (
                    <>
                      <Grid item xs={12} sm={5}>
                        <div style={{ height: '100%' }}>
                          <Typography variant="body2" className="label-title">
                            Created By:
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        {createEvent.author?.name || '-'}
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div style={{ height: '100%' }}>
                          <Typography variant="body2" className="label-title">
                            Date of Creation:
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        {timestamp || '-'}
                      </Grid>
                    </>
                  ) : undefined}

                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      Address:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={streetAddress}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formChanged && formChanged();
                        setParty(validateForm({ ...party, streetAddress: e.target.value }));
                      }}
                      error={showValidator && errors?.streetAddress}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      Address (cont.):
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={streetAddress2}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formChanged && formChanged();
                        setParty({ ...party, streetAddress2: e.target.value });
                      }}
                      error={showValidator && errors?.streetAddress2}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      City:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={addressCity}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formChanged && formChanged();
                        setParty(validateForm({ ...party, addressCity: e.target.value }));
                      }}
                      error={showValidator && errors?.addressCity}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      State:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={addressState}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formChanged && formChanged();
                        setParty(validateForm({ ...party, addressState: e.target.value }));
                      }}
                      error={showValidator && errors?.addressState}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      Zip code:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={addressZip}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formChanged && formChanged();
                        setParty(validateForm({ ...party, addressZip: e.target.value }));
                      }}
                      error={showValidator && errors?.addressZip}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body2" className="label-title">
                      Country:
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={7}>
                    <EasyAutoComplete
                      key={`country-${selectedCountry?.id}`}
                      items={countries || []}
                      label=""
                      textFieldStyle="outlined"
                      optionsLabel="name"
                      selected={selectedCountry}
                      selectedChanged={(value: any): void => {
                        formChanged && formChanged();
                        setParty(
                          validateForm({
                            ...party,
                            addressCountryId: value.id,
                            selectedCountry: value,
                          })
                        );
                      }}
                      error={showValidator && errors?.addressCountryId}
                      getOptionSelected={(option: any, value: any) => {
                        return option.id === value.id;
                      }}
                      disabled={!isApplicationControlled}
                    />
                  </Grid>

                  <Box m={2} />

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Divider />
                        <Box m={2} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4} sm={5}>
                    <Typography variant="body2" className="label-title"></Typography>
                  </Grid>

                  <Grid item xs={8} sm={7}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!isActive}
                          onChange={() => {
                            formChanged && formChanged();
                            setParty(validateForm({ ...party, isActive: !isActive }));
                          }}
                          color="primary"
                          disabled={!isApplicationControlled}
                        />
                      }
                      label={isActive ? 'Active' : 'Inactive'}
                      labelPlacement="start"
                    />
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={3} order={{ xs: 4, sm: 1 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={debounce(() => {
                  showDeleteWarning(true);
                }, DEBOUNCE_TIMEOUT)}
                style={isApplicationControlled ? { background: 'red' } : undefined}
                disabled={!isApplicationControlled}
              >
                Delete
              </Button>
            </Grid>

            <Grid item style={{ width: '50px' }} xs={12} sm={3} order={{ xs: 3, sm: 2 }}></Grid>

            <Grid item xs={12} sm={3} order={{ xs: 2, sm: 3 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={debounce(
                  checkAndProceed!(() => {
                    history.push('/parties-list');
                  }),
                  DEBOUNCE_TIMEOUT
                )}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item xs={12} sm={3} order={{ xs: 1, sm: 4 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={debounce(() => {
                  if (onSubmitValidate()) {
                    setPartyDialogOpen(true);
                  }
                }, DEBOUNCE_TIMEOUT)}
                disabled={!isApplicationControlled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
};
