import React, { FC, useEffect, useMemo, useState } from 'react';
import { EasyAutoComplete } from 'components';
import { useMember } from './hooks';

interface IItem {
  id: string;
  name: string;
  email: string | null;
}

export interface ISelectMemberProps {
  onSelectChange?: (selectedItem: IItem[]) => void;
  selectedItems?: IItem[] | null;
  selectedOwners?: IItem[] | null;
  error?: boolean;
  disabled?: boolean;
}

export const SelectMultipleMembers: FC<ISelectMemberProps> = ({
  onSelectChange,
  selectedItems,
  selectedOwners,
  error,
  disabled,
}) => {
  const [search, setSearch] = useState('');
  const { users, maxPage, loadPage, loadPageCount } = useMember({ search });

  const ownerIds = useMemo(() => {
    return selectedOwners?.map((o) => o.id) ?? [];
  }, [selectedOwners]);

  const loadedUserIds = useMemo(() => {
    return users.map((user) => user.id);
  }, [users]);

  const usersList = useMemo(() => {
    const loadedUsers = [...users];
    if (selectedItems) {
      for (var selectedItem of selectedItems) {
        if (!loadedUserIds.includes(selectedItem.id)) {
          loadedUsers.push(selectedItem);
        }
      }
    }
    return loadedUsers.filter((u) => !ownerIds.includes(u.id));
  }, [users, selectedItems, loadedUserIds, ownerIds]);

  useEffect(() => {
    loadPage(0);
    loadPageCount();
  }, [search, loadPage, loadPageCount]);

  return (
    <EasyAutoComplete
      multiple
      items={usersList}
      disabled={disabled}
      selected={selectedItems || []}
      label=""
      textFieldStyle="outlined"
      optionsLabel="name"
      selectedChanged={(value: any): void => {
        onSelectChange && onSelectChange(value);
      }}
      error={!!error}
      pagination={{
        pages: maxPage,
        loadPage: (page: number) => {
          loadPage(page);
          // loadPageCount();
        },
      }}
      onInputChange={(event: object, value: string, reason: string) => {
        setSearch(value);
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.id === value.id || (value.id === '0' && option.email === value.email);
      }}
    />
  );
};
