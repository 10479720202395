import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import './index.scss';

import { useHistory } from 'react-router-dom';

import { NavigationTabs, documentTabs } from '../NavigationTabs';

import { useComponentContext } from '../../LegalFolderDocumentContext';

import { paths } from 'constants/index';

import { useState, useCallback, useMemo } from 'react';

import { PartyReviewStatus } from './components/PartyReviewStatus/PartyReviewStatus';
import { NewDocumentVersion } from './components/NewDocumentVersion';
import { DocumentApprovalReviewer } from './components/DocumentApprovalReviewer';
import { EventCommentLog } from './components/EventCommentLogs';
import { EventCommentSubmit } from './components/EventCommentSubmit/EventCommentSubmit';
import { FileLogs } from './components/FileLogs/FileLogs';
import { SupportFiles } from './components/SupportFiles/SupportFiles';
import DocumentApprovalSection from './components/DocumentApprovalSection';

import { IReviewer } from '../Reviewers/interfaces';
import { useMsalAccount } from 'hooks/msalAccount';

const isCurrentUser = (account: Record<string, any>, reportUser: IReviewer): boolean => {
  return (
    account?.idToken?.email === reportUser.employeeEmail ||
    account?.idToken?.preferred_username === reportUser.employeeEmail
  );
};

export const FileManagement = () => {
  const { msalAccount: currentAccount } = useMsalAccount();
  const history = useHistory();

  const [rightPartTabsValue, setRightPartTabsValue] = useState('0');

  const { projectFolderDocument, projectFolderDocumentRefetch, refetchEventsList } =
    useComponentContext();

  const {
    id: documentId,
    partyReviewStatus,
    events,
    isPartyReviewRequired,
    versions,
    supportFiles,
    owners,
    containerOwners,
    legalFolderOwners,
  } = projectFolderDocument;

  const handleSetPage = useCallback(
    (newPage: documentTabs) => {
      if (newPage === documentTabs.DOCUMENT_VIEW) {
        history.push(paths.client.PROJECT_FOLDER_DOCUMENT_ID.replace(':documentId', documentId));
      }
    },
    [history, documentId]
  );

  // TODO
  // const onDrop = useCallback(() => {}, []);
  // const removeFile = useCallback(() => {}, []);
  // const isUploading = useCallback(() => {}, []);
  // const percentage = useCallback(() => {}, []);
  // const files = useCallback(() => {}, []);

  const isReviewer = useCallback((): boolean => {
    return (
      !!currentAccount &&
      !!projectFolderDocument?.reviewers.find((user) => isCurrentUser(currentAccount, user))
    );
  }, [projectFolderDocument?.reviewers, currentAccount]);

  const personas = useMemo(
    () => [
      ...(owners?.map(({ id, name }) => ({ id, persona: 'OWNER', name })) ?? []),
      ...(containerOwners?.map(({ id, name }) => ({
        id,
        persona: 'PROJECT FOLDER OWNER',
        name,
      })) ?? []),
      ...(legalFolderOwners?.map(({ id, name }) => ({
        id,
        persona: 'LEGAL FOLDER OWNER',
        name,
      })) ?? []),
    ],
    [containerOwners, legalFolderOwners, owners]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="md" style={!matches ? { padding: '0px' } : undefined}>
      <div>
        <Box m={4} />
        <NavigationTabs
          page={documentTabs.FILE_MANAGEMENT}
          handleSetPage={handleSetPage}
        ></NavigationTabs>
      </div>
      <Paper elevation={3} style={{ padding: '1rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={8}>
                <Grid item xs={8}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <NewDocumentVersion
                            document={projectFolderDocument}
                            refetchDocument={projectFolderDocumentRefetch}
                          />
                        </Grid>

                        {isReviewer() && projectFolderDocument.canReview ? (
                          <Grid item xs={12}>
                            {currentAccount && (
                              <DocumentApprovalSection
                                account={currentAccount}
                                projectFolderDocument={projectFolderDocument}
                                refetchDocument={projectFolderDocumentRefetch}
                              />
                            )}
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body2" className="label-title">
                        SELECT NEXT REVIEWER FOR DOCUMENT APPROVAL
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <DocumentApprovalReviewer
                        projectFolderDocument={projectFolderDocument}
                        refetchDocument={projectFolderDocumentRefetch}
                        enableSubmit
                      ></DocumentApprovalReviewer>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>

                    {!isPartyReviewRequired ? undefined : (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="body2" className="label-title">
                              OTHER PARTY APPROVAL
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <PartyReviewStatus
                              documentId={documentId}
                              initialStatus={partyReviewStatus}
                              events={events}
                              refresh={refetchEventsList}
                            ></PartyReviewStatus>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="body2" className="label-title-nocase">
                        Events/comments log
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ display: 'flex', gap: '2em', width: '85%' }}>
                        <EventCommentSubmit
                          documentId={projectFolderDocument.id}
                          refetchDocument={projectFolderDocumentRefetch}
                        ></EventCommentSubmit>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <EventCommentLog
                        events={events}
                        documentArchived={false}
                        documentId={projectFolderDocument.id}
                        refetchDocument={projectFolderDocumentRefetch}
                        personas={personas}
                      ></EventCommentLog>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Tabs
                        value={rightPartTabsValue}
                        onChange={(e, val) => {
                          setRightPartTabsValue(val);
                        }}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { background: '#85A278' } }}
                      >
                        <Tab label="Document Versions" value="0" />
                        <Tab label="Support Docs" value="1" />
                      </Tabs>
                      <div style={{ display: rightPartTabsValue === '0' ? '' : 'none' }}>
                        <Box m={2} />
                        <FileLogs
                          versions={versions}
                          documentId={parseInt(projectFolderDocument.id)}
                          refetchDocument={projectFolderDocumentRefetch}
                          projectFolderDocument={projectFolderDocument}
                          personas={personas}
                        />
                      </div>

                      <div style={{ display: rightPartTabsValue === '1' ? '' : 'none' }}>
                        {/* Placeholder for support documents */}
                        <Box m={2} />
                        <SupportFiles
                          documentId={parseInt(projectFolderDocument.id)}
                          supportFiles={supportFiles}
                          refetchDocument={projectFolderDocumentRefetch}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box m={5} />
        </Container>
      </Paper>
    </Container>
  );
};
