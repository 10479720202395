import { LegalFolderForm } from 'template/LegalFolder/components/LegalFolderForm/LegalFolderForm';
import { Provider as LegalFolderContext } from 'template/LegalFolder/LegalFolderContext';
import { useComponentContext } from '../../../../TreeTableContext';

export const NewLegalFolderView = () => {
  const { onNewLegalFolderCreated } = useComponentContext();

  return (
    <LegalFolderContext onCreated={onNewLegalFolderCreated}>
      <LegalFolderForm></LegalFolderForm>
    </LegalFolderContext>
  );
};
