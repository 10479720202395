import { gql } from '@apollo/client';
import { ICountry } from './fragments';

export const GET_COUNTRIES = gql`
  query countries(
    $sort: [CountrySort!] = []
    $filter: CountryFilter
    $take: Int = 1000
    $skip: Int = 0
  ) {
    countries(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...ICountry
    }
  }
  ${ICountry}
`;

export const GET_COUNTRY_COUNT = gql`
  query countryCount($filter: CountryFilter) {
    countryCount(filter: $filter)
  }
  ${ICountry}
`;
