import { useComponentContext } from '../../TableSectionContext';
import { useComponentContext as useTreeTableComponentContext } from 'template/TreeTable/TreeTableContext';
import { Provider as ProjectFolderProvider } from 'template/ProjectFolder/ProjectFolderContext';
import { useQuery } from '@apollo/client';
import { GET_PROJECT_FOLDER } from 'graphql/legalFolders/legalFolders';
import { projectFolder } from 'graphql/legalFolders/types/projectFolder';
import { ProjectFolderForm } from 'template/ProjectFolder/components/ProjectFolderForm/ProjectFolderForm';
import { useEffect, useState } from 'react';

export const ProjectFolderView = () => {
  const { projectFolderId, legalFolderId } = useComponentContext();
  const { onProjectFolderUpdated } = useTreeTableComponentContext();
  const { data, loading, refetch, error } = useQuery<projectFolder>(GET_PROJECT_FOLDER, {
    variables: {
      id: projectFolderId,
    },
    errorPolicy: 'all',
  });

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (error) {
      setInvalid(true);
    }
  }, [error]);

  useEffect(() => {
    if (!data && loading) {
      setInvalid(false);
    }
  }, [data, loading]);

  return (
    <ProjectFolderProvider
      loadedProjectFolder={data?.contract_container}
      refetch={() => {
        onProjectFolderUpdated!(projectFolderId!);
        refetch();
      }}
      loading={loading || invalid}
      legalFolderId={legalFolderId!}
    >
      {/* <NewProjectFolderButton></NewProjectFolderButton> */}
      <ProjectFolderForm></ProjectFolderForm>
    </ProjectFolderProvider>
  );
};
