import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { legalFolderUpdateVariables } from 'graphql/legalFolders/types/legalFolderUpdate';
import { legalFolder_contract_legalFolder } from 'graphql/legalFolders/types/legalFolder';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import {
  LEGAL_FOLDER_CREATE_MUTATION,
  LEGAL_FOLDER_SOFT_DELETE,
  LEGAL_FOLDER_UPDATE_MUTATION,
} from 'graphql/legalFolders/legalFolders';
import validate from 'validate.js';
import { validators } from 'constants/validators';
import { useUI } from 'contexts/UiContext';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { pick } from 'lodash';
import { party, party_party } from 'graphql/legalFolders/types/party';
import { GET_PARTY } from 'graphql/legalFolders/parties';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useComponentContext as useMyTask } from 'contexts/MyTask';
import { useNewLegalFolderValidation } from 'hooks/newLegalFolderValidationHook';
import { MAX_OWNERS } from 'constants/config';

interface IParty extends Omit<party_party, '__typename'> {}

interface IOwner {
  id: string;
  name: string;
  email: string | null;
}

interface IMember {
  id: string;
  name: string;
  email: string | null;
}

interface ILegalFolderData extends legalFolderUpdateVariables {
  parties: Array<IParty | null>;
  isValid: boolean;
  showValidator: boolean;
  errors?: any;
  createdAt?: Date;
  updatedAt?: Date;
  owners: IOwner[] | null;
  members: IMember[] | null;
}

export interface IContextState {
  legalFolder: ILegalFolderData;
  loading?: boolean;
  validateLegalFolderNameResult?: any | null | undefined;
  validateLegalFolderPartiesResult?: any[] | null | undefined;
  similarLegalFolders?: any[] | null | undefined;
  validateLegalFolderNameTableDataSource?: any;
  validateLegalFolderPartiesTableDataSource?: any;
  similarLegalFoldersTableDataSource?: any;
}

export interface IContextActions {
  onSetLegalFolder: (cb: (oldLegalFolder: ILegalFolderData) => ILegalFolderData) => void;
  onSubmit: () => void;
  onSubmitValidate: () => boolean;
  onAddPartyBlock: () => void;
  onAddParty: (id: string, index: number) => void;
  onReloadParty: (id: string) => void;
  onRemoveParty: (index: number) => void;
  onDeleteProcess: () => Promise<boolean>;
  onSelectOwnersChange: (selectedownersList: IOwner[]) => void;
  onSelectMembersChange: (selectedMembersList: IMember[]) => void;
  onValidateLegalFolderDetails: () => Promise<boolean>;
}

const legalFolderValidators = {
  name: validators.simpleText,
};

const newLegalFolder: ILegalFolderData = {
  id: '',
  name: '',
  notes: '',
  isValid: true,
  showValidator: false,
  partyIdsToAdd: [],
  partyIdsToRemove: [],
  parties: [null],
  owners: [],
  members: [],
  notifyMembers: true,
  notifyOwners: true,
};

const initialState = {
  legalFolder: newLegalFolder,
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  loadedLegalFolder?: legalFolder_contract_legalFolder | null;
  refetch?: any;
  loading?: boolean;
  onCreated?: (id: string) => void;
  children: any;
}

export const Provider: FC<IProviderProps> = ({
  loadedLegalFolder,
  refetch,
  loading,
  children,
  onCreated,
}) => {
  const { myTask, setMyTask } = useMyTask();
  const client = useApolloClient();
  const { formChanged, resetChanged } = useFormChangedDialogContext();
  const { addSnackbar } = useUI();
  const [legalFolder, setLegalFolder] = useState<ILegalFolderData>(newLegalFolder);

  const legalFolderName = useMemo(() => {
    return legalFolder.name || '';
  }, [legalFolder.name]);

  const legalFolderPartieIds = useMemo(() => {
    return legalFolder.parties.filter((party) => !!party).map((party) => party!.id);
  }, [legalFolder.parties]);

  const {
    validateNewLegalFolder,
    validateLegalFolderNameResult,
    validateLegalFolderPartiesResult,
    similarLegalFolders,
    validateLegalFolderNameTableDataSource,
    validateLegalFolderPartiesTableDataSource,
    similarLegalFoldersTableDataSource,
  } = useNewLegalFolderValidation({
    name: legalFolderName,
    partyIds: legalFolderPartieIds,
  });

  const [createLegalFolderMutation] = useMutation(LEGAL_FOLDER_CREATE_MUTATION);
  const [updateLegalFolderMutation] = useMutation(LEGAL_FOLDER_UPDATE_MUTATION);

  const [deleteLegalFolderMutation] = useMutation(LEGAL_FOLDER_SOFT_DELETE);

  const getPartyQuery = useCallback(
    async (variables: any) => {
      let party: party_party | null = null;
      try {
        const { data } = await client.query<party>({
          query: GET_PARTY,
          variables,
          fetchPolicy: 'network-only',
        });
        party = data?.party;
      } catch (error) {
        apolloErrorHandler(addSnackbar!)(error as ApolloError);
      }

      if (party) {
        setLegalFolder((legalFolder) => {
          const { parties } = legalFolder;
          const newParties = [...parties];
          if (newParties.length > 0 && newParties.slice(-1)[0] === null) {
            newParties.pop();
          }
          const foundIndex = newParties.findIndex(
            (checkParty) => checkParty && checkParty.id === party!.id
          );
          if (foundIndex >= 0) {
            newParties[foundIndex] = party;
          } else {
            newParties.push(party);
          }
          return { ...legalFolder, parties: newParties };
        });
      }
    },
    [client, addSnackbar]
  );

  const memberOwnerCheckDuplicates = useCallback(
    (newState: any) => {
      const { members, owners } = newState;

      console.log('memberOwnerCheckDuplicates');
      console.log('legalFolder', legalFolder);
      console.log('owners', owners);
      console.log('members', members);

      if (!members?.length || !owners?.length) {
        return undefined;
      }
      const ownerIds = owners.map((owner: any) => owner.id);
      const fundMembers = members.filter((member: any) => ownerIds.includes(member.id));

      if (fundMembers?.length) {
        return (
          'Owners and Members duplicates: ' +
          fundMembers.map((member: any) => member.name).join(', ')
        );
      }

      return undefined;
    },
    [legalFolder]
  );

  const validateForm = useCallback(
    (newState: any) => {
      console.log('validateForm');
      let errors = validate(newState, legalFolderValidators);
      const memberDuplicates = memberOwnerCheckDuplicates(newState);
      if (memberDuplicates) {
        console.log('Duplicates found');
        errors = { ...errors, members: memberDuplicates };
      }
      return { ...newState, errors, isValid: errors ? false : true };
    },
    [memberOwnerCheckDuplicates]
  );

  useEffect(() => {
    console.log('legalFolder', legalFolder);
    console.log('legalFolder.isValid', legalFolder.isValid);
  }, [legalFolder]);

  const onSetLegalFolder = useCallback(
    (cb: (oldState: ILegalFolderData) => ILegalFolderData) => {
      formChanged && formChanged();
      setLegalFolder((oldState) => {
        const newState = cb(oldState);
        return validateForm(newState);
      });
    },
    [setLegalFolder, formChanged, validateForm]
  );

  const onValidateLegalFolderDetails = useCallback(async () => {
    const { validateLegalFolderNameResult, validateLegalFolderPartiesResult, similarLegalFolders } =
      await validateNewLegalFolder();
    return (
      !validateLegalFolderNameResult && !validateLegalFolderPartiesResult && !similarLegalFolders
    );
  }, [validateNewLegalFolder]);

  const onSubmitValidate = useCallback(() => {
    const { isValid, name, parties, id, owners, members } = legalFolder;
    if (
      !isValid ||
      !name ||
      !parties.length ||
      parties.find((party) => party === null) === null ||
      (parseInt(id) > 0 && (!owners || owners?.length > MAX_OWNERS || owners.length < 1)) ||
      (members && members?.length > MAX_OWNERS)
    ) {
      setLegalFolder(validateForm({ ...legalFolder, showValidator: true }));
      return false;
    }
    return true;
  }, [legalFolder, validateForm]);

  const onSubmit = async () => {
    console.log('onSubmit', legalFolder);
    if (!onSubmitValidate()) {
      return;
    }

    if (parseInt(legalFolder.id) > 0) {
      const partyIdsToSave: string[] = legalFolder.parties
        .filter((party) => party !== null)
        .map((party) => party!.id);
      const oldPartyIds: string[] = loadedLegalFolder?.parties.map((party) => party.id) || [];

      const oldMembersIds = loadedLegalFolder?.members?.map((member) => member.user?.id) || [];
      const newMembersIds = legalFolder.members?.map((member) => member.id) || [];

      const memberUserIdsToAdd = newMembersIds.filter((itemId) => !oldMembersIds.includes(itemId));
      const memberUserIdsToRemove = oldMembersIds.filter(
        (itemId) => !newMembersIds.includes(itemId!)
      );

      const oldOwnerIds = loadedLegalFolder?.owners?.map((owner) => owner.user?.id) || [];
      const newOwnerIds = legalFolder.owners?.map((owner) => owner.id) || [];

      const ownerUserIdsToAdd = newOwnerIds.filter((itemId) => !oldOwnerIds.includes(itemId));
      const ownerUserIdsToRemove = oldOwnerIds.filter((itemId) => !newOwnerIds.includes(itemId!));

      const saveData = {
        ...pick(legalFolder, ['id', 'name', 'notes', 'notifyOwners', 'notifyMembers']),
        partyIdsToAdd: partyIdsToSave.filter((id) => !oldPartyIds?.includes(id)),
        partyIdsToRemove: oldPartyIds.filter((id) => !partyIdsToSave?.includes(id)),
        memberUserIdsToAdd,
        memberUserIdsToRemove,
        ownerUserIdsToAdd,
        ownerUserIdsToRemove,
      };

      const updateLegalFolderId = await updateProcedure({
        mutation: () =>
          updateLegalFolderMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => data?.contract_legalFolderUpdate?.id,
      });

      if (updateLegalFolderId) {
        await client.resetStore();
      }
    } else {
      const saveData = {
        ...pick(legalFolder, ['name', 'notes', 'notifyOwners', 'notifyMembers']),
        // partyIds: legalFolder.selectedPartyItems.map((party) => party.key),
        partyIds: legalFolder.parties.filter((party) => party !== null).map((party) => party?.id),
        memberUserIds:
          legalFolder.members?.filter((member) => member !== null).map((member) => member.id) || [],
      };

      const newLegalFolderId = await updateProcedure({
        mutation: () =>
          createLegalFolderMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => data?.contract_legalFolderCreate?.id,
      });

      if (!!newLegalFolderId) {
        if (myTask) {
          addSnackbar!({ severity: 'info', text: 'My Task is turned off' });
        }
        setMyTask(false);
        if (onCreated) {
          onCreated(newLegalFolderId);
        } else {
          // TODO: redirect to edit page
        }
      }

      if (newLegalFolderId) {
        await client.resetStore();
      }
    }
    resetChanged && resetChanged();
  };

  const updateProcedure = async ({
    mutation,
    parseResult,
  }: {
    mutation: any;
    parseResult: any;
  }) => {
    let result = undefined;
    try {
      addSnackbar!({
        text: 'please wait ...',
        severity: 'info',
      });
      const { data } = await mutation();
      result = parseResult(data);
      if (result) {
        addSnackbar!({
          text: 'Legal folder is saved',
          severity: 'success',
        });
      } else {
        addSnackbar!({
          text: 'Unable to process request, please try again',
          severity: 'error',
        });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    return result;
  };

  const onAddPartyBlock = useCallback(() => {
    formChanged && formChanged();
    setLegalFolder((legalFolder) => {
      const { parties } = legalFolder;
      return { ...legalFolder, parties: [...parties, null] };
    });
  }, [formChanged]);

  const onAddParty = useCallback(
    (id: string, index: number) => {
      if (!legalFolder.parties.find((party) => party?.id === id)) {
        const variables = { id };
        getPartyQuery(variables);
      }
    },
    [getPartyQuery, legalFolder.parties]
  );

  const onReloadParty = useCallback(
    (id: string) => {
      if (legalFolder.parties.find((party) => party?.id === id)) {
        const variables = { id };
        getPartyQuery(variables);
      }
    },
    [getPartyQuery, legalFolder.parties]
  );

  const onRemoveParty = useCallback(
    (index: number) => {
      formChanged && formChanged();
      setLegalFolder((legalFolder) => {
        const { parties } = legalFolder;
        return {
          ...legalFolder,
          parties: [...parties.slice(0, index), ...parties.slice(index + 1)],
        };
      });
    },
    [formChanged]
  );

  useEffect(() => {
    if (loadedLegalFolder) {
      const {
        id,
        name,
        notes,
        parties,
        owners,
        members,
        notifyOwners,
        notifyMembers,
        createdAt,
        updatedAt,
      } = loadedLegalFolder;

      const mappedOwners: IOwner[] | undefined = owners
        ?.filter((owner) => owner && owner.user)
        .map((owner) => {
          const { id, email, name } = owner!.user!;
          return {
            id,
            email,
            name,
          };
        });

      const mappedMembers: IMember[] | undefined = members
        ?.filter((member) => member && member.user)
        .map((member) => {
          const { id, email, name } = member!.user!;
          return {
            id,
            email,
            name,
          };
        });

      setLegalFolder({
        id,
        name,
        notifyOwners,
        notifyMembers,
        notes,
        isValid: true,
        showValidator: false,
        // selectedPartyItems: parties.map((party) => {
        //   return { key: party.id, name: party.name };
        // }),
        parties: [...parties],
        createdAt,
        updatedAt,
        owners: mappedOwners || [],
        members: mappedMembers || [],
      });
    }
  }, [loadedLegalFolder]);

  const onDeleteProcess = useCallback(async () => {
    let success = false;
    const variables = { legalFolderId: legalFolder.id };
    try {
      const { data } = await deleteLegalFolderMutation({
        variables,
      });
      if (data?.contract_legalFolderSoftDelete) {
        addSnackbar!({ text: 'Legal Folder is deleted', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      // await client.resetStore();
    }
    return success;
  }, [addSnackbar, deleteLegalFolderMutation, legalFolder]);

  const onSelectOwnersChange = useCallback(
    (selectedOwnersList: IOwner[] | null) => {
      formChanged && formChanged();
      setLegalFolder((legalFolder) => {
        const selectedOwnersIds = selectedOwnersList?.map((owner) => owner.id);
        const members = legalFolder.members?.filter(
          (member) => !selectedOwnersIds?.includes(member.id)
        );
        return validateForm({ ...legalFolder, owners: selectedOwnersList, members });
      });
    },
    [setLegalFolder, formChanged, validateForm]
  );

  const onSelectMembersChange = useCallback(
    (selectedMembersList: IOwner[] | null) => {
      console.log('Members changed');
      formChanged && formChanged();
      setLegalFolder((legalFolder) => {
        return validateForm({ ...legalFolder, members: selectedMembersList });
      });
    },
    [setLegalFolder, formChanged, validateForm]
  );

  return (
    <ComponentContext.Provider
      value={{
        legalFolder,
        loading,
        onSetLegalFolder,
        onSubmit,
        onSubmitValidate,
        // onSelectPartyChange,
        onAddPartyBlock,
        onRemoveParty,
        onAddParty,
        onReloadParty,
        onDeleteProcess,
        onSelectOwnersChange,
        onSelectMembersChange,
        onValidateLegalFolderDetails,
        validateLegalFolderNameResult,
        validateLegalFolderPartiesResult,
        similarLegalFolders,
        validateLegalFolderNameTableDataSource,
        validateLegalFolderPartiesTableDataSource,
        similarLegalFoldersTableDataSource,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
