/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from 'react';
import {
  Button,
  Box,
  Divider,
  TextareaAutosize,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { Alert } from '@mui/lab';

import { useUpload } from './hooks';
import { DocumentApprovalUploadSectionProps } from './interfaces';

import { ConfirmationDialog, Upload } from 'components';

import './index.scss';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const defaultDialogConfirmation = {
  title: 'DOCUMENT UPLOAD CONFIRMATION',
  message: 'Please confirm new document upload and new comment.',
  style: { width: 380 },
};

const DocumentApprovalUploadSection: FC<DocumentApprovalUploadSectionProps> = ({
  handleDocumentApproval,
  onChangeComment,
  handleFiles,
  files,
  open,
}: DocumentApprovalUploadSectionProps) => {
  const [dialogContent, setDialogContent] = useState(defaultDialogConfirmation);
  const {
    isUploading,
    percentage,
    onDrop,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    openConfirmDialog,
    removeFile,
  } = useUpload({ handleDocumentApproval, handleFiles });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);

  useEffect(() => {
    setDialogContent({
      title: 'FEEDBACK CONFIRMATION',
      message: 'Please confirm your feedback.',
      style: { width: 380 },
    });
  }, []);

  useEffect(() => {
    setDialogOpen(open);
    if (open === true) {
      setReminderDialogOpen(true);
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="sm" open={dialogOpen}>
      <ConfirmationDialog
        open={openConfirmDialog}
        title={dialogContent.title}
        message={dialogContent.message}
        style={dialogContent.style}
        onClose={handleCloseConfirmDialog}
      />

      <DialogContent dividers>
        <Box mb={2}>
          <Alert severity={'success'}>
            <strong>You are signing the document.</strong>
          </Alert>
        </Box>

        <Divider />
        <Box mt={2} marginBottom={4} className="document-approval-upload-section-upload">
          <span className="document-approval-upload-section-upload-component">
            <Upload
              onDrop={onDrop}
              removeFile={removeFile}
              isUploading={isUploading}
              percentage={percentage}
              files={files}
              hint={''}
            />
          </span>
        </Box>

        <Box marginBottom={1}>
          <Box marginBottom={1}>
            <strong>Comment (optional):</strong>
          </Box>

          <TextareaAutosize
            color="grey"
            style={{
              width: '95%',
              maxWidth: '95%',
              minWidth: '95%',
              minHeight: '1rem',
              padding: 10,
              borderRadius: 4,
              overflow: 'auto',
              border: '1px #ccc solid',
            }}
            aria-label="minimum height"
            minRows={1}
            placeholder="Write comment..."
            className="MuiInputBase-input"
            onChange={onChangeComment}
          />
        </Box>

        <Box marginTop={1} className="document-approval-upload-section-submit" />

        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <span>You can submit the sign feedback without uploading a file.</span>
          </Grid>
        </Grid>

        <Box marginBottom={5} className="document-approval-upload-section-submit" />
      </DialogContent>
      <DialogActions>
        <Grid style={{ margin: 'auto' }}>
          <Box pb={1}>
            <Button
              variant="contained"
              size="medium"
              onClick={debounce((): void => {
                setDialogOpen(false);
                handleDocumentApproval(false);
              }, DEBOUNCE_TIMEOUT)}
              style={{ fontSize: '0.85rem', backgroundColor: '#ddd', width: 135 }}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={debounce(handleOpenConfirmDialog, DEBOUNCE_TIMEOUT)}
              style={{ width: 135 }}
            >
              Submit
            </Button>{' '}
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentApprovalUploadSection;
