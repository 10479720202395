import { Button } from '@mui/material';
import React, { FC } from 'react';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const NewDocumentButton: FC<any> = ({ ...props }) => {
  const { confirmRequest } = useFormChangedDialogContext();
  const { onNewProjectDocument } = useComponentContext();

  const onAddProjectDocument = () => {
    confirmRequest!((confirmed) => {
      confirmed && onNewProjectDocument && onNewProjectDocument();
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={debounce(onAddProjectDocument, DEBOUNCE_TIMEOUT)}
      {...props}
    >
      New Document
    </Button>
  );
};
