import { Button, Grid } from '@mui/material';
import EasyAutoComplete from 'components/EasyAutoComplete';
import React, { FC, useMemo, useState, useEffect } from 'react';
import { documentPartyReviewStatus } from './constant';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { ApolloError, useMutation } from '@apollo/client';
import { useUI } from 'contexts/UiContext';
import { DOCUMENT_PARTY_REVIEW_STATUS_CHANGE } from 'graphql/legalFolders/documents';

import s from './style.module.scss';
import { IEvent } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface IPartyReviewStatusProps {
  documentId: string;
  initialStatus: string;
  events: IEvent[];
  refresh?: () => void;
}

export const PartyReviewStatus: FC<IPartyReviewStatusProps> = ({
  refresh,
  documentId,
  initialStatus,
  events,
}) => {
  const { addSnackbar } = useUI();

  const [status, setStatus] = useState<string>(initialStatus);

  const [documentPartyReviewStatusChangeMutation] = useMutation(
    DOCUMENT_PARTY_REVIEW_STATUS_CHANGE
  );

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const updateProcedure = async ({
    mutation,
    parseResult,
  }: {
    mutation: any;
    parseResult: any;
  }) => {
    let result = undefined;
    try {
      // await apolloClient.resetStore();
      const { data } = await mutation();
      result = parseResult(data);
      if (!!result) {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
        refresh && refresh();
      } else {
        addSnackbar!({
          text: 'Unable to process request, please try again',
          severity: 'error',
        });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    return result;
  };

  const onSubmit = async () => {
    const saveData = {
      status,
      documentId,
    };
    await updateProcedure({
      mutation: () =>
        documentPartyReviewStatusChangeMutation({
          variables: saveData,
        }),
      parseResult: (data: any) => data?.contract_documentPartyReviewStatusChange?.eventType,
    });
  };

  const selectedStatus = useMemo(() => {
    return status
      ? documentPartyReviewStatus.find((checkStatus) => checkStatus.id === status)
      : { id: 'NONE', name: 'None' };
  }, [status]);

  const approvalChangeDates = useMemo(() => {
    const statusChanged = events
      .filter((ev) => ev.eventType === 'DOCUMENT_PARTY_REVIEW_STATUS_CHANGED')
      .reverse();
    let pending;
    let approved;
    try {
      pending = statusChanged.find(
        (ev) => ev.payload && JSON.parse(ev.payload).status === 'PENDING'
      );
      approved = statusChanged.find(
        (ev) => ev.payload && JSON.parse(ev.payload).status === 'APPROVED'
      );
    } catch {}
    return { pending, approved };
  }, [events]);

  return (
    <Grid container spacing={2}>
      <Grid item className={s.action} xs={12} sm={7} md={6} lg={7} xl={8} order={{ xs: 2, sm: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6}>
            <div className={s.select}>
              <EasyAutoComplete
                key={`partyApproval`}
                items={documentPartyReviewStatus}
                label=""
                textFieldStyle="outlined"
                optionsLabel="name"
                selected={selectedStatus}
                selectedChanged={(value: any): void => {
                  setStatus(value.id);
                }}
                getOptionSelected={(option: any, value: any) => {
                  return option.id === value.id;
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} xl={6}>
            <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={initialStatus === status}
                onClick={debounce(onSubmit, DEBOUNCE_TIMEOUT)}
                style={{ whiteSpace: 'nowrap' }}
              >
                Save status change
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={s.status} xs={12} sm={5} md={6} lg={5} xl={4} order={{ xs: 1, sm: 2 }}>
        <div>
          <strong>SENT: </strong>
          {approvalChangeDates.pending ? (
            <>
              <strong>v1.{approvalChangeDates?.pending.version?.version}</strong>
              {' - '}
              {new Date(approvalChangeDates?.pending.createdAt as Date).toLocaleDateString()}
            </>
          ) : (
            ' - '
          )}
        </div>
        <div>
          <strong>APPROVED: </strong>
          {approvalChangeDates.approved ? (
            <>
              <strong>v1.{approvalChangeDates.approved.version?.version}</strong>
              {' - '}
              {new Date(approvalChangeDates.approved.createdAt as Date).toLocaleDateString()}
            </>
          ) : (
            ' - '
          )}
        </div>
      </Grid>
    </Grid>
  );

  // return (
  //   <div className={s.section}>
  //     <div className={s.action}>
  //       <div className={s.select}>
  //         <EasyAutoComplete
  //           key={`partyApproval`}
  //           items={documentPartyReviewStatus}
  //           label=""
  //           textFieldStyle="outlined"
  //           optionsLabel="name"
  //           selected={selectedStatus}
  //           selectedChanged={(value: any): void => {
  //             setStatus(value.id);
  //           }}
  //           getOptionSelected={(option: any, value: any) => {
  //             return option.id === value.id;
  //           }}
  //         />
  //       </div>
  //       <div>
  //         <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
  //           <Button
  //             fullWidth
  //             variant="contained"
  //             color="primary"
  //             disabled={initialStatus === status}
  //             onClick={debounce(onSubmit}
  //             style={{ whiteSpace: 'nowrap' }}
  //           >
  //             Save status change
  //           </Button>
  //         </div>
  //       </div>
  //     </div>
  //     <div className={s.status}>
  //       <div>
  //         <strong>SENT: </strong>
  //         {approvalChangeDates.pending ? (
  //           <>
  //             <strong>v1.{approvalChangeDates.pending.version?.version}</strong>
  //             {' - '}
  //             {new Date(approvalChangeDates.pending.createdAt as Date).toLocaleDateString()}
  //           </>
  //         ) : (
  //           ' - '
  //         )}
  //       </div>
  //       <div>
  //         <strong>APPROVED: </strong>
  //         {approvalChangeDates.approved ? (
  //           <>
  //             <strong>v1.{approvalChangeDates.approved.version?.version}</strong>
  //             {' - '}
  //             {new Date(approvalChangeDates.approved.createdAt as Date).toLocaleDateString()}
  //           </>
  //         ) : (
  //           ' - '
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // );
};
