import { IGanttChartTask } from 'components/GanttChart/interface';
import { DocumentEventType } from 'constants/enums';
import { IDocumentEvent } from 'graphql/legalFolders/types/IDocumentEvent';
import { startCase, toLower } from 'lodash';
import { useMemo } from 'react';
import { IEvent } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { DocusignsProps } from '../../../../Docusigns/interfaces';
import { useParseEvents } from './parseEvents';
import { useParseDocusignEvents } from './parseDocusignEvents';

interface IGantTask extends IGanttChartTask {
  ev?: IEvent;
}

export const useGanttEvents = (
  events: IDocumentEvent[],
  docusignProps: DocusignsProps,
  personas: { id: string; name: string; persona: string }[]
) => {
  const { parsedEvents } = useParseEvents({ events, personas });
  const { parsedEvents: parsedDocusignEvents } = useParseDocusignEvents(docusignProps);

  const tasks = useMemo(() => {
    const groupedEvents = {
      metaDataEvents: [] as IGantTask[],
      otherEvents: [] as IGantTask[],
    };

    for (const ev of parsedEvents) {
      const ganttEvent: any = {
        id: ev.id,
        name: ev.title,
        start: ev.ev.createdAt,
        end: ev.ev.createdAt,
        progress: 0,
        dependencies: '',
        type: 'event',
        ev,
      };

      // change color
      switch (ev.ev.eventType as DocumentEventType) {
        case DocumentEventType.DOCUMENT_CREATED:
        case DocumentEventType.DOCUMENT_UPDATED:
          ganttEvent.custom_class = 'gantt_blue';
          break;
      }

      // Group document updates
      if (
        [
          DocumentEventType.DOCUMENT_OWNER_CHANGED,
          DocumentEventType.DOCUMENT_CREATED,
          DocumentEventType.DOCUMENT_UPDATED,
          DocumentEventType.DOCUMENT_PARTY_REVIEW_STATUS_CHANGED,
        ].includes(ev.ev.eventType as DocumentEventType)
      ) {
        if (
          ev.ev.eventType === DocumentEventType.DOCUMENT_UPDATED &&
          ev.payload &&
          !!ev.payload.reviewStatus
        ) {
          ganttEvent.name = 'Proposal review status changed to ' + ev.payload.reviewStatus;
        } else {
          if (groupedEvents.metaDataEvents.length) {
            const prev = groupedEvents.metaDataEvents[groupedEvents.metaDataEvents.length - 1];
            Object.assign(prev, { end: ganttEvent.start });
            ganttEvent.dependencies = [ganttEvent.dependencies, prev.id].join(',');
          }
          groupedEvents.metaDataEvents.push(ganttEvent);
          continue;
        }
      }

      if (ev.ev.eventType === DocumentEventType.DOCUMENT_FILE_UPLOADED) {
        ganttEvent.name =
          'Uploaded ' +
          startCase(toLower(ev.payload.documentFileGroup)) +
          ' - ' +
          startCase(toLower(ev.payload.documentFileType)) +
          ' file: ' +
          ev.payload.uploadedFile.fileFilename;

        ganttEvent.dependencies = [
          ganttEvent.dependencies,
          groupedEvents.metaDataEvents[0].id,
        ].join(',');
      }

      // Colors
      switch (ev.ev.eventType) {
        case DocumentEventType.DOCUMENT_REVIEW_RESENT:
        case DocumentEventType.DOCUMENT_REVIEW_REQUESTED:
          ganttEvent.custom_class = 'gantt_orange';
          break;

        case DocumentEventType.DOCUMENT_STATUS_FOLDER_SIGNED:
        case DocumentEventType.DOCUMENT_REVIEW_APPROVED:
          ganttEvent.custom_class = 'gantt_green';
          break;

        case DocumentEventType.DOCUMENT_FILE_DELETED:
        case DocumentEventType.DOCUMENT_REVIEW_DISAPPROVED:
        case DocumentEventType.DOCUMENT_VERSION_DELETED:
          ganttEvent.custom_class = 'gantt_red';
          break;

        case DocumentEventType.DOCUMENT_REVIEW_RECALLED:
          ganttEvent.custom_class = 'gantt_yellow';
          break;

        case DocumentEventType.DOCUMENT_VERSION_CREATED:
        case DocumentEventType.DOCUMENT_REVIEWERS_UPDATED:
        case DocumentEventType.DOCUMENT_DOCUSIGN_CREATED:
          ganttEvent.custom_class = 'gantt_blue';
          break;

        case DocumentEventType.DOCUMENT_DOCUSIGN_ENVELOPE_STATUS_CHANGED:
          if (ev.payload.status === 'voided') {
            ganttEvent.custom_class = 'gantt_red';
          }
      }

      if (ev.ev.eventType === DocumentEventType.DOCUMENT_FILE_DELETED) {
        const parentEvent = groupedEvents.otherEvents.find(
          (parent: any) =>
            parent.ev.ev.eventType === DocumentEventType.DOCUMENT_VERSION_CREATED &&
            parent.ev.ev.version?.versionMinor === ev.ev.version?.versionMinor
        );
        if (parentEvent) {
          ganttEvent.dependencies = [ganttEvent.dependencies, parentEvent.id].join(',');
        }
      }

      if (
        ev.ev.eventType === DocumentEventType.DOCUMENT_REVIEW_REQUESTED ||
        ev.ev.eventType === DocumentEventType.DOCUMENT_DOCUSIGN_CREATED
      ) {
        const parentEvent = groupedEvents.otherEvents.find(
          (parent: any) =>
            parent.ev.ev.eventType === DocumentEventType.DOCUMENT_VERSION_CREATED &&
            parent.ev.ev.version?.version === ev.ev.version?.version
        );
        if (parentEvent) {
          ganttEvent.dependencies = [ganttEvent.dependencies, parentEvent.id].join(',');
        }
      }

      if (ev.ev.eventType === DocumentEventType.DOCUMENT_DOCUSIGN_ENVELOPE_STATUS_CHANGED) {
        const parentEvent = groupedEvents.otherEvents.find(
          (parent: any) =>
            parent.ev.ev.eventType === DocumentEventType.DOCUMENT_DOCUSIGN_CREATED &&
            parent.ev.ev.version?.version === ev.ev.version?.version
        );
        if (parentEvent) {
          ganttEvent.dependencies = [ganttEvent.dependencies, parentEvent.id].join(',');
        }
      }

      if (ev.ev.eventType === DocumentEventType.DOCUMENT_COMMENTED) {
        const parentEvent = groupedEvents.otherEvents.find(
          (parent: any) =>
            parent.ev.ev.eventType === DocumentEventType.DOCUMENT_VERSION_CREATED &&
            parent.ev.ev.version?.version === ev.ev.version?.version
        );
        if (parentEvent) {
          ganttEvent.dependencies = [ganttEvent.dependencies, parentEvent.id].join(',');
        }
      }

      if (
        [
          DocumentEventType.DOCUMENT_REVIEW_APPROVED,
          DocumentEventType.DOCUMENT_REVIEW_DISAPPROVED,
        ].includes(ev.ev.eventType)
      ) {
        const parentEvent = groupedEvents.otherEvents
          .slice()
          .reverse()
          .find(
            (parent: any) =>
              parent.ev.ev.eventType === DocumentEventType.DOCUMENT_REVIEW_REQUESTED &&
              parent.ev.ev.subject.user.id === ev.ev.author.user.id
          );
        if (parentEvent && ev.ev.version?.version === parentEvent.ev?.ev.version?.version) {
          parentEvent.end = ev.ev.createdAt;
          ganttEvent.dependencies = [ganttEvent.dependencies, parentEvent.id].join(',');
          // continue;
        }
      }

      if ([DocumentEventType.DOCUMENT_REVIEW_RESENT].includes(ev.ev.eventType)) {
        const parentEvent = groupedEvents.otherEvents
          .slice()
          .reverse()
          .find(
            (parent: any) =>
              parent.ev.ev.eventType === DocumentEventType.DOCUMENT_REVIEW_REQUESTED &&
              parent.ev.ev.subject.user.id === ev.ev.subject.user.id
          );
        if (parentEvent) {
          parentEvent.end = ev.ev.createdAt;
          ganttEvent.dependencies = [ganttEvent.dependencies, parentEvent.id].join(',');
          // continue;
        }
      }

      groupedEvents.otherEvents.push(ganttEvent);
    }

    // Add docusign events into correct timeline
    for (const dsEvent of parsedDocusignEvents) {
      const index = groupedEvents.otherEvents.findIndex(
        (ev) =>
          ev.ev?.ev?.eventType === 'DOCUMENT_DOCUSIGN_CREATED' &&
          (ev.ev?.payload as any | undefined)?.envelopeId === dsEvent.id
      );
      if (index >= 0) {
        const parentEvent = groupedEvents.otherEvents[index];
        dsEvent.dependencies = [dsEvent.dependencies, parentEvent.id].join(',');
        groupedEvents.otherEvents.splice(index + 1, 0, dsEvent);
      } else {
        groupedEvents.otherEvents.push(dsEvent);
      }
    }

    console.log('groupedEvents.otherEvents', groupedEvents.otherEvents);
    return [
      // ...parsedDocusignEvents,
      ...groupedEvents.metaDataEvents,
      ...groupedEvents.otherEvents,
    ];
  }, [parsedEvents, parsedDocusignEvents]);

  return {
    tasks,
  };
};
