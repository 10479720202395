import { useLazyQuery } from '@apollo/client';
import { EasyAutoComplete } from 'components';
import { GET_PROJECT_COUNT, GET_PROJECT_NAMES } from 'graphql/legalFolders/projects';
import { projectCount } from 'graphql/legalFolders/types/projectCount';
import { projectNames } from 'graphql/legalFolders/types/projectNames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';

const rowsPerPage = 20;
const order = [
  { column: 'KEY', order: 'ASC' },
  { column: 'NAME', order: 'ASC' },
];

interface IPartiesListItem {
  key: string;
  name: string;
}

export interface ISelectProjectProps {
  onSelectChange?: (selectedItems: IPartiesListItem[]) => void;
  selectedItems: IPartiesListItem[];
  projectFolderId?: string;
}

export const SelectProject: FC<ISelectProjectProps> = ({
  onSelectChange,
  selectedItems,
  projectFolderId,
}) => {
  const [search, setSearch] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [projects, setParties] = useState<IPartiesListItem[]>([]);

  const [
    loadProjectCount,
    {
      data: dataProjectCount,
      loading: loadingProjectCount,
      refetch: refetchProjectCount,
      called: calledProjectCount,
    },
  ] = useLazyQuery<projectCount>(GET_PROJECT_COUNT);

  const [getProjectNames, { data, loading, refetch, called, error }] = useLazyQuery<projectNames>(
    GET_PROJECT_NAMES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadPage = useCallback(
    (page: number, search: string) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: order && order.length ? order : undefined,
        filter: {
          isActive: true,
          keyOrNameContains: !search || search === '' ? undefined : search,
          contract_containerId: projectFolderId ? parseFloat(projectFolderId) : undefined,
          // contract_containerAssignable: !projectFolderId,
        },
      };

      if (called) {
        refetch!(variables);
      } else {
        getProjectNames({ variables });
      }
    },
    [getProjectNames, refetch, called, projectFolderId]
  );

  const loadPageCount = useCallback(
    (search: string) => {
      const variables = {
        filter: {
          isActive: true,
          keyOrNameContains: !search || search === '' ? undefined : search,
          contract_containerId: projectFolderId ? parseFloat(projectFolderId) : undefined,
          // contract_containerAssignable: !projectFolderId,
        },
      };

      if (calledProjectCount) {
        refetchProjectCount!(variables);
      } else {
        loadProjectCount({ variables });
      }
    },
    [loadProjectCount, refetchProjectCount, calledProjectCount, projectFolderId]
  );

  useEffect(() => {
    if (!loadingProjectCount && dataProjectCount) {
      setTotalItems(dataProjectCount.projectCount);
    }
  }, [loadingProjectCount, dataProjectCount]);

  useEffect(() => {
    if (data && !loading && !error) {
      const ddList = data.projects.map((project) => {
        return { key: project.id, name: project.key + ' - ' + project.name };
      });
      setParties((projects) => {
        return [...projects, ...ddList];
      });
    }
  }, [data, loading, error]);

  useEffect(() => {
    setParties([]);
    loadPage(0, search);
    loadPageCount(search);
  }, [search, loadPage, loadPageCount]);

  const maxPage = useMemo(() => Math.ceil(Number(totalItems) / Number(rowsPerPage)), [totalItems]);

  const projectsIds = useMemo(() => {
    return projects.map((project) => project.key);
  }, [projects]);

  return (
    <EasyAutoComplete
      multiple
      items={[...projects, ...selectedItems.filter((item) => !projectsIds.includes(item.key))]}
      selected={selectedItems}
      label=""
      textFieldStyle="outlined"
      optionsLabel="name"
      selectedChanged={(value: any): void => {
        onSelectChange && onSelectChange(value);
      }}
      error={false}
      pagination={{
        pages: maxPage,
        loadPage: (page: number) => {
          loadPage(page, search);
        },
      }}
      onInputChange={(event: object, value: string, reason: string) => {
        setSearch(value);
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.key === value.key;
      }}
    />
  );
};
