import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_GEO_LOCATION } from 'graphql/legalFolders/geo';
import { resolveGeoAddress } from '../graphql/legalFolders/types/resolveGeoAddress';

export const useGeoAddress = () => {
  const [getGeoAddress, { called, data, loading, error, refetch }] =
    useLazyQuery<resolveGeoAddress>(GET_GEO_LOCATION, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const load = useCallback(
    (variables: any) => {
      if (called) {
        refetch!(variables);
      } else {
        getGeoAddress({ variables });
      }
    },
    [getGeoAddress, refetch, called]
  );

  return {
    load,
    geoAddress: data?.resolveGeoAddress,
    loading,
    error,
  };
};
