import React, { FC } from 'react';
import './index.scss';
import { version, deploy } from '../../../package.json';
import { GET_API_VERSION } from 'graphql/legalFolders/apiInfo';
import { useQuery } from '@apollo/client';
import { getApiVersion } from 'graphql/legalFolders/types/getApiVersion';

export const Footer: FC = () => {
  const { data, loading } = useQuery<getApiVersion>(GET_API_VERSION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className="footer">
      <div>
        © 2021 DeGolyer and MacNaughton Version {version}
        {!loading && data?.api_info.version ? <> (API Version {data.api_info.version}).</> : '.'}
        <span style={{ display: 'none' }}>{deploy}</span>
      </div>
    </div>
  );
};
