const HomeIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" {...props}>
      <path
        fill="currentColor"
        d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z"
        transform="translate(-2 -3)"
      />
    </svg>
  );
};

export default HomeIcon;
