import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TreeTable } from 'template/TreeTable/TreeTable';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { useProjectDocumentPath } from './hooks';

interface IUrlParams {
  id: string;
}

export const TreeTableDocument: FC = () => {
  const { initPage } = useComponentContext();
  const { id: projectDocumentId } = useParams<IUrlParams>();
  const { path, error } = useProjectDocumentPath({ projectDocumentId });

  useEffect(() => {
    if (projectDocumentId && path) {
      const { legalFolderId, projectFolderId } = path;
      if (legalFolderId && projectFolderId) {
        initPage!({
          wellcomeProp: false,
          initTabProp: 'D',
          selectedFolderProp: { legalFolderId, projectFolderId, projectDocumentId },
          documentTabProp: 'edit-info',
        });
      }
    }
  }, [initPage, projectDocumentId, path]);

  return <TreeTable projectFolderDocumentInvalid={error}></TreeTable>;
};
