import React, { FC, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Provider as LegalFolderDocumentContextProvider } from 'template/LegalFolderDocument/LegalFolderDocumentContext';

import { useQuery } from '@apollo/client';
import { projectFolderDocument } from 'graphql/legalFolders/types/projectFolderDocument';
import { useComponentContext } from '../../DocumentsContext';
import { DocumentViewForm } from 'template/LegalFolderDocument/components/DocumentView/components/DocumentViewForm/DocumentViewForm';
import { GET_PROJECT_FOLDER_DOCUMENT } from 'graphql/legalFolders/documents';
import { useComponentContext as useTreeComponentContext } from '../../../../../../TreeTableContext';
import { useUI } from 'contexts/UiContext';

export const ViewDocumentInfo: FC = () => {
  const { addSnackbar } = useUI();
  const { projectDocumentId } = useComponentContext();
  const { onSelectProjectDocument } = useTreeComponentContext();

  const { data, loading, refetch, error } = useQuery<projectFolderDocument>(
    GET_PROJECT_FOLDER_DOCUMENT,
    {
      variables: {
        id: projectDocumentId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (error && addSnackbar) {
      console.log('Error', error.message);
      addSnackbar({ severity: 'error', text: 'Error loading Document' });
    }
  }, [error, addSnackbar]);

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <LegalFolderDocumentContextProvider
        loadedLegalFolderDocument={data?.contract_document}
        refetch={refetch}
        onSelectDocument={onSelectProjectDocument}
      >
        <DocumentViewForm></DocumentViewForm>
      </LegalFolderDocumentContextProvider>
    </LoadingOverlay>
  );
};
