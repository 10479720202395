import React, { FC } from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { GET_PROJECT_FOLDER_DOCUMENT } from 'graphql/legalFolders/documents';
import { projectFolderDocument } from 'graphql/legalFolders/types/projectFolderDocument';

import { Provider as LegalFolderDocumentContextProvider } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';

interface ILegalFolderDocumentUrlParams {
  documentId: string;
}

export interface IPageWithSubroutesProps {
  routes: Array<any>;
}

export const LegalFolderDocumentId: FC<IPageWithSubroutesProps> = ({ routes }) => {
  const { documentId } = useParams<ILegalFolderDocumentUrlParams>();

  const { data, loading, refetch } = useQuery<projectFolderDocument>(GET_PROJECT_FOLDER_DOCUMENT, {
    variables: {
      id: documentId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <LegalFolderDocumentContextProvider
        loadedLegalFolderDocument={data?.contract_document}
        refetch={refetch}
      >
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </LegalFolderDocumentContextProvider>
    </LoadingOverlay>
  );
};
