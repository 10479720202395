import { forOwn, groupBy } from 'lodash';

export const signersByOrder = (docusign: any) => {
  const byRoutingOrder = groupBy(
    docusign?.envelope?.recipients?.signers,
    (signer) => signer.routingOrder
  );

  const arr: any = [];
  forOwn(byRoutingOrder, (value: any, key: string) => {
    arr.push({
      routingOrder: parseInt(key),
      signers: value,
    });
  });

  const sortedArr = arr.sort((a: any, b: any) => a.routingOrder - b.routingOrder);
  return sortedArr;
};

export const carbonCopiesByOrder = (docusign: any) => {
  const byRoutingOrder = groupBy(
    docusign?.envelope?.recipients?.carbonCopies,
    (cc) => cc.routingOrder
  );

  const arr: any = [];
  forOwn(byRoutingOrder, (value: any, key: string) => {
    arr.push({
      routingOrder: parseInt(key),
      cc: value,
    });
  });

  const sortedArr = arr.sort((a: any, b: any) => a.routingOrder - b.routingOrder);
  return sortedArr;
};
