import {
  Box,
  Button,
  Container,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import './index.scss';

import { useComponentContext } from '../../../../LegalFolderDocumentContext';

import { SaveAlt } from '@mui/icons-material';

import { DocumentViewFormFields } from '../DocumentViewFormFields/DocumentViewFormFields';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Print } from 'template/Print/Print';
import { DocumentPrintingMeta } from '../DocumentPrintingMeta/DocumentPrintingMeta';

import { AlertDialog, ConfirmationDialog } from 'components';
import { useHistory } from 'react-router-dom';
import { paths } from 'constants/index';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const DocumentViewForm = () => {
  const { checkAndProceed, resetChanged } = useFormChangedDialogContext();
  const history = useHistory();
  const {
    onSubmit,
    onCancel,
    projectFolderDocument,
    validationSummary,
    onDeleteProcess,
    onCancelProcess,
  } = useComponentContext();
  const { showValidator, projectFolderId } = projectFolderDocument;

  const [submited, setSubmited] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<any>();

  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [displayCancelWarning, showCancelWarning] = useState(false);

  const [printing, setPrinting] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (submited && showValidator && !!validationSummary?.length) {
      setAlertMessage(
        <ul>
          {validationSummary.map((err) => (
            <li>{err}</li>
          ))}
        </ul>
      );
      setAlertOpen(() => true);
    }
  }, [showValidator, validationSummary, submited]);

  const onValidateAndSubmit = useCallback(() => {
    if (formRef.current!.reportValidity()) {
      onSubmit!();
      setSubmited(true);
    }
  }, [onSubmit]);

  const handleDeleteDocument = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        showDeleteWarning(() => false);
        if (await onDeleteProcess!()) {
          resetChanged && resetChanged();
          history.push(
            paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB.replace(
              ':id',
              projectFolderId
            ).replace(':tab', 'D')
          );
        }
      }
    },
    [onDeleteProcess, history, projectFolderId, resetChanged]
  );

  const handleCancelDocument = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onCancelProcess!();
      }
      showCancelWarning(() => false);
    },
    [onCancelProcess]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <AlertDialog
        title="Form validation failed"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setSubmited(false);
          setAlertOpen(() => false);
        }}
      />

      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Document deletion"
        message={'Selected Document will be deleted! The action is irreversible!'}
        onClose={handleDeleteDocument}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <ConfirmationDialog
        open={displayCancelWarning}
        title="Please confirm Document cancelation"
        message={'Selected Document will be canceled! The action is irreversible!'}
        onClose={handleCancelDocument}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <form ref={formRef}>
        <DocumentViewFormFields></DocumentViewFormFields>
      </form>

      <Print printing={printing} setPrinting={setPrinting} PrintingMeta={DocumentPrintingMeta}>
        <DocumentViewFormFields></DocumentViewFormFields>
      </Print>

      <Container maxWidth="md" style={!matches ? { padding: '0px' } : undefined}>
        <Box m={3} />

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <Grid xs={12} md={4} lg={3}>
                <Typography variant="body2" className="label-title">
                  Cover sheet:
                </Typography>
              </Grid>
              <Grid xs={11} md={4} lg={6}>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1em',
                    cursor: 'pointer',
                  }}
                  onClick={debounce(() => {
                    setPrinting(true);
                  }, DEBOUNCE_TIMEOUT)}
                >
                  <SaveAlt></SaveAlt>
                  <Button variant="outlined" color="primary">
                    Download
                  </Button>
                </div>
              </Grid>
              <Grid xs={1} md={4} lg={3}></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box m={5} />
        <Grid container padding={0} spacing={0}>
          <Grid xs={1}></Grid>
          <Grid xs={10}>
            <Grid container padding={0} justifyContent="center" spacing={3}>
              {projectFolderDocument.id ? (
                projectFolderDocument.status === 'CANCELED' ? (
                  projectFolderDocument.canDelete ? (
                    <Grid xs={12} sm={3} order={{ xs: 4, sm: 1 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={debounce(() => {
                          showDeleteWarning(true);
                        }, DEBOUNCE_TIMEOUT)}
                        style={{ background: 'red' }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )
                ) : (
                  <Grid xs={12} sm={4} order={{ xs: 4, sm: 1 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={debounce(() => {
                        showCancelWarning(true);
                      }, DEBOUNCE_TIMEOUT)}
                      style={{ background: 'red' }}
                    >
                      Cancel Document
                    </Button>
                  </Grid>
                )
              ) : undefined}

              <Grid xs={12} sm={1} order={{ xs: 3, sm: 2 }}></Grid>

              <Grid xs={12} sm={3} order={{ xs: 2, sm: 3 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={debounce(checkAndProceed!(onCancel), DEBOUNCE_TIMEOUT)}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid xs={12} sm={3} order={{ xs: 1, sm: 4 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(onValidateAndSubmit, DEBOUNCE_TIMEOUT)}
                  disabled={showValidator && !!validationSummary.length}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={1}></Grid>
        </Grid>
      </Container>
    </>
  );
};
