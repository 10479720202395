import { DocumentStatus } from 'graphql/legalFolders/types/graphql-types';
import { startCase, toLower } from 'lodash';
import { useMemo } from 'react';
import { changeReportStatusName, enumToArray } from 'utils/formats';

export const useDocumentStatus = () => {
  const pairs = useMemo(() => {
    const arr = enumToArray(DocumentStatus);
    const pairs = arr
      .map((item) => ({
        id: item,
        name: changeReportStatusName(startCase(toLower(item))),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    return pairs;
  }, []);

  return { pairs };
};
