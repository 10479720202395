import React, { FC, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';

import { Provider as LegalFolderProvider } from 'template/ProjectFolder/ProjectFolderContext';
import { id } from 'utils/id';
import { GET_LEGAL_FOLDER_ATTRIBUTES } from 'graphql/legalFolders/legalFolders';
import { legalFolderAttributes } from 'graphql/legalFolders/types/legalFolderAttributes';
import { useQuery } from '@apollo/client';

export interface ILegalFolderProps {
  routes: Array<any>;
}

export const LegalFolder: FC<ILegalFolderProps> = ({ routes }) => {
  const legalFolderId = id().toString();

  const { data: legalFolderData, loading: legalFolderLoading } = useQuery<legalFolderAttributes>(
    GET_LEGAL_FOLDER_ATTRIBUTES,
    {
      variables: {
        id: legalFolderId,
      },
    }
  );

  const legalFolderAttributes = useMemo(() => {
    if (!legalFolderLoading && legalFolderData) {
      return legalFolderData?.contract_legalFolder;
    }
    return undefined;
  }, [legalFolderLoading, legalFolderData]);

  if (routes && routes.length) {
    return (
      <LegalFolderProvider
        legalFolderAttributes={legalFolderAttributes!}
        legalFolderId={legalFolderId}
      >
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </LegalFolderProvider>
    );
  }
  return <>EMPTY</>;
};
