import { DocumentEventType } from 'constants/enums';

export const documentStatusColor = (val: Record<string, any>): string => {
  switch (val?.eventType) {
    case DocumentEventType.DOCUMENT_CREATED:
      return 'black';
    case DocumentEventType.DOCUMENT_VERSION_CREATED:
      return 'blue';
    case DocumentEventType.DOCUMENT_COMMENTED:
      return 'black';
    case DocumentEventType.DOCUMENT_OBSERVER_NOTIFICATION_SENT:
    case DocumentEventType.DOCUMENT_REVIEW_REQUESTED:
    case DocumentEventType.DOCUMENT_REVIEW_RESENT:
      return 'orange';
    case DocumentEventType.DOCUMENT_REVIEW_APPROVED:
      return 'green';
    case DocumentEventType.DOCUMENT_ALL_REVIEWS_COMPLETED:
      return 'green';
    case DocumentEventType.DOCUMENT_VERSION_DELETED:
    case DocumentEventType.DOCUMENT_REVIEW_DISAPPROVED:
      return 'red';
    case DocumentEventType.DOCUMENT_REVIEW_POSTPONED:
      return 'grey';
    case DocumentEventType.DOCUMENT_ARCHIVED:
    case DocumentEventType.DOCUMENT_ARCHIVE_INITIATED:
    case DocumentEventType.DOCUMENT_ARCHIVE_DOCUMENT_STORED:
      return 'green';
    case DocumentEventType.DOCUMENT_CLIENT_APPROVAL_STATUS_CHANGED:
      return 'black';

    default:
      return 'black';
  }
};
