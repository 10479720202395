import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';

import './index.scss';

import { useHistory } from 'react-router-dom';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useComponentContext } from '../Party/PartyContext';

import { PartyForm } from 'template/Party/components/PartyForm/PartyForm';
import { AlertDialog } from 'components';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { grades } from 'hooks/partiesFuzzySearch';

export const NewParty: FC = () => {
  const { checkAndProceed } = useFormChangedDialogContext();
  const { party, onSubmitProcess, onSubmitValidate, validationSummary, fuzzySearchResult } =
    useComponentContext();

  const history = useHistory();

  const [partyDialogOpen, setPartyDialogOpen] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [requestPartyNameConfirm, setRequestPartyNameConfirm] = useState(false);

  const { showValidator } = party;
  const { grade } = fuzzySearchResult;

  const onPartyClose = () => {
    history.goBack();
  };

  useEffect(() => {
    if (submited && showValidator && !!validationSummary?.length) {
      setAlertMessage(
        <div>
          Please make sure following fields are filled in correctly:
          <ul>
            {validationSummary.map((err) => (
              <li>{Array.isArray(err) ? err.join('. ') : err}</li>
            ))}
          </ul>
        </div>
      );
      setAlertOpen(() => true);
    }
  }, [showValidator, validationSummary, submited]);

  const handleCloseConfirmDialog = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        if (onSubmitValidate!()) {
          setPartyDialogOpen(true);
        } else {
          setSubmited(true);
        }
      }
      setRequestPartyNameConfirm(false);
    },
    [onSubmitValidate]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
      <AlertDialog
        title="Form validation failed"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setSubmited(false);
          setAlertOpen(() => false);
        }}
      />
      <ConfirmationDialog
        title="Add New"
        message={'New Party will be created'}
        open={partyDialogOpen}
        onClose={(confirm: boolean) => {
          if (confirm) {
            onSubmitProcess!();
          }
          setPartyDialogOpen(() => false);
        }}
      />
      <ConfirmationDialog
        open={requestPartyNameConfirm}
        title={'Duplicate Warning'}
        message={'Are you sure? You will create a duplicate record'}
        onClose={handleCloseConfirmDialog}
        reverseColors
      />
      <Paper
        elevation={3}
        style={{
          padding: '1.5rem 28px 1.5rem 28px',
          marginTop: '65px',
          width: 'fit-content',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
        id="main-paper"
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" className="paper-title">
                New Party
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Paper elevation={3} style={{ padding: '1rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box m={5} />

              <PartyForm></PartyForm>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={debounce(checkAndProceed!(onPartyClose), DEBOUNCE_TIMEOUT)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={false}
                onClick={debounce(() => {
                  if (grade === grades.EXACT_MATCH_FOUND) {
                    setRequestPartyNameConfirm(true);
                  } else {
                    if (onSubmitValidate!()) {
                      setPartyDialogOpen(true);
                    } else {
                      setSubmited(true);
                    }
                  }
                }, DEBOUNCE_TIMEOUT)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
};
