import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TreeTable } from 'template/TreeTable/TreeTable';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { useProjectFolderPath } from './hooks';

interface IUrlParams {
  id: string;
}

export const TreeTableNewDocument: FC = () => {
  const { initPage } = useComponentContext();
  const { id: projectFolderId } = useParams<IUrlParams>();
  const { legalFolderId } = useProjectFolderPath({ projectFolderId });
  const newProjectDocumentTs = useMemo(() => {
    return Date.now();
  }, []);

  useEffect(() => {
    if (projectFolderId && legalFolderId) {
      initPage!({
        wellcomeProp: false,
        initTabProp: 'D',
        selectedFolderProp: {
          legalFolderId,
          projectFolderId,
          projectDocumentId: undefined,
          newProjectDocumentTs,
        },
        documentTabProp: 'new',
      });
    }
  }, [initPage, projectFolderId, legalFolderId, newProjectDocumentTs]);

  return <TreeTable></TreeTable>;
};
