import { useLazyQuery } from '@apollo/client';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { GET_PARTY } from 'graphql/legalFolders/parties';
import { party, party_party } from 'graphql/legalFolders/types/party';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../../constants';

export interface IPopupPartyNameProps {
  id: string;
  onClose: () => void;
}

export const PopupPartyName: FC<IPopupPartyNameProps> = ({ id, onClose }) => {
  const history = useHistory();
  const [loadedParty, setLoadedParty] = useState<party_party | null>();

  const [loadParty, { data, loading, called, refetch, error }] = useLazyQuery<party>(GET_PARTY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const load = useCallback(
    (id: string) => {
      const variables = {
        id,
      };

      if (called) {
        refetch!(variables);
      } else {
        loadParty({ variables });
      }
    },
    [loadParty, refetch, called]
  );

  useEffect(() => {
    if (data && !loading && !error) {
      setLoadedParty(data.party);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  const gotoParty = useCallback(() => {
    onClose();
    history.push(paths.client.CLIENT_DETAILS.replace(':id', id));
    return true;
  }, [history, onClose, id]);

  return (
    <div>
      {loadedParty?.name ? (
        <>
          <div>
            <strong>{loadedParty?.name}</strong>
            <div style={{ display: 'inline-block' }}>
              <LinkButton tooltip={'Edit Client'} onClick={gotoParty} />
            </div>
          </div>
          <br />
        </>
      ) : undefined}
      {loadedParty?.streetAddress ? <div>{loadedParty?.streetAddress}</div> : undefined}
      {loadedParty?.streetAddress2 ? <div>{loadedParty?.streetAddress2}</div> : undefined}
      {loadedParty?.addressZip || loadedParty?.addressCity ? (
        <div>
          {loadedParty?.addressZip} {loadedParty?.addressCity}
        </div>
      ) : undefined}
      {loadedParty?.addressState ? <div>{loadedParty?.addressState}</div> : undefined}
      {loadedParty?.country?.name ? (
        <div>
          {loadedParty?.country?.name} ({loadedParty?.country?.code})
        </div>
      ) : undefined}
    </div>
  );
};
