import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';

export const filtersExtensionTableHead: IHeadCell[] = [
  {
    id: 'projectKey',
    numeric: false,
    disablePadding: false,
    label: 'Project No.',
    filter: 'text-dropdown',
  },
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project Name',
    filter: 'text-dropdown',
  },
  {
    id: 'partyName',
    numeric: false,
    disablePadding: false,
    label: 'Party Name',
    filter: 'text-dropdown',
  },
  {
    cellType: 'divider',
    id: 'divider001',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'documentPeriod',
    numeric: false,
    disablePadding: false,
    label: 'Document Period',
    filter: 'date-range',
  },
  {
    id: 'projectFolderPeriod',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder Period',
    filter: 'date-range',
  },
];
