import { useApolloClient } from '@apollo/client';
import { GET_LEGAL_FOLDERS_DETAILS } from 'graphql/legalFolders/legalFolders';
import { getLegalFoldersDetails } from 'graphql/legalFolders/types/getLegalFoldersDetails';
import { useCallback, useMemo, useState } from 'react';
import { useLegalFoldersTableDataSource } from './legalFoldersTableDataSourceHook';

interface ILegalFolderCheck {
  name: string;
  partyIds: string[];
}
export const useNewLegalFolderValidation = ({ name, partyIds }: ILegalFolderCheck) => {
  const [validateLegalFolderNameResult, setSameNameLegalFolder] = useState<any | null | undefined>(
    undefined
  );
  const [validateLegalFolderPartiesResult, setPartiesLegalFolders] = useState<
    any[] | null | undefined
  >(undefined);
  const [similarLegalFolders, setSimilarLegalFolders] = useState<any[] | null | undefined>(
    undefined
  );

  const client = useApolloClient();

  const exactNameValidationFilter = useMemo(() => {
    return { filter: { name }, withLimitedAccess: true };
  }, [name]);

  const similarNamesValidationFilter = useMemo(() => {
    return { filter: { nameContains: name }, withLimitedAccess: true };
  }, [name]);

  const partiesValidationFilter = useMemo(() => {
    return { filter: { partyIds, partyIdsRequired: true }, withLimitedAccess: true };
  }, [partyIds]);

  const validateLegalFolderNameTableDataSource = useLegalFoldersTableDataSource({
    overridePageLoadParams: exactNameValidationFilter,
  });
  const similarLegalFoldersTableDataSource = useLegalFoldersTableDataSource({
    overridePageLoadParams: similarNamesValidationFilter,
  });
  const validateLegalFolderPartiesTableDataSource = useLegalFoldersTableDataSource({
    overridePageLoadParams: partiesValidationFilter,
  });

  const validateExactName = useCallback(async () => {
    const { data, error } = await client.query<getLegalFoldersDetails>({
      query: GET_LEGAL_FOLDERS_DETAILS,
      variables: exactNameValidationFilter,
      fetchPolicy: 'network-only',
    });

    if (!error && data.contract_legalFolders.length) {
      const val = { ...data.contract_legalFolders[0] };
      setSameNameLegalFolder(val);
      return val;
    } else {
      setSameNameLegalFolder(null);
      return null;
    }
  }, [client, exactNameValidationFilter]);

  const validateSimilarNames = useCallback(async () => {
    const { data, error } = await client.query<getLegalFoldersDetails>({
      query: GET_LEGAL_FOLDERS_DETAILS,
      variables: similarNamesValidationFilter,
      fetchPolicy: 'network-only',
    });

    if (!error && data.contract_legalFolders.length) {
      const val = data.contract_legalFolders.map((lf) => ({ ...lf }));
      setSimilarLegalFolders(val);
      return val;
    } else {
      setSimilarLegalFolders(null);
      return null;
    }
  }, [client, similarNamesValidationFilter]);

  const validateParties = useCallback(async () => {
    const { data, error } = await client.query<getLegalFoldersDetails>({
      query: GET_LEGAL_FOLDERS_DETAILS,
      variables: partiesValidationFilter,
      fetchPolicy: 'network-only',
    });

    if (!error && data.contract_legalFolders.length) {
      const val = data.contract_legalFolders.map((lf) => ({ ...lf }));
      setPartiesLegalFolders(val);
      return val;
    } else {
      setPartiesLegalFolders(null);
      return null;
    }
  }, [client, partiesValidationFilter]);

  const validateNewLegalFolder = useCallback(async () => {
    const validateLegalFolderNameResult = await validateExactName();
    const validateLegalFolderPartiesResult = await validateParties();
    const similarLegalFolders = await validateSimilarNames();
    return { validateLegalFolderNameResult, validateLegalFolderPartiesResult, similarLegalFolders };
  }, [validateExactName, validateParties, validateSimilarNames]);

  return {
    validateNewLegalFolder,
    validateLegalFolderNameResult,
    validateLegalFolderPartiesResult,
    similarLegalFolders,
    validateLegalFolderNameTableDataSource,
    similarLegalFoldersTableDataSource,
    validateLegalFolderPartiesTableDataSource,
  };
};
