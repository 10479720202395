import { Grid, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { Delete, DragIndicator, ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { FC, useMemo, useState } from 'react';
import { IParty } from '../../interface';
import s from './style.module.scss';
import { EditDocument } from 'components/ui/EditDocument/EditDocument';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export interface IPartyTileProps {
  party: IParty;
  index: number;
  onRemoveParty: () => void;
  onEditParty: () => void;
}
export const PartyTile: FC<IPartyTileProps> = ({ party, index, onRemoveParty, onEditParty }) => {
  const {
    addressCity,
    addressState,
    addressZip,
    country,
    name,
    // partyType,
    streetAddress,
    streetAddress2,
    projectSetupClientCode,
  } = party;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const nameWithoutMultipleSpaces = useMemo(() => name.replace(/\s+/g, ' '), [name]);
  const edit = {
    onClick: () => {
      onEditParty && onEditParty();
    },
    tooltip: 'Edit Other Party',
  };

  return (
    <Grid item xs={12} className={s.contactBox}>
      <Grid container spacing={2} alignContent="center" alignItems="center">
        {matches ? (
          <Grid item xs={1}>
            <DragIndicator fontSize="small" style={{ display: 'none' }} />
          </Grid>
        ) : undefined}

        {matches ? (
          <Grid xs={3} md={2} item>
            Name:
          </Grid>
        ) : undefined}

        <Grid item xs={matches ? 5 : 8} md={6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={
              (projectSetupClientCode ? projectSetupClientCode + ' - ' : '') +
              nameWithoutMultipleSpaces
            }
            disabled
          />
        </Grid>
        <Grid
          item
          xs={matches ? 2 : 3}
          style={{
            display: 'flex',
          }}
        >
          <div style={{ paddingRight: '5px' }}>
            <EditDocument {...edit} />
          </div>
          <div style={{ paddingRight: '5px' }}>
            <Tooltip title="Remove Other Party">
              <IconButton
                size="small"
                onClick={debounce(onRemoveParty, DEBOUNCE_TIMEOUT)}
                className={classes.hoverWarning}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div
            onClick={debounce(() => setExpanded(!expanded), DEBOUNCE_TIMEOUT)}
            style={{ cursor: 'pointer' }}
          >
            {expanded ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
          </div>
        </Grid>

        {expanded ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {matches ? <Grid item xs={1}></Grid> : undefined}
              <Grid xs={3} md={2} item>
                Address:
              </Grid>
              <Grid item xs={7} md={7} lg={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={streetAddress + (streetAddress2 ? ', ' + streetAddress2 : '')}
                  disabled
                />
              </Grid>
              <Grid item xs={2} lg={1}></Grid>

              <Grid item xs={3} md={3}></Grid>
              <Grid item xs={7} md={7} lg={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    (addressCity ? addressCity : '') +
                    (addressState ? ', ' + addressState : '') +
                    (addressZip ? ' ' + addressZip : '')
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={2} lg={1}></Grid>

              {country ? (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3}></Grid>
                    <Grid item xs={7} md={7} lg={8}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={country.name}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2} lg={1}></Grid>
                  </Grid>
                </Grid>
              ) : undefined}
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        ) : undefined}
      </Grid>
    </Grid>
  );
};
