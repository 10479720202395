import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { PartySortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { TransformUser, transformUserText } from 'utils/TableTransformFunctions';

export const demacPartiesExportTableHead: IHeadCell[] = [
  {
    id: 'projectSetupClientCode',
    numeric: false,
    disablePadding: false,
    label: 'Client Code',
    sortable: true,
    sortBy: PartySortableColumn.CLIENT_CODE,
    filter: 'text',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
    transformFunction: (data) => data || '--',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
    sortable: true,
    sortBy: PartySortableColumn.NAME,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'alias',
    numeric: false,
    disablePadding: false,
    label: 'Client Alias',
    sortable: true,
    sortBy: PartySortableColumn.ALIAS,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'partyDesignation',
    numeric: false,
    disablePadding: false,
    label: 'Client Type',
    sortable: true,
    sortBy: PartySortableColumn.PARTY_DESIGNATION_ID,
    filter: 'dropdown',
    dataCell: {
      wrap: 'nowrap',
    },
    transformFunction: (data) => data?.name || '--',
  },
  {
    id: 'divisionOwnership',
    numeric: false,
    disablePadding: false,
    label: 'D&M Division Ownership',
    sortable: true,
    sortBy: PartySortableColumn.DIVISION_OWNERSHIP_NAME,
    filter: 'dropdown',
    dataCell: {
      wrap: 'nowrap',
    },
    transformFunction: (data) => data?.name || '--',
  },
  {
    id: 'focalPointUser',
    numeric: false,
    disablePadding: false,
    label: 'D&M Client Focal Point',
    sortable: true,
    sortBy: PartySortableColumn.FOCAL_POINT_USER_NAME,
    filter: 'text',
    dataCell: {
      wrap: 'nowrap',
    },
    transformFunction: transformUserText,
    transformDataCell: TransformUser,
  },
  {
    id: 'partyType',
    numeric: false,
    disablePadding: false,
    label: 'Client / Non-Client',
    transformFunction: (data) => (data?.name === 'CLIENT' ? 'Client' : 'Non-Client'),
  },
  {
    id: 'websiteUrl',
    numeric: false,
    disablePadding: false,
    label: 'WebSite URL',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'linkedInVanityName',
    numeric: false,
    disablePadding: false,
    label: 'LinkedIn Page',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'twitterUsername',
    numeric: false,
    disablePadding: false,
    label: 'Twitter Username',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'streetAddress',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'streetAddress2',
    numeric: false,
    disablePadding: false,
    label: 'Address (cont.)',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'addressCity',
    numeric: false,
    disablePadding: false,
    label: 'City',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'addressState',
    numeric: false,
    disablePadding: false,
    label: 'State',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'addressZip',
    numeric: false,
    disablePadding: false,
    label: 'Zip Code',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    label: 'Country',
    transformFunction: (data) => (data?.name ? data?.name + '(' + data?.code + ')' : '--'),
  },
  {
    id: 'isActive',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    transformFunction: (data) => (data ? 'Active' : 'Inactive'),
  },
  {
    id: 'contacts',
    numeric: false,
    disablePadding: false,
    label: 'Contacts',
    transformFunction: (data) =>
      data?.length
        ? data
            .map(
              (contact: any) =>
                (contact.firstName
                  ? 'First Name: ' + contact.firstName + String.fromCharCode(10)
                  : '') +
                (contact.lastName
                  ? 'Last Name: ' + contact.lastName + String.fromCharCode(10)
                  : '') +
                (contact.jobTitle
                  ? 'Job Title: ' + contact.jobTitle + String.fromCharCode(10)
                  : '') +
                (contact.email ? 'Email: ' + contact.email + String.fromCharCode(10) : '') +
                (contact.phone ? 'Phone: ' + contact.phone + String.fromCharCode(10) : '') +
                (contact.linkedInUrl
                  ? 'LinkedIn: ' + contact.linkedInUrl + String.fromCharCode(10)
                  : '') +
                (contact.isActive ? 'Active' : 'Inactive')
            )
            .join(String.fromCharCode(10) + String.fromCharCode(10))
        : '--',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
  {
    id: 'assignedPartyRelationships',
    numeric: false,
    disablePadding: false,
    label: 'Associated Clients',
    transformFunction: (data) =>
      data?.length
        ? data
            .map(
              (related: any) =>
                (related?.otherParty?.projectSetupClientCode
                  ? related?.otherParty?.projectSetupClientCode + ' - '
                  : '') + related?.otherParty?.name
            )
            .join(String.fromCharCode(10))
        : '--',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
  {
    id: 'stockExchanges',
    numeric: false,
    disablePadding: false,
    label: 'Stock Exchanges',
    transformFunction: (data) =>
      data?.length
        ? data
            .map(
              (se: any) =>
                (se.stockMarket?.name || '-- ') +
                (se.stockMarket?.acronym ? ' (' + se.stockMarket.acronym + ')' : '') +
                ': ' +
                (se.stockExchangeSymbol || '')
            )
            .join(String.fromCharCode(10))
        : '--',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'Notes',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || '--',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
];
