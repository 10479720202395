import ConfirmationDialog from 'components/ConfirmationDialog';
import { FC, useCallback } from 'react';

export interface IOpenUrlInNewTabProps {
  title: string;
  message: string;
  url: string;
  tabTitle: string;
  onClose: any;
  openDialog: boolean;
}
export const OpenUrlInNewTab: FC<IOpenUrlInNewTabProps> = ({
  title,
  message,
  url,
  tabTitle,
  onClose,
  openDialog,
}) => {
  const handleCloseNewTabDialog = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        window.open(url, tabTitle);
      }
      onClose(false);
    },
    [onClose, url, tabTitle]
  );

  return (
    <ConfirmationDialog
      open={openDialog}
      title={title}
      message={message}
      onClose={handleCloseNewTabDialog}
      reverseColors
    />
  );
};
