import React, { createContext, FC, useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { paths } from 'constants/index';

export interface IContextState {
  projectFolderId?: string;
  legalFolderId?: string;
  currentView: ProjectFoldersViewTab;
}

export type ProjectFoldersViewTab = 'list-pf' | 'new' | 'edit-info';

export interface IContextActions {
  changeView: (view: ProjectFoldersViewTab) => void;
}

const initialState: IContextState = {
  currentView: 'list-pf',
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  projectFolderId?: string;
  legalFolderId?: string;
  newProjectFolderTs?: number;
  setProjectFoldersView?: (view: ProjectFoldersViewTab) => void;
  activeProjectFoldersViewTab: ProjectFoldersViewTab;
  children: any;
}

export const Provider: FC<IProviderProps> = ({
  children,
  legalFolderId,
  projectFolderId,
  newProjectFolderTs,
  setProjectFoldersView,
  activeProjectFoldersViewTab,
}) => {
  const history = useHistory();

  const [currentView, setView] = useState<ProjectFoldersViewTab>(activeProjectFoldersViewTab);

  useEffect(() => {
    setView((currentView) => {
      if (currentView !== activeProjectFoldersViewTab) {
        return activeProjectFoldersViewTab;
      }
      return currentView;
    });
  }, [activeProjectFoldersViewTab]);

  useEffect(() => {
    setProjectFoldersView && setProjectFoldersView(currentView);
  }, [currentView, setProjectFoldersView]);

  useEffect(() => {
    if (legalFolderId) {
      if (projectFolderId) {
        setView('edit-info');
      } else {
        setView('list-pf');
      }
    }
  }, [projectFolderId, legalFolderId]);

  useEffect(() => {
    if (newProjectFolderTs) {
      setView(() => {
        return 'new';
      });
    }
  }, [newProjectFolderTs]);

  const changeView = useCallback(
    (view: ProjectFoldersViewTab) => {
      switch (view) {
        case 'edit-info': {
          projectFolderId &&
            history.push(
              paths.treeViewClient.TREEVIEW_PROJECT_FOLDER.replace(':id', projectFolderId)
            );
          break;
        }
        default: {
          legalFolderId &&
            history.push(
              paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS.replace(
                ':id',
                legalFolderId
              )
            );
        }
      }
    },
    [projectFolderId, legalFolderId, history]
  );

  return (
    <ComponentContext.Provider
      value={{
        legalFolderId,
        projectFolderId,
        currentView,
        changeView,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
