import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ActiveTab } from 'template/TreeTable/components/TableSection/TableSectionContext';
import { TreeTable } from 'template/TreeTable/TreeTable';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';

interface IUrlParams {
  id: string;
  tab?: string;
}

export const TreeTableLegalFolder: FC = () => {
  const { initPage } = useComponentContext();
  const { id, tab } = useParams<IUrlParams>();
  useEffect(() => {
    initPage!({
      wellcomeProp: false,
      initTabProp: (tab?.toUpperCase() || 'LF') as ActiveTab,
      selectedFolderProp: { legalFolderId: id },
    });
  }, [id, initPage, tab]);

  return <TreeTable></TreeTable>;
};
