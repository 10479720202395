import { useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  LinearProgress,
  Switch,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
  createFilterOptions,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import EasyAutoComplete from '../../../../components/EasyAutoComplete';

import { useQuery } from '@apollo/client';
import { IPartyDesignation } from 'graphql/legalFolders/types/IPartyDesignation';
import { partyDesignations } from 'graphql/legalFolders/types/partyDesignations';
import { countries } from 'graphql/legalFolders/types/countries';

import './index.scss';
import { GET_PARTY_DESIGNATIONS } from 'graphql/legalFolders/parties';
import { GET_COUNTRIES } from 'graphql/legalFolders/countries';
import { usePartyDivisions } from 'hooks/partyDivisionsHook';
import { SelectUser } from 'components/SelectUser/SelectUser';

import { Contact } from '../Contact/Contact';
import { Projects } from '../Projects/Projects';

import { clientTypeDbMap, IGeneralListItem, useComponentContext } from '../../PartyContext';
import { useSelectClient } from 'hooks/selectClientHook';
import { ExchangesTiles } from '../ExchangeTiles/ExchangeTiles';
import { PartyTypeEnum } from 'graphql/legalFolders/types/graphql-types';
import { TextInputField } from 'components/TextInputField/TextInputField';

import LoadingOverlay from 'react-loading-overlay-ts';
import s from './style.module.scss';
import { Asterisks } from 'components/Asterisks/Asterisks';
import { DEBOUNCE_TIMEOUT, TYPING_TIMEOUT } from 'constants/config';
import { debounce, forOwn, unionBy, uniq } from 'lodash';
import { grades } from 'hooks/partiesFuzzySearch';
import { matchSorter } from 'match-sorter';
import { capitalizeAllWordsFirstLetterUS, joinStringArray } from 'utils/formats';
import IconButton from '@mui/material/IconButton';
import {
  Place as PlaceIcon,
  NotListedLocation as NotListedLocationIcon,
} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

import { useGeoAddress } from 'hooks/geoAddressHook';
import { GeoMapDialog } from 'components/GeoMap/GeoMap';

const MAX_BLINK_COUNT = 6;

interface IUser {
  id: string;
  name: string;
  email: string | null;
}
export interface IPartyFormProps {
  onReady?: () => void;
  printView?: boolean;
}

type GeoSearchState = 'unknown' | 'found' | 'not found' | 'searching' | 'typing';

export const PartyForm: FC<IPartyFormProps> = ({ onReady, printView }) => {
  const [displayGeoAddress, setDisplayGeoAddress] = useState<{
    geoX: number | null;
    geoY: number | null;
  } | null>();
  const { load: loadGeo, geoAddress, loading: geoLoading, error: geoError } = useGeoAddress();

  useEffect(() => {
    if (geoAddress && !geoLoading && !geoError) {
      setDisplayGeoAddress(geoAddress);
    }
  }, [geoAddress, geoLoading, geoError]);

  const { party, originalParty, onChangeState, partyContacts, partyExchanges, fuzzySearchResult } =
    useComponentContext();
  const { divisionPairs } = usePartyDivisions();

  const { data: designationsData, loading: designationsLoading } =
    useQuery<partyDesignations>(GET_PARTY_DESIGNATIONS);

  const { data: countriesData, loading: countriesLoading } = useQuery<countries>(GET_COUNTRIES);

  const [designations, setDesignations] = useState<Array<IPartyDesignation>>();
  const [countries, setCountries] = useState<Array<IGeneralListItem>>();
  const [blinkCount, setBlinkCount] = useState(MAX_BLINK_COUNT);
  const [geoSearchState, setGeoSearchState] = useState<GeoSearchState>('unknown');
  const [showLocation, setShowLocation] = useState(false);

  const {
    id,
    name,
    alias,
    streetAddress,
    streetAddress2,
    addressCity,
    addressState,
    addressZip,
    isActive,
    selectedClientType,
    selectedClientDesignation,
    selectedCountry,
    projectSetupClientCode,
    parentCompanyName,

    isApplicationControlled,
    showValidator,
    errors,
    createEvent,
    selectedDivisionOwnership,
    selectedFocalPointUser,
    notes,

    websiteUrl,
    linkedInVanityName,
    twitterUsername,

    selectedRelatedParties,
  } = party;

  const addressDebounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const loadGeoData = useCallback(() => {
    if (
      streetAddress ||
      streetAddress2 ||
      addressCity ||
      addressZip ||
      addressState ||
      selectedCountry?.id
    ) {
      loadGeo({
        streetAddress,
        streetAddress2,
        addressCity,
        addressZip,
        addressState,
        countryName: selectedCountry?.id
          ? countriesData?.countries.find((country) => country.id === selectedCountry.id)?.name
          : undefined,
      });
    } else {
      setGeoSearchState('not found');
    }
  }, [
    streetAddress,
    streetAddress2,
    addressCity,
    addressState,
    addressZip,
    selectedCountry?.id,
    countriesData,
    loadGeo,
  ]);

  const loadGeoDataRef = useRef(loadGeoData);
  useEffect(() => {
    loadGeoDataRef.current = loadGeoData;
  }, [loadGeoData]);

  // When user is typing new address, switch to "typing", after debounce time to "searching"
  const addressChanged = useCallback(() => {
    if (addressDebounceTimer.current) {
      clearInterval(addressDebounceTimer.current);
    }
    addressDebounceTimer.current = setTimeout(() => {
      loadGeoDataRef.current();
      setBlinkCount(0);
    }, TYPING_TIMEOUT * 4);
  }, []);

  // If party loaded, resolve geo address
  useEffect(() => {
    if (party.id && geoSearchState === 'unknown') {
      if (!party.geos?.length || party.geos[0].updatedAt >= party.updatedAt!) {
        loadGeoDataRef.current();
      } else {
        setDisplayGeoAddress(party.geos[0]);
      }
    }
  }, [party, geoSearchState]);

  // We have result to display
  useEffect(() => {
    if (geoAddress && !geoLoading) {
      if (geoAddress.geoX) {
        setGeoSearchState('found');
      } else {
        setGeoSearchState('not found');
      }
    }
  }, [geoAddress, geoLoading]);

  useEffect(() => {
    if (geoSearchState === 'found' && !geoAddress && !geoLoading) {
      setGeoSearchState('not found');
    }
  }, [geoAddress, geoLoading, geoSearchState]);

  const blinkTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const blink = useCallback(() => {
    if (blinkTimerRef.current) {
      clearInterval(blinkTimerRef.current);
    }
    blinkTimerRef.current = setTimeout(() => {
      if (geoSearchState === 'searching') {
        setBlinkCount((old) => (old + 1) % 2);
      } else {
        setBlinkCount((old) => old + 1);
      }
    }, TYPING_TIMEOUT);
  }, [geoSearchState]);

  const blinkRef = useRef(blink);

  useEffect(() => {
    blinkRef.current = blink;
  }, [blink]);

  useEffect(() => {
    if (blinkCount < MAX_BLINK_COUNT) {
      blinkRef.current();
    }
  }, [blinkCount]);

  const showBlinkIcon = useMemo(() => blinkCount % 2 === 0, [blinkCount]);

  const { grade, cache, search } = fuzzySearchResult;

  const nameProgress = useMemo(() => {
    switch (grade) {
      case grades.EXACT_MATCH_FOUND:
        return 100;
      case grades.WHOLE_SEARCH_STRING_MATCH:
        return 100;
      case grades.WHOLE_SEARCH_STRING_AS_SUBSTRING:
        return 30;
      case grades.PARTIAL_SEARCH_STRING_AS_SUBSTRING1:
        return 80;
      case grades.PARTIAL_SEARCH_STRING_AS_SUBSTRING2:
        return 85;
      case grades.ANY_MATCH1:
        return 90;
      case grades.ANY_MATCH2:
        return 80;
      case grades.ANY_MATCH3:
        return 70;
    }
    return 100;
  }, [grade]);

  const resultDescription = useMemo(() => {
    switch (grade) {
      case grades.EXACT_MATCH_FOUND:
        return 'client name is taken';
      case grades.WHOLE_SEARCH_STRING_MATCH: {
        const matchingResults = cache[search]?.found?.filter(
          (item) => item.name.toLowerCase() === search
        );

        const countryNames = matchingResults
          ?.filter((item) => item.country)
          .map(
            (item) =>
              countriesData?.countries?.find((country) => country.id === item.country.id)?.name
          )
          .filter((name) => !!name);

        return (
          'same client name found' +
          (countryNames?.length
            ? ' in ' +
              joinStringArray(
                uniq(countryNames)
                  .map((countryName) => countryName && capitalizeAllWordsFirstLetterUS(countryName))
                  .sort()
              )
            : '')
        );
      }
      case grades.WHOLE_SEARCH_STRING_AS_SUBSTRING:
        return 'client name is found as a part of an existing client name';
      case grades.PARTIAL_SEARCH_STRING_AS_SUBSTRING1:
        return 'part of client name is used';
      case grades.PARTIAL_SEARCH_STRING_AS_SUBSTRING2:
        return 'part of client name is used';
      case grades.ANY_MATCH1:
        return 'some words found';
      case grades.ANY_MATCH2:
        return 'some words found';
      case grades.ANY_MATCH3:
        return 'words found';
    }
    return 'client name is free to use';
  }, [grade, cache, countriesData, search]);

  const mergedCacheResults = useMemo(() => {
    const words = search.split(' ');
    let results: Array<{ id: string; name: string }> = [];

    switch (grade) {
      case grades.ANY_MATCH1:
      case grades.ANY_MATCH2:
      case grades.ANY_MATCH3:
        forOwn(cache, (value, key) => {
          if (words.includes(key)) {
            results = unionBy(results, value.found, (item: any) => item.id);
          }
        });
        break;
      default:
        forOwn(cache, (value, key) => {
          results = unionBy(results, value.found, (item: any) => item.id);
        });
    }

    const sortedResults = results.sort((a, b) => a.name.localeCompare(b.name));

    const fuzzyResults = words.reduceRight(
      (items: any, word: string) =>
        matchSorter(items, word, {
          keys: ['name'],
        }) as Array<{ id: string; name: string }>,
      sortedResults
    );

    const extendResult = (result: any) =>
      !countriesData?.countries?.length
        ? result
        : {
            ...result,
            longName:
              result.name +
              (result.country
                ? ' (' +
                  (countriesData.countries.find((country) => country.id === result.country.id)
                    ?.name || 'Country is not found') +
                  ')'
                : ''),
          };

    if (fuzzyResults.length) {
      return fuzzyResults?.map((result) => extendResult(result));
    } else {
      return sortedResults?.map((result) => extendResult(result));
    }
  }, [grade, cache, search, countriesData]);

  const nameProgresType = useMemo(() => {
    switch (grade) {
      case grades.EXACT_MATCH_FOUND:
        return 'Warning';
      case grades.WHOLE_SEARCH_STRING_MATCH:
        return 'Success';
      default:
        return 'Ok';
    }
  }, [grade]);

  const clientProps = useSelectClient({
    error: false,
  });

  const { contacts, addContact, deleteContact, updateContact } = partyContacts || {};
  const { exchanges, addExchange, deleteExchange, updateExchange } = partyExchanges || {};

  const isNewParty = !id || id === '';

  const [projectsLoaded, setProjectsLoaded] = useState(isNewParty);

  const loading = useMemo(() => {
    return designationsLoading || countriesLoading || !projectsLoaded;
  }, [designationsLoading, countriesLoading, projectsLoaded]);

  useEffect(() => {
    if (!!onReady && !designationsLoading && !countriesLoading && projectsLoaded) {
      setTimeout(onReady, 0);
    }
  }, [designationsLoading, countriesLoading, projectsLoaded, onReady]);

  useEffect(() => {
    if (designationsData && !designationsLoading) {
      setDesignations([
        ...designationsData.partyDesignations.filter((designation) => designation.isSelectable),
      ]);
    }
  }, [designationsData, designationsLoading]);

  useEffect(() => {
    if (countriesData && !countriesLoading) {
      setCountries(
        countriesData.countries.map((country) => {
          return {
            id: country.id,
            name: `${country.name} (${country.code})`,
          };
        })
      );
    }
  }, [countriesData, countriesLoading]);

  const timestamp = useMemo(() => {
    if (!createEvent) return undefined;
    return new Date(createEvent.createdAt).toLocaleDateString('en-EN', {
      year: 'numeric',
      month: 'long', // 'numeric',
      day: 'numeric',
    });
  }, [createEvent]);

  const onSetFocalPoint = (value: IUser): void => {
    onChangeState!((party) => ({
      ...party,
      selectedFocalPointUser: value,
    }));
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const onRemoveContact = useCallback(
    (index: number) => () => {
      deleteContact!(index);
    },
    [deleteContact]
  );

  const onUpdateContact = useCallback(
    (index: number) => (value: any) => {
      updateContact!(index, value);
    },
    [updateContact]
  );

  const setProjectsLoading = useCallback(
    ({ loading, data, error }: { loading: boolean; data: any; error: boolean }) => {
      setProjectsLoaded(!loading && (!!data || !!error));
    },
    []
  );

  let contactIndex = -1;
  return (
    <>
      {showLocation ? (
        <GeoMapDialog
          party={
            displayGeoAddress
              ? {
                  ...party,
                  geos: [displayGeoAddress],
                  country: selectedCountry?.id
                    ? countriesData?.countries.find((country) => country.id === selectedCountry.id)
                    : undefined,
                }
              : undefined
          }
          id={id}
          onClose={() => {
            setShowLocation(false);
          }}
        ></GeoMapDialog>
      ) : undefined}
      <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Box m={5} />

            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  Client Name <Asterisks count={1} tooltipTitle="Client Name is required" />:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                {!isApplicationControlled ? (
                  <TextInputField
                    size="small"
                    value={name || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeState!((party) => ({ ...party, name: e.target.value }));
                    }}
                    fullWidth
                    error={showValidator && errors?.name}
                    disabled={!isApplicationControlled}
                    placeholder="Input the full legal name of the Client…"
                    onClear={() => {
                      onChangeState!((party) => ({ ...party, name: '' }));
                    }}
                    onUndo={
                      !originalParty.name || originalParty.name === name
                        ? undefined
                        : () => {
                            onChangeState!((party) => ({ ...party, name: originalParty.name }));
                          }
                    }
                  />
                ) : (
                  <div>
                    <div>
                      <EasyAutoComplete
                        label=""
                        textFieldStyle="outlined"
                        optionsLabel="longName"
                        placeholder="Enter client name"
                        items={mergedCacheResults || []}
                        selected={undefined}
                        selectedChanged={(value: any): void => {}}
                        onInputChange={(event: object, value: string, reason: string) => {
                          if (reason !== 'reset' || value !== '') {
                            onChangeState!((party) => ({ ...party, name: value }));
                          }
                        }}
                        inputValue={name}
                        filterOptions={createFilterOptions({
                          stringify: (option: any) => name || '',
                        })}
                        error={showValidator && errors?.name}
                        getSelectedLabel={(option: any) => {
                          return option.name;
                        }}
                      />
                    </div>
                    <div
                      style={
                        grade !== grades.IGNORE &&
                        nameProgress &&
                        nameProgresType &&
                        name &&
                        isApplicationControlled &&
                        (!originalParty || originalParty.name !== name)
                          ? { paddingTop: '2px' }
                          : { display: 'none' }
                      }
                    >
                      <LinearProgress
                        variant="determinate"
                        value={nameProgress}
                        classes={
                          nameProgresType === 'Success'
                            ? {
                                root: s.barRootSuccess,
                                barColorPrimary: s.barColorPrimarySuccess,
                              }
                            : nameProgresType === 'Warning'
                            ? {
                                root: s.barRootWarning,
                                barColorPrimary: s.barColorPrimaryWarning,
                              }
                            : {
                                root: s.barRootOk,
                                barColorPrimary: s.barColorPrimaryOk,
                              }
                        }
                      />
                    </div>
                    <div
                      style={
                        grade !== grades.IGNORE &&
                        nameProgress &&
                        nameProgresType &&
                        name &&
                        isApplicationControlled &&
                        (!originalParty || originalParty.name !== name)
                          ? { paddingTop: '2px', textAlign: 'right' }
                          : { display: 'none' }
                      }
                    >
                      <Typography
                        variant="body2"
                        className="label-title-desc-nocase"
                        style={{
                          color:
                            nameProgresType === 'Success'
                              ? 'blue'
                              : nameProgresType === 'Warning'
                              ? 'red'
                              : 'green',
                        }}
                      >
                        {resultDescription}
                      </Typography>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid xs={12} sm={5}>
                <Typography variant="body2" className="label-title-nocase">
                  Country:
                </Typography>
              </Grid>

              <Grid xs={12} sm={7}>
                <EasyAutoComplete
                  key={`country-${selectedCountry?.id}`}
                  items={countries || []}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selected={selectedCountry}
                  selectedChanged={(value: any): void => {
                    addressChanged();
                    onChangeState!((party) => ({
                      ...party,
                      addressCountryId: value.id,
                      selectedCountry: value,
                    }));
                  }}
                  error={showValidator && errors?.addressCountryId}
                  getOptionSelected={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                  disabled={!isApplicationControlled}
                  placeholder="Select country..."
                />
              </Grid>
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  Alias:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <TextInputField
                  size="small"
                  value={alias || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeState!((party) => ({ ...party, alias: e.target.value }));
                  }}
                  fullWidth
                  error={showValidator && errors?.alias}
                  placeholder="Input an alias..."
                  // disabled={!isApplicationControlled}
                  onClear={() => {
                    onChangeState!((party) => ({ ...party, alias: '' }));
                  }}
                  onUndo={
                    !originalParty.alias || originalParty.alias === alias
                      ? undefined
                      : () => {
                          onChangeState!((party) => ({
                            ...party,
                            alias: originalParty.alias,
                          }));
                        }
                  }
                />
              </Grid>
              {projectSetupClientCode && projectSetupClientCode !== '' ? (
                <>
                  <Grid
                    xs={12}
                    sm={5}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: matches ? '44px' : undefined,
                    }}
                  >
                    <Typography variant="body2" className="label-title-nocase">
                      Client Code:
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={7} style={{ opacity: '0.38' }}>
                    {projectSetupClientCode}
                  </Grid>
                </>
              ) : undefined}
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  Parent Company Name:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7} style={{ opacity: '0.38' }}>
                {parentCompanyName || '--'}
              </Grid>
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  Client / Non-Client :
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <EasyAutoComplete
                  key={`type-${selectedClientType?.key}`}
                  items={[
                    { key: 'CLIENT', name: 'Client' },
                    { key: 'NON-CLIENT', name: 'Non-Client' },
                  ]}
                  selected={selectedClientType}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selectedChanged={(value: any): void => {
                    onChangeState!((party) => ({
                      ...party,
                      type: PartyTypeEnum[
                        clientTypeDbMap[value.key as keyof typeof clientTypeDbMap]
                      ],
                      selectedClientType: value,
                    }));
                  }}
                  error={showValidator && errors?.type}
                  getOptionSelected={(option: any, value: any) => {
                    return option.key === value.key;
                  }}
                  disabled={!isApplicationControlled}
                />
              </Grid>
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  Client Type <Asterisks count={1} tooltipTitle="Client Type is required" />:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <EasyAutoComplete
                  key={`designation-${selectedClientDesignation?.id}`}
                  items={designations || []}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selected={selectedClientDesignation}
                  selectedChanged={(value: any): void => {
                    onChangeState!((party) => ({
                      ...party,
                      designationId: value.id,
                      selectedClientDesignation: value,
                    }));
                  }}
                  error={showValidator && errors?.designationId}
                  getOptionSelected={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                  disabled={!isApplicationControlled}
                  placeholder="Select a client type"
                />
              </Grid>
              {party.id && createEvent ? (
                <>
                  <Grid
                    xs={12}
                    sm={5}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: matches ? '44px' : undefined,
                    }}
                  >
                    <div style={{ height: '100%' }}>
                      <Typography variant="body2" className="label-title-nocase">
                        Created By:
                      </Typography>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={7}>
                    {createEvent.author?.name || '-'}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={5}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: matches ? '44px' : undefined,
                    }}
                  >
                    <div style={{ height: '100%' }}>
                      <Typography variant="body2" className="label-title-nocase">
                        Date of Creation:
                      </Typography>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={7}>
                    {timestamp || '-'}
                  </Grid>
                </>
              ) : undefined}

              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  D&M Client Division Ownership:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <EasyAutoComplete
                  key={`division-${selectedDivisionOwnership?.id}`}
                  items={divisionPairs || []}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selected={selectedDivisionOwnership}
                  selectedChanged={(value: IGeneralListItem) => {
                    onChangeState!((party) => {
                      return {
                        ...party,
                        selectedDivisionOwnership: value,
                      };
                    });
                  }}
                  error={showValidator && !!errors?.selectedDocumentType}
                  getOptionSelected={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                  placeholder="Select D&M division"
                />
              </Grid>

              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  D&M Client Focal Point:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <SelectUser
                  onSelectChange={onSetFocalPoint}
                  selectedItem={selectedFocalPointUser}
                ></SelectUser>
              </Grid>
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  WebSite URL:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <TextInputField
                  size="small"
                  value={websiteUrl || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeState!((party) => ({ ...party, websiteUrl: e.target.value }));
                  }}
                  fullWidth
                  error={showValidator && errors?.websiteUrl}
                  placeholder="Input a WebSite url..."
                  // disabled={!isApplicationControlled}
                  onClear={() => {
                    onChangeState!((party) => ({ ...party, websiteUrl: '' }));
                  }}
                  onUndo={
                    !originalParty.websiteUrl || originalParty.websiteUrl === websiteUrl
                      ? undefined
                      : () => {
                          onChangeState!((party) => ({
                            ...party,
                            websiteUrl: originalParty.websiteUrl,
                          }));
                        }
                  }
                />
              </Grid>
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  LinkedIn Page:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <TextInputField
                  size="small"
                  value={linkedInVanityName || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeState!((party) => ({ ...party, linkedInVanityName: e.target.value }));
                  }}
                  fullWidth
                  error={showValidator && errors?.linkedInVanityName}
                  // disabled={!isApplicationControlled}
                  placeholder="Input a LinkedIn username..."
                  onClear={() => {
                    onChangeState!((party) => ({ ...party, linkedInVanityName: '' }));
                  }}
                  onUndo={
                    !originalParty.linkedInVanityName ||
                    originalParty.linkedInVanityName === linkedInVanityName
                      ? undefined
                      : () => {
                          onChangeState!((party) => ({
                            ...party,
                            linkedInVanityName: originalParty.linkedInVanityName,
                          }));
                        }
                  }
                />
              </Grid>
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: matches ? '44px' : undefined,
                }}
              >
                <Typography variant="body2" className="label-title-nocase">
                  Twitter Username:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <TextInputField
                  size="small"
                  value={twitterUsername || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeState!((party) => ({ ...party, twitterUsername: e.target.value }));
                  }}
                  fullWidth
                  error={showValidator && errors?.twitterUsername}
                  // disabled={!isApplicationControlled}
                  placeholder="Input a Twitter username..."
                  onClear={() => {
                    onChangeState!((party) => ({ ...party, twitterUsername: '' }));
                  }}
                  onUndo={
                    !originalParty.twitterUsername ||
                    originalParty.twitterUsername === twitterUsername
                      ? undefined
                      : () => {
                          onChangeState!((party) => ({
                            ...party,
                            twitterUsername: originalParty.twitterUsername,
                          }));
                        }
                  }
                />
              </Grid>

              <Grid xs={12} sm={5}>
                <Typography variant="body2" className="label-title-nocase">
                  Address:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7} style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <TextInputField
                    size="small"
                    fullWidth
                    value={streetAddress || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeState!((party) => {
                        addressChanged();
                        return {
                          ...party,
                          streetAddress: e.target.value,
                        };
                      });
                    }}
                    error={showValidator && errors?.streetAddress}
                    disabled={!isApplicationControlled}
                    placeholder="Input address..."
                    onClear={() => {
                      addressChanged();
                      onChangeState!((party) => ({ ...party, streetAddress: '' }));
                    }}
                    onUndo={
                      !originalParty.streetAddress || originalParty.streetAddress === streetAddress
                        ? undefined
                        : () => {
                            onChangeState!((party) => ({
                              ...party,
                              streetAddress: originalParty.streetAddress,
                            }));
                          }
                    }
                  />
                </div>
                {geoSearchState === 'found' && blinkCount >= MAX_BLINK_COUNT ? (
                  <div style={{ width: '40px', display: 'flex', justifyContent: 'right' }}>
                    <Tooltip title="Geo Map">
                      <IconButton
                        style={{ padding: '0' }}
                        size="small"
                        onClick={debounce((): void => {
                          setShowLocation(true);
                        }, DEBOUNCE_TIMEOUT)}
                      >
                        <PlaceIcon style={{ color: '#006ad4' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : undefined}
                {geoSearchState === 'searching' ||
                geoSearchState === 'typing' ||
                blinkCount < MAX_BLINK_COUNT ? (
                  <div style={{ width: '40px', display: 'flex', justifyContent: 'right' }}>
                    <Tooltip title="Geo Map">
                      <IconButton
                        style={{ padding: '0' }}
                        size="small"
                        onClick={debounce((): void => {
                          setShowLocation(true);
                        }, DEBOUNCE_TIMEOUT)}
                      >
                        {geoError ? (
                          'error'
                        ) : (
                          <NotListedLocationIcon
                            style={{ color: '#006ad4', opacity: showBlinkIcon ? '1' : '.2' }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : undefined}
              </Grid>
              <Grid xs={12} sm={5}>
                <Typography variant="body2" className="label-title-nocase">
                  Address (cont.):
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <TextInputField
                  size="small"
                  fullWidth
                  value={streetAddress2 || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    addressChanged();
                    onChangeState!((party) => ({
                      ...party,
                      streetAddress2: e.target.value,
                    }));
                  }}
                  error={showValidator && errors?.streetAddress2}
                  disabled={!isApplicationControlled}
                  placeholder="Input address (cont.)..."
                  onClear={() => {
                    addressChanged();
                    onChangeState!((party) => ({ ...party, streetAddress2: '' }));
                  }}
                  onUndo={
                    !originalParty.streetAddress2 || originalParty.streetAddress2 === streetAddress2
                      ? undefined
                      : () => {
                          onChangeState!((party) => ({
                            ...party,
                            streetAddress2: originalParty.streetAddress2,
                          }));
                        }
                  }
                />
              </Grid>
              <Grid xs={12} sm={5}>
                <Typography variant="body2" className="label-title-nocase">
                  City:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <TextInputField
                  size="small"
                  fullWidth
                  value={addressCity || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    addressChanged();
                    onChangeState!((party) => ({
                      ...party,
                      addressCity: e.target.value,
                    }));
                  }}
                  error={showValidator && errors?.addressCity}
                  disabled={!isApplicationControlled}
                  placeholder="Input city..."
                  onClear={() => {
                    addressChanged();
                    onChangeState!((party) => ({ ...party, addressCity: '' }));
                  }}
                  onUndo={
                    !originalParty.addressCity || originalParty.addressCity === addressCity
                      ? undefined
                      : () => {
                          addressChanged();
                          onChangeState!((party) => ({
                            ...party,
                            addressCity: originalParty.addressCity,
                          }));
                        }
                  }
                />
              </Grid>
              <Grid xs={12} sm={5}>
                <Typography variant="body2" className="label-title-nocase">
                  State:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <TextInputField
                  size="small"
                  fullWidth
                  value={addressState || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    addressChanged();
                    onChangeState!((party) => ({
                      ...party,
                      addressState: e.target.value,
                    }));
                  }}
                  error={showValidator && errors?.addressState}
                  disabled={!isApplicationControlled}
                  placeholder="Input state..."
                  onClear={() => {
                    addressChanged();
                    onChangeState!((party) => ({ ...party, addressState: '' }));
                  }}
                  onUndo={
                    !originalParty.addressState || originalParty.addressState === addressState
                      ? undefined
                      : () => {
                          addressChanged();
                          onChangeState!((party) => ({
                            ...party,
                            addressState: originalParty.addressState,
                          }));
                        }
                  }
                />
              </Grid>
              <Grid xs={12} sm={5}>
                <Typography variant="body2" className="label-title-nocase">
                  Zip Code:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <TextInputField
                  size="small"
                  fullWidth
                  value={addressZip || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    addressChanged();
                    onChangeState!((party) => ({ ...party, addressZip: e.target.value }));
                  }}
                  error={showValidator && errors?.addressZip}
                  disabled={!isApplicationControlled}
                  placeholder="Input ZIP code..."
                  onClear={() => {
                    addressChanged();
                    onChangeState!((party) => ({ ...party, addressZip: '' }));
                  }}
                  onUndo={
                    !originalParty.addressZip || originalParty.addressZip === addressZip
                      ? undefined
                      : () => {
                          addressChanged();
                          onChangeState!((party) => ({
                            ...party,
                            addressZip: originalParty.addressZip,
                          }));
                        }
                  }
                />
              </Grid>

              <Grid xs={4} sm={5}>
                <Typography variant="body2" className="label-title-nocase"></Typography>
              </Grid>

              <Grid xs={8} sm={7}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!isActive}
                      onChange={() => {
                        onChangeState!((party) => ({ ...party, isActive: !isActive }));
                      }}
                      color="primary"
                      disabled={!isApplicationControlled}
                    />
                  }
                  label={isActive ? 'Active' : 'Inactive'}
                  labelPlacement="start"
                />
              </Grid>
            </Grid>

            <Box m={2} />

            <Grid container padding={0} spacing={2} className={s.dataBlock}>
              <Grid xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>

            <Grid container padding={0} spacing={2}>
              <Grid xs={12} sm={12}>
                <Typography variant="body2" className="label-title-nocase">
                  Contact Details:
                </Typography>
              </Grid>
              <Grid xs={12} sm={12}>
                {contacts?.map((contact) => {
                  contactIndex++;
                  return (
                    <div key={contactIndex} className={s.dataBlock}>
                      <Contact
                        contact={contact}
                        onRemoveContact={onRemoveContact(contactIndex)}
                        onUpdateContact={onUpdateContact(contactIndex)}
                      ></Contact>
                    </div>
                  );
                })}
              </Grid>
              <Grid xs={5}>
                <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
                  <Typography variant="body2" className="label-title-nocase">
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={
                        addContact ? debounce(() => addContact(), DEBOUNCE_TIMEOUT) : undefined
                      }
                    >
                      Add Contact
                    </Button>
                  </Typography>
                </div>
              </Grid>
              <Grid xs={7}></Grid>
            </Grid>

            <Box m={4} />

            <Grid container padding={0} spacing={2} className={s.dataBlock}>
              <Grid xs={12} sm={5}>
                <Typography variant="body2" className="label-title-nocase">
                  Associated Clients:
                </Typography>
              </Grid>
              <Grid xs={12} sm={7}>
                <EasyAutoComplete
                  selected={selectedRelatedParties || []}
                  multiple
                  key={`associatedClients`}
                  label=""
                  placeholder="Select associated clients"
                  textFieldStyle="outlined"
                  getOptionSelected={(option: any, value: any) => {
                    return option.key === value.key;
                  }}
                  {...clientProps}
                  selectedChanged={(value: any): void => {
                    onChangeState!((party) => ({
                      ...party,
                      selectedRelatedParties: value,
                    }));
                  }}
                />
              </Grid>
            </Grid>
            <Box m={2} />

            <Grid container padding={0} spacing={2} className={s.dataBlock}>
              <Grid xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>

            <Grid container padding={0} spacing={2} className={s.dataBlock}>
              <>
                <Grid xs={12} sm={12}>
                  <Typography variant="body2" className="label-title-nocase">
                    Stock Exchanges:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={12}>
                  <ExchangesTiles
                    exchanges={exchanges || []}
                    onDelete={deleteExchange!}
                    onUpdate={updateExchange!}
                  ></ExchangesTiles>
                </Grid>
                <Grid xs={5}>
                  <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
                    <Typography variant="body2" className="label-title-nocase">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={addExchange ? debounce(addExchange, DEBOUNCE_TIMEOUT) : undefined}
                      >
                        Add Stock Exchange
                      </Button>
                    </Typography>
                  </div>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Typography variant="body2" className="label-title-nocase">
              Notes:
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextareaAutosize
              className="MuiInputBase-input"
              color="grey"
              style={{
                width: '95%',
                maxWidth: '95%',
                minWidth: '95%',
                minHeight: '1rem',
                padding: 10,
                borderRadius: 4,
                border: '1px #ccc solid',
                overflow: 'auto',
                marginBottom: '1rem',
              }}
              aria-label="minimum height"
              minRows={4}
              value={notes || ''}
              onChange={(e: any) => {
                onChangeState!((party) => ({ ...party, notes: e.target.value }));
              }}
              placeholder="Input notes…"
            ></TextareaAutosize>
          </Grid>
        </Grid>

        <Box m={2} />

        {!isNewParty ? (
          <>
            <Grid container padding={0} spacing={2} className={s.dataBlock}>
              <Grid xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>

            <Grid container padding={0} spacing={2} className={s.dataBlock}>
              <Grid xs={12}>
                <Typography variant="body2" className="label-title-nocase">
                  Client Projects:
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Projects partyId={id} setLoading={setProjectsLoading}></Projects>
              </Grid>
            </Grid>
          </>
        ) : undefined}
      </LoadingOverlay>
    </>
  );
};
