import { Box, FormControlLabel, Grid, LinearProgress, Switch, Typography } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import DocumentApprovalUploadSection from '../DocumentSignUploadSection';
import { azureStorageUpload } from 'utils/azureStorageUpload';
import { useMutation, useApolloClient } from '@apollo/client';
import { ILegalFolderDocumentData } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { AlertDialog } from 'components';
import { DOCUMENT_SIGN } from 'graphql/legalFolders/documents';
import { MutationFunction } from '@apollo/client';
import { id } from 'utils/id';
import { errorMessage } from 'utils/errors';
import LoadingOverlay from 'react-loading-overlay-ts';

export interface IDocumentSignSectionProps {
  projectFolderDocument: ILegalFolderDocumentData;
  isSigned: boolean;
  refetchDocument: MutationFunction;
  account: Record<string, any>;
  disabled?: boolean;
}

export const DocumentSignSection: FC<IDocumentSignSectionProps> = ({
  isSigned,
  projectFolderDocument,
  refetchDocument,
  account,
  disabled,
}) => {
  const [signPopupOpen, setSignPopupOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState<Record<string, any>[]>([]);
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const apolloClient = useApolloClient();

  const signConditions = useMemo(() => {
    const { statusFolder, isPartyReviewRequired, partyReviewStatus, reviewers, versions } =
      projectFolderDocument;
    return {
      statusFolder: statusFolder === 'APPROVED',
      partyApprovalStatus:
        (isPartyReviewRequired && partyReviewStatus === 'APPROVED') || !isPartyReviewRequired,
      reviewers: !!reviewers.find((reviewer) => !reviewer.disabled),
      versions: !!versions.length,
    };
  }, [projectFolderDocument]);

  const [documentSign, { error: errorDocumentSign, loading: loadingDocumentSign }] =
    useMutation(DOCUMENT_SIGN);

  const handleDocumentApproval = async (confirm: boolean): Promise<any> => {
    setSignPopupOpen(false);

    if (confirm) {
      let file: any = null;
      // eslint-disable-next-line prefer-destructuring
      if (files && files.length > 0) file = files[0];

      let uploadedFile = {};

      if (file) {
        setUploadingLoading(true);

        const uploadResult = await azureStorageUpload(
          apolloClient,
          'VERSION',
          parseInt(projectFolderDocument?.id),
          file.name,
          file
        );

        setUploadingLoading(false);

        if (uploadResult.hasErrors) {
          setAlertMessage(uploadResult.errorMessage);
          setAlertOpen(true);
          return;
        }

        uploadedFile = {
          blobContainerName: uploadResult.containerName,
          blobName: uploadResult.blobName,
          fileFilename: uploadResult.fileFilename,
          fileMimeType: uploadResult.fileMimeType,
        };
      }

      const variablesWithFile = {
        variables: { comment, documentId: projectFolderDocument?.id, uploadedFile },
      };
      const variablesNoFile = {
        variables: { comment, documentId: projectFolderDocument?.id },
      };

      const variables = file ? variablesWithFile : variablesNoFile;

      const documentMutation = documentSign(variables);

      documentMutation
        .then((): void => {
          refetchDocument({ variables: { id: id() } });
        })
        .catch((e) => {
          setAlertOpen(true);
          setAlertMessage(e?.message);
        });
    }
  };

  return (
    <LoadingOverlay active={uploadingLoading} spinner text="Please wait, upload in progress...">
      <AlertDialog
        title="Error"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      <Grid container alignContent="center" alignItems="center">
        <Grid item xs={5}>
          <Typography variant="body2" className="label-title">
            Signed by all Parties:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={isSigned}
                onChange={() => {
                  setSignPopupOpen(true);
                }}
                disabled={disabled}
              />
            }
            label={isSigned ? 'Signed' : 'Not signed'}
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isSigned}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={disabled}
                onClick={() => {
                  setSignPopupOpen(true);
                }}
              />
            }
            label={isSigned ? 'Signed' : 'Not signed'}
          ></FormControlLabel> */}
        </Grid>
        {disabled && !isSigned ? (
          <Grid item xs={12}>
            <Typography className="label-title-desc-nocase">
              {!signConditions.versions ? (
                <div>At least one document version must be uploaded.</div>
              ) : undefined}
              {!signConditions.reviewers ? (
                <div>At least one reviewer must be assigned and enabled.</div>
              ) : undefined}
              {!signConditions.statusFolder ? (
                <div>Document must be reviewed and approved by all reviewers.</div>
              ) : undefined}
              {!signConditions.partyApprovalStatus ? (
                <div>Other Party approval is required.</div>
              ) : undefined}
            </Typography>
          </Grid>
        ) : undefined}
        {errorDocumentSign || loadingDocumentSign ? (
          <Grid item xs={12}>
            {errorMessage(errorDocumentSign)}
            <Box marginTop={5}>{loadingDocumentSign && <LinearProgress />}</Box>
          </Grid>
        ) : undefined}
      </Grid>
      <DocumentApprovalUploadSection
        open={signPopupOpen}
        title="Document Signed"
        handleDocumentApproval={handleDocumentApproval}
        handleFiles={setFiles}
        files={files}
        comment={comment}
        onChangeComment={(event: any): void => {
          setComment(event?.target?.value);
        }}
        account={account}
        projectFolderDocument={projectFolderDocument}
        refetchDocument={refetchDocument}
        disableTitlebar={false}
      />
    </LoadingOverlay>
  );
};
