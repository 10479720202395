import { gql } from '@apollo/client';

export const IContractProposalUserRef = gql`
  fragment IContractProposalUserRef on ProposalUser {
    user {
      id
      oid
      email
      name
    }
    isEnabled
  }
`;

export const IContractProposal = gql`
  fragment IContractProposal on ContractProposalObjectType {
    id
    name
    owners {
      ...IContractProposalUserRef
    }
    leadUser {
      ...IContractProposalUserRef
    }
  }
  ${IContractProposalUserRef}
`;
