import { FC } from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';

export interface ISubRoute {
  routes: Array<any>;
}

export const ReportsPage: FC<ISubRoute> = ({ routes }) => {
  console.log('Page: REPORTS');
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
};
