import React, { FC } from 'react';
import { Button } from '@mui/material';

import { LegalFolderNavbarProps } from './interfaces';
import './index.scss';
import s from './style.module.scss';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export enum projectFolderTabs {
  PROJECT_FOLDER,
  VIEW_DOCUMENTS,
}

export const NavigationTabs: FC<LegalFolderNavbarProps> = ({
  page,
  handleSetPage,
  newDocument,
}) => {
  const gotoLegalFolder = (): void => {
    handleSetPage(projectFolderTabs.PROJECT_FOLDER);
  };

  const gotoViewDocuments = (): void => {
    handleSetPage(projectFolderTabs.VIEW_DOCUMENTS);
  };

  const activeButton = (btnType: projectFolderTabs): string => {
    return page === btnType ? 'active' : '';
  };

  return (
    <div className={s['report-nav-container']}>
      <Button
        fullWidth
        className={`report-nav-item ${activeButton(projectFolderTabs.PROJECT_FOLDER)}`}
        variant="contained"
        color="secondary"
        onClick={debounce(gotoLegalFolder, DEBOUNCE_TIMEOUT)}
      >
        <strong>Project folder</strong>
      </Button>
      {!newDocument ? (
        <div className="legalfolder-tabs-action-button">
          <div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={debounce(gotoViewDocuments, DEBOUNCE_TIMEOUT)}
            >
              <strong>View documents</strong>
            </Button>
          </div>
        </div>
      ) : undefined}
    </div>
  );
};
