import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { projectFolder_contract_container_contacts } from 'graphql/legalFolders/types/projectFolder';
import React, { FC, useCallback } from 'react';
import { DragIndicator, Delete } from '@mui/icons-material';
import s from './style.module.scss';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface IContact extends Omit<projectFolder_contract_container_contacts, '__typename'> {}
export interface IContactsProps {
  contacts: IContact[];
  setContact: (index: number, contact: IContact) => void;
  deleteContact: (index: number) => void;
}

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export const Contacts: FC<IContactsProps> = ({ contacts, setContact, deleteContact }) => {
  const classes = useStyles();

  const onSetContact = useCallback(
    (index: number, contact: IContact, field: string) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setContact(index, { ...contact, [field]: e.target.value });
      },
    [setContact]
  );

  const onDeleteContact = useCallback(
    (index: number) => () => {
      deleteContact(index);
    },
    [deleteContact]
  );

  let index = -1;
  return (
    <Grid container>
      {contacts.map((contact) => {
        index++;
        const { email, name, phone } = contact;
        return (
          <Grid item xs={12} className={s.contactBox} key={index}>
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <Grid item xs={1}>
                <DragIndicator fontSize="small" style={{ display: 'none' }} />
              </Grid>
              <Grid xs={4} md={3} item>
                Name:
              </Grid>
              <Grid item xs={6} md={7}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={name}
                  onChange={onSetContact(index, contact, 'name')}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Delete contact">
                  <IconButton
                    size="small"
                    onClick={debounce(onDeleteContact(index), DEBOUNCE_TIMEOUT)}
                    className={classes.hoverWarning}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={4} md={3}>
                Email:
              </Grid>
              <Grid item xs={6} md={7}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={email}
                  onChange={onSetContact(index, contact, 'email')}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={4} md={3}>
                Phone:
              </Grid>
              <Grid item xs={6} md={7}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={phone}
                  onChange={onSetContact(index, contact, 'phone')}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
