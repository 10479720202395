import React, { FC, useCallback, useState, useMemo } from 'react';
import { useComponentContext } from '../../LegalFolderContext';

import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PartiesTiles } from '../PartiesTiles/PartiesTiles';
import { EditClientDialog } from 'template/Party/components/EditClientDialog/EditClientDialog';
import { DEBOUNCE_TIMEOUT, MAX_MEMBERS, MAX_OWNERS } from 'constants/config';
import { debounce } from 'lodash';
import { SelectMultipleOwners } from 'template/LegalFolderDocument/components/DocumentView/components/SelectMultipleOwners/SelectMultipleOwners';
import { useMsalAccount } from 'hooks/msalAccount';
import { SelectMultipleMembers } from 'template/LegalFolderDocument/components/DocumentView/components/SelectMultipleMembers/SelectMultipleMembers';
import { useCurrentUser } from 'hooks/currentUserHook';

export const LegalFolderFormFields: FC = () => {
  const { msalAccount: account } = useMsalAccount();
  const { users: currentAccountUsers } = useCurrentUser();

  const {
    legalFolder,
    onSetLegalFolder,
    onAddPartyBlock,
    onAddParty,
    onReloadParty,
    onRemoveParty,
    onSelectOwnersChange,
    onSelectMembersChange,
  } = useComponentContext();
  const {
    id,
    name,
    notes,
    errors,
    showValidator,
    parties,
    notifyOwners,
    notifyMembers,
    createdAt,
    updatedAt,
    owners,
    members,
  } = legalFolder;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [editClient, setEditClient] = useState<string>();

  const onEditParty = useCallback(
    (index: number) => {
      if (parties[index]?.id) {
        setEditClient(parties[index]?.id);
      }
    },
    [parties]
  );

  const membersIncludesOwner = useMemo(() => {
    if (!members?.length || !owners?.length) {
      return false;
    }
    const ownerIds = owners?.map((owner) => owner.id);
    const found = members?.find((member) => ownerIds?.includes(member.id));
    return !!found;
  }, [members, owners]);

  return (
    <Container maxWidth="md" style={!matches ? { padding: '0px' } : undefined}>
      {editClient ? (
        <EditClientDialog
          id={editClient}
          onClose={() => {
            onReloadParty!(editClient);
            setEditClient(undefined);
          }}
        ></EditClientDialog>
      ) : undefined}
      <Grid container padding={0} spacing={2}>
        <Grid xs={12}>
          <Box m={5} />
          <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
            <Grid xs={12} md={5} lg={4}>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Typography variant="body2" className="label-title">
                  Legal folder name:
                </Typography>
                <div style={{ padding: '0 2px' }}>
                  <Typography variant="body2" className="label-title-desc">
                    (required)
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={7} lg={8}>
              <TextField
                variant="outlined"
                size="small"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onSetLegalFolder!((oldLegalFolder) => {
                    return { ...oldLegalFolder, name: e.target.value };
                  });
                }}
                fullWidth
                error={showValidator && errors?.name}
                style={{ paddingRight: '12px' }}
              />
            </Grid>
            <Grid xs={5} lg={4}>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Typography variant="body2" className="label-title">
                  Owners:
                </Typography>
                {!!id ? (
                  <div style={{ padding: '0 2px' }}>
                    <Typography variant="body2" className="label-title-desc">
                      (required)
                    </Typography>
                  </div>
                ) : undefined}
              </div>
            </Grid>
            <Grid xs={7} lg={8}>
              {id.length ? (
                <SelectMultipleOwners
                  selectedItems={owners}
                  onSelectChange={onSelectOwnersChange}
                  error={showValidator && (!owners || !owners.length || owners.length > MAX_OWNERS)}
                ></SelectMultipleOwners>
              ) : (
                <TextField
                  variant="outlined"
                  size="small"
                  value={account?.name || ''}
                  onChange={() => {}}
                  fullWidth
                  error={false}
                  disabled={true}
                />
              )}
            </Grid>
            <Grid xs={5} lg={4}>
              <Typography variant="body2" className="label-title">
                Members:
              </Typography>
            </Grid>
            <Grid xs={7} lg={8}>
              <SelectMultipleMembers
                selectedItems={members}
                selectedOwners={
                  id.length
                    ? owners
                    : currentAccountUsers?.map(({ id, name, email }) => ({ id, name, email }))
                }
                onSelectChange={onSelectMembersChange}
                error={
                  showValidator &&
                  !!members &&
                  (members.length > MAX_MEMBERS || !!membersIncludesOwner || errors?.members)
                }
              ></SelectMultipleMembers>
            </Grid>
            {id.length ? (
              <>
                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Created Date:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  {createdAt
                    ? new Date(createdAt).toLocaleDateString('en-EN', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                    : '--'}
                </Grid>

                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Last Modified Date:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  {updatedAt
                    ? new Date(updatedAt).toLocaleDateString('en-EN', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                    : '--'}
                </Grid>
              </>
            ) : undefined}

            <Grid xs={5} lg={4}>
              <Typography variant="body2" className="label-title">
                Notify owners:
              </Typography>
            </Grid>
            <Grid xs={7} lg={8}>
              <RadioGroup
                row
                aria-label="isOriginParty"
                name="position"
                value={notifyOwners ? 1 : 0}
                onChange={() => {
                  onSetLegalFolder!((oldLegalFolder) => {
                    return {
                      ...oldLegalFolder,
                      notifyOwners: !oldLegalFolder.notifyOwners,
                    };
                  });
                }}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Grid>

            <Grid xs={5} lg={4}>
              <Typography variant="body2" className="label-title">
                Notify members:
              </Typography>
            </Grid>
            <Grid xs={7} lg={8}>
              <RadioGroup
                row
                aria-label="isOriginParty"
                name="position"
                value={notifyMembers ? 1 : 0}
                onChange={() => {
                  onSetLegalFolder!((oldLegalFolder) => {
                    return {
                      ...oldLegalFolder,
                      notifyMembers: !oldLegalFolder.notifyMembers,
                    };
                  });
                }}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>

          <Box m={5} />
          <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
            <Grid xs={12}>
              <Box marginBottom={1}>
                <Box marginBottom={1}>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography variant="body2" className="label-title">
                      Other party:
                    </Typography>
                    <div style={{ padding: '0 2px' }}>
                      <Typography variant="body2" className="label-title-desc">
                        (required)
                      </Typography>
                    </div>
                  </div>
                </Box>
                <PartiesTiles
                  parties={parties}
                  onAddParty={onAddParty!}
                  onRemoveParty={onRemoveParty!}
                  showValidator={showValidator}
                  onEditParty={onEditParty}
                ></PartiesTiles>
              </Box>
            </Grid>

            <Grid xs={3}>
              <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
                <Typography variant="body2" className="label-title">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onAddPartyBlock && debounce(onAddPartyBlock, DEBOUNCE_TIMEOUT)}
                    disabled={parties.length > 0 && parties.slice(-1)[0] === null}
                  >
                    Add party
                  </Button>
                </Typography>
              </div>
            </Grid>
            <Grid xs={9}></Grid>
          </Grid>
          <Box m={5} />
          <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
            <Grid xs={12}>
              <Box marginBottom={1}>
                <Box marginBottom={1}>
                  <Typography variant="body2" className="label-title">
                    Notes:
                  </Typography>
                </Box>
                <TextareaAutosize
                  color="grey"
                  style={{
                    width: '95%',
                    minHeight: '1rem',
                    padding: 10,
                    borderRadius: 4,
                    overflow: 'auto',
                    border: '1px #ccc solid',
                  }}
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Write notes..."
                  onChange={(e: React.ChangeEvent<{ value: string }>) => {
                    onSetLegalFolder!((oldLegalFolder) => {
                      return { ...oldLegalFolder, notes: e.target.value };
                    });
                  }}
                  value={notes || ''}
                  className="MuiInputBase-input"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
