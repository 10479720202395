import {
  GET_LEGAL_FOLDERS_COUNT,
  GET_LEGAL_FOLDERS_DETAILS,
} from 'graphql/legalFolders/legalFolders';
import { LegalFolderFilter } from 'graphql/legalFolders/types/graphql-types';
import { useCallback } from 'react';
import { useTableDataSource } from './tableDataSourceHook';

const getCount = (data: any) => data.contract_legalFolderCount;
const getItems = (data: any) => data.contract_legalFolders;

export interface ILegalFoldersTableDataSourceProps {
  overridePageLoadParams: any;
  tableStorageKey?: string;
}

export const useLegalFoldersTableDataSource = ({
  overridePageLoadParams,
  tableStorageKey,
}: ILegalFoldersTableDataSourceProps) => {
  const filterToAPI = useCallback((filterValues: any): LegalFolderFilter => ({}), []);

  const { items, loadPage, totalItems, loading, onFilterChange, pageLoadParams, error } =
    useTableDataSource<any, any, any, LegalFolderFilter>({
      overridePageLoadParams,
      GET_COUNT_QUERY: GET_LEGAL_FOLDERS_COUNT,
      GET_QUERY: GET_LEGAL_FOLDERS_DETAILS,
      getCount,
      getItems,
      tableStorageKey,
      initRowsPerPage: 5,
      filterToAPI,
    });

  return { items, loadPage, totalItems, loading, onFilterChange, pageLoadParams, error };
};
