import { useQuery } from '@apollo/client';
import { projectFolder } from 'graphql/legalFolders/types/projectFolder';
import { legalFolderAttributes } from 'graphql/legalFolders/types/legalFolderAttributes';
import LoadingOverlay from 'react-loading-overlay-ts';
import { GET_LEGAL_FOLDER_ATTRIBUTES, GET_PROJECT_FOLDER } from 'graphql/legalFolders/legalFolders';
import { Provider as LegalFolderProvider } from 'template/ProjectFolder/ProjectFolderContext';
import { useComponentContext } from '../../../../../TableSection/TableSectionContext';
import { DocumentsTable } from '../DocumentsTable/DocumentsTable';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { DocumentStatusFolder } from 'graphql/legalFolders/types/graphql-types';
import { useMemo } from 'react';
import s from './style.module.scss';
import { useState } from 'react';
import { IDocumentsTableLoadParams } from '../DocumentsTable/interface';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { getLegalFolderDocumentsTotalCounters } from 'graphql/legalFolders/types/getLegalFolderDocumentsTotalCounters';

import { useComponentContext as useMyTask } from 'contexts/MyTask';

const useStyles = makeStyles({
  content: {
    alignItems: 'baseline',
  },
});

const statusFolders = [
  DocumentStatusFolder.NEGOTIATING,
  DocumentStatusFolder.APPROVED,
  DocumentStatusFolder.SIGNED,
];

const statusFolderNaming = {
  [DocumentStatusFolder.NEGOTIATING]: 'Negotiating',
  [DocumentStatusFolder.APPROVED]: 'D&M Approved',
  [DocumentStatusFolder.SIGNED]: 'Signed by All Parties',
};

export const ProjectDocuments = () => {
  const { myTask } = useMyTask();
  const { projectFolderId, legalFolderId } = useComponentContext();
  const [recordsInfo, setRecordsInfo] = useState<{ [key: string]: number }>({});
  const [totalsInfo, setTotalsInfo] = useState<{
    [key: string]: getLegalFolderDocumentsTotalCounters;
  }>({});

  const cumulativeTotals: getLegalFolderDocumentsTotalCounters = useMemo(() => {
    const cumulative = Object.keys(totalsInfo).reduce(
      (acc, key) => {
        const { inaccessible, total, userOnly } = totalsInfo[key];
        return {
          inaccessible: inaccessible + acc.inaccessible,
          total: total + acc.total,
          userOnly: userOnly + acc.userOnly,
        };
      },
      { inaccessible: 0, total: 0, userOnly: 0 } as getLegalFolderDocumentsTotalCounters
    );
    return cumulative;
  }, [totalsInfo]);

  const classes = useStyles();

  const { data, loading, refetch } = useQuery<projectFolder>(GET_PROJECT_FOLDER, {
    variables: {
      id: projectFolderId,
    },
  });

  const { data: legalFolderData, loading: legalFolderLoading } = useQuery<legalFolderAttributes>(
    GET_LEGAL_FOLDER_ATTRIBUTES,
    {
      variables: {
        id: legalFolderId,
      },
    }
  );

  const loadedProjectFolder = useMemo(() => {
    if (!loading && data) {
      return data?.contract_container;
    }
    return undefined;
  }, [data, loading]);

  const loadedLegalFolder = useMemo(() => {
    if (!legalFolderLoading && legalFolderData) {
      return legalFolderData?.contract_legalFolder;
    }
    return undefined;
  }, [legalFolderLoading, legalFolderData]);

  const initDocumentsTableLoadParams: Partial<IDocumentsTableLoadParams> | undefined =
    useMemo(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const order = params.get('order');
      if (order === 'action') {
        return {
          order: SortOrder.DESC,
          orderBy: 'CURRENT_USER_ACTION_PENDING',
        };
      }
      return undefined;
    }, []);

  const accordions = useMemo(() => {
    let foundExpanded = false;
    return statusFolders.map((folder) => {
      const isExpanded = !foundExpanded && recordsInfo[folder] > 0;
      foundExpanded = foundExpanded || isExpanded;
      const { inaccessible, total, userOnly } = totalsInfo[folder] ?? {
        inaccessible: 0,
        total: 0,
        userOnly: 0,
      };
      const messages = [];
      if (recordsInfo[folder]) {
        messages.push(
          recordsInfo[folder] === 0
            ? 'empty'
            : recordsInfo[folder] === 1
            ? '1 document'
            : recordsInfo[folder] + ' documents'
        );
      }
      if (inaccessible > total) {
        messages.push(`${inaccessible - total} inaccessible`);
      }
      if (userOnly < total && myTask) {
        messages.push(`${total - userOnly} hidden`);
      }
      return (
        <Accordion defaultExpanded={isExpanded} key={folder + isExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={classes}
            style={{ marginTop: '10px' }}
          >
            <Typography variant="body2" className="label-title-nocase">
              {statusFolderNaming[folder]}
            </Typography>
            <div style={{ padding: '0 8px' }}>
              <Typography variant="body2" className="label-title-desc">
                {messages.length ? `(${messages.join(', ')})` : ''}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentsTable
              projectFolderId={projectFolderId!}
              statusFolders={[folder]}
              onRecords={(num: number) => {
                setRecordsInfo((old) => {
                  if (old[folder] !== num) {
                    return { ...old, [folder]: num };
                  }
                  return old;
                });
              }}
              onTotals={(totals: any) => {
                setTotalsInfo((old) => {
                  if (old[folder] !== totals) {
                    return { ...old, [folder]: totals };
                  }
                  return old;
                });
              }}
              initDocumentsTableLoadParams={initDocumentsTableLoadParams}
            />
          </AccordionDetails>
        </Accordion>
      );
    });
  }, [projectFolderId, recordsInfo, classes, initDocumentsTableLoadParams, myTask, totalsInfo]);

  const warningMessage = useMemo(() => {
    const { inaccessible, total, userOnly } = cumulativeTotals;
    const hasInaccessableItems = inaccessible > total;
    const hasHiddenItems = total > userOnly && myTask;
    const messages = [];
    if (hasInaccessableItems) {
      messages.push("Project folders has more documents but you don't have access to them.");
    }
    if (hasHiddenItems) {
      messages.push(
        `My Task is active and ${total - userOnly} document${
          total - userOnly > 1 ? 's are' : ' is'
        } hidden.`
      );
    }
    return messages.join(' ');
  }, [cumulativeTotals, myTask]);

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <LegalFolderProvider
        loadedProjectFolder={loadedProjectFolder}
        refetch={refetch}
        legalFolderId={legalFolderId!}
      >
        <div className={s.documentsView}>
          Viewing documents for <strong>{loadedProjectFolder?.name}</strong> in{' '}
          <strong>{loadedLegalFolder?.name}</strong> legal folder
          {accordions}
          <div className={s.messageBox}>{warningMessage}</div>
        </div>
      </LegalFolderProvider>
    </LoadingOverlay>
  );
};
