export const TYPING_TIMEOUT =
  process.env.REACT_APP_TYPING_TIMEOUT === undefined
    ? 400
    : parseInt(process.env.REACT_APP_TYPING_TIMEOUT);
export const API_BASE = process.env?.REACT_APP_API_BASE || '';
export const AZURE_CLIENT_ID = process.env?.REACT_APP_AZURE_CLIENT_ID || '';
export const AZURE_AUTHORITY = process.env?.REACT_APP_AZURE_AUTHORITY || '';
export const POST_LOGOUT_REDIRECT_URI = process.env?.REACT_APP_POST_LOGOUT_REDIRECT_URI || '';
export const SNACKBAR_AUTOHIDE = parseInt(process.env.REACT_APP_SNACKBAR_AUTOHIDE || '2') * 1000;
export const SNACKBAR_ERROR_AUTOHIDE =
  parseInt(process.env.REACT_APP_SNACKBAR_ERROR_AUTOHIDE || '5') * 1000;
export const DEFAULT_ROWS_PER_PAGE = parseInt(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE || '20');
export const APPLICATION = process.env.REACT_APP_APPLICATION || 'CONTRACTS';
export const TABLE_PAGINATION = (process.env.REACT_APP_TABLE_PAGINATION || '20,50,100')
  .split(',')
  .map((x) => parseInt(x));
export const NAME_LENGTH_LIMIT = parseInt(process.env.REACT_NAME_LENGTH_LIMIT || '60'); // set undefined for unlimited length
export const DEBOUNCE_TIMEOUT =
  process.env.REACT_APP_DEBOUNCE_TIMEOUT === undefined
    ? 300
    : parseInt(process.env.REACT_APP_DEBOUNCE_TIMEOUT);
export const DOCUSIGN_SCOPE_REDIRECT_URL =
  process.env.REACT_APP_DOCUSIGN_SCOPE_REDIRECT_URL || 'https://www.example.com';

export const DOCUSIGN_ENABLED =
  process.env.REACT_APP_DOCUSIGN_ENABLED &&
  ['true', 'yes', '1'].includes(process.env.REACT_APP_DOCUSIGN_ENABLED.toLowerCase());

export const DOCUSIGN_RETURN_URL = process.env.REACT_APP_DOCUSIGN_RETURN_URL;

export const PROPOSALS_APP_URL =
  process.env.REACT_APP_PROPOSALS_APP_URL || 'https://proposals.demac.com';

export const MAINTENANCE_PERIOD_URL =
  process.env.REACT_APP_MAINTENANCE_PERIOD_URL === ''
    ? undefined
    : process.env.REACT_APP_MAINTENANCE_PERIOD_URL || '/maintenance.json';

export const MAX_OWNERS =
  process.env.REACT_APP_MAX_OWNERS === undefined ? 8 : parseInt(process.env.REACT_APP_MAX_OWNERS);

export const MAX_MEMBERS =
  process.env.REACT_APP_MAX_MEMBERS === undefined
    ? 100
    : parseInt(process.env.REACT_APP_MAX_MEMBERS);
