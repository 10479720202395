import { ApolloError, useApolloClient } from '@apollo/client';
import { useUI } from 'contexts/UiContext';
import { GET_PROJECT_WITH_USERS } from 'graphql/legalFolders/projects';
import {
  projectFolderDocument_contract_document_projects,
  projectFolderDocument_contract_document_projects_leader,
} from 'graphql/legalFolders/types/projectFolderDocument';
import { useCallback, useState } from 'react';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { projectWithUsers } from 'graphql/legalFolders/types/projectWithUsers';

export interface IProjectLeader
  extends Omit<projectFolderDocument_contract_document_projects_leader, '__typename'> {
  projectId: string;
}

export const useProjectLeaders = () => {
  const client = useApolloClient();
  const { addSnackbar } = useUI();

  const [projectLeaders, setProjectLeaders] = useState<IProjectLeader[]>([]);

  const addProjectLeadersFromProjects = useCallback(
    (projects: projectFolderDocument_contract_document_projects[]) => {
      setProjectLeaders((projectLeaders) => {
        return [
          ...projectLeaders,
          ...projects
            .filter((project) => !!project.leader)
            .map((project) => {
              return {
                projectId: project.id,
                ...project.leader!,
              };
            }),
        ];
      });
    },
    []
  );

  const rmProjectLeadersFromProjects = useCallback((projectIds: string[]) => {
    setProjectLeaders((projectLeaders) => {
      return [...projectLeaders.filter((user) => !projectIds.includes(user.projectId))];
    });
  }, []);

  const fetchProjectLeadersFromProjects = useCallback(
    async (projectIds: string[]) => {
      const addLeaders: IProjectLeader[] = [];
      for (let i = 0; i < projectIds.length; i++) {
        const id = projectIds[i];
        const vars = { id };
        try {
          const { data, error } = await client.query<projectWithUsers>({
            query: GET_PROJECT_WITH_USERS,
            variables: vars,
            fetchPolicy: 'network-only',
          });
          if (!error && data.project.leader) {
            addLeaders.push({ ...data.project.leader, projectId: id });
          }
        } catch (e) {
          apolloErrorHandler(addSnackbar!)(e as ApolloError);
        }
        if (addLeaders.length) {
          setProjectLeaders((projectLeaders) => [...projectLeaders, ...addLeaders]);
        }
      }
    },
    [addSnackbar, client]
  );

  return {
    projectLeaders,
    addProjectLeadersFromProjects,
    rmProjectLeadersFromProjects,
    fetchProjectLeadersFromProjects,
  };
};
