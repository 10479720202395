import { FC } from 'react';
import s from './style.module.scss';

export interface INoAccessProps {
  name: string;
}

export const NoAccess: FC<INoAccessProps> = ({ name }) => {
  return (
    <div className={s.messageBox}>
      <div style={{ padding: '20px', color: 'red' }}>
        {name} does not exist or you don't have access.
      </div>
    </div>
  );
};
