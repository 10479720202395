import React, { FC } from 'react';
import { Button } from '@mui/material';

import { DocumentNavbarProps } from './interfaces';
import './index.scss';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export enum documentTabs {
  DOCUMENT_VIEW,
  FILE_MANAGEMENT,
}

export const NavigationTabs: FC<DocumentNavbarProps> = ({ page, handleSetPage, newDocument }) => {
  const gotoDocumentView = (): void => {
    handleSetPage(documentTabs.DOCUMENT_VIEW);
  };

  const gotoFileManagement = (): void => {
    handleSetPage(documentTabs.FILE_MANAGEMENT);
  };

  const activeButton = (btnType: documentTabs): string => {
    return page === btnType ? 'active' : '';
  };

  return (
    <div className="report-nav-container">
      <Button
        fullWidth
        className={`report-nav-item ${activeButton(documentTabs.DOCUMENT_VIEW)}`}
        variant="contained"
        color="secondary"
        onClick={debounce(gotoDocumentView, DEBOUNCE_TIMEOUT)}
      >
        <strong>Document information</strong>
      </Button>
      <Button
        fullWidth
        className={`report-nav-item ${activeButton(documentTabs.FILE_MANAGEMENT)}`}
        variant="contained"
        color="secondary"
        onClick={debounce(gotoFileManagement, DEBOUNCE_TIMEOUT)}
        disabled={newDocument}
      >
        <strong>
          File Management
          <br />
          and Approval
        </strong>
      </Button>
    </div>
  );
};
