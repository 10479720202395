import React, { FC, useMemo } from 'react';
import { IParty } from './interface';
import { PartyTile } from './components/PartyTile/PartyTile';
import { SelectPartyTile } from './components/SelectPartyTile/SelectPartyTile';

export interface IPartiesTilesProps {
  parties: Array<IParty | null>;
  onAddParty: (id: string, index: number) => void;
  onRemoveParty: (index: number) => void;
  onEditParty: (index: number) => void;
  showValidator?: boolean;
}

export const PartiesTiles: FC<IPartiesTilesProps> = ({
  parties,
  onAddParty,
  onRemoveParty,
  onEditParty,
  showValidator,
}) => {
  let index = -1;
  const onRemove = (index: number) => () => onRemoveParty(index);
  const onAdd = (index: number) => (id: string) => onAddParty(id, index);
  const onEdit = (index: number) => () => onEditParty(index);
  const error = showValidator && !parties.length;
  const usedPartyIds = useMemo(
    () => parties.filter((party) => !!party?.id).map((party) => party!.id),
    [parties]
  );

  return (
    <div
      style={
        error
          ? {
              border: '1px solid red',
              minHeight: '50px',
              borderRadius: '5px',
              width: '95%',
              margin: '12px auto',
            }
          : undefined
      }
    >
      {parties.map((party) => {
        index++;
        return (
          <div key={index}>
            {party ? (
              <PartyTile
                party={party}
                index={index}
                onRemoveParty={onRemove(index)}
                onEditParty={onEdit(index)}
              ></PartyTile>
            ) : (
              <SelectPartyTile
                onAddParty={onAdd(index)}
                onRemoveParty={onRemove(index)}
                error={!!showValidator}
                usedPartyIds={usedPartyIds}
              ></SelectPartyTile>
            )}
          </div>
        );
      })}
    </div>
  );
};
