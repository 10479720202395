import { EasyAutoComplete } from 'components';
import { GET_STOCK_EXCHANGES } from 'graphql/legalFolders/stockExchanges';
import { useAutoCompleteSimpleDataSource } from 'hooks/autoCompleteSimpleDataSource';
import { FC, useMemo } from 'react';
import { IStockExchangeData } from '../../interface';

export interface ISelectOneExchangeProps {
  exchange: IStockExchangeData | null;
  onSelectChange: (value: Partial<IStockExchangeData>) => void;
}

export const SelectOneExchange: FC<ISelectOneExchangeProps> = ({ exchange, onSelectChange }) => {
  const exchangesQuery = useMemo(() => {
    return {
      GET_QUERY: GET_STOCK_EXCHANGES,
      getItemId: (item: any) => item.id,
      getItems: (data: any) =>
        [...data.stockExchanges]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((se) => ({
            ...se,
            ddLabel: `${se.name}${se.acronym ? ' (' + se.acronym + ')' : ''}`,
          })),
    };
  }, []);

  const exchangesProps = useAutoCompleteSimpleDataSource<any, any, any>(exchangesQuery);

  const updateExchange =
    (getValue: (props: any) => Partial<IStockExchangeData>) => (props: any) => {
      const value = getValue(props);
      onSelectChange(value);
    };

  return (
    <EasyAutoComplete
      selected={exchange?.stockMarket || null}
      key={`stockExchanges`}
      label=""
      optionsLabel="ddLabel"
      textFieldStyle="outlined"
      {...exchangesProps}
      selectedChanged={updateExchange((value: any) => ({
        stockMarket: value,
      }))}
      getSelectedLabel={(option: any) => {
        return option.name;
      }}
    />
  );
};
