import React, { FC, useCallback, useState, useEffect } from 'react';

import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';

import { Routes, Header, Theme } from './components';
import { client } from './graphql';

import './index.scss';
import { Footer } from './components/Footer';

import { ManagedUIContext } from 'contexts/UiContext';
import { Layout } from 'components/Layout/Layout';

import { Provider as ChangedDialogContextProvider } from 'template/FormChangedDialog/FormChangedDialogContext';
import { Provider as MyTaskContextProvider } from 'contexts/MyTask';
import { Provider as LegalFoldersTreeProvider } from 'contexts/LegalFoldersTree';

import { FormChangedDialog } from 'template/FormChangedDialog/FormChangedDialog';

import { getAccessToken } from 'authorization/auth-utils-msal2';
import {
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { EventType, InteractionStatus } from '@azure/msal-browser';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Providers as MGT_Providers, ProviderState } from '@microsoft/mgt-element';

// Global Person card config
import { MgtPersonCard } from '@microsoft/mgt-react';
import { MaintenanceAlert } from 'components/Maintenance/MaintenenceAlert';
MgtPersonCard.config.useContactApis = false;
MgtPersonCard.config.sections.profile = false;
MgtPersonCard.config.sections.files = false;
MgtPersonCard.config.sections.mailMessages = false;
MgtPersonCard.config.sections.organization = false;

export const AppMsal: FC<any> = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const history = useHistory();

  const [token, setToken] = useState<string | undefined>();

  const refreshToken = useCallback(async () => {
    const result = await getAccessToken();
    if (result) {
      sessionStorage?.setItem('msal.idtoken', result);
    }
    setToken(result ? result : undefined);
  }, []);

  useEffect(() => {
    if (!token) {
      sessionStorage?.removeItem('msal.idtoken');
    }
  }, [token]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      // no need to await
      refreshToken();
    }
  }, [refreshToken, inProgress]);

  useEffect(() => {
    MGT_Providers.globalProvider.setState(
      inProgress !== InteractionStatus.None
        ? ProviderState.Loading
        : isAuthenticated
        ? ProviderState.SignedIn
        : ProviderState.SignedOut
    );
  }, [isAuthenticated, inProgress]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        history.push('/');
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, history]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ChangedDialogContextProvider>
        <MyTaskContextProvider>
          <LegalFoldersTreeProvider>
            <Theme>
              <FormChangedDialog></FormChangedDialog>
              <ToastContainer closeButton={false} />
              <Router>
                <ManagedUIContext>
                  <Header />
                  <ApolloProvider client={client}>
                    <AuthenticatedTemplate>
                      {token ? (
                        <Layout>
                          <MaintenanceAlert />
                          <Routes />
                        </Layout>
                      ) : undefined}
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <Layout>
                        <Routes />
                      </Layout>
                    </UnauthenticatedTemplate>
                    <Footer />
                  </ApolloProvider>
                </ManagedUIContext>
              </Router>
            </Theme>
          </LegalFoldersTreeProvider>
        </MyTaskContextProvider>
      </ChangedDialogContextProvider>
    </LocalizationProvider>
  );
};
