import { Button } from '@mui/material';
import React, { FC } from 'react';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const NewLegalFolderButton: FC<any> = ({ ...props }) => {
  const { confirmRequest } = useFormChangedDialogContext();
  const { onUnselectLegalFolder } = useComponentContext();
  const onAddLegalFolder = () => {
    confirmRequest!((confirmed) => {
      confirmed && onUnselectLegalFolder && onUnselectLegalFolder();
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={debounce(onAddLegalFolder, DEBOUNCE_TIMEOUT)}
      {...props}
    >
      New legal folder
    </Button>
  );
};
