import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { allDocumentsTableHead } from 'constants/allDocumentsTable';
import { filtersExtensionTableHead } from 'constants/filtersExtensionTable';
import { Box, Button, FormControlLabel, Link, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';
import { useComponentContext } from './AllDocumentsTableContext';
import { FC, useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { useUI } from 'contexts/UiContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { ExportToExcel } from './components/ExportToExcel/ExportToExcel';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useLegalFoldersNamesAutocompleteDataHook } from 'hooks/legalFoldersNamesAutocompleteDataHook';
import { useProjectFoldersNamesAutocompleteDataHook } from 'hooks/projectFoldersNamesAutocompleteDataHook';
import { useDocumentsNamesAutocompleteDataHook } from 'hooks/documentsAutocompleteDataHook';
import { useUsersAutocompleteDataHook } from 'hooks/usersAutoCompleteDataHook';
import { PopoverForm } from 'components/PopoverForm/PopoverForm';
import { FilterForm } from 'components/ui/FilterForm/FilterForm';
import { ArrowIcon } from 'components/ArrowIcon/ArrowIcon';
import { useProjectsKeysAutocompleteDataHook } from 'hooks/projectsKeysAutocompleteDataHook';
import { useProjectsNamesAutocompleteDataHook } from 'hooks/projectsNamesAutocompleteDataHook';
import { usePartyNamesAutocompleteDataHook } from 'hooks/partyNamesAutocompleteDataHook';

export interface IAllDocumentsTableViewProps {
  title?: string;
  onReady?: () => void;
  printView?: boolean;
}

export const AllDocumentsTableView: FC<IAllDocumentsTableViewProps> = ({
  onReady,
  printView,
  title,
}) => {
  const { addSnackbar } = useUI();
  const {
    totalItems,
    documents,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    clearAllFilters,
    myTask,
    setMyTask,
    onDocumentSelect,
    filterOptions,
    filterValues,
    error,
  } = useComponentContext();

  const [filtersAnchorEl, setFiltersAnchorEl] = useState<null | HTMLElement>(null);
  const openFiltersPopover = Boolean(filtersAnchorEl);
  const openFilters = (event: Record<string, any>): void => {
    setFiltersAnchorEl(event.currentTarget);
  };

  const closeFilters = () => {
    setFiltersAnchorEl(null);
  };

  useEffect(() => {
    if (!!onReady && !loading && documents) {
      onReady();
    }
  }, [loading, documents, onReady]);

  useEffect(() => {
    if (!loading && !!error) {
      apolloErrorHandler(addSnackbar!)(error);
    }
  }, [loading, error, addSnackbar]);

  const exportPageLoadParams = useMemo(() => {
    return {
      ...pageLoadParams,
      filter: { ...pageLoadParams.filter, onlyCurrentUserDocuments: myTask },
    };
  }, [myTask, pageLoadParams]);

  const legalFoldersNamesProps = useLegalFoldersNamesAutocompleteDataHook({
    search: filterValues['legalFolderName'],
  });

  const projectFoldersNamesProps = useProjectFoldersNamesAutocompleteDataHook({
    search: filterValues['projectFolderName'],
  });

  const documentsNamesProps = useDocumentsNamesAutocompleteDataHook({
    search: filterValues['name'],
  });

  const ownerNamesProps = useUsersAutocompleteDataHook({
    search: filterValues['ownerNames'],
  });

  const currentlyReviewingProps = useUsersAutocompleteDataHook({
    search: filterValues['currentlyReviewing'],
  });

  const partyNamesProps = usePartyNamesAutocompleteDataHook({
    search: filterValues['partyName'],
  });

  const projectsNamesProps = useProjectsNamesAutocompleteDataHook({
    search: filterValues['projectName'],
  });

  const projectsKeysProps = useProjectsKeysAutocompleteDataHook({
    search: filterValues['projectKey'],
  });

  const extendedAllDocumentsTableHead = useMemo(() => {
    allDocumentsTableHead.find((item) => item.id === 'legalFolderName')!.filterProps =
      legalFoldersNamesProps;

    allDocumentsTableHead.find((item) => item.id === 'projectFolderName')!.filterProps =
      projectFoldersNamesProps;

    allDocumentsTableHead.find((item) => item.id === 'name')!.filterProps = documentsNamesProps;

    allDocumentsTableHead.find((item) => item.id === 'ownerNames')!.filterProps = ownerNamesProps;

    allDocumentsTableHead.find((item) => item.id === 'currentlyReviewing')!.filterProps =
      currentlyReviewingProps;

    return allDocumentsTableHead;
  }, [
    legalFoldersNamesProps,
    projectFoldersNamesProps,
    documentsNamesProps,
    ownerNamesProps,
    currentlyReviewingProps,
  ]);

  const extendedFiltersExtensionTableHead = useMemo(() => {
    filtersExtensionTableHead.find((item) => item.id === 'projectName')!.filterProps =
      projectsNamesProps;

    filtersExtensionTableHead.find((item) => item.id === 'projectKey')!.filterProps =
      projectsKeysProps;

    filtersExtensionTableHead.find((item) => item.id === 'partyName')!.filterProps =
      partyNamesProps;

    return filtersExtensionTableHead;
  }, [projectsNamesProps, projectsKeysProps, partyNamesProps]);

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <PopoverForm open={openFiltersPopover} anchorEl={filtersAnchorEl} handleClose={closeFilters}>
        <FilterForm
          headCells={extendedFiltersExtensionTableHead}
          loadPage={loadPage}
          filterOptions={filterOptions}
          filterValues={filterValues}
          onFilterChange={onFilterChange}
          initRowsPerPage={pageLoadParams.rowsPerPage}
          initOrder={pageLoadParams.order || SortOrder.ASC}
          initOrderBy={pageLoadParams.orderBy}
          initPage={pageLoadParams.page}
        ></FilterForm>
      </PopoverForm>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Grid container spacing={2} padding={'0px'}>
            <Grid
              xs={12}
              sm={4}
              style={{ display: 'flex', alignItems: 'center' }}
              order={{ xs: 1 }}
            >
              {title ? (
                <div style={{ paddingLeft: '0.875rem', paddingTop: '0.875rem' }}>
                  <Typography variant="body2" className="label-title-nocase">
                    {title}
                  </Typography>
                </div>
              ) : undefined}
            </Grid>
            <Grid xs={12} sm={4} order={{ xs: 3, sm: 2 }}>
              {filterApplied ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                </Box>
              ) : undefined}
            </Grid>
            <Grid
              xs={12}
              sm={4}
              style={{ display: 'flex', justifyContent: 'end' }}
              order={{ xs: 2, sm: 3 }}
            >
              <FormControlLabel
                style={{ display: 'none' }}
                control={
                  <Switch
                    color="primary"
                    checked={myTask}
                    onChange={() => {
                      setMyTask!(!myTask);
                    }}
                  ></Switch>
                }
                label="My Task"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Link component="button" onClick={openFilters} style={{ paddingLeft: '10px' }}>
            <Typography variant="caption">Add filters</Typography>
            <ArrowIcon down={true} />
          </Link>
        </Grid>
        <Grid xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              totalItems={totalItems}
              dataCells={documents}
              headCells={extendedAllDocumentsTableHead}
              loadPage={loadPage}
              handleSelect={onDocumentSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
              paginationSideComponent={
                <ExportToExcel
                  pageLoadParams={exportPageLoadParams}
                  totalItems={totalItems}
                ></ExportToExcel>
              }
            />
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};
