import { useEffect } from 'react';
import { FC } from 'react';
import { useLegalFolder } from 'template/TreeTable/components/Breadcrumbs/legalFolderHook';
import { useComponentContext as useTreeComponentContext } from 'template/TreeTable/TreeTableContext';

export interface IDocumentPrintingMeta {
  onReady: () => void;
}
export const LegalFolderPrintingMeta: FC<IDocumentPrintingMeta> = ({ onReady }) => {
  const { selectedFolder } = useTreeComponentContext();

  const { legalFolderName } = useLegalFolder({ id: selectedFolder?.legalFolderId });

  useEffect(() => {
    if (!!legalFolderName) {
      onReady();
    }
  }, [legalFolderName, onReady]);

  return (
    <div>
      <div>{window.location.href}</div>
    </div>
  );
};
