import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { EasyAutoComplete } from 'components';
import { useLegalFolders } from './hooks';
import { find } from 'lodash';

interface IItem {
  id: string;
  name: string;
}

export interface ISelectLegalFolderProps {
  onSelectChange?: (selectedItem: IItem) => void;
  selectedItem?: IItem | null;
  [key: string]: any;
}

export const SelectLegalFolder: FC<ISelectLegalFolderProps> = ({
  onSelectChange,
  selectedItem,
  ...props
}) => {
  const [search, setSearch] = useState('');
  const { legalFolders, maxPage, loadPage, loadPageCount } = useLegalFolders({ search });

  const loadPageRef = useRef<any>();

  useEffect(() => {
    loadPageRef.current = loadPage;
  }, [loadPage]);

  useEffect(() => {
    loadPageRef.current && loadPageRef.current(0);
  }, []);

  const legalFoldersList = useMemo(() => {
    if (!selectedItem || find(legalFolders, (legalFolder) => legalFolder.id === selectedItem.id)) {
      return [...legalFolders];
    }
    return [selectedItem, ...legalFolders];
  }, [legalFolders, selectedItem]);

  return (
    <EasyAutoComplete
      key={selectedItem?.id || '_'}
      items={legalFoldersList || [selectedItem]}
      selected={selectedItem}
      label=""
      textFieldStyle="outlined"
      optionsLabel="name"
      selectedChanged={(value: any): void => {
        onSelectChange && onSelectChange(value);
      }}
      error={false}
      pagination={{
        pages: maxPage,
        loadPage: (page: number) => {
          loadPage(page);
          loadPageCount();
        },
      }}
      onInputChange={(event: object, value: string, reason: string) => {
        setSearch(value);
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.id === value.id || (value.id === '0' && option.email === value.email);
      }}
      fullWidth
      {...props}
      style={{
        ...props.style,
        // minWidth: '9.375rem'
      }}
      onOpen={() => {
        setSearch('');
      }}
    />
  );
};
