import { useQuery } from '@apollo/client';
import { GET_DOCUMENT_TYPES } from 'graphql/legalFolders/documents';
import { documentTypes } from 'graphql/legalFolders/types/documentTypes';
import { useCallback, useMemo } from 'react';

export const useDocumentTypes = () => {
  const { data } = useQuery<documentTypes>(GET_DOCUMENT_TYPES);

  const getPairs = useCallback(() => {
    return (
      data?.contract_documentTypes.map(({ id, name }) => {
        return {
          id,
          name,
        };
      }) || []
    );
  }, [data]);

  const documentTypePairs = useMemo(() => {
    return getPairs();
  }, [getPairs]);

  return { data, documentTypePairs };
};
