import { gql } from '@apollo/client';

export const GET_SAS_URL = gql`
  query generateUploadBlobSASUrl(
    $documentId: ID!
    $filename: String!
    $documentFileType: DocumentFileType!
  ) {
    contract_generateUploadBlobSASUrl(
      documentId: $documentId
      filename: $filename
      documentFileType: $documentFileType
    ) {
      url
    }
  }
`;
