import React, { FC, useEffect } from 'react';
import { TreeTable } from 'template/TreeTable/TreeTable';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';

export const TreeTableHome: FC = () => {
  const { initPage } = useComponentContext();

  useEffect(() => {
    initPage!({
      wellcomeProp: true,
      initTabProp: undefined,
      selectedFolderProp: undefined,
    });
  }, [initPage]);

  return <TreeTable></TreeTable>;
};
