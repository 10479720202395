import { FC, useState } from 'react';
import { Container, Paper, useTheme, useMediaQuery } from '@mui/material';

import './index.scss';
import { IDocumentsTableHookLoadParams } from 'template/TreeTable/components/AllDocumentsTable/documentsTableHook';
import { Provider as AllProjectFoldersTableContextProvider } from 'template/TreeTable/components/AllProjectFoldersTable/AllProjectFoldersTableContext';
import { AllProjectFoldersTableView } from 'template/TreeTable/components/AllProjectFoldersTable/AllProjectFoldersTableView';
import { Print } from 'template/Print/Print';
import { AllProjectFoldersTablePrintingMeta } from './AllProjectFoldersTablePrintingMeta';

export const ProjectFoldersTemplate: FC<{
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IDocumentsTableHookLoadParams>;
  tableStorageKey?: string;
  title?: string;
}> = ({ overridePageLoadParams, initPageLoadParams, tableStorageKey, title }) => {
  const [printing, setPrinting] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '4px 4px 16px 4px' }
            : { padding: '4px 4px 16px 4px', marginTop: '6px' }
        }
        id="main-paper"
      >
        <AllProjectFoldersTableContextProvider
          overridePageLoadParams={overridePageLoadParams}
          initPageLoadParams={initPageLoadParams}
          tableStorageKey={tableStorageKey}
        >
          <AllProjectFoldersTableView title={title}></AllProjectFoldersTableView>

          <Print
            printing={printing}
            setPrinting={setPrinting}
            PrintingMeta={AllProjectFoldersTablePrintingMeta}
            PrintingData={AllProjectFoldersTableView}
            landscape
            subtitle="Documents List"
          ></Print>
        </AllProjectFoldersTableContextProvider>
      </Paper>
    </Container>
  );
};
