import { useLazyQuery } from '@apollo/client';
import {
  GET_LEGAL_FOLDERS_NAMES,
  GET_LEGAL_FOLDERS_COUNT,
} from 'graphql/legalFolders/legalFolders';
import { getLegalFolders } from 'graphql/legalFolders/types/getLegalFolders';
import { getLegalFoldersCount } from 'graphql/legalFolders/types/getLegalFoldersCount';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface ILegalFolder {
  id: string;
  name: string;
}

export interface IUseOwnerState {
  legalFolders: ILegalFolder[];
  totalItems: number;
  maxPage: number;
  loadPage: (page: number) => void;
  loadPageCount: () => void;
}

const rowsPerPage = 20;
const order = [{ column: 'NAME', order: 'ASC' }];

interface IUseOwnerProps {
  search: string;
}
export const useLegalFolders = ({ search }: IUseOwnerProps): IUseOwnerState => {
  const [localSearch, setLocalSearch] = useState(search);
  const [totalItems, setTotalItems] = useState(0);
  const [legalFolders, setLegalFolders] = useState<ILegalFolder[]>([]);

  const [
    loadLegalFolders,
    {
      data: dataLegalFolders,
      loading: loadingLegalFolders,
      refetch: refetchLegalFolders,
      called: calledLegalFolders,
      error: errorLegalFolders,
    },
  ] = useLazyQuery<getLegalFolders>(GET_LEGAL_FOLDERS_NAMES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    loadLegalFoldersCount,
    {
      data: dataLegalFoldersCount,
      loading: loadingLegalFoldersCount,
      refetch: refetchLegalFoldersCount,
      called: calledLegalFoldersCount,
    },
  ] = useLazyQuery<getLegalFoldersCount>(GET_LEGAL_FOLDERS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const maxPage = useMemo(() => Math.ceil(Number(totalItems) / Number(rowsPerPage)), [totalItems]);

  const loadPage = useCallback(
    (page: number) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: order && order.length ? order : undefined,
        filter: {
          nameContains: search,
        },
      };

      if (calledLegalFolders) {
        refetchLegalFolders!(variables);
      } else {
        loadLegalFolders({ variables });
      }
    },
    [loadLegalFolders, refetchLegalFolders, calledLegalFolders, search]
  );

  const loadPageCount = useCallback(() => {
    const variables = {
      filter: {
        nameContains: search,
      },
    };

    if (calledLegalFoldersCount) {
      refetchLegalFoldersCount!(variables);
    } else {
      loadLegalFoldersCount({ variables });
    }
  }, [loadLegalFoldersCount, refetchLegalFoldersCount, calledLegalFoldersCount, search]);

  useEffect(() => {
    if (!loadingLegalFoldersCount && dataLegalFoldersCount) {
      setTotalItems(dataLegalFoldersCount.contract_legalFolderCount);
    }
  }, [loadingLegalFoldersCount, dataLegalFoldersCount]);

  useEffect(() => {
    if (!errorLegalFolders && dataLegalFolders && !loadingLegalFolders) {
      setLegalFolders((legalFolders) => {
        return [...legalFolders, ...dataLegalFolders.contract_legalFolders];
      });
    }
  }, [dataLegalFolders, loadingLegalFolders, errorLegalFolders]);

  useEffect(() => {
    if (localSearch !== search) {
      setLegalFolders([]);
      setLocalSearch(search);
      loadPage(0);
      loadPageCount();
    }
  }, [loadPage, loadPageCount, search, localSearch]);

  return {
    legalFolders,
    totalItems,
    maxPage,
    loadPage,
    loadPageCount,
  };
};
