export const docuSignErrorApiCodes = [
  {
    code: 'ACCESS_CODE_IN_WRONG_FORMAT',
    text: 'Access code provided is not in the correct format.',
  },
  {
    code: 'ACCOUNT_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The accound id did not identify an account in the system.',
  },
  {
    code: 'ACCOUNT_HAS_BEEN_SUSPENDED',
    text: 'The specified account is suspended',
  },
  {
    code: 'ACCOUNT_IS_NOT_MANAGED_BY_DISTRIBUTOR',
    text: 'The account is not managed by the distributor.',
  },
  {
    code: 'ACCOUNT_LACKS_CUSTOM_TAB_PERMISSION',
    text: 'This account lacks sufficient permissions to use custom tab.',
  },
  {
    code: 'ACCOUNT_LACKS_MERGE_FIELD_PERMISSION',
    text: 'This account lacks sufficient permissions to use merge Fields.',
  },
  {
    code: 'ACCOUNT_LACKS_PERMISSION_TO_CORRECT_TABS',
    text: 'The account lacks sufficient permissions to correct tabs.',
  },
  {
    code: 'ACCOUNT_LACKS_PERMISSIONS',
    text: 'This account lacks sufficient permissions.',
  },
  {
    code: 'ACCOUNT_LACKS_PRICING_PLAN',
    text: 'The specified account lacks an account pricing plan',
  },
  {
    code: 'ACCOUNT_NOT_AUTHORIZED_FOR_ENVELOPE',
    text: 'This account is not authorized to access the requested envelope.',
  },
  {
    code: 'ACCOUNT_PLAN_DOES_NOT_EIXST_IN_SYSTEM',
    text: 'The current account plan does not exist in the system.',
  },
  {
    code: 'ACCOUNT_PROVISIONER_INSUFFICIENT_SEATS',
    text: 'Insufficient account provisioning distributor seats.',
  },
  {
    code: 'ACCOUNT_PROVISIONER_INVALID_ADMIN_NRDS',
    text: 'Invalid Admin NRDS ID',
  },
  {
    code: 'ACCOUNT_SPECIFIED_INVALID',
    text: 'The account id provided does not exist, or is improperly formatted.',
  },
  {
    code: 'ACCOUNT_TRANSFER_IN_PROGRESS',
    text: 'The account is being transferred to another site.',
  },
  {
    code: 'ACCOUNT_TRANSFERRED,',
    text: 'This account has been transferred to another site.',
  },
  {
    code: 'ACTIVATION_FAILED',
    text: 'The token for a recipient that has failed activation cannot be generated.',
  },
  {
    code: 'ADDRESS_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The address id did not identify an address in the system.',
  },
  {
    code: 'ADDRESS_UPDATE_FAILED',
    text: 'The address update failed.',
  },
  {
    code: 'ADDRESSBOOK_ACCOUNTNAME_INVALID',
    text: 'The account name provided does not exist or is null or empty.',
  },
  {
    code: 'ADDRESSBOOK_ATTEMPTED_UPDATE_FAILED',
    text: 'Attempting to update the address book failed.',
  },
  {
    code: 'ADDRESSBOOK_CANTSHARE',
    text: 'Invalid address book share permissions.',
  },
  {
    code: 'ADDRESSBOOK_CONTACTID_INVALID',
    text: 'The contact id provided does not exist in the address book, or is improperly formatted.',
  },
  {
    code: 'ADDRESSBOOK_DESIGNATION_INVALID',
    text: 'Address book designation is not valid.',
  },
  {
    code: 'ADDRESSBOOK_EMAIL_INVALID',
    text: 'Invalid email for the address book entry.',
  },
  {
    code: 'ADDRESSBOOK_ID_INVALID',
    text: 'Invalid ID for the address book entry.',
  },
  {
    code: 'ADDRESSBOOK_INVALID_RESULT_STATE',
    text: 'The address book action resulted in an invalid state.',
  },
  {
    code: 'ADDRESSBOOK_INVALID_SEARCH_CRITERIA',
    text: 'The search criteria provided are invalid.',
  },
  {
    code: 'ADDRESSBOOK_INVALID_SHARING_OPTION',
    text: 'Only ‘true’ or ‘false’ are valid values for ‘shared’.',
  },
  {
    code: 'ADDRESSBOOK_INVALID_UPDATE_CRITERIA',
    text: 'The update criteria provided are invalid.',
  },
  {
    code: 'ADDRESSBOOK_NOTOWNER',
    text: 'Invalid owner for the address book entry.',
  },
  {
    code: 'ADDRESSBOOK_USERNAME_INVALID',
    text: 'The user name provided was null or empty.',
  },
  {
    code: 'ANCHOR_TAB_STRING_NOT_FOUND',
    text: 'The specified anchor tab string was not found in the document.',
  },
  {
    code: 'ANCHOR_TAG_PROCESSING_FAILURE',
    text: 'An error occurred during anchor tag processing.',
  },
  {
    code: 'API_DOCGEN_FILE_CREATE',
    text: 'File could not be created.',
  },
  {
    code: 'API_DOCGEN_FILE_NO_VALUE',
    text: 'File does not contain API documentation.',
  },
  {
    code: 'API_DOCGEN_FILE_NOT_EXIST',
    text: 'File does not exist.',
  },
  {
    code: 'API_DOCGEN_FILE_READ',
    text: 'File could not be read.',
  },
  {
    code: 'API_DOCGEN_INVALIDFILE',
    text: 'File is invalid.',
  },
  {
    code: 'APPSTORE_ERROR',
    text: 'App Store error.',
  },
  {
    code: 'APPSTORE_PAYMENT_METHOD_ERROR',
    text: 'Payment method error.',
  },
  {
    code: 'APPSTORE_PRODUCTID_INVALID',
    text: 'Product id is Invalid.',
  },
  {
    code: 'APPSTORE_PRODUCTID_MISSING',
    text: 'Product id not provided',
  },
  {
    code: 'APPSTORE_RECEIPT_DATA_MISSING',
    text: 'Receipt data not provided',
  },
  {
    code: 'APPSTORE_RECEIPT_MISSING',
    text: 'Receipt not provided',
  },
  {
    code: 'APPSTORE_RECEIPT_VERIFICATION_FAILED',
    text: 'Receipt verification failed.',
  },
  {
    code: 'AUTHORIZATION_HEADER_REQUIRED',
    text: 'Authorization header is required.',
  },
  {
    code: 'AUTHORIZATION_INSUFFICIENT_SCOPE',
    text: 'The request requires higher privileges than provided by the access token.',
  },
  {
    code: 'AUTHORIZATION_INVALID_REQUEST',
    text: 'The authorization request is malformed.',
  },
  {
    code: 'AUTHORIZATION_INVALID_TOKEN',
    text: 'The access token provided is expired, revoked or malformed.',
  },
  {
    code: 'AUTHORIZATION_MAX_ACCESS_TOKENS_EXCEEDED',
    text: 'Maximum number of access tokens exceeded.',
  },
  {
    code: 'AUTHORIZATION_REVOKED',
    text: 'Authorization has been revoked.',
  },
  {
    code: 'AUTHORIZATION_REVOKED_FAILED',
    text: 'Unable to revoke access.',
  },
  {
    code: 'BCC_EMAIL_ADDRESS_ID_NOT_FOUND',
    text: 'BCC email address id not found.',
  },
  {
    code: 'BILLING_CHANGES_PENDING',
    text: 'Billing changes are pending.',
  },
  {
    code: 'BILLING_INVOICES_NOT_RETRIEVED',
    text: 'Billing invoices could not be retrieved.',
  },
  {
    code: 'BILLING_NOT_CONFIGURED',
    text: 'Billing not configured in the system.',
  },
  {
    code: 'BILLING_PERIOD_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The current account billing period does not exist in the system.',
  },
  {
    code: 'BILLING_PLAN_ERROR',
    text: 'The billing plan could not be changed.',
  },
  {
    code: 'BILLING_PLAN_PREVIEW_ERROR',
    text: 'The billing plan could not be previewed.',
  },
  {
    code: 'BILLING_SETUP_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The billing setup is not found in the system.',
  },
  {
    code: 'BILLING_SYSTEM_LOGIN_FAILED',
    text: 'Login to billing system failed.',
  },
  {
    code: 'BRAND_CREATE_FAILED',
    text: 'Brands could not be created.',
  },
  {
    code: 'BRAND_DELETE_FAILED',
    text: 'Brands could not be deleted.',
  },
  {
    code: 'BRAND_LOCKED',
    text: 'Brand is locked and cannot be reset.',
  },
  {
    code: 'BRAND_UPDATE_FAILED',
    text: 'One or more brands could not be updated.',
  },
  {
    code: 'BULK_ENVELOPE_ACCOUNT_LACKS_PERMISSIONS',
    text: 'This account lacks bulk send permissions.',
  },
  {
    code: 'BULK_ENVELOPE_BLANK_CSV_HEADER_FIELD',
    text: 'CSV file contains blank header fields.',
  },
  {
    code: 'BULK_ENVELOPE_DUPLICATE_RECIPIENTS',
    text: 'Bulk recipient duplicates found.',
  },
  {
    code: 'BULK_ENVELOPE_EMPTY_VALUE',
    text: 'Invalid empty value.',
  },
  {
    code: 'BULK_ENVELOPE_FIELD_NOT_SUPPORTED',
    text: 'Field not supported for bulk recipient.',
  },
  {
    code: 'BULK_ENVELOPE_FILE_DELETE_FAILED',
    text: 'Fail to delete bulk send file.',
  },
  {
    code: 'BULK_ENVELOPE_HAS_NO_BULK_RECIPIENTS',
    text: 'Bulk recipient envelope doesn’t have bulk recipients.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_AUTH_ID',
    text: 'Invalid authentication identifier.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_BATCHID',
    text: 'Batch id is invalid.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_CHARACTERS',
    text: 'Invalid characters.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_CSV_LIST',
    text: 'Invalid bulk recipient CSV list.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_CSV_ROW',
    text: 'Invalid line in bulk recipient CSV list.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_EMAIL',
    text: 'Invalid Email.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_PERMISSION_PHONE_AUTH',
    text: 'Recipient phone authentication not enabled for account.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_PERMISSION_SAML_AUTH',
    text: 'Recipient SAML authentication not enabled for account.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_PERMISSION_SMS_AUTH',
    text: 'Recipient SMS Authentication not enabled for account.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_PERMISSION_SOCIAL_AUTH',
    text: 'Recipient social Authentication not enabled for account.',
  },
  {
    code: 'BULK_ENVELOPE_INVALID_PHONE',
    text: 'Invalid phone number.',
  },
  {
    code: 'BULK_ENVELOPE_MAX_ENVELOPE_EXCEEDED',
    text: 'Maximum number of bulk envelope exceeded.',
  },
  {
    code: 'BULK_ENVELOPE_MAX_RECIPIENTS_EXCEEDED',
    text: 'Maximum number of bulk recipients was exceeded.',
  },
  {
    code: 'BULK_ENVELOPE_MAX_VALUE_LENGTH_EXCEEDED',
    text: 'Maximum length for a value was exceeded.',
  },
  {
    code: 'BULK_ENVELOPE_MUST_HAVE_ACCOUNT',
    text: 'Bulk recipient must have an account.',
  },
  {
    code: 'BULK_ENVELOPE_NOT_DRAFT_OR_TEMPLATE',
    text: 'The requested envelope is not a draft or template.',
  },
  {
    code: 'BULK_ENVELOPE_ONLY_ONE_BULK_RECIPIENT_ALLOWED',
    text: 'Bulk envelope only allows one bulk recipient.',
  },
  {
    code: 'BULK_ENVELOPE_RECIPIENT_NOT_BULK_RECIPIENT',
    text: 'Recipient is not a bulk recipient.',
  },
  {
    code: 'BULK_ENVELOPE_RECIPIENT_NOT_BULK_RECIPIENT',
    text: 'Recipient is not a bulk recipient.',
  },
  {
    code: 'BULK_ENVELOPE_REQUIRED_FIELD_MISSING',
    text: 'Required field is missing.',
  },
  {
    code: 'BULK_ENVELOPE_SEND_FAILED',
    text: 'Fail to send bulk envelopes.',
  },
  {
    code: 'BULK_ENVELOPE_SMS_AUTH_PHONE_INVALID',
    text: 'SMS authentication phone number cannot be usersupplied.',
  },
  {
    code: 'BULK_ENVELOPE_SMS_AUTH_PHONE_MISSING',
    text: 'SMS authentication phone number cannot be empty.',
  },
  {
    code: 'BULK_ENVELOPE_TEMPLATE_IS_TIED_TO_POWERFORM',
    text: 'Template that is tied to a powerform is not allowed to bulk send.',
  },
  {
    code: 'BULK_ENVELOPE_USER_LACKS_PERMISSIONS',
    text: 'This user lacks bulk send permissions.',
  },
  {
    code: 'BURST_APIINVOCATION_LIMIT_EXCEEDED',
    text: 'The maximum number of burst API invocations has been exceeded.',
  },
  {
    code: 'CACHE_INVALID_INIT_STRING',
    text: 'The initialization string was null or empty.',
  },
  {
    code: 'CACHE_OBJECT_SIZE_EXCEEDED',
    text: 'The cache object size has been exceeded.',
  },
  {
    code: 'CANNOT_ADD_DOCUMENT',
    text: 'Cannot add the document for the envelope.',
  },
  {
    code: 'CANNOT_ALLOW_MARKUP',
    text: 'Account does not have permission to set allow Markup.',
  },
  {
    code: 'CANNOT_ASSIGN_MANAGERS_SAME_ROUTING_ORDER',
    text: 'Multiple send to manage Roles (Agent, Editor or Intermediary) cannot be assigned same routing order.',
  },
  {
    code: 'CANNOT_ASSIGN_TAB_TO_MANAGERROLE',
    text: 'Send to manage Role (Agent, Editor or Intermediary) cannot have tabs assigned.',
  },
  {
    code: 'CANNOT_DELETE_FROM_RECYCLEBIN',
    text: 'Envelopes may not be deleted from the RecycleBin',
  },
  {
    code: 'CANNOT_DELETE_PREDEFINED_FOLDERS',
    text: 'You cannot delete predefined system folders.',
  },
  {
    code: 'CANNOT_EXCLUDE_DOCUMENT',
    text: 'This document cannot be excluded for this recipient.',
  },
  {
    code: 'CANNOT_SEND_TO_AGENT',
    text: 'Account does not have permission to send to Agent recipient type.',
  },
  {
    code: 'CANNOT_SEND_TO_CERTIFIED_DELIVERY',
    text: 'Account does not have permission to send to Certified Delivery recipient type.',
  },
  {
    code: 'CANNOT_SEND_TO_EDITOR',
    text: 'Account does not have permission to send to Editor recipient type.',
  },
  {
    code: 'CANNOT_SEND_TO_INPERSONSIGNER',
    text: 'Account does not have permission to send to InPersonSigner recipient type',
  },
  {
    code: 'CANNOT_SEND_TO_INTERMEDIARY',
    text: 'Account does not have permission to send to Intermediary recipient type.',
  },
  {
    code: 'CANNOT_SHARE_TO_ADMIN_GROUP',
    text: 'Templates are shared to Admin group by default',
  },
  {
    code: 'CAPTIVE_CARBON_COPY_RECIPIENT_NOT_SUPPORTED',
    text: 'A carbon copy recipient has been specified as captive. This operation is not supported.',
  },
  {
    code: 'CAPTIVE_IN_PERSON_SIGNER_RECIPIENT_NOT_SUPPORTED',
    text: 'Captive recipient cannot be of type InPersonSigner.',
  },
  {
    code: 'CAPTIVE_RECIPIENT_NOT_FOUND',
    text: 'The specified captive recipient was not found.',
  },
  {
    code: 'CAPTIVE_RECIPIENT_TOKEN_CLIENTURLS_NULL',
    text: 'Invalid RequestRecipientTokenClientURLs value. The clientUrls argument is null.',
  },
  {
    code: 'CAPTIVE_RECIPIENT_TOKEN_CLIENTURLS_NULL',
    text: 'Invalid RequestRecipientTokenClientURLs value. The clientUrls argument is null.',
  },
  {
    code: 'CONDITIONALTAB_HAS_INVALID_PARENT',
    text: 'A conditional tab references an invalid parent. Parent label must match another tab. Only one parent allowed. Signature tabs may not be parent tabs.',
  },
  {
    code: 'CONNECT_CANNOT_CHANGE_TYPE',
    text: 'Connect configuration cannot change type after creation.',
  },
  {
    code: 'CONNECT_ERROR',
    text: 'Connect Error.',
  },
  {
    code: 'CONNECT_SERVICE_UNSUPPORTED',
    text: 'Connect service does not support object instance search.',
  },
  {
    code: 'CONNECT_SERVICE_USER_LACKS_PERMISSION',
    text: 'User lacks permission, not allowed to perform object instance search.',
  },
  {
    code: 'CONSUMER_DISCLOSURE_ACCEPTANCE_REQUIRED',
    text: 'Consumer disclosure acceptance is required.',
  },
  {
    code: 'CORRECTED_EMAIL_IS_RESERVED',
    text: 'The corrected email(s) are reserved.',
  },
  {
    code: 'CORRECTION_HAS_A_BLANK_USERNAME',
    text: '',
  },
  {
    code: 'CORRECTION_HAS_A_USERNAME_WITH_INVALID_CHARACTERS',
    text: 'The specified envelope correction has a username with invalid characters.',
  },
  {
    code: 'CORRECTION_HAS_DUPLICATE_RECIPIENTS',
    text: 'The specified envelope corrections have duplicate recipients.',
  },
  {
    code: 'CORRECTION_RESULTS_IN_DUPLICATE_RECIPIENTS',
    text: 'The specified envelope corrections result in duplicate recipients.',
  },
  {
    code: 'CREDIT_CARD_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The credit Card does not exist in the system.',
  },
  {
    code: 'CREDIT_CARD_EXPIRED',
    text: 'Credit card is expired.',
  },
  {
    code: 'CREDIT_CARD_INVALID_ADDRESS',
    text: 'Credit card has invalid address.',
  },
  {
    code: 'CREDIT_CARD_NOT_ALLOWED_FOR_CURRENCY',
    text: 'The credit Card is not allowed for the specified currency.',
  },
  {
    code: 'CREDIT_CARD_NOT_AUTHORIZED',
    text: 'Credit card not authorized.',
  },
  {
    code: 'CREDIT_CARD_UPDATE_FAILED',
    text: 'Credit card update failed.',
  },
  {
    code: 'CUSTOM_FIELD_ALREADY_EXISTS',
    text: 'Custom field already exists.',
  },
  {
    code: 'CUSTOM_SETTING_NOT_FOUND',
    text: 'The specified setting was not found.',
  },
  {
    code: 'CUSTOM_SETTINGS_MAX_EXCEEDED',
    text: 'The maximum length for custom settings was exceeded.',
  },
  {
    code: 'CUSTOM_SETTINGS_NOT_FOUND',
    text: 'Custom settings were not found.',
  },
  {
    code: 'CUSTOM_TAB_APICREDENTIAL_IS_NULL',
    text: 'The API credential is null.',
  },
  {
    code: 'CUSTOM_TAB_APICREDENTIAL_IS_NULL',
    text: 'The API credential is null.',
  },
  {
    code: 'CUSTOM_TAB_CREATE_FAILED',
    text: 'Custom tab create failed.',
  },
  {
    code: 'CUSTOM_TAB_CREATE_FAILED',
    text: 'Custom tab create failed.',
  },
  {
    code: 'CUSTOM_TAB_DELETE_FAILED',
    text: 'Custom tab delete failed.',
  },
  {
    code: 'CUSTOM_TAB_IS_NULL',
    text: 'The Custom Tab is null.',
  },
  {
    code: 'CUSTOM_TAB_IS_NULL',
    text: 'The Custom Tab is null.',
  },
  {
    code: 'CUSTOM_TAB_UNSUPPORTED_CONNECT_SERVICE',
    text: 'Connect service does not support merge fields.',
  },
  {
    code: 'CUSTOM_TAB_UPDATE_FAILED',
    text: 'Custom tab update failed.',
  },
  {
    code: 'CUSTOM_TAB_UPDATE_FAILED',
    text: 'Custom tab update failed.',
  },
  {
    code: 'CUSTOMTAB_IS_INCOMPLETE',
    text: 'A Custom Tab is not Complete. A Custom Tab requires both a name and a TabLabel.',
  },
  {
    code: 'DECLINE_TO_SIGN_USER_IS_NOT_RECIPIENT',
    text: 'User does not match recipient.',
  },
  {
    code: 'DELETED_DRAFTS_CAN_ONLY_BE_MOVED_TO_DRAFTS',
    text: 'Deleted draft envelopes can only be moved back to the drafts folder.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_CPF_NUMBER_IS_INVALID',
    text: 'Digital signature CPF number is invalid.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_ALLOW_COMBINED\\n_PDF_DOWNLOAD',
    text: 'Cannot download documents with digital signatures that have been appended with the certificate of completion because this breaks the digital signatures.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_ALLOW_CORRECT',
    text: 'This envelope cannot be corrected because it was already digitally signed, and correcting the envelope would break the digital signature.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_ALLOW_MARKUP',
    text: 'Digital certificate does not allow ‘Document Markup’.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_ALLOW_MULTI_RECIPS\\n_AT_SAME_ROUTE',
    text: 'Digital certificate does not allow more than one signer in the same routing order.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_ALLOW_NOTARY',
    text: '‘Sign With Notary’ is not allowed for this envelope because a digital certificate is present. Either remove the digital certificate or disable the ‘Sign With Notary’ option.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_ALLOW_SHARED_TABS',
    text: 'Digital certificate does not allow ‘Shared tabs’.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_ALLOW_SIGN_ON_PAPER',
    text: '‘Sign on Paper’ is not allowed for this envelope because a digital certificate is present. Either remove the digital certificate or disable the ‘Sign on Paper’ option.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_ALLOW_WATER_MARK',
    text: 'The watermark feature is not allowed for this envelope because a digital certificate is present. Either remove the digital certificate or disable the watermark account settings.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_DOES_NOT_SUPPORT_THE_RECIPIENT_TYPE',
    text: 'Digital signature does not support the recipient type.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_HAS_DUPLICATE_TABS',
    text: 'Duplicate digital signatures tabs.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_HAS_INCOMPATIBLE_TABS',
    text: 'Digital certificate require a Digital Signature, and all signers in subsequent routing orders can only have Signature and Initial tabs.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_IS_NOT_ENABLED',
    text: 'Digital certificate is not enabled for account.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_PROVIDER_IS_UNKNOWN',
    text: 'Digital signature provider is unknown.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_REQUIRES_SIGNATURE_TAB',
    text: 'Digital certificate requires at least one required, non-conditional Signature or Initials tab.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_REQUIRES_SUBSEQUENT_RECIPIENT\\n_HAS_DIGITAL_CERTIFICATE',
    text: 'Digital certificate require a Digital Signature, and all signers in subsequent routing orders must have recipient certificate, and Signature or Initial tabs.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_SIGNATURE_TAB_NOT_PERMITTED,',
    text: 'RequireSignerCertificate permission is required to use Digital Signature Tabs.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_SIGNER_ROLE_IS_REQUIRED',
    text: 'Digital signature signer role is required.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_WITH_21CFR_DOES_NOT_ALLOW_INPERSON',
    text: '‘Sign in person’ is not allowed for this envelope because it is required to meet 21 CFR part 11 compliance.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_WITH_21CFR_DOES_NOT_ALLOW_MARKUP',
    text: 'Document markup is not allowed for this envelope because it is required to meet 21 CFR part 11 compliance.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_WITH_21CFR_DOES_NOT_ALLOW_NOTARY',
    text: '‘Sign with notary’ is not allowed for this envelope because it is required to meet 21 CFR part 11 compliance.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_WITH_21CFR_MUST_HAVE_SIGNATURE_TAB',
    text: 'Signers that are required to meet 21 CFR part 11 compliance must have at least one required, non-conditional Signature or Initials tab.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_WITH_OPEN_TRUST_HAS_NO\\n_RECIPIENT_AUTH',
    text: 'One or more recipients signing with an OpenTrust digital certificate is missing identity information. You must select either Access Code or SMS authentication for these recipients.',
  },
  {
    code: 'DIGITAL_CERTIFICATE_WITH_OPEN_TRUST_HAS_TOO_MANY_TABS',
    text: 'Maximum Signature and Initial tag(s) is exceeded on this OpenTrust Protect & Sign Digital Signature Signer.',
  },
  {
    code: 'DISCOUNT_FAILED',
    text: 'There is an error selecting the specified discount.',
  },
  {
    code: 'DOCUMENT_DOES_NOT_EXIST',
    text: 'The document specified was not found.',
  },
  {
    code: 'DOCUMENT_NAME_NOT_SPECIFIED',
    text: 'Document name not specified.',
  },
  {
    code: 'DOCUMENT_PURGE_FAILED',
    text: 'Error adding documents to the purge queue.',
  },
  {
    code: 'DOCUMENT_UPDATE_FAILED',
    text: 'The document could not be added/updated.',
  },
  {
    code: 'DOCUMENT_UPDATE_NOT_ALLOWED',
    text: 'Document update is not allowed.',
  },
  {
    code: 'DOCUMENT_UPLOAD_NOT_ALLOWED',
    text: 'Document upload not allowed.',
  },
  {
    code: 'DOCUMENT_UPLOAD_ONLY_PDF_ALLOWED',
    text: 'Only PDF document upload allowed.',
  },
  {
    code: 'DOCUMENT_UPLOAD_PDF_NOT_ALLOWED',
    text: 'PDF document upload not allowed.',
  },
  {
    code: 'DOCUMENTFIELDNAMETOLONG',
    text: 'Document field name to long.',
  },
  {
    code: 'DOCUMENTFIELDVALUETOLONG',
    text: 'Document field value to long.',
  },
  {
    code: 'DOMAIN_CHANGE_FAILED',
    text: 'The email domain change was not initiated.',
  },
  {
    code: 'DOMAIN_CHANGE_INTERRUPTED',
    text: 'The domain change was not successful.',
  },
  {
    code: 'DOMAIN_CHANGE_PARTIALLY_APPLIED',
    text: 'The domain change may have been partially successful.',
  },
  {
    code: 'DRAFT_ENVELOPES_CAN_ONLY_BE_MOVED_TO\\n_RECYCLEBIN',
    text: 'Draft envelopes can only be moved to the RecycleBin.',
  },
  {
    code: 'DUPLICATE_BCC_EMAIL_ADDRESS',
    text: 'Duplicate BCC email address.',
  },
  {
    code: 'DUPLICATE_BCC_EMAIL_ADDRESS_ID',
    text: 'Duplicate BCC email Address id specified.',
  },
  {
    code: 'EDIT_LOCK_ENVELOPE_ALREADY_LOCKED',
    text: 'The envelope is already locked.',
  },
  {
    code: 'EDIT_LOCK_ENVELOPE_LOCKED',
    text: 'The envelope is locked.',
  },
  {
    code: 'EDIT_LOCK_ENVELOPE_NOT_LOCKED',
    text: 'The envelope is not locked.',
  },
  {
    code: 'EDIT_LOCK_ERROR',
    text: 'Edit lock error.',
  },
  {
    code: 'EDIT_LOCK_FAILED_TO_ACQUIRE_LOCK',
    text: 'Failed to acquire the lock.',
  },
  {
    code: 'EDIT_LOCK_FAILED_TO_UPDATE_LOCK',
    text: 'Failed to update the lock.',
  },
  {
    code: 'EDIT_LOCK_INVALID_LOCK_DURATION',
    text: 'Invalid lock duration value.',
  },
  {
    code: 'EDIT_LOCK_INVALID_LOCK_TYPE',
    text: 'Invalid lock type.',
  },
  {
    code: 'EDIT_LOCK_INVALID_LOCKEDBYAPP',
    text: 'Invalid LockedByApp value.',
  },
  {
    code: 'EDIT_LOCK_INVALID_STATE_FOR_LOCK',
    text: 'Failed to acquire the lock because the envelope status was not ‘created’, ‘sent’ or ‘delivered’.',
  },
  {
    code: 'EDIT_LOCK_NOT_ENABLED',
    text: 'Locking for edit is not enabled.',
  },
  {
    code: 'EDIT_LOCK_NOT_LOCK_OWNER',
    text: 'The user is not the owner of the lock.',
  },
  {
    code: 'EDIT_LOCK_TEMPLATE_ALREADY_LOCKED',
    text: 'The template is already locked.',
  },
  {
    code: 'EDIT_LOCK_TEMPLATE_LOCKED',
    text: 'The template is locked.',
  },
  {
    code: 'EDIT_LOCK_TEMPLATE_NOT_LOCKED',
    text: 'The template is not locked.',
  },
  {
    code: 'EDIT_SCRATCHPAD_CREATE_ERROR',
    text: 'Error creating the scratchpad.',
  },
  {
    code: 'EDIT_SCRATCHPAD_NOT_ENABLED',
    text: 'Scratchpad not enabled.',
  },
  {
    code: 'EDIT_SCRATCHPAD_SAVE_ERROR',
    text: 'Error saving the scratchpad changes to the database.',
  },
  {
    code: 'EDITOR_MUST_HAVE_ACCOUNT',
    text: 'The recipient Editor must be an existing DocuSign account member.',
  },
  {
    code: 'EMAIL_IS_RESERVED',
    text: 'The specified email(s) are reserved.',
  },
  {
    code: 'EMAIL_NOT_ACTIVE',
    text: 'The email address does not specify an active user.',
  },
  {
    code: 'EMAIL_NOT_SHARED',
    text: 'The email address is not shared.',
  },
  {
    code: 'EMAIL_SETTINGS_ALREADY_EXISTS',
    text: 'This email settings object already exists.',
  },
  {
    code: 'EMAIL_SETTINGS_NOT_FOUND',
    text: 'No email settings were found.',
  },
  {
    code: 'ENVELOPE_AC_EXPORT_COMPLETED',
    text: 'Envelope already exported.',
  },
  {
    code: 'ENVELOPE_AC_EXPORT_INVALID_STATUS',
    text: 'Envelope is not in the correct export status.',
  },
  {
    code: 'ENVELOPE_AC_EXPORT_INVALID_TRANSID',
    text: 'Invalid transaction ID.',
  },
  {
    code: 'ENVELOPE_AC_EXPORT_NOT_AC_COPY',
    text: 'Envelope not authoritative copy.',
  },
  {
    code: 'ENVELOPE_ALLOWANCE_EXCEEDED',
    text: 'The envelope allowance for the account has been exceeded.',
  },
  {
    code: 'ENVELOPE_ALREADY_SENT',
    text: 'Envelope has already been sent.',
  },
  {
    code: 'ENVELOPE_CANNOT_CORRECT_INVALID_STATE',
    text: 'Only envelopes in the ‘Sent’ or ‘Delivered’ states may be corrected.',
  },
  {
    code: 'ENVELOPE_CANNOT_TRANSFER_INVALID_ACSTATUS',
    text: 'Envelopes with AC Status - Deposit Pending or Deposited or Deposited As Authoritative Copy or Deposit Failed cannot be transferred.',
  },
  {
    code: 'ENVELOPE_CANNOT_TRANSFER_INVALID_STATE',
    text: 'The specified envelope is not in a terminal state and cannot be transferred.',
  },
  {
    code: 'ENVELOPE_CANNOT_USE_TEMPLATEID_FOR_ENVELOPE\\n_OPERATION',
    text: 'Cannot use a template id for an envelope operation.',
  },
  {
    code: 'ENVELOPE_CANNOT_VOID_INVALID_STATE',
    text: 'Only envelopes in the ‘Sent’ or ‘Delivered’ states may be voided.',
  },
  {
    code: 'ENVELOPE_CUSTOM_FIELD_MISSING',
    text: 'A required envelope custom field is missing.',
  },
  {
    code: 'ENVELOPE_DECLINE_TO_SIGN_INVALID_STATUS',
    text: 'The envelope is no longer in a status to be signed.',
  },
  {
    code: 'ENVELOPE_DOES_NOT_EXIST',
    text: 'The envelope specified either does not exist or you have no rights to it.',
  },
  {
    code: 'ENVELOPE_HAS_BEEN_VOIDED',
    text: 'The envelope you are attempting to access has been voided by the sender. For more information, please contact the sender.',
  },
  {
    code: 'Note: This error code can also be generated due to envelope expiration.',
    text: '',
  },
  {
    code: 'ENVELOPE_HAS_DUPLICATE_RECIPIENTS',
    text: 'The specified envelope has duplicate recipients.',
  },
  {
    code: 'ENVELOPE_HAS_INSESSION_RECIPIENTS',
    text: 'The specified envelope has In-Session recipients.',
  },
  {
    code: 'ENVELOPE_HAS_NO_RECIPIENTS',
    text: 'The specified envelope has no recipients.',
  },
  {
    code: 'ENVELOPE_INVALID_STATUS',
    text: 'Invalid envelope status.',
  },
  {
    code: 'ENVELOPE_IS_INCOMPLETE',
    text: 'The envelope is not complete. A complete envelope requires documents, recipients, tabs, and a subject line.',
  },
  {
    code: 'ENVELOPE_NOT_DRAFT',
    text: 'The requested envelope is not a draft.',
  },
  {
    code: 'ENVELOPE_NOT_ENABLED_WETSIGN',
    text: 'Sign on paper has not been enabled for this envelope.',
  },
  {
    code: 'ENVELOPE_NOT_IN_FOLDER',
    text: 'The envelope does not exist in the folder',
  },
  {
    code: 'ENVELOPE_ONLYTHREECUSTOMFIELDS_ALLOWED',
    text: 'Only three custom fields allowed per envelope.',
  },
  {
    code: 'ENVELOPE_TRANSFEREE_ALREADY_OWNS_ENVELOPE',
    text: 'The specified transfer recipient of the envelope is already the owner of the envelope.',
  },
  {
    code: 'EXISTING_EORIGINAL_CONNECT',
    text: 'There is already any existing eOriginal Connect integration.',
  },
  {
    code: 'EXISTING_SALESFORCE_CONNECT',
    text: 'This is already any existing Salesforce Connect integration.',
  },
  {
    code: 'EXTERNAL_DOC_SERVICE_ERROR',
    text: 'An error occurred in the external doc service.',
  },
  {
    code: 'EXTERNAL_DOC_SERVICE_NOT_AUTHENTICATED',
    text: 'External doc service not authenticated.',
  },
  {
    code: 'FAILED_EMAIL_SENDING',
    text: 'Email sending failed.',
  },
  {
    code: 'FAILED_SCHEMA_VALIDATION',
    text: 'Schema validation failed. Additional errors follow.',
  },
  {
    code: 'FAILED_TO_CONNECT_TO_SERVICE',
    text: 'Unable to connect to the service.',
  },
  {
    code: 'FAILED_TO_CONNECT_TO_SERVICE',
    text: 'Unable to connect to the service.',
  },
  {
    code: 'FAILED_TO_CREATE_REQUEST',
    text: 'Failed to create the request for the operation.',
  },
  {
    code: 'FAILED_TO_CREATE_RESPONSE',
    text: 'Failed to create the response for the operation.',
  },
  {
    code: 'FAX_BACK_MUST_BE_SIGNER',
    text: 'Fax recipient Token must be of type Signer.',
  },
  {
    code: 'FAX_ERROR_DOMAIN_INVALID',
    text: 'The domain name specified was invalid. This field is required.',
  },
  {
    code: 'FAX_ERROR_INVALID_ENVELOPE_ID',
    text: 'The fax entry could not be saved.',
  },
  {
    code: 'FAX_ERROR_NO_MATCHING_FAX_RECORD',
    text: 'A corresponding fax status could not be found.',
  },
  {
    code: 'FAX_ERROR_OCCURRED',
    text: 'Fax error occurred.',
  },
  {
    code: 'FAX_ERROR_POST_INVALID',
    text: 'The POST request for submitting a fax was invalid or not present.',
  },
  {
    code: 'FAX_ERROR_SAVE_FAX_FAILED',
    text: 'The envelope fax id provided is invalid (zero or less).',
  },
  {
    code: 'FAX_ERROR_STATUS_REQUEST_INVALID',
    text: 'The fax error request was null or empty.',
  },
  {
    code: 'FAX_FAILED_TO_SEND',
    text: 'The fax was attempted, but failed to send.',
  },
  {
    code: 'FAX_FORMAT_INVALID',
    text: 'The fax could not be read as a PDF.',
  },
  {
    code: 'FAX_INTEGRATOR_NOT_AUTHORIZED',
    text: 'The fax provider is not authorized to send or receive faxes.',
  },
  {
    code: 'FAX_RECIPIENT_CORRECT_WAS_SENT',
    text: 'Fax Recipient cannot be corrected after sent.',
  },
  {
    code: 'FAX_RECIPIENT_ROUTING',
    text: 'Fax Recipient must be the only recipient at a routing order.',
  },
  {
    code: 'FAX_RECIPIENT_TYPE_NOT_SUPPORTED',
    text: 'Fax Recipient Type must be a signer.',
  },
  {
    code: 'FAX_START_PENDING_FAILED',
    text: 'Fax Pending Failed.',
  },
  {
    code: 'FAX_USAGE_NOT_RECORDED',
    text: 'Fax usage could not be recorded.',
  },
  {
    code: 'FILTER_END_GREATER_THAN_MAX_ALLOWED',
    text: 'Filter End Date is greater than maximum value allowed.',
  },
  {
    code: 'FILTER_END_LESS_THEN_MIN_ALLOWED',
    text: 'Filter End Date is less than minimum value allowed.',
  },
  {
    code: 'FILTER_START_EXCEEDS_END',
    text: 'Filter Start Date cannot be greater than Filter End Date.',
  },
  {
    code: 'FILTER_START_GREATER_THAN_MAX_ALLOWED',
    text: 'Filter Start Date is greater than maximum value allowed.',
  },
  {
    code: 'FILTER_START_LESS_THAN_MIN_ALLOWED',
    text: 'Filter Start Date is less than minimum value allowed.',
  },
  {
    code: 'FOLDER_ALREADY_SUBFOLDER',
    text: 'Folder is already a sub-folder of the to-folders',
  },
  {
    code: 'FOLDER_CANNOT_BE_MOVED',
    text: 'The folder cannot be moved',
  },
  {
    code: 'FOLDER_ID_DEFINED_DURING_ADD',
    text: 'The folder id must not be defined when adding a folder.',
  },
  {
    code: 'FOLDER_ID_NOT_FOUND',
    text: 'The folder id was not found.',
  },
  {
    code: 'FOLDER_NAME_NOT_DEFINED',
    text: 'The name was not specified for the folder.',
  },
  {
    code: 'FOLDER_NOT_FOUND',
    text: 'The specified folder id was not found for the user.',
  },
  {
    code: 'FOLDER_UPDATE_FAILED',
    text: 'The folder could not be created/updated.',
  },
  {
    code: 'FORMAT_CONVERSION_ERROR',
    text: 'The data could not be converted.',
  },
  {
    code: 'FORMAT_CONVERSION_ERROR_INVALID_FILE_TYPE',
    text: 'Invalid file type chosen for conversion.',
  },
  {
    code: 'FORMULA_CIRCULAR_REFERENCE',
    text: 'Recipient Calculated Fields have a circular reference.',
  },
  {
    code: 'GET_PAGE_IMAGE_FAILED',
    text: 'Get page image failed.',
  },
  {
    code: 'GET_PAGE_IMAGE_FAILED',
    text: 'Get page image failed.',
  },
  {
    code: 'GET_PAGE_IMAGES_FAILED',
    text: 'Get page images failed.',
  },
  {
    code: 'GROUP_INFORMATION_NOT_PROVIDED',
    text: 'Group information not provided',
  },
  {
    code: 'HAS_TO_BE_SHARED_TEMPLATE_FOLDER',
    text: 'The folder has to be a shared template folder',
  },
  {
    code: 'HOURLY_APIINVOCATION_LIMIT_EXCEEDED',
    text: 'The maximum number of hourly API invocations has been exceeded.',
  },
  {
    code: 'IN_PERSON_SIGNER_NAME_CANNOT_BE_BLANK',
    text: 'For In Person Signer type, the Recipient Signer Name cannot be blank.',
  },
  {
    code: 'IN_PERSON_SIGNING_HOST_MUST_BE_VALID_USER',
    text: 'The In Person Signing Host must be a valid and active DocuSign user.',
  },
  {
    code: 'INVALID_ACCESSIBILITY_FORMAT',
    text: 'Accessibility format is invalid.',
  },
  {
    code: 'INVALID_ACCOUNT_ID_USER_ID',
    text: 'Either the account ID or the user ID is invalid.',
  },
  {
    code: 'INVALID_ACCOUNT_ROLE_SETTINGS',
    text: 'The settings for the account role are invalid.',
  },
  {
    code: 'INVALID_ADDITIONAL_MEMBERSHIP_CAPACITY_VALUE',
    text: 'Invalid additional membership capacity value.',
  },
  {
    code: 'INVALID_ADDRESS_COUNTRY',
    text: 'The value for ‘country’ is not supported.',
  },
  {
    code: 'INVALID_ADDRESS_STATE',
    text: 'The value for ‘state’ is not supported.',
  },
  {
    code: 'INVALID_ANCHOR_TAB_STRING',
    text: 'The Anchor Tab string format is invalid.',
  },
  {
    code: 'INVALID_API_VERSION',
    text: 'The API version specified is not valid.',
  },
  {
    code: 'INVALID_APPTOKEN',
    text: 'AppToken is invalid or distributor not found for AppToken',
  },
  {
    code: 'INVALID_BILLING_PLAN_RETIRED',
    text: 'The billing plan is retired and cannot be used.',
  },
  {
    code: 'INVALID_BILLING_PLAN_SUCCESSOR',
    text: 'Invalid successor billing plan.',
  },
  {
    code: 'INVALID_BILLING_PROFILE',
    text: 'The billing profile is invalid for this operation.',
  },
  {
    code: 'INVALID_BRAND',
    text: 'Invalid Brand for this account.',
  },
  {
    code: 'INVALID_BRAND_ID',
    text: 'The brand identifier is unknown or invalid.',
  },
  {
    code: 'INVALID_BRAND_LOGO',
    text: 'The branding logo is unsupported or invalid.',
  },
  {
    code: 'INVALID_BRAND_LOGOTYPE',
    text: 'The type of logo is unrecognized.',
  },
  {
    code: 'INVALID_CAPTIVE_RECIPIENT_OPERATION',
    text: 'Invalid operation on captive recipient.',
  },
  {
    code: 'INVALID_CHARACTERS_PROVIDED',
    text: 'Invalid characters detected',
  },
  {
    code: 'INVALID_CLIENTUSERID',
    text: 'Invalid value specified for clientUserId.',
  },
  {
    code: 'INVALID_CONNECT_CONFIGURATION_TYPE',
    text: 'Invalid Connect Configuration Type.',
  },
  {
    code: 'INVALID_CONNECT_ID',
    text: 'Invalid connect id.',
  },
  {
    code: 'INVALID_CONNECT_ID',
    text: 'Invalid Connect ID.',
  },
  {
    code: 'INVALID_CONNECT_LOGID',
    text: 'Invalid connect log id.',
  },
  {
    code: 'INVALID_CONTENT_TRANSFER_ENCODING',
    text: 'Invalid ‘content transfer encoding’ for envelope.',
  },
  {
    code: 'INVALID_CONTENT_TYPE',
    text: 'Content Type specified is not supported.',
  },
  {
    code: 'INVALID_COUNTRY',
    text: 'The Country is not valid in the system.',
  },
  {
    code: 'INVALID_CREDIT_CARD_EXPIRATION',
    text: 'Credit Card Expiration is not valid.',
  },
  {
    code: 'INVALID_CREDIT_CARD_EXPIRATION_YEAR',
    text: 'Credit Card Expiration Year is not valid.',
  },
  {
    code: 'INVALID_CREDIT_CARD_INFORMATION',
    text: 'The credit card information supplied was not valid.',
  },
  {
    code: 'INVALID_CREDIT_CARD_NUMBER',
    text: 'Credit Card Number is not valid.',
  },
  {
    code: 'INVALID_CREDIT_CARD_TYPE',
    text: 'The Credit Card Type is not valid in the system.',
  },
  {
    code: 'INVALID_CSS_COLOR',
    text: 'The CSS color is not recognized as a valid and supported W3C-compliant format.',
  },
  {
    code: 'INVALID_CURRENCY',
    text: 'The currency is not valid in the system.',
  },
  {
    code: 'INVALID_CURRENCY_REQUEST',
    text: 'The currency type cannot be changed.',
  },
  {
    code: 'INVALID_CUSTOM_FIELD_ID',
    text: 'Invalid Custom Field Id.',
  },
  {
    code: 'INVALID_CUSTOM_SETTING_NAME',
    text: 'Invalid Custom Setting Name.',
  },
  {
    code: 'INVALID_CUSTOM_TAB_PROPERTY',
    text: 'Custom Tab Property is invalid.',
  },
  {
    code: 'INVALID_CUSTOM_TAB_TYPE',
    text: 'Custom Tab Type is invalid.',
  },
  {
    code: 'INVALID_CUSTOM_TAB_USER_ID',
    text: 'Custom Tab User Id is invalid.',
  },
  {
    code: 'INVALID_DISTRIBUTOR',
    text: 'The Distributor specified is not valid.',
  },
  {
    code: 'INVALID_DOCUMENT_ID',
    text: 'The DocumentId provided is invalid.',
  },
  {
    code: 'INVALID_DOCUMENT_ORDER',
    text: 'Invalid Document Order.',
  },
  {
    code: 'INVALID_DOCUMENT_SEQUENCE',
    text: 'Document sequence is invalid.',
  },
  {
    code: 'INVALID_EMAIL_ADDRESS',
    text: 'Invalid email address.',
  },
  {
    code: 'INVALID_EMAIL_ADDRESS_FOR_RECIPIENT',
    text: 'The email address for the recipient is invalid. The recipient Id follows.',
  },
  {
    code: 'INVALID_EMAIL_ADDRESS_FOR_SENDER',
    text: 'The email address for the sender is invalid.',
  },
  {
    code: 'INVALID_EMAILCHANGEFORFEDERATEDUSER',
    text: 'Email address for a federated user cannot be changed.',
  },
  {
    code: 'INVALID_ENVELOPE_EMAILBLURB',
    text: 'Invalid Envelope Email Blurb.',
  },
  {
    code: 'INVALID_ENVELOPE_SUBJECT',
    text: 'Invalid Envelope Subject.',
  },
  {
    code: 'INVALID_EXPIRATION_REMINDER_DATA',
    text: 'Invalid envelope expiration and/or reminder information provided.',
  },
  {
    code: 'INVALID_FAX_RECIPIENT_SIGNERCERTREQUIREMENT',
    text: 'Invalid request for Fax token with a PKI signer certificate requirement.',
  },
  {
    code: 'INVALID_FAXNUMBER',
    text: 'Fax Number is invalid.',
  },
  {
    code: 'INVALID_FOLDER_FILTER',
    text: 'Invalid value specified for the folder filter.',
  },
  {
    code: 'INVALID_FOLDER_ID',
    text: 'A folder ID is missing or invalid.',
  },
  {
    code: 'INVALID_FOLDER_TYPE',
    text: 'The folder type specified is not valid.',
  },
  {
    code: 'INVALID_GROUP_ID',
    text: 'The MemberGroupId provided is invalid.',
  },
  {
    code: 'INVALID_GROUP_NAME',
    text: 'The group name is invalid.',
  },
  {
    code: 'INVALID_INCLUDED_SEATS_VALUE',
    text: 'Included seats must be greater than zero and not less than the minimum included seats.',
  },
  {
    code: 'INVALID_INCREMENTAL_SEATS_VALUE',
    text: 'Incremental seats must be greater than or equal to zero.',
  },
  {
    code: 'INVALID_INVOICE_ID',
    text: 'Invoice Id is not valid',
  },
  {
    code: 'INVALID_LIST_VALUE',
    text: 'Value specified is not part of the list item values.',
  },
  {
    code: 'INVALID_LOGIN_STATUS',
    text: 'Invalid login status.',
  },
  {
    code: 'INVALID_MEMBER_GROUP_ID',
    text: 'Invalid Member Group ID',
  },
  {
    code: 'INVALID_MERGEUSERID',
    text: 'Invalid MergeUserId.',
  },
  {
    code: 'INVALID_MERGEUSERID',
    text: 'Invalid MergeUserId.',
  },
  {
    code: 'INVALID_MULTI_PART_REQUEST',
    text: 'An error was found while parsing the multipart request.',
  },
  {
    code: 'INVALID_OAUTH2_SAML_ASSERTION',
    text: 'Invalid Grant',
  },
  {
    code: 'INVALID_ORGANIZATIONID',
    text: 'Invalid Organization ID.',
  },
  {
    code: 'INVALID_PAGE_TRANSACTIONID',
    text: 'The Page TransactionId is invalid.',
  },
  {
    code: 'INVALID_PAGE_TRANSACTIONID',
    text: 'The Page TransactionId is invalid.',
  },
  {
    code: 'INVALID_PARENT_FOLDER_ID',
    text: 'Invalid parent folder id.',
  },
  {
    code: 'INVALID_PASSWORD',
    text: 'The password is invalid.',
  },
  {
    code: 'INVALID_PASSWORD_CHALLENGE',
    text: 'Invalid forgotten password challenge.',
  },
  {
    code: 'INVALID_PAYMENT_ID',
    text: 'The Payment Id is not valid',
  },
  {
    code: 'INVALID_PERMISSION_PROFILE_ID',
    text: 'The SettingsTemplateId provided is invalid.',
  },
  {
    code: 'INVALID_PERMISSION_PROFILE_NAME',
    text: 'The permission profile name is invalid or already in use.',
  },
  {
    code: 'INVALID_PLAN_CURRENCY',
    text: 'The plan is not offered in the requested currency.',
  },
  {
    code: 'INVALID_PLAN_FEATURESET',
    text: 'The feature set is not configured for the requested plan.',
  },
  {
    code: 'INVALID_PLANID',
    text: 'The PlanId specified is not valid.',
  },
  {
    code: 'INVALID_PUT_RECIPIENT_SIGNATURE_ATTRIBUTE',
    text: 'Invalid attribute specified for updating recipient signature.',
  },
  {
    code: 'INVALID_RECIPIENT_EMAILBLURB',
    text: 'Invalid Recipient Email Blurb.',
  },
  {
    code: 'INVALID_RECIPIENT_ID',
    text: 'A recipient ID is missing or invalid.',
  },
  {
    code: 'INVALID_RECIPIENT_SETTING_VALUE',
    text: 'Recipient setting value is not an allowed value.',
  },
  {
    code: 'INVALID_RECIPIENT_STATUS_FOR_CORRECT',
    text: 'Invalid recipient status for correct.',
  },
  {
    code: 'INVALID_RECIPIENT_STATUS_FOR_DELETE',
    text: 'Invalid recipient status for delete.',
  },
  {
    code: 'INVALID_RECIPIENT_SUBJECT',
    text: 'Invalid Recipient Subject.',
  },
  {
    code: 'INVALID_RELATED_FIELD',
    text: 'Invalid related field sequence.',
  },
  {
    code: 'INVALID_RELATED_FIELD',
    text: 'Invalid related field sequence.',
  },
  {
    code: 'INVALID_REQUEST_BODY',
    text: 'The request body is missing or improperly formatted.',
  },
  {
    code: 'INVALID_REQUEST_PARAMETER',
    text: 'The request contained at least one invalid parameter.',
  },
  {
    code: 'INVALID_REQUEST_RESPONSE_OVERRIDE_FORMAT',
    text: 'The URL override format is not supported. Use supported formats such as .json or .xml.',
  },
  {
    code: 'INVALID_SALESFORCE_CREDENTIALS',
    text: 'Invalid Salesforce credentials.',
  },
  {
    code: 'INVALID_SESSION_TIMEOUT_VALUE',
    text: 'The Session Timeout value is not valid.',
  },
  {
    code: 'INVALID_SIGNATURE_STATUS',
    text: 'Invalid status specified for signature.',
  },
  {
    code: 'INVALID_SOCIAL_ACCOUNT',
    text: 'Social account information provided is invalid.',
  },
  {
    code: 'INVALID_SOCIAL_INFORMATION',
    text: 'The Social Provider information specified is not valid.',
  },
  {
    code: 'INVALID_SOCIAL_PROVIDER',
    text: 'Social Provider is not valid.',
  },
  {
    code: 'INVALID_SOCIAL_USER_NAME',
    text: 'Social Provider User Name is not valid.',
  },
  {
    code: 'INVALID_STATE',
    text: 'The State/Province is not valid in the system.',
  },
  {
    code: 'INVALID_TAB_FORMULA',
    text: 'The Tab formula is not valid.',
  },
  {
    code: 'INVALID_TAB_OPERATION',
    text: 'The Tab specified is not valid for the requested operation.',
  },
  {
    code: 'INVALID_TABID',
    text: 'Invalid TabId specified.',
  },
  {
    code: 'INVALID_TOKEN',
    text: 'Invalid_Token.',
  },
  {
    code: 'INVALID_TOKEN_FORMAT',
    text: 'The security token format does not conform to expected schema.',
  },
  {
    code: 'INVALID_URI',
    text: 'The URI is not valid.',
  },
  {
    code: 'INVALID_USER_OFFSET',
    text: 'The specified User Offset exceeds the page boundaries.',
  },
  {
    code: 'INVALID_USERCUSTOMTABID',
    text: 'UserCustomTabId is invalid.',
  },
  {
    code: 'INVALID_USERID',
    text: 'Invalid UserId.',
  },
  {
    code: 'INVALID_USERNAME',
    text: 'The user name is invalid.',
  },
  {
    code: 'INVALID_USERNAME_FOR_RECIPIENT',
    text: 'The user name for the recipient is invalid.',
  },
  {
    code: 'INVALID_WATERMARK',
    text: 'The watermark is invalid.',
  },
  {
    code: 'INVALID_WATERMARK_PREVIEW',
    text: 'There is an error generating Watermark preview.',
  },
  {
    code: 'INVALID_WORKSPACE_NAME',
    text: 'Workspace name is invalid.',
  },
  {
    code: 'INVALID_WORKSPACE_NAME',
    text: 'Workspace name is invalid.',
  },
  {
    code: 'INVALID_XML_SCHEMA',
    text: 'Invalid Xml schema.',
  },
  {
    code: 'INVALIDAUTHENTICATIONSETUP',
    text: 'Authentication is not setup correctly for the recipient.',
  },
  {
    code: 'INVOICE_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The InvoiceID did not identify an Invoice in the system.',
  },
  {
    code: 'INVOICE_PDF_NOT_AVAILABLE',
    text: 'The Invoice PDF is not available.',
  },
  {
    code: 'JANRAIN_REQUEST_ERROR',
    text: 'Janrain request Error.',
  },
  {
    code: 'LENGTH_EXCEEDED_ADDRESSLINE1',
    text: 'Address Line 1 length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_ADDRESSLINE2',
    text: 'Address Line 2 length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_CITY',
    text: 'City length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_COUNTRY',
    text: 'Country length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_CREDIT_CARD_NAME',
    text: 'Credit Card Cardholder Name length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_EMAIL',
    text: 'Email length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_FAX',
    text: 'Fax length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_FIRSTNAME',
    text: 'First Name length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_LASTNAME',
    text: 'Last Name length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_PHONE',
    text: 'Phone length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_POSTALCODE',
    text: 'Postal Code length exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_RECIPIENT_CUSTOMFIELD1',
    text: 'CustomField1 maximum length Exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_RECIPIENT_CUSTOMFIELD2',
    text: 'CustomField2 Maximum length Exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_RECIPIENT_CUSTOMFIELD3',
    text: 'CustomField3 maximum length Exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_RECIPIENT_EXTERNALUSERNAME',
    text: 'ExternalUserName maximum length Exceeded.',
  },
  {
    code: 'LENGTH_EXCEEDED_STATE',
    text: 'State/Province length exceeded.',
  },
  {
    code: 'MAX_ACCOUNTS_EXCEEDED',
    text: 'The maximum number of accounts was exceeded.',
  },
  {
    code: 'MAX_BCC_ADDRESSES_EXCEEDED',
    text: 'The maximum number of BCC email addresses has been exceeded.',
  },
  {
    code: 'MAX_CONNECT_CUSTOM_CONFIGURATION_EXCEEDED',
    text: 'Maximum number of connect custom configuration exceeded.',
  },
  {
    code: 'MAX_MEMBERS_EXCEEDED',
    text: 'The maximum number of members for the account has been exceeded.',
  },
  {
    code: 'MAX_SALE_DISCOUNT_PERIODS_EXCEEDED',
    text: 'The maximum sale discount period has been exceeded.',
  },
  {
    code: 'MAX_TABS_EXCEEDED',
    text: 'The maximum number of tabs was exceeded.',
  },
  {
    code: 'MESSAGE_LOCKED',
    text: 'Message is locked.',
  },
  {
    code: 'METHOD_NOT_ALLOWED',
    text: 'The HTTP method specified is not allowed with this resource.',
  },
  {
    code: 'METHOD_NOT_IMPLEMENTED',
    text: 'The method specified is not implemented.',
  },
  {
    code: 'MOBILE_NOTIFIER_CONFIG_FAILED',
    text: 'Mobile notifier configuration failed.',
  },
  {
    code: 'MULTIPLESATABSONDOCUMENT',
    text: 'More than one signer attachment tab is placed on a signer attachment document for a recipient.',
  },
  {
    code: 'MUST_DELETE_SUBFOLDERS',
    text: 'You must delete all sub-folders first before you can delete this folder.',
  },
  {
    code: 'MUST_ENABLE_SIGNONPAPER',
    text: 'EnableWetSign must be set on the envelope.',
  },
  {
    code: 'MUST_MOVE_ENVELOPES_BEFORE_DELETE',
    text: 'You must move all of the envelopes before you can delete this folder.',
  },
  {
    code: 'NAR_NOT_ENABLED_FOR_USER',
    text: 'NAR not enabled for user.',
  },
  {
    code: 'NAR_UNABLE_TO_VALIDATE_CREDENTIALS',
    text: 'NAR not able to validate NRDs ID and NRDs Last Name.',
  },
  {
    code: 'NAR_USER_NOT_ACTIVE',
    text: 'NAR user not active.',
  },
  {
    code: 'NO_DOCUMENT_RECEIVED',
    text: 'The document element did not contain the encoded document, or there is a problem with the encoding.',
  },
  {
    code: 'NO_SECURITY_APPLIANCES_CONFIGURED',
    text: 'No Security Appliances configured on account.',
  },
  {
    code: 'NOT_ELIGIBLE_FOR_RENEWAL_CANCELLATION',
    text: 'Not eligible for renewal cancellation.',
  },
  {
    code: 'NOT_SUPPORTED',
    text: 'Functionality not supported.',
  },
  {
    code: 'OAUTH_ERROR',
    text: 'An OAuth2 error occurred.',
  },
  {
    code: 'OFFLINE_SIGNING_CLIENTUSERID_REQUIRED',
    text: 'ClientUserId is required for offline signer.',
  },
  {
    code: 'OFFLINE_SIGNING_DATETIME_INVALID',
    text: 'Invalid value specified for offline DateTime.',
  },
  {
    code: 'OFFLINE_SIGNING_DELIVERY_METHOD_MUST_BE_SIGNER',
    text: 'DeliveryMethod of offline is only allowed for signer recipients.',
  },
  {
    code: 'OFFLINE_SIGNING_DOCUMENTS_REQUIRED',
    text: 'Documents are required for offline signing.',
  },
  {
    code: 'OFFLINE_SIGNING_DUPLICATE_RECIPIENTS',
    text: 'Must have distinct email and username for all offline recipients.',
  },
  {
    code: 'OFFLINE_SIGNING_INVALID_DRAFT_ENVELOPE',
    text: 'Draft envelopes are not allowed for offline signing.',
  },
  {
    code: 'OFFLINE_SIGNING_INVALID_SIGNER_ROUTING_ORDER',
    text: 'Invalid offline signer routing order.',
  },
  {
    code: 'OFFLINE_SIGNING_INVALID_TAB_TYPE',
    text: 'Invalid tab type specified for offline signing.',
  },
  {
    code: 'OFFLINE_SIGNING_NOT_ALLOWED',
    text: 'Offline signing not allowed.',
  },
  {
    code: 'OFFLINE_SIGNING_SIGNEDDATETIME_REQUIRED',
    text: '‘signedDateTime’ must be specified for offline recipients.',
  },
  {
    code: 'OFFLINE_SIGNING_SIGNER_TABS_REQUIRED',
    text: 'Offline signer must have at least one signature or initials tab.',
  },
  {
    code: 'OFFLINE_SIGNING_TAB_IMAGE_REQUIRED',
    text: 'Signature image not provided for offline signature or initials tab.',
  },
  {
    code: 'ONESIGNALLSIGN_NOT_SATISFIED',
    text: 'Freeform signing is not allowed for your account because it conflicts with other settings, please place signing tabs for each signer.',
  },
  {
    code: 'PAGE_IMAGE_NOT_FOUND',
    text: 'The page image specified was not found.',
  },
  {
    code: 'PAGE_IMAGE_NOT_RETRIEVED',
    text: 'The page image is not available.',
  },
  {
    code: 'PARTNER_APP_CREATION_NOT_ALLOWED',
    text: 'Partner application creation not allowed in this environment.',
  },
  {
    code: 'PARTNER_APP_NAME_REQUIRED',
    text: 'Partner application Name is required.',
  },
  {
    code: 'PARTNER_APP_REDIRECT_URI_INVALID',
    text: 'Partner application Redirect URI is invalid.',
  },
  {
    code: 'PARTNER_AUTHENTICATION_FAILED',
    text: 'The specified Integration Key was not found or is disabled.',
  },
  {
    code: 'PARTNER_INVALID_CLIENT_ID',
    text: 'Invalid Client Id provided.',
  },
  {
    code: 'PARTNER_NO_AUTHORITY_FOR_SENDER',
    text: 'The sender specified is not part of a group that the Partner is authorized to send for.',
  },
  {
    code: 'PAYMENT_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The Payment does not exist in the system.',
  },
  {
    code: 'PAYMENT_NOT_VALID',
    text: 'The Payment is not valid.',
  },
  {
    code: 'PAYMENT_PROCESSOR_EXISTS',
    text: 'A payment processor already exists for the specified account.',
  },
  {
    code: 'PAYMENT_PROCESSOR_FEATURE_NOT_ALLOWED',
    text: 'The payment processing feature is not allowed.',
  },
  {
    code: 'PAYMENT_PROCESSOR_INVALID_CREDENTIALS',
    text: 'Invalid payment processor credentials.',
  },
  {
    code: 'PAYMENT_PROCESSOR_INVALID_CURRENCY',
    text: 'The currency is invalid for this payment processor.',
  },
  {
    code: 'PAYMENT_PROCESSOR_NOT_ADDED',
    text: 'Unable to add payment processor.',
  },
  {
    code: 'PAYMENT_PROCESSOR_NOT_AVAILABLE',
    text: 'The payment processor is not available.',
  },
  {
    code: 'PAYMENT_PROCESSOR_NOT_UPDATED',
    text: 'Unable to update payment processor.',
  },
  {
    code: 'PAYMENT_PROCESSOR_TYPE_CANNOT_BE_MODIFIED',
    text: 'The type cannot be changed for an existing payment processor.',
  },
  {
    code: 'PAYMENT_PROCESSOR_TYPE_EXISTS',
    text: 'A payment processor already exists for the specified type.',
  },
  {
    code: 'PAYMENT_PROCESSOR_TYPE_NOT_SUPPORTED',
    text: 'The requested payment processor type is not supported.',
  },
  {
    code: 'PDF_INACCESSIBLE',
    text: 'PDF URL Inaccessible',
  },
  {
    code: 'PDF_NOT_FOUND',
    text: 'PDF was not found.',
  },
  {
    code: 'PDF_NOT_FOUND',
    text: 'PDF was not found.',
  },
  {
    code: 'PDF_VALIDATION_FAILED',
    text: 'The validation of the PDF file failed.',
  },
  {
    code: 'PERMISSION_PROFILE_ID_DOES_NOT_MATCH\\n_PERMISSION_PROFILE_NAME',
    text: 'Either permission profile Id or permission profile name is invalid is invalid.',
  },
  {
    code: 'PLAN_ITEM_NOT_ENABLED',
    text: 'A requested plan item is not enabled for this account.',
  },
  {
    code: 'PLAN_START_DATE_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The current account plan start date does not exist in the system.',
  },
  {
    code: 'POWERFORMS_DIGITALCERTS_FREE_FORM_TABS\\n_NOT_ALLOWED',
    text: 'Signers that are required to use a digital certificate must have at least one required, non-conditional Signature or Initials tab.',
  },
  {
    code: 'POWERFORMS_DIGITALCERTS_MARKUP_NOT_ALLOWED',
    text: 'Document markup is not allowed because a digital certificate is required for a signer.',
  },
  {
    code: 'POWERFORMS_DIGITALCERTS_MULTIPLE_RECIPIENTS\\n_ROUTING_ORDER',
    text: 'Signers that are required to use a digital certificate must be the only recipient in a routing order. Please edit the routing order or remove the digital certificate requirement.',
  },
  {
    code: 'POWERFORMS_DIGITALCERTS_SHARED_TABS\\n_NOT_ALLOWED',
    text: 'Shared tags are not allowed because a digital certificate is required for a signer.',
  },
  {
    code: 'POWERFORMS_DIRECT_NOT_ENABLED',
    text: 'Direct signing mode is not enabled for PowerForms.',
  },
  {
    code: 'POWERFORMS_INCOMPLETE_RECIPIENT',
    text: 'Recipient UserName, Email or Role not set.',
  },
  {
    code: 'POWERFORMS_INVALID_ENVELOPEID_FOR_RECIPIENT',
    text: 'Invalid envelope for recipient.',
  },
  {
    code: 'POWERFORMS_NOT_ADMIN',
    text: 'User lacks permission, not a PowerForms admin.',
  },
  {
    code: 'POWERFORMS_NOT_ENABLED',
    text: 'PowerForms is not enabled on the account.',
  },
  {
    code: 'POWERFORMS_NOT_USER',
    text: 'User lacks permission, not a PowerForms user.',
  },
  {
    code: 'POWERFORMS_POWERFORMID_MISMATCH',
    text: 'PowerFormId mismatch.',
  },
  {
    code: 'POWERFORMS_POWERFORMID_NOT_FOUND',
    text: 'The PowerForm specified was not found',
  },
  {
    code: 'POWERFORMS_POWERFORMID_REQUIRED',
    text: 'PowerFormId is required.',
  },
  {
    code: 'POWERFORMS_RECIPIENT_DENIED_DOCUMENTS',
    text: 'Recipient denied access to documents.',
  },
  {
    code: 'POWERFORMS_SIGNINGMODE_REQUIRED',
    text: 'Signing mode is required.',
  },
  {
    code: 'POWERFORMS_TEMPLATE_FIRST_RECIPIENT',
    text: 'Invalid template, the first recipient in the routing order must be a role recipient.',
  },
  {
    code: 'POWERFORMS_TEMPLATEID_REQUIRED',
    text: 'TemplateId is required.',
  },
  {
    code: 'POWERFORMS_USESREMAINING_REQUIRED',
    text: 'Uses remaining is required when max use is enabled.',
  },
  {
    code: 'PURCHASED_ENVELOPES_NOT_ADDED',
    text: 'Purchased envelopes not added.',
  },
  {
    code: 'RECIPIENT_ALREADY_EXISTS_IN_ENVELOPE',
    text: 'This recipientId already exists.',
  },
  {
    code: 'RECIPIENT_AUTHENTICATION_REQUIRED',
    text: 'Recipient has not passed authentication steps.',
  },
  {
    code: 'RECIPIENT_CORRECT_SUCCESS_DECLINE_TO\\n_SIGN_FAILED',
    text: 'Recipient correct succeed, but Decline to Sign failed.',
  },
  {
    code: 'RECIPIENT_DELETE_FAILED',
    text: 'The recipient could not be deleted.',
  },
  {
    code: 'RECIPIENT_HAS_FAILED_SECURITY_CHECK',
    text: 'The requested user has failed the security check for the requested envelope. Correct the envelope to continue.',
  },
  {
    code: 'RECIPIENT_HAS_INVALID_ROUTINGORDER',
    text: 'The specified recipient has invalid Routing Order.',
  },
  {
    code: 'RECIPIENT_MUST_BE_VALID_USER',
    text: 'Account settings indicate the recipient must be an active DocuSign user.',
  },
  {
    code: 'RECIPIENT_NO_ACCESS',
    text: 'Recipient does not have permission to access document.',
  },
  {
    code: 'RECIPIENT_NOT_ACCOUNTLESS',
    text: 'Recipient must not have an active account.',
  },
  {
    code: 'RECIPIENT_NOT_CAPTIVE',
    text: 'The specified recipient is not a captive recipient. This operation requires a captive recipient.',
  },
  {
    code: 'RECIPIENT_NOT_FOUND_FOR_CORRECTION',
    text: 'The specified User is not a recipient of the specified envelope.',
  },
  {
    code: 'RECIPIENT_NOT_IN_SEQUENCE',
    text: 'The token for an out of sequence recipient cannot be generated.',
  },
  {
    code: 'RECIPIENT_NOT_SIGNER',
    text: 'Recipient is not a signer type.',
  },
  {
    code: 'RECIPIENT_SMS_AUTH_MISSING',
    text: 'Recipient SMS authentication specified but information missing.',
  },
  {
    code: 'RECIPIENT_SMS_AUTH_PHONE_MISSING',
    text: 'SMS authentication phone number cannot be empty.',
  },
  {
    code: 'RECIPIENT_UPDATE_FAILED',
    text: 'The recipient could not be updated.',
  },
  {
    code: 'RECIPIENTS_LOCKED',
    text: 'Recipients are locked and cannot be modified.',
  },
  {
    code: 'RECIPIENTS_NOT_PROVIDED',
    text: 'No recipients were found in the request.',
  },
  {
    code: 'REDUNDANT_API_DOCUMENT_ACCESS',
    text: 'Redundant API document access violation',
  },
  {
    code: 'REGULAREXPRESSION_IS_INVALID',
    text: 'The regular expression provided is not valid.',
  },
  {
    code: 'REPORT_DATA_INVALID',
    text: 'Report data is invalid.',
  },
  {
    code: 'REPORT_DATE_INVALID',
    text: 'No date value was provided or the date provided was null.',
  },
  {
    code: 'REPORT_DOES_NOT_EXIST',
    text: 'The Enterprise Report being requested does not exist.',
  },
  {
    code: 'REPORT_DUPLICATE_NAME_EXISTS',
    text: 'You have already saved a report by this name.',
  },
  {
    code: 'REPORT_EXECUTION_FAILED',
    text: 'The Enterprise Report failed to execute. See additional information for more details.',
  },
  {
    code: 'REPORT_MAX_SAVED_REPORTS_EXCEEDED',
    text: 'You have reached the maximum number of saved reports. Please deactivate one and try again.',
  },
  {
    code: 'REPORT_MAX_SCHEDULED_EXCEEDED',
    text: 'You have reached the maximum number of scheduled reports. Please deactivate one and try again.',
  },
  {
    code: 'REPORT_MISSING_REQUIRED_PARAMETER',
    text: 'Report is missing a required parameter.',
  },
  {
    code: 'REPORT_MULTIPLE_MATCHES_FOUND',
    text: 'The Enterprise Report could not be executed because more than one report match the query/filter provided.',
  },
  {
    code: 'REPORT_QUERY_INVALID',
    text: 'No Enterprise Report data could be returned because one or more query/filter parameters was invalid.',
  },
  {
    code: 'REPORT_QUERY_PARAMETER_TYPES_INCONSISTENT',
    text: 'Query parameter types are not consistent.',
  },
  {
    code: 'REPORT_QUERY_PARAMETERS_EXCEED_EXPECTED',
    text: 'Too many query parameters specified.',
  },
  {
    code: 'REPORT_REQUIRED_PARAMETER_NOT_SET',
    text: 'A parameter is not set for the report.',
  },
  {
    code: 'REPORT_SCHEDULE_ALREADY_EXISTS',
    text: 'Report schedule already exists.',
  },
  {
    code: 'REPORT_SCHEDULE_CREATION_FAILED',
    text: 'Report schedule could not be created.',
  },
  {
    code: 'REPORT_SCHEDULE_INVALID_OPTION',
    text: 'Invalid option for report schedule.',
  },
  {
    code: 'REPORT_SCHEDULE_SCHEDULE_FAILED',
    text: 'Report schedule failed.',
  },
  {
    code: 'REPORT_SUBSCRIPTION_FAILED',
    text: 'Report subscription failed.',
  },
  {
    code: 'REPORT_TIMEZONE_INVALID',
    text: 'The timezone offset specified is invalid.',
  },
  {
    code: 'REPORT_XML_INVALID',
    text: 'Report Xml is invalid.',
  },
  {
    code: 'REQUEST_ENTITY_TOO_LARGE',
    text: 'The request is too large. Typically generated if you exceed the HTTP request limit.',
  },
  {
    code: 'REQUIRE_ALL_SHARED_TAB_NOT_ENABLED',
    text: 'Allow Shared Tabs is not enabled on account.',
  },
  {
    code: 'REQUIRE_ALL_SHARED_TAB_NOT_SET',
    text: 'Shared must be set to ‘true’ when setting ‘Require All’ to ‘true’.',
  },
  {
    code: 'REQUIRED_ADDRESS',
    text: 'The address is required.',
  },
  {
    code: 'REQUIRED_ADDRESSLINE1',
    text: 'Address Line 1 is required.',
  },
  {
    code: 'REQUIRED_CITY',
    text: 'City is required.',
  },
  {
    code: 'REQUIRED_COUNTRY',
    text: 'Country is required.',
  },
  {
    code: 'REQUIRED_CREDIT_CARD_NAME',
    text: 'Credit Card Cardholder Name is required.',
  },
  {
    code: 'REQUIRED_EMAIL',
    text: 'Email is required.',
  },
  {
    code: 'REQUIRED_FIRSTNAME',
    text: 'First Name is required.',
  },
  {
    code: 'REQUIRED_LASTNAME',
    text: 'Last Name is required.',
  },
  {
    code: 'REQUIRED_POSTALCODE',
    text: 'Postal Code is required.',
  },
  {
    code: 'REQUIRED_SECURITY_CHECK_URL_MISSING',
    text: 'A security check was specified for the recipient but the appropriate callback URL was not provided.',
  },
  {
    code: 'REQUIRED_STATE',
    text: 'State/Province is required.',
  },
  {
    code: 'REQUIRED_TAB_INCOMPLETE',
    text: 'A Required field is incomplete.',
  },
  {
    code: 'RESOURCE_NOT_FOUND',
    text: 'The URL provided does not resolve to a resource.',
  },
  {
    code: 'ROLE_DOES_NOT_EXIST',
    text: 'The role does not exist.',
  },
  {
    code: 'SAML_AUTHENTICATION_NOT_ALLOWED',
    text: 'SAML Authentication not allowed.',
  },
  {
    code: 'SATABONNONSADOCUMENT',
    text: 'A signer attachment tab is placed on a non-signer attachment document (a document without an AttachmentDescription).',
  },
  {
    code: 'SENDER_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The UserName and Email did not identify a sender in the system.',
  },
  {
    code: 'SENDER_REQUIRED_FIELD_CANNOT_BE_DELETED',
    text: 'Sender required fields cannot be deleted from envelope.',
  },
  {
    code: 'SENDER_REQUIRED_FIELD_NOT_ALLOWED',
    text: 'Property ‘sender required’ can only be set/modified on ‘Text’ or ‘Drop Down’ tags on a template.',
  },
  {
    code: 'SENDER_REQUIRED_FIELD_NOT_SATISFIED',
    text: 'Not all ‘sender required fields’ have been populated.',
  },
  {
    code: 'SHARE_TYPE_NOT_VALID',
    text: 'Share Type for template sharing has to be shared_to or not_shared',
  },
  {
    code: 'SHARED_TEMPLATE_FOLDERS_CAN_ONLY_BE_MOVED\\n_TO_SHARED_TEMPLATE_FOLDERS',
    text: 'Shared templates folder can only be moved inside shared template folders',
  },
  {
    code: 'SHARED_TEMPLATE_NOT_CREATOR',
    text: 'Shared Templates can only be moved by the creator of the template.',
  },
  {
    code: 'SHARED_VIEW_LINK_NOT_ALLOWED',
    text: 'Shared view links not allowed.',
  },
  {
    code: 'SIGNATURE_NOT_FOUND',
    text: 'The signature specified was not found.',
  },
  {
    code: 'SIGNER_CERTIFICATE_PROVIDER_NOT_SET',
    text: 'PKI Certificate Provider is not set.',
  },
  {
    code: 'SIGNER_CERTIFICATE_RECIPIENT_TYPE_INVALID',
    text: 'RequireSignerCertificate can only be used for Recipient Type Signer and InPersonSigner',
  },
  {
    code: 'SIGNER_CERTIFICATE_REQUIRED_TAB_ERROR',
    text: 'RequireSignerCertificate specified but no required SignHere or InitialHere tabs are present.',
  },
  {
    code: 'SIGNER_CERTIFICATE_ROUTING_ORDER_ERROR',
    text: 'When RequireSignerCertificate is specified, there must not be another recipient at the same routing order.',
  },
  {
    code: 'SIGNER_CERTIFICATE_SETTINGS_CONFLICT',
    text: 'RequireSignerCertificate conflicts with another envelope setting.',
  },
  {
    code: 'SIGNER_CERTIFICATE_TYPE_NOT_ENABLED',
    text: 'Signer certificate type not enabled for account.',
  },
  {
    code: 'SIGNER_CERTIFICATE_TYPE_NOT_SUPPORTED',
    text: 'Signer certificate type not supported.',
  },
  {
    code: 'SIGNER_RECIPIENT_HAS_NO_SIGNABLE_TABS',
    text: 'One or more Signer type recipients have not been assigned any signable tabs.',
  },
  {
    code: 'SIGNING_GROUP_CANNOT_UPDATE_GROUPTYPE',
    text: 'Update of signing group groupType is not allowed.',
  },
  {
    code: 'SIGNING_GROUP_CAPTIVE_RECIPIENT_NOT_ALLOWED',
    text: 'Captive recipient not allowed to be a signing group.',
  },
  {
    code: 'SIGNING_GROUP_CAPTIVE_RECIPIENT_NOT_ALLOWED',
    text: 'Captive recipient not allowed to be a signing group.',
  },
  {
    code: 'SIGNING_GROUP_FAX_DELIVERY_NOT_ALLOWED',
    text: 'Fax delivery is not allowed with signing groups.',
  },
  {
    code: 'SIGNING_GROUP_FAX_DELIVERY_NOT_ALLOWED',
    text: 'Fax delivery is not allowed with signing groups.',
  },
  {
    code: 'SIGNING_GROUP_INVALID',
    text: 'Invalid signing group supplied.',
  },
  {
    code: 'SIGNING_GROUP_INVALID',
    text: 'Invalid signing group supplied.',
  },
  {
    code: 'SIGNING_GROUP_MAX_GROUPNAME_LENGTH_EXCEEDED',
    text: 'Maximum length exceeded for group name.',
  },
  {
    code: 'SIGNING_GROUP_MAX_REACHED',
    text: 'Maximum signing groups reached.',
  },
  {
    code: 'SIGNING_GROUP_MAX_REACHED',
    text: 'Maximum signing groups reached.',
  },
  {
    code: 'SIGNING_GROUP_MAX_USERS_REACHED',
    text: 'Maximum users in a signing group reached.',
  },
  {
    code: 'SIGNING_GROUP_MAX_USERS_REACHED',
    text: 'Maximum users in a signing group reached.',
  },
  {
    code: 'SIGNING_GROUP_SMS_AUTH_NOT_ALLOWED',
    text: 'SMS authentication is not allowed with signing groups.',
  },
  {
    code: 'SIGNING_GROUP_SMS_AUTH_NOT_ALLOWED',
    text: 'SMS authentication is not allowed with signing groups.',
  },
  {
    code: 'SMS_AUTHENTICATION_NOT_ALLOWED',
    text: 'SMS Authentication not allowed.',
  },
  {
    code: 'SOBO_USER_NOT_ALLOWED',
    text: 'A ‘Send on Behalf Of’ user is not permitted for this operation.',
  },
  {
    code: 'SOCIAL_LOGIN_NOT_ENABLED',
    text: 'Social login not enabled on account.',
  },
  {
    code: 'SOCIAL_PROVIDER_ALREADY_MAPPED',
    text: 'This social provider account is already mapped in the system.',
  },
  {
    code: 'SUBSCRIPTION_ERROR',
    text: 'There is an error with the subscription.',
  },
  {
    code: 'SUBSCRIPTION_FAILED',
    text: 'The subscription failed.',
  },
  {
    code: 'SUBSCRIPTION_NOT_FOUND',
    text: 'The subscription was not found.',
  },
  {
    code: 'TAB_MERGEFIELD_VALUE_NOT_RETRIEVED',
    text: 'A Mergefield value for a Tab could not be retrieved.',
  },
  {
    code: 'TAB_NOT_FOUND',
    text: 'Tab specified was not found.',
  },
  {
    code: 'TAB_OUT_OF_BOUNDS',
    text: 'Tab is placed off of the page.',
  },
  {
    code: 'TAB_PAGE_NUMBER_NOT_SPECIFIED',
    text: 'Page number not specified in tab element.',
  },
  {
    code: 'TAB_PAGENUMBER_IS_NOT_IN_DOCUMENT',
    text: 'The pagenumber specified in the tab element is not in the document that the tab refers to.',
  },
  {
    code: 'TAB_REFERS_TO_MISSING_DOCUMENT',
    text: 'The DocumentId specified in the tab element does not refer to a document in this envelope.',
  },
  {
    code: 'TAB_REFERS_TO_MISSING_RECIPIENT',
    text: 'The RecipientId specified in the tab element does not refer to a recipient of this envelope.',
  },
  {
    code: 'TEMPLATE_ADDITIONALTABS_ERROR',
    text: 'Unable to add AdditionalTabs from Templates to Envelope.',
  },
  {
    code: 'TEMPLATE_AUTHENTICATION_FAILED',
    text: 'Not authorized to change template.',
  },
  {
    code: 'TEMPLATE_CANNOT_ADD_DOCUMENT',
    text: 'Cannot add the document for the template.',
  },
  {
    code: 'TEMPLATE_CANNOT_USE_ENVELOPEID_FOR\\n_TEMPLATE_OPERATION',
    text: 'Cannot use an envelope id for a template operation.',
  },
  {
    code: 'TEMPLATE_DOCUMENTID_MAPPING_INVALID',
    text: 'The document ID has already been used.',
  },
  {
    code: 'TEMPLATE_FOLDER_NOT_EMPTY',
    text: 'The template folder isn’t empty',
  },
  {
    code: 'TEMPLATE_ID_INVALID',
    text: 'Invalid template ID.',
  },
  {
    code: 'TEMPLATE_IS_SHARED',
    text: 'Template already shared',
  },
  {
    code: 'TEMPLATE_MERGE_INVALID_ROUTE_ORDER',
    text: 'The routing order of the recipients is not valid.',
  },
  {
    code: 'TEMPLATE_NOT_PROVIDED',
    text: 'Template was not provided.',
  },
  {
    code: 'TEMPLATE_NOT_SHARED',
    text: 'Template has not been shared',
  },
  {
    code: 'TEMPLATE_OVERRIDE_ENVELOPEINFORMATION_ERROR',
    text: 'Unable to override Envelope data from EnvelopeInformation.',
  },
  {
    code: 'TEMPLATE_OVERRIDE_RECIPIENTDATA_ERROR',
    text: 'Unable to override recipient data from Recipients to Envelope.',
  },
  {
    code: 'TEMPLATE_PASSWORD_REQUIRED',
    text: 'This user lacks sufficient permissions to access this resource. A password is required to edit this template.',
  },
  {
    code: 'TEMPLATE_RECIPIENTID_FOR_ROLE_NOTFOUND',
    text: 'Recipient ID not found in the Recipient list for the role.',
  },
  {
    code: 'TEMPLATE_REQUIRED_RECIP_NOT_SATISFIED',
    text: 'Required recipient in the template has not been provided.',
  },
  {
    code: 'TEMPLATE_ROLESPECIFIED_DOES_NOT_EXIST',
    text: 'Role does not exist in the template.',
  },
  {
    code: 'TEMPLATE_TO_ENVELOPE_ERROR',
    text: 'Unable to transform Template and data to Envelope.',
  },
  {
    code: 'TEMPLATE_UNABLE_TO_FLATTEN_PDF',
    text: 'Unable to flatting PDF for the Template.',
  },
  {
    code: 'TEMPLATE_UNABLE_TO_LOAD',
    text: 'Unable to load template.',
  },
  {
    code: 'TEMPLATE_UNABLE_TO_LOAD_FIELDDATA',
    text: 'Unable to load field data from PDF.',
  },
  {
    code: 'TEMPLATE_UNABLE_TO_PROCESS_FIELDDATA',
    text: 'Unable to process field data for the Template.',
  },
  {
    code: 'TEMPLATE_UPLOAD_NOT_ALLOWED',
    text: 'Template upload not allowed.',
  },
  {
    code: 'TO_FOLDER_NOT_FOUND',
    text: 'Destination folder doesn’t exist',
  },
  {
    code: 'TRANSACTIONID_DOES_NOT_EXIST',
    text: 'The transaction ID does not exist.',
  },
  {
    code: 'TRANSACTIONID_REDUNDANT',
    text: 'The transaction ID has already been submitted.',
  },
  {
    code: 'TRANSACTIONID_UNABLE_TO_SUBMIT',
    text: 'Unable to submit the transaction for processing.',
  },
  {
    code: 'UNABLE_TO_CLONE_ENVELOPE',
    text: 'Unable to clone the specified envelope.',
  },
  {
    code: 'UNABLE_TO_CONVERT_DOCUMENT',
    text: 'System was unable to convert this document to a PDF.',
  },
  {
    code: 'UNABLE_TO_DELETE_DOCUMENT',
    text: 'Unable to delete document.',
  },
  {
    code: 'UNABLE_TO_LOAD_DOCUMENT',
    text: 'Unable to load the document.',
  },
  {
    code: 'UNABLE_TO_PURGE_DOCUMENTS',
    text: 'Unable to purge documents.',
  },
  {
    code: 'UNKNOWN_ENVELOPE_RECIPIENT',
    text: 'The recipient you have identified is not a valid recipient of the specified envelope.',
  },
  {
    code: 'UNSPECIFIED_ERROR',
    text: 'An Error Occurred.',
  },
  {
    code: 'UNSPECIFIED_ERROR_IN_BILLING_SYSTEM',
    text: 'An error occurred in the billing system.',
  },
  {
    code: 'UNSPECIFIED_ERROR_PROCESSING_PAYMENT',
    text: 'A payment processing error occurred in the billing system.',
  },
  {
    code: 'USER_ALREADY_EXISTS_IN_ACCOUNT',
    text: 'Username and email combination already exists for this account.',
  },
  {
    code: 'USER_AUTHENTICATION_FAILED',
    text: 'One or both of Username and Password are invalid.',
  },
  {
    code: 'USER_AWAITING_ACTIVATION',
    text: 'This user name and email are awaiting activation.',
  },
  {
    code: 'USER_CREDENTIALS_ARE_EXTERNALLY_MANAGED',
    text: 'User credentials are managed by an external identity provider.',
  },
  {
    code: 'USER_DOES_NOT_BELONG_TO_SPECIFIED_ACCOUNT',
    text: 'The specified User is not a member of the specified account.',
  },
  {
    code: 'USER_DOES_NOT_EXIST_IN_SYSTEM',
    text: 'The UserID did not identify a User in the system.',
  },
  {
    code: 'USER_LACKS_MEMBERSHIP',
    text: 'The UserID does not have a valid membership in this account.',
  },
  {
    code: 'USER_LACKS_PERMISSIONS',
    text: 'This User lacks sufficient permissions.',
  },
  {
    code: 'USER_LACKS_RECIPIENTEMAILNOTIFICATION_PERMISSION',
    text: 'Account or user does not have permission to set recipient email notifications.',
  },
  {
    code: 'USER_MERGE_FAILED',
    text: 'The user merge failed',
  },
  {
    code: 'USER_MERGE_FAILED',
    text: 'The user merge failed',
  },
  {
    code: 'USER_NOT_ACCOUNT_ADMIN',
    text: 'User is not an account administrator.',
  },
  {
    code: 'USER_NOT_ENVELOPE_OWNER',
    text: 'This user lacks sufficient permissions to access this resource. The user is not the owner of the envelope.',
  },
  {
    code: 'USER_NOT_ENVELOPE_SENDER',
    text: 'This user is not the sender of the envelope. Only the sender of the envelope may perform the requested operation.',
  },
  {
    code: 'USER_NOT_ENVELOPE_SENDER_OR_RECIPIENT',
    text: 'This user is not the sender or a recipient of the envelope. Only the sender or a recipient of the envelope may perform the requested operation.',
  },
  {
    code: 'USER_NOT_FOLDER_OWNER',
    text: 'The user does not own the folder',
  },
  {
    code: 'USER_NOT_FOUND',
    text: 'No User was found for given criteria.',
  },
  {
    code: 'USER_NOT_RECIPIENT',
    text: 'User is not a recipient.',
  },
  {
    code: 'USER_NOT_TEMPLATE_OWNER',
    text: 'This user lacks sufficient permissions to access this resource. The user is not the owner of the template.',
  },
  {
    code: 'USERNAME_IS_NOT_ALLOWED',
    text: 'The specified username(s) and email(s) are not in our system and the email does not allow new users.',
  },
  {
    code: 'USERS_IN_PERMISSION_PROFILE',
    text: 'There are users still associated to this permission profile.',
  },
  {
    code: 'WATERMARK_DISABLED',
    text: 'Watermark is disabled.',
  },
  {
    code: 'WATERMARK_UPDATE_FAILED',
    text: 'Watermark could not be updated.',
  },
  {
    code: 'WORKSPACE_DATA_ERROR',
    text: 'An unexpected data issue occurred.',
  },
  {
    code: 'WORKSPACE_DATA_ERROR_MAXIMUM_ATTEMPTS',
    text: 'The maximum number of blob write attempts was exceeded.',
  },
  {
    code: 'WORKSPACE_DOES_NOT_EXIST',
    text: 'The workspace does not exist or permission denied.',
  },
  {
    code: 'WORKSPACE_FILE_DOES_NOT_EXIST',
    text: 'The workspace file does not exist.',
  },
  {
    code: 'WORKSPACE_FILEAUTHORIZATION\\n_CANNOT_UPDATE_OWNER',
    text: 'Cannot update a file owner’s permissions to that file or a workspace owner’s permissions to any file.',
  },
  {
    code: 'WORKSPACE_FILEAUTHORIZATION\\n_FILE_IS_PUBLIC',
    text: 'Cannot update or retrieve a public file’s permissions.',
  },
  {
    code: 'WORKSPACE_FILEAUTHORIZATION\\n_INVALID_PERMISSIONS',
    text: 'CanView must always be enabled for any other file permission.',
  },
  {
    code: 'WORKSPACE_FOLDER_CANNOT_DELETE_ROOT',
    text: 'Cannot delete root folder.',
  },
  {
    code: 'WORKSPACE_FOLDER_CANNOT_MOVE',
    text: 'Cannot move a workspace folder into its own nested folder.',
  },
  {
    code: 'WORKSPACE_FOLDER_DOES_NOT_EXIST',
    text: 'The workspace folder does not exist.',
  },
  {
    code: 'WORKSPACE_FOLDER_IS_NOT_EMPTY',
    text: 'The workspace folder is not empty and cannot be deleted.',
  },
  {
    code: 'WORKSPACE_IS_CLOSED',
    text: 'The workspace is closed and cannot be modified.”)]',
  },
  {
    code: 'WORKSPACE_NOT_ON_THIS_SITE',
    text: 'This action can only be requested on the site where the workspace lives.',
  },
  {
    code: 'WORKSPACE_TOTALFILESIZE_EXCEEDED',
    text: 'The maximum amount of file storage was exceeded.',
  },
  {
    code: 'WORKSPACE_USER_DOES_NOT_EXIST',
    text: 'The workspace participant does not exist.',
  },
];
