import { FC, useEffect, useRef, useState } from 'react';
import { IDocuSignSectionSigner } from '../DocuSignSection/DocuSignSection';
import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { EasyAutoComplete } from 'components';
import { useUsersAutocompleteDataHook } from 'hooks/usersAutoCompleteDataHook';

export interface IDocusignRecipientSelection {
  recipient: IDocuSignSectionSigner;
  setRecipient: any;
  removeItem: any;
  disabled?: boolean;
  recipientTypeLabel: string;
}

export const DocusignRecipientSelection: FC<IDocusignRecipientSelection> = ({
  setRecipient,
  recipient,
  removeItem,
  disabled,
  recipientTypeLabel,
}) => {
  const [selectedItem, onSelectChange] = useState<any>();

  const setRecipientRef = useRef<any>();
  useEffect(() => {
    setRecipientRef.current = setRecipient;
  }, [setRecipient]);

  const recipientRef = useRef<any>();
  useEffect(() => {
    recipientRef.current = recipient;
  }, [recipient]);

  useEffect(() => {
    if (selectedItem) {
      const { email, userId, name } = recipientRef?.current ?? {};
      if (
        selectedItem.email !== email ||
        selectedItem.id !== userId ||
        selectedItem.name !== name
      ) {
        const { id, name, email } = selectedItem;
        setRecipientRef.current && setRecipientRef.current({ userId: id, name, email });
      }
    }
  }, [selectedItem]);

  const usersProps = useUsersAutocompleteDataHook({
    search: '',
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <EasyAutoComplete
          key={'DocusignRecipientSelection'}
          label=""
          textFieldStyle="outlined"
          optionsLabel="name"
          {...usersProps}
          getOptionSelected={(option: any, value: any) => {
            return option.id === value.id || (value.id === '0' && option.email === value.email);
          }}
          placeholder={`Enter ${recipientTypeLabel} name`}
          // Text input simulation
          inputValue={recipient?.name || ''}
          onInputChange={(...props: any) => {
            const [event, value, reason] = props;
            if (reason === 'input') {
              setRecipient({ email: recipient?.email, name: value, userId: undefined });
              usersProps.onInputChange(event, value, reason);
            }
          }}
          selectedChanged={(value: any): void => {
            onSelectChange && onSelectChange(value);
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          placeholder={`Enter ${recipientTypeLabel} email`}
          variant="outlined"
          size="small"
          value={recipient?.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRecipient({ name: recipient?.name, email: e.target.value, userId: undefined });
          }}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={2}>
        <Tooltip title="Remove Signer">
          <IconButton
            size="small"
            onClick={!!removeItem ? debounce(removeItem!, DEBOUNCE_TIMEOUT) : undefined}
            disabled={!removeItem || disabled}
          >
            <Clear fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
