import React, { FC, useEffect } from 'react';
import { TreeTable } from 'template/TreeTable/TreeTable';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';

export const TreeTableNewLegalFolder: FC = () => {
  const { initPage } = useComponentContext();
  useEffect(() => {
    initPage!({ wellcomeProp: false, initTabProp: 'LF' });
  }, [initPage]);
  return <TreeTable></TreeTable>;
};
