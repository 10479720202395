import { FC, useCallback, useState } from 'react';
import {
  // Cancel as CancelIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey, red } from '@mui/material/colors';
import { ConfirmationDialog } from 'components';
import { useUI } from 'contexts/UiContext';
import { DOCUMENT_CANCEL, DOCUMENT_SOFT_DELETE } from 'graphql/legalFolders/fileManager';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: red[400] + ' !important',
    },
  },
}));

export interface IDocumentCancelOrDeleteIcon {
  status: string;
  id: string;
}
export const DocumentCancelOrDeleteIcon: FC<IDocumentCancelOrDeleteIcon> = ({ status, id }) => {
  const classes = useStyles();
  const { addSnackbar } = useUI();
  const client = useApolloClient();

  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [displayCancelWarning, showCancelWarning] = useState(false);

  const [deleteDocumentMutation] = useMutation(DOCUMENT_SOFT_DELETE);
  const [cancelDocumentMutation] = useMutation(DOCUMENT_CANCEL);

  const onCancelProcess = useCallback(async () => {
    let success = false;
    const variables = { documentId: id };
    try {
      const { data } = await cancelDocumentMutation({
        variables,
      });
      if (data?.contract_documentCancel?.eventType) {
        addSnackbar!({ text: 'Document is canceled', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, cancelDocumentMutation, id, client]);

  const handleCancelDocument = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onCancelProcess!();
      }
      showCancelWarning(() => false);
    },
    [onCancelProcess]
  );

  const onDeleteProcess = useCallback(async () => {
    let success = false;
    const variables = { documentId: id };
    try {
      const { data } = await deleteDocumentMutation({
        variables,
      });
      if (data?.contract_documentDelete) {
        addSnackbar!({ text: 'Document is deleted', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, deleteDocumentMutation, id, client]);

  const handleDeleteDocument = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onDeleteProcess!();
      }
      showCancelWarning(() => false);
    },
    [onDeleteProcess]
  );

  return (
    <div
      onClick={(e: any) => {
        e.preventDefault();
        e.cancelBubble = true;
        e.stopPropagation();
      }}
    >
      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Document deletion"
        message={'Selected Document will be deleted! The action is irreversible!'}
        onClose={handleDeleteDocument}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <ConfirmationDialog
        open={displayCancelWarning}
        title="Please confirm Document cancelation"
        message={'Selected Document will be canceled! The action is irreversible!'}
        onClose={handleCancelDocument}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      {status !== 'CANCELED' ? undefined : (
        // <div>
        //   <Tooltip title="Cancel Document">
        //     <IconButton
        //       size="small"
        //       style={{ color: grey[500] }}
        //       className={classes.hoverWarning}
        //       onClick={debounce((e: any) => {
        //         showCancelWarning(true);
        //       }}
        //     >
        //       <CancelIcon fontSize="small" />
        //     </IconButton>
        //   </Tooltip>
        // </div>
        <div>
          <Tooltip title="Delete Document">
            <IconButton
              size="small"
              style={{ color: grey[500] }}
              className={classes.hoverWarning}
              onClick={debounce((e: any) => {
                showDeleteWarning(true);
              }, DEBOUNCE_TIMEOUT)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
