import { useAutoCompleteDataSource } from './autoCompleteDataSource';

import { ProjectFilter } from 'graphql/legalFolders/types/graphql-types';

import { GET_PROJECT_COUNT, GET_PROJECT_NAMES } from 'graphql/legalFolders/projects';
import { projectNames, projectNames_projects } from 'graphql/legalFolders/types/projectNames';
import { projectCount } from 'graphql/legalFolders/types/projectCount';

const getCount = (count: projectCount) => count.projectCount;
const getItems = (data: projectNames) => data.projects.map((item) => ({ ...item, name: item.key }));
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ keyOrNameContains: localSearch });

export interface IProjectsKeysAutocompleteDataHook {
  search: string;
}

export const useProjectsKeysAutocompleteDataHook = ({
  search,
}: IProjectsKeysAutocompleteDataHook) => {
  const resp = useAutoCompleteDataSource<
    projectNames,
    projectCount,
    projectNames_projects,
    ProjectFilter
  >({
    GET_QUERY: GET_PROJECT_NAMES,
    GET_COUNT_QUERY: GET_PROJECT_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};
