import { useMemo } from 'react';
import { useComponentContext } from '../../TableSectionContext';
import { Provider as ProjectFolderProvider } from 'template/ProjectFolder/ProjectFolderContext';
import { ProjectFolderForm } from 'template/ProjectFolder/components/ProjectFolderForm/ProjectFolderForm';
import { useComponentContext as useTreeComponentContext } from '../../../../TreeTableContext';
import { GET_LEGAL_FOLDER_ATTRIBUTES } from 'graphql/legalFolders/legalFolders';
import { useQuery } from '@apollo/client';
import { legalFolderAttributes } from 'graphql/legalFolders/types/legalFolderAttributes';

export const NewProjectFolderView = () => {
  const { legalFolderId } = useComponentContext();
  const { onNewProjectFolderCreated } = useTreeComponentContext();

  const { data: legalFolderData, loading: legalFolderLoading } = useQuery<legalFolderAttributes>(
    GET_LEGAL_FOLDER_ATTRIBUTES,
    {
      variables: {
        id: legalFolderId,
      },
    }
  );

  const legalFolderAttributes = useMemo(() => {
    if (!legalFolderLoading && legalFolderData) {
      return legalFolderData?.contract_legalFolder;
    }
    return undefined;
  }, [legalFolderLoading, legalFolderData]);

  return (
    <ProjectFolderProvider
      legalFolderAttributes={legalFolderAttributes}
      legalFolderId={legalFolderId!}
      onCreated={onNewProjectFolderCreated}
    >
      <ProjectFolderForm></ProjectFolderForm>
    </ProjectFolderProvider>
  );
};
