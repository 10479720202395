import { gql } from '@apollo/client';
import {
  IDocumentsListDocument,
  ILegalFolderDocument,
  IDocumentType,
  IDocumentEvent,
  IDocumentsListDocumentAll,
  IDocumentsExportListDocument,
} from './fragments';

export const GET_PROJECT_DOCUMENT_NAME = gql`
  query projectDocumentName($id: ID!) {
    contract_document(id: $id) {
      name
    }
  }
`;

export const GET_PROJECT_FOLDER_DOCUMENT = gql`
  query projectFolderDocument($id: ID!) {
    contract_document(id: $id) {
      ...ILegalFolderDocument
    }
  }
  ${ILegalFolderDocument}
`;

export const GET_PROJECT_FOLDER_DOCUMENT_PATH = gql`
  query projectFolderDocumentPath($id: ID!) {
    contract_document(id: $id) {
      id
      name
      container {
        id
        name
        legalFolder {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_FOLDER_DOCUMENT_EVENTS = gql`
  query projectFolderDocumentEvents($id: ID!) {
    contract_document(id: $id) {
      events {
        ...IDocumentEvent
      }
    }
  }
  ${IDocumentEvent}
`;

export const GET_PROJECT_FOLDER_DOCUMENTS_NAMES = gql`
  query getLegalFolderDocumentsNames(
    $sort: [DocumentSort!] = []
    $filter: DocumentFilter
    $take: Int
    $skip: Int = 0
  ) {
    contract_documents(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
    }
  }
`;

export const GET_PROJECT_FOLDER_DOCUMENTS = gql`
  query getLegalFolderDocuments(
    $sort: [DocumentSort!] = []
    $filter: DocumentFilter
    $take: Int
    $skip: Int = 0
  ) {
    contract_documents(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IDocumentsListDocument
    }
  }
  ${IDocumentsListDocument}
`;

export const GET_PROJECT_FOLDER_DOCUMENTS_ALL = gql`
  query getLegalFolderDocumentsAll(
    $sort: [DocumentSort!] = []
    $filter: DocumentFilter
    $take: Int
    $skip: Int = 0
  ) {
    contract_documents(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IDocumentsListDocumentAll
    }
  }
  ${IDocumentsListDocumentAll}
`;

export const GET_DOCUMENTS_EXPORT_LIST = gql`
  query getDocumentsExport(
    $sort: [DocumentSort!] = []
    $filter: DocumentFilter
    $take: Int
    $skip: Int = 0
  ) {
    contract_documents(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IDocumentsExportListDocument
    }
  }
  ${IDocumentsExportListDocument}
`;

export const CHECK_DOCUMENTS_ACTION_PENDING = gql`
  query checkDocumentsActionPending($filter: DocumentFilter) {
    contract_documents(
      filter: $filter
      take: 2
      sort: { column: CURRENT_USER_ACTION_PENDING, order: DESC }
    ) {
      id
      currentUserHasActionPending
    }
  }
`;

export const GET_PROJECT_FOLDER_DOCUMENTS_COUNT = gql`
  query getLegalFolderDocumentsCount($filter: DocumentFilter) {
    contract_documentCount(filter: $filter)
  }
`;

export const GET_PROJECT_FOLDER_DOCUMENTS_TOTAL_COUNTERS = gql`
  query getLegalFolderDocumentsTotalCounters(
    $totalFilter: DocumentFilter
    $userOnlyFilter: DocumentFilter
    $inaccessibleFilter: DocumentFilter
  ) {
    total: contract_documentCount(filter: $totalFilter, includeInaccessible: false)
    userOnly: contract_documentCount(filter: $userOnlyFilter, includeInaccessible: false)
    inaccessible: contract_documentCount(filter: $inaccessibleFilter, includeInaccessible: true)
  }
`;

export const PROJECT_FOLDER_DOCUMENT_CREATE_MUTATION = gql`
  mutation projectFolderDocumentCreate(
    $projectFolderId: ID!
    $documentTypeId: ID!
    $parentDocumentId: ID
    $name: String!
    $isOriginParty: Boolean
    $indemnity: DocumentIndemnity
    $monetaryTransactionValue: Float
    $notes: String
    $specialInstructions: String
    $reviewersReminderMinutePeriod: Int
    $startDate: DateTime
    $endDate: DateTime
    $projectIds: [ID!]
    $divisionIds: [ID!]
    $outsideCounsel: Boolean
    $contactIds: [ID!]
  ) {
    contract_documentCreate(
      containerId: $projectFolderId
      documentTypeId: $documentTypeId
      parentDocumentId: $parentDocumentId
      name: $name
      isOriginParty: $isOriginParty
      indemnity: $indemnity
      monetaryTransactionValue: $monetaryTransactionValue
      notes: $notes
      specialInstructions: $specialInstructions
      reviewersReminderMinutePeriod: $reviewersReminderMinutePeriod
      startDate: $startDate
      endDate: $endDate
      projectIds: $projectIds
      divisionIds: $divisionIds
      outsideCounsel: $outsideCounsel
      contactIds: $contactIds
    ) {
      id
    }
  }
`;

export const PROJECT_FOLDER_DOCUMENT_UPDATE_MUTATION = gql`
  mutation projectFolderDocumentUpdate(
    $id: ID!
    $documentTypeId: ID
    $parentDocumentId: ID
    $name: String
    $isOriginParty: Boolean
    $indemnity: DocumentIndemnity
    $monetaryTransactionValue: Float
    $notes: String
    $specialInstructions: String
    $reviewersReminderMinutePeriod: Int
    $startDate: DateTime
    $endDate: DateTime
    $projectIdsToAdd: [ID!]
    $projectIdsToRemove: [ID!]
    $divisionIdsToAdd: [ID!]
    $divisionIdsToRemove: [ID!]
    $outsideCounsel: Boolean
    $contactIdsToAdd: [ID!]
    $contactIdsToRemove: [ID!]
  ) {
    contract_documentUpdate(
      id: $id
      documentTypeId: $documentTypeId
      parentDocumentId: $parentDocumentId
      name: $name
      isOriginParty: $isOriginParty
      indemnity: $indemnity
      monetaryTransactionValue: $monetaryTransactionValue
      notes: $notes
      specialInstructions: $specialInstructions
      reviewersReminderMinutePeriod: $reviewersReminderMinutePeriod
      startDate: $startDate
      endDate: $endDate
      projectIdsToAdd: $projectIdsToAdd
      projectIdsToRemove: $projectIdsToRemove
      divisionIdsToAdd: $divisionIdsToAdd
      divisionIdsToRemove: $divisionIdsToRemove
      outsideCounsel: $outsideCounsel
      contactIdsToAdd: $contactIdsToAdd
      contactIdsToRemove: $contactIdsToRemove
    ) {
      id
    }
  }
`;

export const DOCUMENT_MONETARY_TRANSACTION_BREAKDOVN_CHANGE_MUTATION = gql`
  mutation documentMonetaryTransactionBreakdownChange(
    $documentId: ID!
    $monetaryTransactionBreakdownsToAdd: [DocumentMonetaryTransactionBreakdownAdd!]
    $monetaryTransactionBreakdownsToModify: [DocumentMonetaryTransactionBreakdownModify!]
    $monetaryTransactionBreakdownsToRemove: [DocumentMonetaryTransactionBreakdownRemove!]
  ) {
    contract_documentMonetaryTransactionBreakdownChange(
      documentId: $documentId
      monetaryTransactionBreakdownsToAdd: $monetaryTransactionBreakdownsToAdd
      monetaryTransactionBreakdownsToModify: $monetaryTransactionBreakdownsToModify
      monetaryTransactionBreakdownsToRemove: $monetaryTransactionBreakdownsToRemove
    )
  }
`;

export const DOCUMENT_REVIEWERS_CHANGE_MUTATION = gql`
  mutation documentReviewersChange(
    $documentId: ID!
    $reviewersToAdd: [DocumentReviewerAddInput!]
    $reviewersToModify: [DocumentReviewerModifyInput!]
  ) {
    contract_documentReviewersChange(
      documentId: $documentId
      reviewersToAdd: $reviewersToAdd
      reviewersToModify: $reviewersToModify
    ) {
      eventType
    }
  }
`;

export const DOCUMENT_OBSERVERS_CHANGE_MUTATION = gql`
  mutation documentObserversChange(
    $documentId: ID!
    $observersToAdd: [DocumentObserverAddInput!]
    $observersToModify: [DocumentObserverModifyInput!]
  ) {
    contract_documentObserversChange(
      documentId: $documentId
      observersToAdd: $observersToAdd
      observersToModify: $observersToModify
    ) {
      eventType
    }
  }
`;

export const GET_DOCUMENT_TYPES = gql`
  query documentTypes {
    contract_documentTypes(sort: [{ column: NAME, order: ASC }]) {
      ...IDocumentType
    }
  }
  ${IDocumentType}
`;

export const DOCUMENT_COMMENT_CREATE = gql`
  mutation documentCommentCreate(
    $documentId: ID!
    $comment: String!
    $uploadedFile: UploadedFileInput
  ) {
    contract_documentComment(
      documentId: $documentId
      comment: $comment
      uploadedFile: $uploadedFile
    ) {
      ...IDocumentEvent
    }
  }
  ${IDocumentEvent}
`;

export const DOCUMENT_COMMENT_REPLY_CREATE = gql`
  mutation documentCommentReplyCreate($documentId: ID!, $comment: String!, $refEventId: ID!) {
    contract_documentCommentReply(
      documentId: $documentId
      comment: $comment
      refEventId: $refEventId
    ) {
      ...IDocumentEvent
    }
  }
  ${IDocumentEvent}
`;

export const DOCUMENT_PARTY_REVIEW_STATUS_CHANGE = gql`
  mutation documentPartyReviewStatusChange($documentId: ID!, $status: DocumentPartyReviewStatus!) {
    contract_documentPartyReviewStatusChange(documentId: $documentId, status: $status) {
      ...IDocumentEvent
    }
  }
  ${IDocumentEvent}
`;

export const DOCUMENT_REQUEST_REVIEW = gql`
  mutation documentRequestReview($documentId: ID!, $userId: ID!) {
    contract_documentRequestReview(documentId: $documentId, userId: $userId) {
      user {
        id
      }
    }
  }
`;

export const DOCUMENT_PARTY_REWIEW_REQUIRED_UPDATE = gql`
  mutation documentPartyReviewIsRequiredChange($documentId: ID!, $isRequired: Boolean!) {
    contract_documentPartyReviewIsRequiredChange(documentId: $documentId, isRequired: $isRequired) {
      eventType
    }
  }
`;

export const DOCUMENT_REVIEW_APPROVE = gql`
  mutation documentReviewApprove(
    $documentId: ID!
    $comment: String
    $uploadedFile: UploadedFileInput
  ) {
    contract_documentReviewApprove(
      documentId: $documentId
      comment: $comment
      uploadedFile: $uploadedFile
    ) {
      user {
        id
      }
    }
  }
`;

export const DOCUMENT_SIGN = gql`
  mutation documentSign($documentId: ID!, $comment: String, $uploadedFile: UploadedFileInput) {
    contract_documentSetStatusFolderSigned(
      documentId: $documentId
      comment: $comment
      uploadedFile: $uploadedFile
    )
  }
`;

export const DOCUMENT_REVIEW_DISAPPROVE = gql`
  mutation documentReviewDisapprove(
    $documentId: ID!
    $comment: String
    $uploadedFile: UploadedFileInput
  ) {
    contract_documentReviewDisapprove(
      documentId: $documentId
      comment: $comment
      uploadedFile: $uploadedFile
    ) {
      user {
        id
      }
    }
  }
`;

export const DOCUMENT_REVIEW_RECALL = gql`
  mutation documentReviewRecall($documentId: ID!, $userId: ID!) {
    contract_documentReviewRecall(documentId: $documentId, userId: $userId) {
      user {
        id
      }
    }
  }
`;

export const DOCUMENT_SEND_REVIEW_REQUEST_REMINDER = gql`
  mutation documentSendReviewRequestReminder($documentId: ID!, $userId: ID!) {
    contract_documentSendReviewRequestReminder(documentId: $documentId, userId: $userId) {
      user {
        id
      }
    }
  }
`;

export const DOCUMENT_SEND_OBSERVER_NOTIFICATION = gql`
  mutation documentSendObserverNotification($documentId: ID!, $userId: ID!) {
    contract_documentSendObserverNotification(documentId: $documentId, userId: $userId) {
      user {
        id
      }
    }
  }
`;

// export const DOCUMENT_CHANGE_OWNER_MUTATION = gql`
//   mutation documentOwnerChange($documentId: ID!, $ownerUserId: ID!) {
//     contract_documentOwnerChange(documentId: $documentId, ownerUserId: $ownerUserId)
//   }
// `;

export const DOCUMENT_OWNERS_CHANGE_MUTATION = gql`
  mutation documentOwnersChange(
    $documentId: ID!
    $ownersToAdd: [DocumentOwnerAddInput!]
    $ownersToModify: [DocumentOwnerModifyInput!]
  ) {
    contract_documentOwnersChange(
      documentId: $documentId
      ownersToAdd: $ownersToAdd
      ownersToModify: $ownersToModify
    )
  }
`;

// contract_documentMove result is DocumentEvent
export const DOCUMENT_MOVE = gql`
  mutation documentMove($documentId: ID!, $destinationContainerId: ID!) {
    contract_documentMove(
      documentId: $documentId
      destinationContainerId: $destinationContainerId
    ) {
      id
    }
  }
`;
