import { useQuery } from '@apollo/client';
import { GET_CONTRACTS_DIVISIONS } from 'graphql/legalFolders/divisions';
import { contractsDivisionsList } from 'graphql/legalFolders/types/contractsDivisionsList';
import { useCallback, useMemo } from 'react';

export const useDivisions = () => {
  const { data } = useQuery<contractsDivisionsList>(GET_CONTRACTS_DIVISIONS);

  const getDivisionsPairs = useCallback(() => {
    return (
      data?.divisions.map(({ id, name }) => {
        return {
          id,
          name,
        };
      }) || []
    );
  }, [data]);

  const divisionPairs = useMemo(() => {
    return getDivisionsPairs();
  }, [getDivisionsPairs]);

  return { data, divisionPairs };
};
