import React, { FC, useRef, useState, useEffect } from 'react';
import { IHeadCell } from '../TableHead/TableHead';
import { TableCell, TableRow, TextField } from '@mui/material';
import EasyAutoComplete from 'components/EasyAutoComplete';

import { TYPING_TIMEOUT } from 'constants/config';
import cn from 'classnames';
import s from './style.module.scss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { HierarchyMultipleSelect } from 'components/HierarchyMultipleSelect/HierarchyMultipleSelect';

export interface IFiltersRowProps {
  headCells: IHeadCell[];
  filterOptions: any;
  filterValues?: any;
  onFilterChange?: (filterValues: any) => void;
  stickyHeader?: boolean;
}

// const dateStyle = {
//   backgroundColor: '#fff',
//   borderRadius: 4,
//   border: '1px solid #aaa',
// };

export const FiltersRow: FC<IFiltersRowProps> = ({
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  stickyHeader,
}) => {
  const [selected, setSelected] = useState<any>(filterValues || {});
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [localSelected, setLocalSelected] = useState<{ [id: string]: any }>({});
  const [open, setOpen] = useState<{ [id: string]: boolean }>({});

  useEffect(() => {
    setSelected(filterValues);
  }, [filterValues]);

  useEffect(() => {
    if (onFilterChange && selected) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      timer.current = setTimeout(() => {
        onFilterChange(selected);
      }, TYPING_TIMEOUT);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [selected, onFilterChange]);

  let count = -1;
  return (
    <TableRow
      style={
        stickyHeader
          ? {
              position: 'sticky',
              top: 45,
            }
          : undefined
      }
    >
      {headCells.map((headCell) => {
        count++;
        return (
          <TableCell
            key={count.toString() + '_' + headCell.id}
            className={cn({ [s.paddingHalf]: headCell.padding === 'half' })}
          >
            {headCell.filter === 'text' ? (
              <TextField
                key={`txt-${headCell.id}`}
                variant="outlined"
                size="small"
                value={selected[headCell.id] || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelected({ ...selected, [headCell.id]: e.target.value });
                }}
                style={{ backgroundColor: 'white' }}
                fullWidth
              />
            ) : undefined}
            {headCell.filter === 'component' && headCell.filterComponent ? (
              <headCell.filterComponent
                key={`dd-${headCell.id}`}
                onSelectChange={(value: any): void => {
                  setSelected({ ...selected, [headCell.id]: value });
                }}
                selectedItem={selected[headCell.id]}
                label=""
                textFieldStyle="outlined"
                style={{ backgroundColor: 'white' }}
                textFieldStyling={{ margin: '0px' }}
              />
            ) : undefined}
            {headCell.filter === 'dropdown' ? (
              <EasyAutoComplete
                key={`dd-${headCell.id}`}
                items={filterOptions[headCell.id]}
                selected={selected[headCell.id]}
                label=""
                textFieldStyle="outlined"
                optionsLabel="name"
                selectedChanged={(value: any): void => {
                  setSelected({ ...selected, [headCell.id]: value });
                }}
                getOptionSelected={(option: any, value: any) => {
                  return (
                    (!!option.id && option.id === value.id) ||
                    (!!option.key && option.key === value.key) ||
                    (!!option.name && option.name === value.name)
                  );
                }}
                style={{ backgroundColor: 'white' }}
                textFieldStyling={{ margin: '0px' }}
              />
            ) : undefined}
            {headCell.filter === 'text-dropdown' ? (
              <EasyAutoComplete
                key={`txt-dd-${headCell.id}`}
                label=""
                textFieldStyle="outlined"
                getOptionSelected={(option: any, value: any) => {
                  return (
                    (!!option.id && option.id === value.id) ||
                    (!!option.key && option.key === value.key) ||
                    (!!option.name && option.name === value.name)
                  );
                }}
                style={{ backgroundColor: 'white' }}
                textFieldStyling={{ margin: '0px' }}
                // Defaults
                optionsLabel="name"
                // Passed props
                {...headCell.filterProps}
                // Open dropdown only on arrow click
                open={!!open[headCell.id]}
                onOpen={(e: any) => {
                  if (e.type === 'click') {
                    setOpen({ ...open, [headCell.id]: true });
                  }
                }}
                onClose={() => setOpen({ ...open, [headCell.id]: false })}
                // Text input simulation
                inputValue={selected[headCell.id] || ''}
                onInputChange={(...props: any) => {
                  const [, value, reason] = props;
                  if (reason === 'input') {
                    setSelected({ ...selected, [headCell.id]: value });
                    headCell.filterProps.onInputChange(...props);
                  }
                }}
                // Dropdown
                selected={localSelected}
                selectedChanged={(value: any): void => {
                  setLocalSelected({ ...localSelected, [headCell.id]: value }); // for dropdown search
                  setSelected({ ...selected, [headCell.id]: value.name }); // for table search
                }}
              />
            ) : undefined}
            {headCell.filter === 'hierarchy-multiple' ? (
              <HierarchyMultipleSelect
                selectMode={'leafOnly'}
                key={`dd-${headCell.id}`}
                items={filterOptions[headCell.id]}
                selected={selected[headCell.id]}
                selectedChanged={(value: any): void => {
                  setSelected({ ...selected, [headCell.id]: value });
                }}
              />
            ) : undefined}
            {headCell.filter === 'date' ? (
              <div style={{ padding: '1px' }}>
                <DatePicker
                  value={selected[headCell.id] || null}
                  onChange={(date) => {
                    setSelected({ ...selected, [headCell.id]: date });
                  }}
                  componentsProps={{
                    actionBar: { actions: ['clear'] as PickersActionBarAction[] },
                  }}
                  renderInput={(props) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      style={{ backgroundColor: 'white' }}
                      size="small"
                      {...props}
                    />
                  )}
                ></DatePicker>
              </div>
            ) : undefined}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
