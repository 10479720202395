import React, { FC, useMemo } from 'react';
import { Breadcrumbs as GenericBreadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { ActiveTab } from '../TableSection/TableSectionContext';
import { DocumentViewTab } from '../TableSection/components/DocumentsView/DocumentsContext';
import { ProjectFoldersViewTab } from '../TableSection/components/ProjectFoldersView/ProjectFoldersContext';

export interface IBreadcrumbsProps {
  projectFolder?: { id?: string; name?: string };
  legalFolder?: { id?: string; name?: string };
  document?: { id?: string; name?: string };
  onClickHome: () => void;
  onLegalFolderClick: () => void;
  onProjectFolderClick: () => void;
  onDocumentClick: () => void;
  initTab?: ActiveTab;
  initDocumentTab?: DocumentViewTab;
  initProjectFoldersTab?: ProjectFoldersViewTab;
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({
  projectFolder,
  legalFolder,
  document,
  onClickHome,
  onLegalFolderClick,
  onProjectFolderClick,
  onDocumentClick,
  initTab,
  initDocumentTab,
  initProjectFoldersTab,
}) => {
  const title = document
    ? document?.id
      ? initDocumentTab === 'edit-fm'
        ? 'File Management and Approval'
        : document?.name || ''
      : 'New Project Document'
    : projectFolder
    ? projectFolder.id
      ? initTab === 'D'
        ? 'Documents'
        : projectFolder.name || ''
      : initProjectFoldersTab === 'list-pf'
      ? 'Project Folder List'
      : 'New Project Folder'
    : legalFolder
    ? legalFolder?.id
      ? legalFolder.name || ''
      : 'New Legal Folder'
    : undefined;

  const links = useMemo(() => {
    const list = [];
    if (legalFolder && projectFolder) {
      list.push({ cb: onLegalFolderClick, title: legalFolder.name!, category: 'Legal Folder' });
    }
    if (projectFolder && (document || initTab === 'D')) {
      list.push({
        cb: onProjectFolderClick,
        title: projectFolder.name!,
        category: 'Project Folder',
      });
    }
    if (document && initTab === 'D' && initDocumentTab === 'edit-fm') {
      list.push({
        cb: onDocumentClick,
        title: document.name!,
        category: 'Document',
      });
    }
    return list;
  }, [
    legalFolder,
    projectFolder,
    document,
    onLegalFolderClick,
    onProjectFolderClick,
    initDocumentTab,
    initTab,
    onDocumentClick,
  ]);

  return <GenericBreadcrumbs onClickHome={onClickHome} links={links} pageTitle={title} />;
};
