import { useLazyQuery } from '@apollo/client';
import { GET_USERS } from 'graphql/legalFolders/users';
import { usersList } from 'graphql/legalFolders/types/usersList';
import { useCallback, useEffect, useState } from 'react';
import { chain } from 'lodash';

export interface IUsersMap {
  [id: string]: any;
}
export const useUsersList = () => {
  const [usersMap, setUsersMap] = useState<IUsersMap>();

  const [
    getUsers,
    {
      data: dataUsers,
      loading: loadingUsers,
      refetch: refetchUsers,
      called: calledUsers,
      error: errorUsers,
    },
  ] = useLazyQuery<usersList>(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadUsers = useCallback(() => {
    const variables = {
      take: 10000,
      filter: {
        isActive: true,
      },
    };

    if (calledUsers) {
      refetchUsers!(variables);
    } else {
      getUsers({ variables });
    }
  }, [getUsers, refetchUsers, calledUsers]);

  useEffect(() => {
    if (!errorUsers && dataUsers && !loadingUsers) {
      setUsersMap(chain(dataUsers.users).keyBy('id').value());
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  return {
    usersMap,
    loadUsers,
    loadingUsers,
  };
};
