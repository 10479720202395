import { gql } from '@apollo/client';
import { IParty, IPartyEvent, IPartyGeoInfo } from './partiesFragments';

export const GET_PARTY = gql`
  query party($id: ID!) {
    party(id: $id) {
      ...IParty
    }
  }
  ${IParty}
`;

export const PARTY_SOFT_DELETE = gql`
  mutation partySoftDelete($id: ID!) {
    partySoftDelete(id: $id)
  }
`;

export const GET_PARTIES = gql`
  query parties(
    $sort: [PartySortInput!] = []
    $filter: PartyFilterInput
    $take: Int
    $skip: Int = 0
  ) {
    parties(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IParty
    }
  }
  ${IParty}
`;

export const GET_PARTIES_GEO_INFO = gql`
  query partiesGeoInfo(
    $sort: [PartySortInput!] = []
    $filter: PartyFilterInput
    $take: Int
    $skip: Int = 0
  ) {
    parties(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IPartyGeoInfo
    }
  }
  ${IPartyGeoInfo}
`;

export const GET_PARTIES_UNIQUE = gql`
  query getPartiesUnique(
    $sort: [PartySortInput!] = []
    $filter: PartyFilterInput
    $take: Int
    $skip: Int = 0
  ) {
    parties(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
      country {
        id
      }
    }
  }
`;

export const GET_PARTY_NAMES = gql`
  query partyNames(
    $sort: [PartySortInput!] = []
    $filter: PartyFilterInput
    $take: Int
    $skip: Int = 0
  ) {
    parties(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
      projectSetupClientCode
    }
  }
`;

export const GET_PARTY_COUNT = gql`
  query partyCount($filter: PartyFilterInput) {
    partyCount(filter: $filter)
  }
`;

export const GET_PARTY_DESIGNATIONS = gql`
  query partyDesignations {
    partyDesignations {
      id
      name
      isSelectable
    }
  }
`;

export const PARTY_CREATE_MUTATION = gql`
  mutation partyCreate(
    $name: String!
    $alias: String
    $type: PartyTypeEnum!
    $designationId: ID!
    $streetAddress: String
    $streetAddress2: String
    $addressCity: String
    $addressState: String
    $addressZip: String
    $addressCountryId: ID
    $isActive: Boolean = true
    $divisionOwnershipId: ID
    $focalPointUserId: ID
    $notes: String
    $twitterUsername: String
    $linkedInVanityName: String
    $websiteUrl: String
    $partyRelationshipPartyIdsToAdd: [ID!]
  ) {
    partyCreate(
      name: $name
      alias: $alias
      type: $type
      designationId: $designationId
      streetAddress: $streetAddress
      streetAddress2: $streetAddress2
      addressCity: $addressCity
      addressState: $addressState
      addressZip: $addressZip
      addressCountryId: $addressCountryId
      isActive: $isActive
      divisionOwnershipId: $divisionOwnershipId
      focalPointUserId: $focalPointUserId
      notes: $notes
      twitterUsername: $twitterUsername
      linkedInVanityName: $linkedInVanityName
      websiteUrl: $websiteUrl
      partyRelationshipPartyIdsToAdd: $partyRelationshipPartyIdsToAdd
    ) {
      id
    }
  }
`;

export const PARTY_UPDATE_MUTATION = gql`
  mutation partyUpdate(
    $id: ID!
    $name: String
    $alias: String
    $type: PartyTypeEnum
    $designationId: ID
    $streetAddress: String
    $streetAddress2: String
    $addressCity: String
    $addressState: String
    $addressZip: String
    $addressCountryId: ID
    $isActive: Boolean
    $divisionOwnershipId: ID
    $focalPointUserId: ID
    $notes: String
    $twitterUsername: String
    $linkedInVanityName: String
    $websiteUrl: String
    $partyRelationshipPartyIdsToAdd: [ID!]
    $partyRelationshipPartyIdsToRemove: [ID!]
  ) {
    partyUpdate(
      id: $id
      name: $name
      alias: $alias
      type: $type
      designationId: $designationId
      streetAddress: $streetAddress
      streetAddress2: $streetAddress2
      addressCity: $addressCity
      addressState: $addressState
      addressZip: $addressZip
      addressCountryId: $addressCountryId
      isActive: $isActive
      divisionOwnershipId: $divisionOwnershipId
      focalPointUserId: $focalPointUserId
      notes: $notes
      twitterUsername: $twitterUsername
      linkedInVanityName: $linkedInVanityName
      websiteUrl: $websiteUrl
      partyRelationshipPartyIdsToAdd: $partyRelationshipPartyIdsToAdd
      partyRelationshipPartyIdsToRemove: $partyRelationshipPartyIdsToRemove
    ) {
      id
    }
  }
`;

export const PARTY_STOCK_EXCHANGE_CHANGE = gql`
  mutation partyStockExchangeChange(
    $partyId: ID!
    $partyStockExchangesToAdd: [PartyStockExchangeAddInput!]
    $partyStockExchangesToModify: [PartyStockExchangeModifyInput!]
    $partyStockExchangeIdsToRemove: [ID!]
  ) {
    partyStockExchangeChange(
      partyId: $partyId
      partyStockExchangesToAdd: $partyStockExchangesToAdd
      partyStockExchangesToModify: $partyStockExchangesToModify
      partyStockExchangeIdsToRemove: $partyStockExchangeIdsToRemove
    ) {
      eventType
    }
  }
`;

export const PARTY_COMMENT_CREATE = gql`
  mutation partyCommentCreate($partyId: ID!, $comment: String!, $application: Application) {
    partyComment(partyId: $partyId, comment: $comment, application: $application) {
      ...IPartyEvent
    }
  }
  ${IPartyEvent}
`;

export const PARTY_COMMENT_REPLY_CREATE = gql`
  mutation partyCommentReplyCreate(
    $partyId: ID!
    $comment: String!
    $refEventId: ID!
    $application: Application
  ) {
    partyCommentReply(
      partyId: $partyId
      comment: $comment
      refEventId: $refEventId
      application: $application
    ) {
      ...IPartyEvent
    }
  }
  ${IPartyEvent}
`;
