import { gql } from '@apollo/client';
import { IDocumentFile, IDocumentVersion } from './fragments';

export const DOCUMENT_VERSION_CREATE = gql`
  mutation documentVersionCreate(
    $comment: String
    $documentId: ID!
    $uploadedFile: UploadedFileInput!
  ) {
    contract_documentVersionCreate(
      comment: $comment
      documentId: $documentId
      uploadedFile: $uploadedFile
    ) {
      ...IDocumentVersion
    }
  }
  ${IDocumentVersion}
`;

export const DOCUMENT_SUPPORT_FILE_CREATE = gql`
  mutation documentSupportFileCreate($documentId: ID!, $uploadedFile: UploadedFileInput!) {
    contract_documentSupportFileCreate(documentId: $documentId, uploadedFile: $uploadedFile) {
      ...IDocumentFile
    }
  }
  ${IDocumentFile}
`;

export const DOCUMENT_SUPPORT_FILE_DELETE = gql`
  mutation documentSupportFileDelete($documentId: ID!, $documentFileId: ID!) {
    contract_documentSupportFileDelete(documentId: $documentId, documentFileId: $documentFileId)
  }
`;

export const DOCUMENT_VERSION_SOFT_DELETE = gql`
  mutation documentVersionSoftDelete(
    $documentId: ID!
    $documentFileId: ID!
    $version: Int!
    $comment: String
  ) {
    contract_documentVersionSoftDelete(
      documentId: $documentId
      documentFileId: $documentFileId
      version: $version
      comment: $comment
    )
  }
`;

export const DOCUMENT_SOFT_DELETE = gql`
  mutation documentDelete($documentId: ID!) {
    contract_documentDelete(documentId: $documentId)
  }
`;

export const DOCUMENT_CANCEL = gql`
  mutation documentCancel($documentId: ID!) {
    contract_documentCancel(documentId: $documentId) {
      eventType
    }
  }
`;
