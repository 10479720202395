import React, { FC, useRef } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import { IconButton, InputBase, InputBaseProps, Paper, Theme } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

export interface ISearchFieldProps extends InputBaseProps {
  onSearch: any;
  onChange: any;
}

export const SearchField: FC<ISearchFieldProps> = ({ onSearch, onChange, ...props }) => {
  const classes = useStyles();
  const inputRef = useRef<any>();

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            inputRef?.current && inputRef?.current.blur();
          }
        }}
        inputRef={inputRef}
        {...props}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        onClick={debounce(onSearch, DEBOUNCE_TIMEOUT)}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
