import { ApolloError } from '@apollo/client';
import { apolloErrorTexts } from './apolloErrorHandler';

export const tryUpdateProcedure = async ({
  mutation,
  parseResult,
}: {
  mutation: any;
  parseResult: any;
}) => {
  let isError = false;
  let result: any;
  let foundErrors;
  try {
    const { data, errors } = await mutation();
    foundErrors = errors;
    isError = !!errors;
    result = parseResult(data);
  } catch (error) {
    foundErrors = apolloErrorTexts(error as ApolloError);
    isError = true;
  }
  return { result, isError, errors: foundErrors };
};
