import { useQuery } from '@apollo/client';
import { GET_DOCUMENTS_USERS } from 'graphql/legalFolders/users';
import { useEffect, useState } from 'react';
import { sortByName } from 'utils/functions';

export interface IUsersArrayItem {
  discipline: string;
  division: any;
  email: string;
  id: string;
  name: string;
}

export const useReviewers = () => {
  const [usersArray, setUsersArray] = useState<IUsersArrayItem[]>([]);
  const {
    data: { users } = {
      users: [],
    },
    loading,
  } = useQuery(GET_DOCUMENTS_USERS, {
    variables: { take: 2000, userFilter: { isActive: true } },
  });

  useEffect(() => {
    if (!loading && users) {
      const tmp = [];
      for (const u of users) {
        const usr = {
          discipline: u.discipline,
          division: u.division,
          email: u.email,
          id: u.id,
          name: u.name,
        };

        const userAlreadyAdded = tmp.filter((e) => e.email === u.email).length > 0;

        if (!userAlreadyAdded) {
          tmp.push(usr);
        }
      }
      setUsersArray(tmp.sort(sortByName));
    }
  }, [users, loading]);

  return {
    usersArray,
    loading,
  };
};
