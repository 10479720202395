import { useEffect } from 'react';
import { FC } from 'react';
import { useLegalFolder } from 'template/TreeTable/components/Breadcrumbs/legalFolderHook';
import { useProjectFolder } from 'template/TreeTable/components/Breadcrumbs/projectFolderHook';
import { useComponentContext as useTreeComponentContext } from 'template/TreeTable/TreeTableContext';

export interface IDocumentPrintingMeta {
  onReady: () => void;
}
export const ProjectFolderPrintingMeta: FC<IDocumentPrintingMeta> = ({ onReady }) => {
  const { selectedFolder } = useTreeComponentContext();

  const { legalFolderName } = useLegalFolder({ id: selectedFolder?.legalFolderId });
  const { projectFolderName } = useProjectFolder({ id: selectedFolder?.projectFolderId });

  useEffect(() => {
    if (!!legalFolderName && !!projectFolderName) {
      onReady();
    }
  }, [legalFolderName, projectFolderName, onReady]);

  return (
    <div>
      <div>
        <strong>{projectFolderName}</strong> project folder, under{' '}
        <strong>{legalFolderName}</strong> legal folder.
      </div>
      <div>{window.location.href}</div>
    </div>
  );
};
