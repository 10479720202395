import { Button } from '@mui/material';
import React, { FC } from 'react';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const NewProjectFolderButton: FC<any> = ({ ...props }) => {
  const { confirmRequest } = useFormChangedDialogContext();
  const { onNewProjectFolder } = useComponentContext();
  const onAddProjectFolder = () => {
    confirmRequest!((confirmed) => {
      confirmed && onNewProjectFolder && onNewProjectFolder();
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={debounce(onAddProjectFolder, DEBOUNCE_TIMEOUT)}
      {...props}
    >
      New project folder
    </Button>
  );
};
