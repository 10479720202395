import { DocumentStatusFolder } from 'graphql/legalFolders/types/graphql-types';
import { startCase, toLower } from 'lodash';
import { useMemo } from 'react';
import { enumToArray } from 'utils/formats';

export const useStatusFolder = () => {
  const pairs = useMemo(() => {
    const arr = enumToArray(DocumentStatusFolder);
    const pairs = arr.map((item) => ({
      id: item,
      name: startCase(toLower(item)),
    }));
    return pairs;
  }, []);

  return { pairs };
};
