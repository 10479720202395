import { gql } from '@apollo/client';
import { IContractProposal } from './contractProposalsFragments';

export const GET_CONTRACT_PROPOSALS = gql`
  query contractProposalsList(
    $sort: [ContractProposalSort!] = []
    $filter: ContractProposalFilter
    $take: Int
    $skip: Int = 0
  ) {
    contract_proposals(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
    }
  }
`;

export const GET_CONTRACT_PROPOSALS_COUNT = gql`
  query contractProposalsCount($filter: ContractProposalFilter) {
    contract_proposalCount(filter: $filter)
  }
`;

export const GET_CONTRACT_PROPOSAL = gql`
  query contractProposal($id: ID!) {
    contract_proposal(id: $id) {
      ...IContractProposal
    }
  }
  ${IContractProposal}
`;
