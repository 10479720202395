import React, { FC, useState, useEffect } from 'react';
import { Grid, Button, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { useMutation } from '@apollo/client';

import { DocumentObserversNotificationProps } from './interfaces';

import { EasyAutoComplete, ConfirmationDialog, AlertDialog } from 'components';
import { Dialog } from 'interfaces';
import { IObserver } from 'template/LegalFolderDocument/components/Observers/interfaces';
import { DOCUMENT_SEND_OBSERVER_NOTIFICATION } from 'graphql/legalFolders/documents';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

interface IObserverDocumentUser extends IObserver {
  observer: string;
}

export const DocumentObserversNotification: FC<DocumentObserversNotificationProps> = ({
  projectFolderDocument,
  refetchDocument,
  enableSubmit,
  observersChanged,
}: DocumentObserversNotificationProps) => {
  const [selectedObservers, setSelectedObservers] = useState<IObserverDocumentUser[]>([]);
  const [observers, setObservers] = useState<IObserverDocumentUser[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [mutationErrorOpen, setMutationErrorOpen] = useState(false);

  const [selectNextObserver, { loading: loadingSelectedObserver }] = useMutation(
    DOCUMENT_SEND_OBSERVER_NOTIFICATION
  );

  useEffect(() => {
    if (projectFolderDocument?.observers.length > 0) {
      const onlyActive = projectFolderDocument?.observers.filter((e) => e.disabled === false);

      setObservers(
        onlyActive.map((item) => ({
          ...item,
          observer: `${item?.type?.replace(/_/g, ' ') || ''} - ${item?.employeeName}`,
        }))
      );
    }
  }, [projectFolderDocument.observers]);

  const handleCloseConfirmDialog = (confirmation = false): void => {
    if (confirmation) {
      for (const selectedObserver of selectedObservers) {
        selectNextObserver({
          variables: { userId: selectedObserver?.employeeId, documentId: projectFolderDocument.id },
        })
          .then(() => {
            setSelectedObservers([]);
            refetchDocument();
          })
          .catch((e) => {
            setMutationErrorMessage(e?.message);
            setMutationErrorOpen(true);
          });
      }
    } else {
      setSelectedObservers([]);
    }
    setOpenConfirmDialog(false);
    if (!confirmation) setSelectedObservers([]);
  };

  const handleObserverSelection = (value: any): void => {
    setSelectedObservers(value);

    if (observersChanged) observersChanged(value);
  };

  const submitObserverSelection = (): void => {
    setOpenConfirmDialog(true);
  };

  const dialogMessages = (): Dialog[] => {
    let dialog = { title: '', message: '', style: { width: 380 } };

    return selectedObservers.map((selectedObserver) => {
      const observer = `${selectedObserver?.type} - ${selectedObserver?.employeeName}`;
      const style = 'color: #555;';

      dialog = {
        title: `CONFIRMING OBSERVER, <u style="${style}">${observer}</u>`,
        message: `Please confirm you are sending a notification to the observer. ${observer}.`,
        style: { width: 320 },
      };

      return dialog;
    });
  };

  const theme = useTheme();
  const isNotMd = useMediaQuery(theme.breakpoints.up('md'));

  const dialogVariant = (): JSX.Element => {
    return (
      <ConfirmationDialog
        open={openConfirmDialog}
        dialogs={dialogMessages()}
        onClose={handleCloseConfirmDialog}
      />
    );
  };

  return (
    <div className="document-section">
      {dialogVariant()}

      <AlertDialog
        title="Error"
        message={mutationErrorMessage}
        open={mutationErrorOpen}
        onClose={() => {
          setMutationErrorOpen(false);
        }}
      />

      <Grid container spacing={isNotMd ? 0 : 2} alignItems="center" direction="row" item md={12}>
        <Grid item xs={12} sm={8} md={6} lg={8} xl={6}>
          <EasyAutoComplete
            style={{ paddingRight: 20 }}
            items={observers}
            selected={selectedObservers}
            multiple
            textFieldStyle="outlined"
            optionsLabel="observer"
            icon={(option: IObserverDocumentUser): JSX.Element => {
              return <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
            }}
            selectedChanged={handleObserverSelection}
          />
        </Grid>
        {enableSubmit && (
          <Grid item xs={12} sm={4} md={3}>
            <Button
              className="btn-responsive"
              size="small"
              variant="contained"
              color="primary"
              disabled={loadingSelectedObserver || selectedObservers?.length === 0}
              onClick={debounce(submitObserverSelection, DEBOUNCE_TIMEOUT)}
            >
              {loadingSelectedObserver && <CircularProgress size={22} />}
              {!loadingSelectedObserver && 'Send'}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
