import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { PartySortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { TransformUser } from 'utils/TableTransformFunctions';

export const demacPartiesTableHead: IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Party Name',
    sortable: true,
    sortBy: PartySortableColumn.NAME,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'alias',
    numeric: false,
    disablePadding: false,
    label: 'Party Alias',
    sortable: true,
    sortBy: PartySortableColumn.ALIAS,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'projectSetupClientCode',
    numeric: false,
    disablePadding: false,
    label: 'Client Code',
    sortable: true,
    sortBy: PartySortableColumn.CLIENT_CODE,
    filter: 'text',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
  },
  {
    id: 'partyType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    sortable: true,
    sortBy: PartySortableColumn.PARTY_TYPE,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
  },
  {
    id: 'divisionOwnership',
    numeric: false,
    disablePadding: false,
    label: 'D&M Division Ownership',
    sortable: true,
    sortBy: PartySortableColumn.DIVISION_OWNERSHIP_NAME,
    filter: 'dropdown',
    dataCell: {
      wrap: 'nowrap',
    },
    transformFunction: (data) => data || '-',
  },
  {
    id: 'focalPointUser',
    numeric: false,
    disablePadding: false,
    label: 'D&M Client Focal Point',
    sortable: true,
    sortBy: PartySortableColumn.FOCAL_POINT_USER_NAME,
    filter: 'text',
    dataCell: {
      wrap: 'nowrap',
    },
    transformDataCell: TransformUser,
  },
  {
    id: 'streetAddress',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    sortable: true,
    sortBy: PartySortableColumn.ADDRESS,
    filter: 'text',
    dataCell: { wrap: 'box', connected: 'middle' },
  },
  {
    id: 'addressCity',
    numeric: false,
    disablePadding: false,
    label: 'City',
    sortable: true,
    sortBy: PartySortableColumn.CITY,
    filter: 'text',
    dataCell: {
      wrap: 'nowrap',
      connected: 'right',
    },
  },
  {
    id: 'addressState',
    numeric: false,
    disablePadding: false,
    label: 'State',
    sortable: true,
    sortBy: PartySortableColumn.STATE,
    filter: 'text',
    dataCell: {
      align: 'left',
      shrink: 'content',
    },
  },
  {
    id: 'addressZip',
    numeric: false,
    disablePadding: false,
    label: 'ZIP',
    sortable: true,
    sortBy: PartySortableColumn.ZIP,
    filter: 'text',
    dataCell: {
      align: 'center',
      shrink: 'content',
    },
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    label: 'Country',
    sortable: false,
    sortBy: PartySortableColumn.COUNTRY_ID,
    filter: 'dropdown',
    dataCell: { wrap: 'nowrap', connected: 'right' },
  },
  {
    id: 'clientType',
    numeric: false,
    disablePadding: false,
    label: 'Party Type',
    sortable: true,
    sortBy: PartySortableColumn.PARTY_DESIGNATION_ID,
    filter: 'dropdown',
    dataCell: {
      wrap: 'nowrap',
    },
  },
];
