import { useCallback, useState } from 'react';
import { useApolloClient, ApolloError } from '@apollo/client';

import { GET_PROJECT_FOLDERS, GET_PROJECT_FOLDERS_COUNT } from 'graphql/legalFolders/legalFolders';
import { getProjectFolders } from 'graphql/legalFolders/types/getProjectFolders';
import { getProjectFoldersCount } from 'graphql/legalFolders/types/getProjectFoldersCount';

export interface IProcess {
  status: 'stop' | 'run' | 'fin';
  stages: Array<{ key: string; name: string }>;
  progress: { [key: string]: { steps: number; current: number } };
}

const getInitialLoadProjectFoldersStatus: () => IProcess = () => ({
  status: 'stop',
  stages: [
    { key: 'calculate', name: 'Collecting Info' },
    { key: 'getProjectFolders', name: 'Loading Pages' },
  ],
  progress: {
    calculate: { steps: 2, current: 0 },
    getProjectFolders: { steps: 1, current: 0 },
  },
});

export const useLoadProjectFolders = () => {
  const client = useApolloClient();
  const [loadProjectFoldersStatus, setLoadProjectFoldersStatus] = useState<IProcess>(
    getInitialLoadProjectFoldersStatus()
  );

  const setLoadProjectFolderStepCount = useCallback((step: string, count: number) => {
    setLoadProjectFoldersStatus((old) => ({
      ...old,
      progress: { ...old.progress, [step]: { steps: count, current: 0 } },
    }));
  }, []);

  const incLoadProjectFolderStepProgress = useCallback((step: string) => {
    setLoadProjectFoldersStatus((old) => {
      if (old.progress[step].current + 1 < old.progress[step].steps) {
        return {
          ...old,
          progress: {
            ...old.progress,
            [step]: { ...old.progress[step], current: old.progress[step].current + 1 },
          },
        };
      }
      return {
        ...old,
        progress: {
          ...old.progress,
          [step]: {
            ...old.progress[step],
            steps: old.progress[step].steps + 1,
            current: old.progress[step].current + 1,
          },
        },
      };
    });
  }, []);

  const finLoadProjectFolderStepProgress = useCallback((step: string) => {
    setLoadProjectFoldersStatus((old) => ({
      ...old,
      progress: {
        ...old.progress,
        [step]: { ...old.progress[step], current: old.progress[step].steps },
      },
    }));
  }, []);

  const loadProjectFolders = useCallback(
    async ({
      allPages,
      rowsPerPage,
      variables,
    }: {
      variables: any;
      allPages: boolean;
      rowsPerPage: number;
    }) => {
      setLoadProjectFoldersStatus(() => getInitialLoadProjectFoldersStatus());
      setLoadProjectFoldersStatus((old) => ({ ...old, status: 'run' }));

      let result: { data: any; error: ApolloError | undefined };

      if (!allPages) {
        finLoadProjectFolderStepProgress('calculate');

        const { data, error } = await client.query<getProjectFolders>({
          query: GET_PROJECT_FOLDERS,
          variables: { ...variables },
          fetchPolicy: 'network-only',
        });
        finLoadProjectFolderStepProgress('getProjectFolders');

        if (data.contract_containers) {
          result = { data: data?.contract_containers, error };
        } else {
          result = { data: [], error };
        }
      } else {
        incLoadProjectFolderStepProgress('calculate');
        const { data: projectFolderCount, error: projectFolderCountError } =
          await client.query<getProjectFoldersCount>({
            query: GET_PROJECT_FOLDERS_COUNT,
            variables: { ...variables },
          });
        finLoadProjectFolderStepProgress('calculate');

        if (projectFolderCount && !projectFolderCountError) {
          setLoadProjectFolderStepCount(
            'getProjectFolders',
            Math.ceil(projectFolderCount.contract_containerCount / rowsPerPage)
          );
        }

        result = { data: [], error: undefined };
        let lastPage = false;

        for (var pageNo = 0; !lastPage; pageNo++) {
          const { data, error } = await client.query<getProjectFolders>({
            query: GET_PROJECT_FOLDERS,
            variables: { ...variables, take: rowsPerPage, skip: rowsPerPage * pageNo },
          });

          if (error) {
            result.data.error = error;
            break;
          }

          lastPage = !data?.contract_containers?.length;
          if (!lastPage) {
            incLoadProjectFolderStepProgress('getProjectFolders');
            result.data.push(...data.contract_containers);
          }
        }
        finLoadProjectFolderStepProgress('getProjectFolders');
      }

      const updatedResult = {
        ...result,
        data: result.data.map((data: any) => ({
          ...data,
        })),
      };

      setLoadProjectFoldersStatus((old) => ({ ...old, status: 'fin' }));
      return updatedResult;
    },
    [
      client,
      incLoadProjectFolderStepProgress,
      setLoadProjectFolderStepCount,
      finLoadProjectFolderStepProgress,
    ]
  );

  return {
    loadProjectFolders,
    loadProjectFoldersStatus,
  };
};
