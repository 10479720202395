import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { LegalFolderSortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { TransformOwnersWithEmail, TransformStringArray } from 'utils/TableTransformFunctions';

export const relatedLegalFoldersTableHead: IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Legal Folder Name',
    sortable: true,
    sortBy: LegalFolderSortableColumn.NAME,
    dataCell: {
      shrink: 'content',
    },
  },
  {
    id: 'owners',
    numeric: false,
    disablePadding: false,
    label: 'Owners',
    dataCell: {
      shrink: 'content',
      align: 'left',
    },

    transformDataCell: TransformOwnersWithEmail,
  },
  {
    id: 'parties',
    numeric: false,
    disablePadding: false,
    label: 'Other Parties',

    dataCell: {
      shrink: 'content',
      align: 'left',
    },

    transformDataCell: TransformStringArray,
  },
  {
    id: 'currentUserHasAccessRights',
    numeric: false,
    disablePadding: false,
    label: 'Your Access',

    dataCell: {
      shrink: 'content',
      align: 'left',
    },

    transformFunction: (data, row) => {
      if (data === true) {
        return 'Allowed';
      }

      if (data === false) {
        return 'Not Allowed';
      }

      return 'N/A';
    },
  },
];
