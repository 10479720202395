import { useAutoCompleteDataSource } from './autoCompleteDataSource';

import {
  getLegalFolders,
  getLegalFolders_contract_legalFolders,
} from 'graphql/legalFolders/types/getLegalFolders';
import { getLegalFoldersCount } from 'graphql/legalFolders/types/getLegalFoldersCount';
import { LegalFolderFilter } from 'graphql/legalFolders/types/graphql-types';

import {
  GET_LEGAL_FOLDERS_COUNT,
  GET_LEGAL_FOLDERS_NAMES,
} from 'graphql/legalFolders/legalFolders';

const getCount = (count: getLegalFoldersCount) => count.contract_legalFolderCount;
const getItems = (data: getLegalFolders) => data.contract_legalFolders;
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ nameContains: localSearch });

export interface ILegalFoldersNamesAutocompleteDataHook {
  search: string;
}

export const useLegalFoldersNamesAutocompleteDataHook = ({
  search,
}: ILegalFoldersNamesAutocompleteDataHook) => {
  const resp = useAutoCompleteDataSource<
    getLegalFolders,
    getLegalFoldersCount,
    getLegalFolders_contract_legalFolders,
    LegalFolderFilter
  >({
    GET_QUERY: GET_LEGAL_FOLDERS_NAMES,
    GET_COUNT_QUERY: GET_LEGAL_FOLDERS_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};
