import { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { Box } from '@mui/material';

// API
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import { getProjectFolders } from 'graphql/legalFolders/types/getProjectFolders';
import { getProjectFoldersCount } from 'graphql/legalFolders/types/getProjectFoldersCount';

// Custom Components
import { paths } from 'constants/index';

// Utilities
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';

// Specific styles
import './index.scss';
import s from './style.module.scss';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';

import { IPageLoadParams } from './interface';
import {
  GET_PROJECT_FOLDERS,
  GET_PROJECT_FOLDERS_COUNT,
  GET_PROJECT_FOLDERS_TOTAL_COUNTERS,
} from 'graphql/legalFolders/legalFolders';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { ContainerFilter } from 'graphql/legalFolders/types/graphql-types';

// Prepared to filter by owner
// import { usersList, usersList_users } from 'graphql/legalFolders/types/usersList';
// import { GET_USERS } from 'graphql/legalFolders/users';

import { Table } from 'components/ui/Table/Table';
import { demacProjectFoldersTableHead } from 'constants/projectFoldersTable';

import { GET_CONTRACTS_DIVISIONS } from 'graphql/legalFolders/divisions';
import { contractsDivisionsList } from 'graphql/legalFolders/types/contractsDivisionsList';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useUI } from 'contexts/UiContext';

import { useLegalFolder } from 'template/TreeTable/components/Breadcrumbs/legalFolderHook';
import { useComponentContext as useTreeTableComponentContext } from 'template/TreeTable/TreeTableContext';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { getProjectFoldersTotalCounters } from 'graphql/legalFolders/types/getProjectFoldersTotalCounters';

export interface IProjectFoldersTemplateProps {
  legalFolderId: string;
}

export const ProjectFoldersTemplate: FC<IProjectFoldersTemplateProps> = ({ legalFolderId }) => {
  const history = useHistory();
  const { addSnackbar } = useUI();
  const { myTask } = useTreeTableComponentContext();
  const { setItem, getItem } = useTableStorage({
    key: 'ProjectFolders',
  });

  const [projectFolders, setProjectFolders] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemsCounters, setTotalItemsCounters] = useState<getProjectFoldersTotalCounters>({
    inaccessible: 0,
    total: 0,
    userOnly: 0,
  });

  const { legalFolderName } = useLegalFolder({ id: legalFolderId });

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: getItem().rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filter: {
      legalFolderIds: [legalFolderId],
      onlyCurrentUserDocuments: myTask,
    },
  });

  useEffect(() => {
    const oldValue = getItem().rowsPerPage;
    if (pageLoadParams.rowsPerPage !== oldValue) {
      setItem({ rowsPerPage: pageLoadParams.rowsPerPage });
    }
  }, [pageLoadParams.rowsPerPage, setItem, getItem]);

  const [
    loadLegalFoldersCount,
    {
      data: dataLegalFoldersCount,
      loading: loadingLegalFoldersCount,
      refetch: refetchLegalFoldersCount,
      called: calledLegalFoldersCount,
      error: errorLegalFoldersCount,
    },
  ] = useLazyQuery<getProjectFoldersCount>(GET_PROJECT_FOLDERS_COUNT);

  const [
    loadLegalFoldersTotalCounters,
    {
      data: dataLegalFoldersTotalCounters,
      loading: loadingLegalFoldersTotalCounters,
      refetch: refetchLegalFoldersTotalCounters,
      called: calledLegalFoldersTotalCounters,
      error: errorLegalFoldersTotalCounters,
    },
  ] = useLazyQuery<getProjectFoldersTotalCounters>(GET_PROJECT_FOLDERS_TOTAL_COUNTERS);

  const { data: divisionsData } = useQuery<contractsDivisionsList>(GET_CONTRACTS_DIVISIONS);

  const [loadLegalFolders, { called, data, loading, refetch, error }] =
    useLazyQuery<getProjectFolders>(GET_PROJECT_FOLDERS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    setPageLoadParams((old) => ({
      ...old,
      page: 0,
      filter: { ...old.filter, onlyCurrentUserDocuments: myTask },
    }));
  }, [myTask]);

  useEffect(() => {
    if (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
  }, [loading, error, addSnackbar]);

  useEffect(() => {
    if (errorLegalFoldersCount) {
      apolloErrorHandler(addSnackbar!)(errorLegalFoldersCount);
    }
  }, [loadingLegalFoldersCount, errorLegalFoldersCount, addSnackbar]);

  useEffect(() => {
    if (!loadingLegalFoldersCount && dataLegalFoldersCount) {
      setTotalItems(dataLegalFoldersCount.contract_containerCount);
    }
  }, [loadingLegalFoldersCount, dataLegalFoldersCount]);

  useEffect(() => {
    if (errorLegalFoldersTotalCounters) {
      apolloErrorHandler(addSnackbar!)(errorLegalFoldersTotalCounters);
    }
  }, [loadingLegalFoldersTotalCounters, errorLegalFoldersTotalCounters, addSnackbar]);

  useEffect(() => {
    if (!loadingLegalFoldersTotalCounters && dataLegalFoldersTotalCounters) {
      setTotalItemsCounters(dataLegalFoldersTotalCounters);
    }
  }, [loadingLegalFoldersTotalCounters, dataLegalFoldersTotalCounters]);

  useEffect(() => {
    if (!loading && data) {
      setProjectFolders(
        data.contract_containers?.map((projectFolder) => {
          const {
            id,
            name,
            endDate: expirationDate,
            updatedAt: lastModifiedDate,
            documentCount,
            owners,
            createdAt,
            documentsDivisions,
            projects,
          } = projectFolder;
          return {
            id,
            name,
            documentCount,
            expirationDate,
            lastModifiedDate,
            ownerNames: owners?.map((owner) => owner.user?.name),
            createdAt,
            documentsDivisions,
            projects,
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    const { rowsPerPage, page, orderBy, order, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter,
    };

    if (called) {
      refetch!(variables);
    } else {
      loadLegalFolders({ variables });
    }
  }, [loadLegalFolders, refetch, called, pageLoadParams]);

  useEffect(() => {
    const { filter } = pageLoadParams;
    const variables = {
      filter,
    };
    if (calledLegalFoldersCount) {
      refetchLegalFoldersCount!(variables);
    } else {
      loadLegalFoldersCount({ variables });
    }
  }, [loadLegalFoldersCount, calledLegalFoldersCount, refetchLegalFoldersCount, pageLoadParams]);

  useEffect(() => {
    const { filter } = pageLoadParams;
    const variables = {
      totalFilter: { ...filter, onlyCurrentUserDocuments: false },
      userOnlyFilter: { ...filter, onlyCurrentUserDocuments: true },
      inaccessibleFilter: { ...filter, onlyCurrentUserDocuments: false },
    };
    if (calledLegalFoldersTotalCounters) {
      refetchLegalFoldersTotalCounters!(variables);
    } else {
      loadLegalFoldersTotalCounters({ variables });
    }
  }, [
    loadLegalFoldersTotalCounters,
    calledLegalFoldersTotalCounters,
    refetchLegalFoldersTotalCounters,
    pageLoadParams,
  ]);

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onLegalFolderSelect = useCallback(
    (id: string) => {
      const newUrl = paths.treeViewClient.TREEVIEW_PROJECT_FOLDER.replace(':id', id);
      history.push(newUrl);
      return true;
    },
    [history]
  );

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const newFilter: ContainerFilter = {
          nameContains: filterValues.name || undefined,
          createdAt: filterValues.createdAt || undefined,
          updatedAt: filterValues.lastModifiedDate || undefined,
          endDate: filterValues.expirationDate || undefined,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams]
  );

  const filterOptions = useMemo(() => {
    const getDivisionsPairs = () => {
      return (
        divisionsData?.divisions.map(({ id, name }) => {
          return {
            id,
            name,
          };
        }) || []
      );
    };

    // Prepared to filter by Owner
    // const getOwnersPairs = () => {
    //   return (
    //     ownersData?.users.map(({ id, name }) => {
    //       return {
    //         id,
    //         name,
    //       };
    //     }) || []
    //   );
    // };

    return {
      divisions: [{ id: 'All', name: 'All' }, ...getDivisionsPairs()],
      // Prepared to filter by Owner
      // ownerName: [{ id: 'All', name: 'All' }, ...getOwnersPairs()],
    };
  }, [
    divisionsData,

    // Prepared to filter by Owner
    // ,ownersData
  ]);

  const filterValues = {
    divisions: { id: 'All', name: 'All' },
  };

  const warningMessage = useMemo(() => {
    const { inaccessible, total, userOnly } = totalItemsCounters;
    const hasInaccessableItems = inaccessible > total;
    const hasHiddenItems = total > userOnly && myTask;
    const messages = [];
    if (hasInaccessableItems) {
      messages.push("Legal folder has more project folders but you don't have access to them.");
    }
    if (hasHiddenItems) {
      messages.push(
        `My Task is active and ${total - userOnly} project folder${
          total - userOnly > 1 ? 's are' : ' is'
        } hidden.`
      );
    }
    return messages.join(' ');
  }, [totalItemsCounters, myTask]);

  return (
    <div style={{ margin: '10px' }}>
      <LoadingOverlay spinner active={loading} text="Loading your content...">
        <div style={{ padding: '10px' }}>
          Viewing Project Folders for <strong>{legalFolderName}</strong> Legal Folder
        </div>
        <Box
          style={{
            borderRadius: '1px',
            boxShadow: '0px 2px 5px #00000030',
            border: '1px solid #00000030',
          }}
        >
          <Table
            totalItems={totalItems}
            dataCells={projectFolders}
            headCells={demacProjectFoldersTableHead}
            loadPage={loadPage}
            handleSelect={onLegalFolderSelect}
            filterOptions={filterOptions}
            filterValues={filterValues}
            onFilterChange={onFilterChange}
            initRowsPerPage={pageLoadParams.rowsPerPage}
            hideFilters
          ></Table>
        </Box>
        <div className={s.messageBox}>{warningMessage}</div>
      </LoadingOverlay>
    </div>
  );
};
