import { useLazyQuery } from '@apollo/client';
import { EasyAutoComplete } from 'components';
import { GET_CONTRACTS_DIVISIONS } from 'graphql/legalFolders/divisions';
import { contractsDivisionsList } from 'graphql/legalFolders/types/contractsDivisionsList';

import React, { FC, useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';

const rowsPerPage = 999;
const order = [{ column: 'NAME', order: 'ASC' }];

interface IDivisionsListItem {
  key: string;
  name: string;
}

export interface ISelectDivisionProps {
  onSelectChange?: (selectedItems: IDivisionsListItem[]) => void;
  selectedItems: IDivisionsListItem[];
  [key: string]: any;
}

export const SelectDivision: FC<ISelectDivisionProps> = ({
  onSelectChange,
  selectedItems,
  ...props
}) => {
  const [search, setSearch] = useState('');
  const [divisions, setDivisions] = useState<IDivisionsListItem[]>([]);

  const [getDivisionNames, { data, loading, refetch, called, error }] =
    useLazyQuery<contractsDivisionsList>(GET_CONTRACTS_DIVISIONS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadPage = useCallback(
    (page: number, search: string) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: order && order.length ? order : undefined,
      };

      if (called) {
        refetch!(variables);
      } else {
        getDivisionNames({ variables });
      }
    },
    [getDivisionNames, refetch, called]
  );

  useEffect(() => {
    if (data && !loading && !error) {
      const ddList = data.divisions.map((division) => {
        return { key: division.id, name: division.name };
      });
      setDivisions((divisions) => {
        return [...divisions, ...ddList];
      });
    }
  }, [data, loading, error]);

  useEffect(() => {
    loadPage(0, '');
  }, [loadPage]);

  const maxPage = 1;

  const divisionsIds = useMemo(() => {
    return divisions.map((division) => division.key);
  }, [divisions]);

  return (
    <EasyAutoComplete
      multiple
      items={[...divisions, ...selectedItems.filter((item) => !divisionsIds.includes(item.key))]}
      selected={selectedItems}
      label=""
      textFieldStyle="outlined"
      optionsLabel="name"
      selectedChanged={(value: any): void => {
        onSelectChange && onSelectChange(value);
      }}
      pagination={{
        pages: maxPage,
        loadPage: (page: number) => {
          loadPage(page, search);
        },
      }}
      onInputChange={(event: object, value: string, reason: string) => {
        setSearch(value);
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.key === value.key;
      }}
      {...props}
    />
  );
};
