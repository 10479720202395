import { NewDocument } from './components/NewDocument/NewDocument';
import { ProjectDocuments } from './components/ProjectDocuments/ProjectDocuments';
import { ViewDocumentFileManagement } from './components/ViewDocumentFileManagement/ViewDocumentFileManagement';
import { ViewDocumentInfo } from './components/ViewDocumentInfo/ViewDocumentInfo';
import { useComponentContext } from './DocumentsContext';

export interface IDocumentViewProps {}

export const DocumentsView = () => {
  const { currentView } = useComponentContext();

  switch (currentView) {
    case 'new':
      return <NewDocument></NewDocument>;
    case 'edit-info':
      return <ViewDocumentInfo></ViewDocumentInfo>;
    case 'edit-fm':
      return <ViewDocumentFileManagement></ViewDocumentFileManagement>;
  }
  return <ProjectDocuments></ProjectDocuments>;
};
