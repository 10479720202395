/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';

import { id } from 'utils/id';

import { azureStorageUpload } from 'utils/azureStorageUpload';
import { DOCUMENT_VERSION_CREATE } from 'graphql/legalFolders/fileManager';

export const useNewDocumentVersion = ({
  document,
  refetchDocument,
  onUploadStart,
  onUploadEnd,
}: any): any => {
  const [newDocument, setNewDocument] = useState(false);
  const [comment, setComment] = useState('');
  const [requireReapprovalFromAllStakeHolders, setRequireReapprovalFromAllStakeHolders] =
    useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [files, setFiles] = useState<Record<string, any>[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [documentVersionCreate, { loading: isUploading }] = useMutation(DOCUMENT_VERSION_CREATE);

  const apolloClient = useApolloClient();

  const handleNewDocument = useCallback(
    (value: any): void => {
      setNewDocument(value);
    },
    [setNewDocument]
  );

  const handleComment = (event: any): void => {
    setComment(event.target.value);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles(acceptedFiles);
  }, []);

  const removeFile = useCallback(() => {
    setFiles([]);
  }, []);

  const handleOpenConfirmDialog = (): void => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = async (result: any): Promise<void> => {
    setOpenConfirmDialog(false);

    if (result === false) return;

    if (files) {
      const file: any = files[0];

      onUploadStart();

      const uploadResult = await azureStorageUpload(
        apolloClient,
        'VERSION',
        document?.id,
        file.name,
        file
      );

      // TODO, how to handle this?
      if (uploadResult.hasErrors) {
        onUploadEnd({ hasErrors: true, errorMessage: uploadResult.errorMessage });
        // displayError(uploadResult.errorMessage);
        return;
      }

      onUploadEnd({ hasErrors: false, errorMessage: '' });

      const uploadedFile = {
        blobContainerName: uploadResult.containerName,
        blobName: uploadResult.blobName,
        fileFilename: uploadResult.fileFilename,
        fileMimeType: uploadResult.fileMimeType,
      };

      documentVersionCreate({
        variables: {
          comment,
          documentId: document?.id,
          uploadedFile,
        },
      }).then(() => {
        removeFile();
        setComment('');
        setRequireReapprovalFromAllStakeHolders(false);
        refetchDocument && refetchDocument({ variables: { id: id() } });
      });
    }
  };

  const handleRequireReapprovalFromAllStakeHolders = (): void => {
    setRequireReapprovalFromAllStakeHolders((prev) => !prev);
  };

  return {
    isUploading,
    percentage,
    files,
    onDrop,
    removeFile,
    openConfirmDialog,
    setOpenConfirmDialog,
    requireReapprovalFromAllStakeHolders,
    setRequireReapprovalFromAllStakeHolders,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    handleRequireReapprovalFromAllStakeHolders,
    comment,
    handleComment,
    newDocument,
    handleNewDocument,
  };
};
