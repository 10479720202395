import { gql } from '@apollo/client';
import { division } from '../fragments';

const divisionQueries = {
  GET_DIVISION: gql`
  query($id: ID!) {
    division(id: $id) {
      ${division}
    }
  }
`,

  GET_DIVISIONS: gql`
  query GetProject($take: Int = 100, $skip: Int = 0) {
    divisions(take: $take, skip: $skip) {
      ${division}
    }
  }
`,
};

export default divisionQueries;
