import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { MonetaryTransactionType } from 'graphql/legalFolders/types/graphql-types';
import { IMonetaryTransaction } from 'template/LegalFolderDocument/components/DocumentView/components/MonetaryTransactions/MonetaryTransactions';
import { capitalizeFirstLetter, formatCurrencyUSD } from 'utils/formats';
import { TransformDocumentStatusText } from 'utils/TableTransformFunctions';

export const allDocumentsExportTableHead: IHeadCell[] = [
  {
    id: 'legalFolder',
    numeric: false,
    disablePadding: false,
    label: 'Legal Folder',
    transformFunction: (data: any | undefined, row: any) => {
      return row?.container?.legalFolder?.name || '--';
    },
  },
  {
    id: 'legalFolder.owners',
    numeric: false,
    disablePadding: false,
    label: 'Legal Folder Owners',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data, row) => {
      return (
        row?.container?.legalFolder?.owners
          ?.map((owner: any) => owner.user.name)
          .join(String.fromCharCode(10)) || '--'
      );
    },
  },
  {
    id: 'legalFolder.members',
    numeric: false,
    disablePadding: false,
    label: 'Legal Folder Members',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data, row) => {
      return (
        row?.container?.legalFolder?.members
          ?.map((member: any) => member.user.name)
          .join(String.fromCharCode(10)) || '--'
      );
    },
  },
  {
    id: 'legalFolder.parties',
    numeric: false,
    disablePadding: false,
    label: 'Other Party',
    transformFunction: (data, row) => {
      return row?.container?.legalFolder?.parties?.length
        ? row?.container?.legalFolder?.parties
            ?.map((party: any) =>
              party ? party.projectSetupClientCode + ' - ' + party.name : undefined
            )
            .filter((party: any) => !!party)
            .join(String.fromCharCode(10))
        : '--';
    },
  },

  {
    id: 'projectFolder',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder',
    transformFunction: (data, row) => {
      return row?.container?.name || '--';
    },
  },
  {
    id: 'projectFolder.startDate',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder Start Date',
    transformFunction: (data, row) => {
      if (!row?.container?.startDate) {
        return '--';
      }
      return new Date(row?.container?.startDate).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
  },
  {
    id: 'projectFolder.endDate',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder End Date',
    transformFunction: (data, row) => {
      if (!row?.container?.endDate) {
        return '--';
      }
      return new Date(row?.container?.endDate).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
  },

  {
    id: 'projectFolder.owners',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder Owners',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data, row) => {
      return (
        row?.container?.owners
          ?.map((owner: any) => owner.user.name)
          .join(String.fromCharCode(10)) || '--'
      );
    },
  },
  {
    id: 'projectFolder.members',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder Members',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data, row) => {
      return (
        row?.container?.members
          ?.map((member: any) => member.user.name)
          .join(String.fromCharCode(10)) || '--'
      );
    },
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Document',
  },
  {
    id: 'divisions',
    numeric: false,
    disablePadding: false,
    label: 'Divisions',
    transformFunction: (data, row, order, sorted) => {
      if (!data?.length) return '--';
      if (!sorted) return data.map((item: { name: string }) => item.name).join(', ');
      if (order === SortOrder.ASC) {
        return data
          .map((item: { name: string }) => item.name)
          .sort()
          .join(', ');
      }
      return data
        .map((item: { name: string }) => item.name)
        .sort()
        .reverse()
        .join(', ');
    },
  },
  {
    id: 'documentType',
    numeric: false,
    disablePadding: false,
    label: 'Type',

    transformFunction: (data) => {
      return data?.name || '--';
    },
  },
  {
    id: 'pendingReviewers',
    numeric: false,
    disablePadding: false,
    label: 'Currently Reviewing',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) => {
      if (!data) {
        return '--';
      }
      const dataNames: string[] = data
        ? data.map((item: any) => item?.user?.name || 'Unknown User')
        : undefined;
      return dataNames.join(String.fromCharCode(10));
    },
  },
  {
    id: 'reviewers',
    numeric: false,
    disablePadding: false,
    label: 'Reviewers',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) => {
      if (!data) {
        return '--';
      }
      const dataNames: string[] = data
        ? data.map((item: any) => item?.user?.name || 'Unknown User')
        : undefined;
      return dataNames.join(String.fromCharCode(10));
    },
  },
  {
    id: 'statusFolder',
    numeric: false,
    disablePadding: false,
    label: 'Status Folder',
    transformFunction: (data: string) => capitalizeFirstLetter(data.toLowerCase()),
  },
  {
    id: 'lastModifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Changed',
    transformFunction: (date, row) => {
      if (!row?.updatedAt) {
        return '--';
      }
      return new Date(row?.updatedAt).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
  },
  {
    id: 'ownerNames',
    numeric: false,
    disablePadding: false,
    label: 'Owners',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data, row) => {
      if (!row?.owners?.length) {
        return '--';
      }
      const names: string[] = row?.owners.map((item: any) => item?.user?.name || 'Unknown User');
      return names.join(String.fromCharCode(10));
    },
  },

  // {
  //   id: 'lastActionBy',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Last Action By',
  //   sortable: false,
  //   filter: 'text',
  //   dataCell: {
  //     shrink: 'content',
  //   },
  // },

  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',

    transformFunction: (data, row) => TransformDocumentStatusText({ data, row }),
  },
  {
    id: 'parentDocument',
    numeric: false,
    disablePadding: false,
    label: 'Parent Document',

    transformFunction: (data) => {
      return data?.name || '--';
    },
  },
  {
    id: 'childDocuments',
    numeric: false,
    disablePadding: false,
    label: 'Child Documents',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) => {
      if (!data.length) {
        return '--';
      }
      const items = data.map((item: any) => item.name);
      return items.join(String.fromCharCode(10));
    },
  },
  {
    id: 'projects',
    numeric: false,
    disablePadding: false,
    label: 'Projects Assigned',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) => {
      if (!data.length) {
        return '--';
      }
      const items = data.map((item: any) => item.key + ' - ' + item.name);
      return items.join(String.fromCharCode(10));
    },
  },
  {
    id: 'isOriginParty',
    numeric: false,
    disablePadding: false,
    label: 'Origin',

    transformFunction: (data) => {
      if (data === false) return 'D&M';
      if (data === true) return 'Other Party';
      return '--';
    },
  },
  {
    id: 'isPartyReviewRequired',
    numeric: false,
    disablePadding: false,
    label: 'Other Party Approval',

    transformFunction: (data) => {
      if (data === false) return 'Not required';
      if (data === true) return 'Required';
      return '--';
    },
  },
  {
    id: 'includesIndemnity',
    numeric: false,
    disablePadding: false,
    label: 'Indemnity',

    transformFunction: (data) => {
      return data || '--';
    },
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'Document Start Date',
    transformFunction: (data, row) => {
      if (!data) {
        return '--';
      }
      return new Date(data).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: false,
    label: 'Document End Date',
    transformFunction: (data, row) => {
      if (!data) {
        return '--';
      }
      return new Date(data).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
  },
  {
    id: 'Revenue',
    numeric: false,
    disablePadding: false,
    label: 'Revenue',
    transformFunction: (data, row) => {
      if (row?.monetaryTransactionType !== MonetaryTransactionType.COST) {
        return row?.monetaryTransactionValue
          ? formatCurrencyUSD(row?.monetaryTransactionValue)
          : 'N/A';
      } else {
        return '--';
      }
    },
  },
  {
    id: 'Cost',
    numeric: false,
    disablePadding: false,
    label: 'Cost',
    transformFunction: (data, row) => {
      if (row?.monetaryTransactionType === MonetaryTransactionType.COST) {
        return row?.monetaryTransactionValue
          ? formatCurrencyUSD(row?.monetaryTransactionValue)
          : 'N/A';
      } else {
        return '--';
      }
    },
  },
  {
    id: 'monetaryTransactionBreakdowns',
    numeric: false,
    disablePadding: false,
    label: 'Breakdowns',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data, row) => {
      if (!data?.length) {
        return '--';
      }
      const items = data.map(
        (bd: IMonetaryTransaction) =>
          (bd.monetaryTransactionValue
            ? formatCurrencyUSD(parseFloat(bd.monetaryTransactionValue))
            : '--') +
          '  (Year ' +
          (bd.startTimestamp ? new Date(bd.startTimestamp).getUTCFullYear().toString() : '--') +
          ')'
      );
      return items.join(String.fromCharCode(10));
    },
  },

  {
    id: 'outsideCounsel',
    numeric: false,
    disablePadding: false,
    label: 'Outside Counsel',

    transformFunction: (data) => {
      if (data === false) return 'No';
      if (data === true) return 'Yes';
      return '--';
    },
  },

  {
    id: 'contacts',
    numeric: false,
    disablePadding: false,
    label: 'Contacts',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) =>
      data?.length
        ? data
            .map((contact: any) => {
              const parts: Array<any> = [];

              if (contact.name) {
                parts.push({ name: 'Name', value: contact.name });
              }
              if (contact.email) {
                parts.push({ name: 'Email', value: contact.email });
              }
              if (contact.phone) {
                parts.push({ name: 'Phone', value: contact.phone });
              }

              return parts
                .map((part) => part.name + ': ' + part.value)
                .join(String.fromCharCode(10));
            })
            .filter((contact: any) => !!contact)
            .join(String.fromCharCode(10) + String.fromCharCode(10))
        : '--',
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'Notes',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || '--',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
  {
    id: 'specialInstructions',
    numeric: false,
    disablePadding: false,
    label: 'Special Instructions',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || '--',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
];
