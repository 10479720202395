import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_PROJECT_FOLDER_DOCUMENT_PATH } from 'graphql/legalFolders/documents';
import { projectFolderDocumentPath } from 'graphql/legalFolders/types/projectFolderDocumentPath';

export interface IHook {
  path?: ILegalFolderDocumentPath;
  error: boolean;
}

export interface IUseProjectDocumentPathProps {
  projectDocumentId: string;
}

export interface ILegalFolderDocumentPath {
  legalFolderId?: string;
  projectFolderId?: string;
}
export const useProjectDocumentPath = ({
  projectDocumentId,
}: IUseProjectDocumentPathProps): IHook => {
  const [path, setPath] = useState<ILegalFolderDocumentPath>();

  const { data, loading, error } = useQuery<projectFolderDocumentPath>(
    GET_PROJECT_FOLDER_DOCUMENT_PATH,
    {
      variables: { id: projectDocumentId },
      errorPolicy: 'all',
    }
  );

  useEffect(() => {
    if (data && !loading) {
      setPath({
        legalFolderId: data.contract_document.container?.legalFolder.id,
        projectFolderId: data.contract_document.container?.id,
      });
    }
  }, [data, loading]);
  return {
    path,
    error: !!error,
  };
};
