/* eslint-disable camelcase */
import React, { FC, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  AppBar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Snackbar,
  Box,
  useTheme,
  useMediaQuery,
  Collapse,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  ListAltRounded as ListAltRoundedIcon,
  Home as HomeIcon,
  FolderOpen as FolderOpenIcon,
  Assessment as AssesmentIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

import { ExitToApp as ExitToAppIcon } from '@mui/icons-material';

import { grey } from '@mui/material/colors';

import { Logo } from '../../images';

import './index.scss';
import { UserInitials } from '..';

import { AccountIcon } from '../../images/icons';
import { FileDarkIcon } from 'images/icons';

import cn from 'classnames';
import { paths } from '../../constants';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

import { getMsal1Account, msalLogout } from 'authorization/auth-utils-msal2';
import { useMsalAccount } from 'hooks/msalAccount';

const Header: FC<any> = () => {
  const { msalAccount: currentAccount } = useMsalAccount();

  const { checkAndProceed } = useFormChangedDialogContext();
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [autoLogoffMessageShown, setAutoLogoffMessageShown] = useState(false);
  const [reportsExpanded, setReportsExpanded] = useState(false);

  const [loggedUserAccount, setLoggedUserAccount] = useState(currentAccount);
  const [userEmail, setUserEmail] = useState('');

  const history = useHistory();
  const { pathname } = useLocation();

  const [reportsAnchorEl, setReportsAnchorEl] = React.useState<null | HTMLElement>(null);
  const openReportsMenu = Boolean(reportsAnchorEl);

  const firstTab = useMemo(() => {
    return pathname.split('/')[1];
  }, [pathname]);

  const tabLegalFoldersActive = useMemo(
    () =>
      [
        'project-folders',
        'project-folder',
        'project-folder-document',
        'new-project-folder',
      ].includes(firstTab),
    [firstTab]
  );

  const tabPartiesActive = useMemo(
    () => ['parties-list', 'party', 'new-party'].includes(firstTab),
    [firstTab]
  );
  const tabDocumentsActive = useMemo(() => ['dashboard'].includes(firstTab), [firstTab]);
  const tabReportsActive = useMemo(() => ['reports'].includes(firstTab), [firstTab]);

  const gotoHome = (): void => {
    history.push('/');
  };

  const gotoLegalFolders = (): void => {
    history.push(paths.client.DASHBOARD);
  };

  const gotoParties = (): void => {
    history.push(paths.client.PARTIES);
  };

  const gotoReports = (event: Record<string, any>): void => {
    setReportsAnchorEl(event.currentTarget);
  };

  const handleCloseReportsMenu = () => {
    setReportsAnchorEl(null);
  };

  const gotoDocuments = (): void => {
    history.push(paths.client.DOCUMENTS);
  };

  const gotoDocumentsForPartyApproval = (): void => {
    setUserDrawerOpen(false);
    handleCloseReportsMenu();
    history.push(paths.client.REPORT_DOCUMENTS_NOT_SIGNED_BY_ALL_PARTIES);
  };

  const gotoProjectFoldersMissingProject = (): void => {
    setUserDrawerOpen(false);
    handleCloseReportsMenu();
    history.push(paths.client.REPORT_PROJECT_FOLDERS_WO_PROJECTS);
  };

  const openUserMenu = (): void => {
    setUserDrawerOpen(true);
  };

  const closeUserMenu = (): void => {
    setUserDrawerOpen(false);
  };

  const logout = useCallback((): void => {
    msalLogout();
  }, []);

  let autoLogoutTimeoutId = useRef(-1);
  let autoLogoutMessageTimeoutId = useRef(-1);

  const displayAutoLogoutWarning = useCallback(
    (location: any) => {
      clearTimeout(autoLogoutMessageTimeoutId.current);
      clearTimeout(autoLogoutTimeoutId.current);
      setAutoLogoffMessageShown(false);

      if (location && location.pathname && location.pathname.toLowerCase() === '/login') {
        setAutoLogoffMessageShown(false);
      } else {
        autoLogoutMessageTimeoutId.current = window.setTimeout(() => {
          setAutoLogoffMessageShown(true);

          autoLogoutTimeoutId.current = window.setTimeout(() => {
            logout();
          }, 1000 * 60 * 5); // logout 5 minutes after message is shown
        }, 1000 * 60 * 235); // show message after 235 minutes (4 hours - 5 minutes) of inactivity
      }
    },
    [logout]
  );

  const getLoggedInUserEmail = (): any => {
    return (
      <Typography
        variant="subtitle2"
        style={{ display: 'inline-block', padding: '1rem', color: '#85A278' }}
      >
        {userEmail}
      </Typography>
    );
  };

  useEffect((): void => {
    // #Zoran, uncomment if auto renew token is wanted
    // Token expiry is set to 4 hours, so it is not needed
    // setInterval(() => renewToken(), 1000 * 120); // try to auto renew token every 2 minutes

    displayAutoLogoutWarning(history.location);

    // todo: check dependency and run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): void => {
    history.listen((location) => {
      displayAutoLogoutWarning(location);
    });
  }, [history, displayAutoLogoutWarning]);

  useEffect((): void => {
    const accountEmail: string =
      (loggedUserAccount?.idToken?.email as string | undefined) ||
      loggedUserAccount?.idToken?.preferred_username ||
      '';
    setUserEmail(accountEmail);
  }, [loggedUserAccount]);

  useEffect((): void => {
    setLoggedUserAccount(getMsal1Account());
  }, [currentAccount]);

  const userLoggedIn = (): any => {
    return getMsal1Account();
  };

  const getUserInfoButton = (): JSX.Element => <UserInitials {...getMsal1Account()} />;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <AppBar position="sticky" elevation={3} style={{ backgroundColor: grey[50] }}>
      <Drawer anchor="right" open={userDrawerOpen} onClose={closeUserMenu}>
        <List style={{ width: 280 }}>
          <ListItem
            button
            key="home"
            onClick={debounce(checkAndProceed!(gotoHome), DEBOUNCE_TIMEOUT)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </List>

        <List style={{ width: 280 }}>
          <ListItem
            button
            key="Documents"
            onClick={debounce(checkAndProceed!(gotoDocuments), DEBOUNCE_TIMEOUT)}
            selected={tabDocumentsActive}
          >
            <ListItemIcon>
              <div style={{ paddingLeft: '8px' }}>
                <FileDarkIcon className="fileIcon" />
              </div>
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>

        <List style={{ width: 280 }}>
          <ListItem
            button
            key="OtherParties"
            onClick={debounce(checkAndProceed!(gotoParties), DEBOUNCE_TIMEOUT)}
            selected={tabPartiesActive}
          >
            <ListItemIcon>
              <div style={{ paddingLeft: '5px' }}>
                <AccountIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Other Parties" />
          </ListItem>
        </List>

        <Divider />

        <List style={{ width: 280 }}>
          <ListItem
            button
            key="Reports"
            onClick={() => {
              setReportsExpanded((expanded: boolean) => !expanded);
            }}
            selected={tabReportsActive}
          >
            <ListItemIcon>
              <div style={{ paddingLeft: '8px' }}>
                <AssesmentIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Reports" />
            {reportsExpanded ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={reportsExpanded} timeout="auto" unmountOnExit>
            <List component={'div'} style={{ paddingLeft: '1em' }}>
              <ListItem
                button
                key="OtherPartieApproval"
                onClick={debounce(
                  checkAndProceed!(gotoDocumentsForPartyApproval),
                  DEBOUNCE_TIMEOUT
                )}
              >
                <ListItemIcon>
                  <div style={{ paddingLeft: '8px' }}>
                    <FileDarkIcon className="fileIcon" />
                  </div>
                </ListItemIcon>
                <ListItemText primary="Documents Not Signed by All Parties" />
              </ListItem>

              <ListItem
                button
                key="ProjectFoldersMissingProjects"
                onClick={debounce(
                  checkAndProceed!(gotoProjectFoldersMissingProject),
                  DEBOUNCE_TIMEOUT
                )}
              >
                <ListItemIcon>
                  <div style={{ paddingLeft: '8px' }}>
                    <FolderOpenIcon fontSize="small" className="fileIcon" />
                  </div>
                </ListItemIcon>
                <ListItemText primary="Project Folders without Assigned Projects" />
              </ListItem>
            </List>
          </Collapse>
        </List>

        <Divider />

        <List>
          <ListItem
            button
            key="logout"
            onClick={debounce(checkAndProceed!(logout), DEBOUNCE_TIMEOUT)}
          >
            <ListItemIcon>
              <div style={{ paddingLeft: '2px' }}>
                <ExitToAppIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Box
          order={{ xs: 1, sm: 1 }}
          className={cn({ 'header-separator': userLoggedIn() })}
          style={{ paddingRight: '0px', paddingLeft: '16px', borderTop: '1px solid #b2b2b230' }}
        >
          <div className="header-section-inline">
            <div
              onClick={debounce(checkAndProceed!(gotoHome), DEBOUNCE_TIMEOUT)}
              style={{ cursor: 'pointer' }}
            >
              <span id="header-logo">
                <Logo />
              </span>
            </div>
            {!userLoggedIn() ? (
              <div
                className="header-section-inline"
                onClick={debounce(checkAndProceed!(gotoLegalFolders), DEBOUNCE_TIMEOUT)}
                style={{ cursor: 'pointer' }}
              >
                <ListAltRoundedIcon fontSize="small" style={{ color: grey[900] }} /> &nbsp;{' '}
                <Typography
                  variant="subtitle2"
                  color="inherit"
                  noWrap
                  style={
                    !userLoggedIn() || tabLegalFoldersActive
                      ? { color: grey[900] }
                      : { color: '#006ad4' }
                  }
                >
                  Legal Folders Dashboard
                </Typography>
              </div>
            ) : undefined}
          </div>
        </Box>
        <Box
          order={{ xs: 3, sm: 2 }}
          style={{
            ...{ display: 'flex', minHeight: '62px', borderTop: '1px solid #b2b2b230' },
            ...(matches ? undefined : { width: '100%' }),
          }}
        >
          {userLoggedIn() && (
            <div className="header-section header-separator">
              <Typography
                variant="subtitle2"
                color="inherit"
                noWrap
                style={tabDocumentsActive ? { color: grey[900] } : { color: '#006ad4' }}
              >
                <div
                  className="header-section-inline"
                  onClick={debounce(checkAndProceed!(gotoDocuments), DEBOUNCE_TIMEOUT)}
                  style={{ cursor: 'pointer' }}
                >
                  <FileDarkIcon
                    className={cn(
                      tabDocumentsActive ? 'actionIconActive' : 'actionIconInactive',
                      'fileIcon'
                    )}
                  />{' '}
                  <Box component="div" display={{ xs: 'inline' }}>
                    <div className="header-action">&nbsp;Dashboard</div>
                  </Box>
                </div>
              </Typography>
            </div>
          )}
          {userLoggedIn() && (
            <div className="header-section header-separator">
              <Typography
                variant="subtitle2"
                color="inherit"
                noWrap
                style={tabPartiesActive ? { color: grey[900] } : { color: '#006ad4' }}
              >
                <div
                  className="header-section-inline"
                  onClick={debounce(checkAndProceed!(gotoParties), DEBOUNCE_TIMEOUT)}
                  style={{ cursor: 'pointer' }}
                >
                  <AccountIcon
                    className={tabPartiesActive ? 'actionIconActive' : 'actionIconInactive'}
                  />{' '}
                  <Box component="div" display={{ xs: 'inline' }}>
                    &nbsp;<span className="header-action">Other Parties</span>
                  </Box>
                </div>
              </Typography>
            </div>
          )}
          {userLoggedIn() && (
            <div className="header-section header-separator">
              <Menu
                id="basic-menu"
                anchorEl={reportsAnchorEl}
                // getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={openReportsMenu}
                onClose={handleCloseReportsMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                  style: {
                    padding: 0,
                  },
                }}
                PaperProps={{
                  style: {
                    transform: 'translateY(10%)',
                  },
                }}
              >
                <MenuItem
                  selected={false}
                  onClick={debounce(
                    checkAndProceed!(gotoDocumentsForPartyApproval),
                    DEBOUNCE_TIMEOUT
                  )}
                >
                  Documents not Signed by All Parties
                </MenuItem>
                <MenuItem
                  selected={false}
                  onClick={debounce(
                    checkAndProceed!(gotoProjectFoldersMissingProject),
                    DEBOUNCE_TIMEOUT
                  )}
                >
                  Project Folders without Assigned Projects
                </MenuItem>
              </Menu>
              <Typography
                variant="subtitle2"
                color="inherit"
                noWrap
                style={tabReportsActive ? { color: grey[900] } : { color: '#006ad4' }}
              >
                <div
                  className="header-section-inline"
                  onClick={gotoReports}
                  style={{ cursor: 'pointer' }}
                >
                  <AssesmentIcon
                    style={tabReportsActive ? { color: grey[900] } : { color: '#006ad4' }}
                  />{' '}
                  <Box component="div" display={{ xs: 'inline' }}>
                    &nbsp;<span className="header-action">Reports</span>
                  </Box>
                </div>
              </Typography>
            </div>
          )}
        </Box>
        <Box
          order={{ xs: 2, sm: 3 }}
          style={{ flexGrow: 1, paddingRight: '1rem', borderTop: '1px solid #b2b2b230' }}
        >
          {userLoggedIn() && (
            <div
              style={{
                textAlign: 'right',
                cursor: 'pointer',
                minHeight: '62px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
              onClick={debounce(openUserMenu, DEBOUNCE_TIMEOUT)}
            >
              <Box component="div" display="inline">
                {getLoggedInUserEmail()}
              </Box>
              <Box component="div" display="inline">
                {getUserInfoButton()}
              </Box>
            </div>
          )}
        </Box>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="You will be logged out in 5 minutes. Refresh page to cancel."
        open={autoLogoffMessageShown}
      />
    </AppBar>
  );
};

export default Header;
