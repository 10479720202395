import { useEffect } from 'react';
import { FC } from 'react';
import { useLegalFolder } from 'template/TreeTable/components/Breadcrumbs/legalFolderHook';
import { useProjectDocument } from 'template/TreeTable/components/Breadcrumbs/projectDocumentHook';
import { useProjectFolder } from 'template/TreeTable/components/Breadcrumbs/projectFolderHook';
import { useComponentContext as useTreeComponentContext } from 'template/TreeTable/TreeTableContext';
import { useComponentContext as useDocumentComponentContext } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import s from './style.module.scss';

export interface IDocumentPrintingMeta {
  onReady: () => void;
}
export const DocumentPrintingMeta: FC<IDocumentPrintingMeta> = ({ onReady }) => {
  const { selectedFolder } = useTreeComponentContext();
  const { projectFolderDocument } = useDocumentComponentContext();

  const { legalFolderName } = useLegalFolder({ id: selectedFolder?.legalFolderId });
  const { projectFolderName } = useProjectFolder({ id: selectedFolder?.projectFolderId });
  const { projectDocumentName } = useProjectDocument({ id: selectedFolder?.projectDocumentId });

  useEffect(() => {
    if (!!legalFolderName && !!projectFolderName && !!projectDocumentName) {
      onReady();
    }
  }, [legalFolderName, projectFolderName, projectDocumentName, onReady]);

  return (
    <div>
      <div>
        Document <strong>{projectDocumentName}</strong> in <strong>{projectFolderName}</strong>{' '}
        project folder, under <strong>{legalFolderName}</strong> legal folder.
      </div>
      {/* <div className={s.breadcrumbs}>
        <div className={s.title}>{legalFolderName}</div> <div>|</div>
        <div className={s.title}>{projectFolderName}</div> <div>|</div>
        <div className={s.title}>{projectDocumentName}</div>
      </div> */}
      <div className={s.statusFolder}>
        <div className={s.lineLabel}>Document is in</div>
        <div className={s.statusFolderValue}>
          {projectFolderDocument.statusFolder?.toLowerCase()}
        </div>
        <div>status folder</div>
      </div>
      <div>{window.location.href}</div>
    </div>
  );
};
