import { gql } from '@apollo/client';
import {} from './fragments';

export const CONTACT_CREATE_MUTATION = gql`
  mutation contactCreate($name: String!, $email: String, $phone: String) {
    contract_contactCreate(name: $name, email: $email, phone: $phone) {
      id
    }
  }
`;

export const CONTACT_UPDATE_MUTATION = gql`
  mutation contactUpdate($id: ID!, $name: String!, $email: String, $phone: String) {
    contract_contactUpdate(id: $id, name: $name, email: $email, phone: $phone) {
      id
    }
  }
`;
