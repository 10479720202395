import { useApolloClient } from '@apollo/client';
import { CHECK_DOCUMENTS_ACTION_PENDING } from 'graphql/legalFolders/documents';
import { DocumentFilter } from 'graphql/legalFolders/types/graphql-types';
import { useCallback } from 'react';
import { checkDocumentsActionPending } from 'graphql/legalFolders/types/checkDocumentsActionPending';

export const useApolloUtil = () => {
  const client = useApolloClient();

  const getSingleActionPendingDocumentId = useCallback(
    async (filter: DocumentFilter | undefined) => {
      let foundError = undefined;
      let documentId = undefined;
      let multipleFound = false;
      try {
        const { data, error } = await client.query<checkDocumentsActionPending>({
          query: CHECK_DOCUMENTS_ACTION_PENDING,
          variables: { filter },
          fetchPolicy: 'network-only',
        });
        if (error) {
          foundError = error;
        } else {
          if (
            data.contract_documents.length &&
            data.contract_documents[0].currentUserHasActionPending
          ) {
            documentId = data.contract_documents[0].id;
          }
          if (
            data.contract_documents.length > 1 &&
            data.contract_documents[1].currentUserHasActionPending
          ) {
            multipleFound = true;
          }
        }
      } catch (e: any) {
        foundError = { ...e };
      }
      return { error: foundError, multipleFound, documentId };
    },
    [client]
  );

  return {
    getSingleActionPendingDocumentId,
  };
};
