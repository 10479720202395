import { useLazyQuery } from '@apollo/client';
import { GET_LEGAL_FOLDER_ATTRIBUTES } from 'graphql/legalFolders/legalFolders';

import {
  legalFolderAttributes,
  legalFolderAttributes_contract_legalFolder,
} from 'graphql/legalFolders/types/legalFolderAttributes';
import { useCallback, useEffect, useState } from 'react';

export interface ILegalFolder
  extends Omit<legalFolderAttributes_contract_legalFolder, '__typename'> {}

export interface IUseLegalFolderNameProps {
  id?: string;
}

export interface IUseLegalFolderNameState {
  legalFolder?: ILegalFolder | null;
  load: () => void;
  loading: boolean;
  error: any;
}
export const useLegalFolderName = ({ id }: IUseLegalFolderNameProps): IUseLegalFolderNameState => {
  const [legalFolder, setLegalFolder] = useState<ILegalFolder | undefined | null>();
  const [getData, { data, loading, refetch, error, called }] = useLazyQuery<legalFolderAttributes>(
    GET_LEGAL_FOLDER_ATTRIBUTES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const load = useCallback(() => {
    if (id) {
      const variables = {
        id,
      };

      if (called) {
        refetch!(variables);
      } else {
        getData({ variables });
      }
    } else {
      setLegalFolder(undefined);
    }
  }, [called, getData, id, refetch]);

  useEffect(() => {
    if (data && !loading) {
      if (data.contract_legalFolder) {
        setLegalFolder(data.contract_legalFolder);
      } else {
        setLegalFolder(null);
      }
    }
  }, [data, loading]);

  return { legalFolder, load, loading, error };
};
