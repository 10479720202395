import { Box, Button, Grid } from '@mui/material';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC, useMemo } from 'react';
import { SelectLegalFolder } from 'components/SelectLegalFolder/SelectLegalFolder';

interface IItem {
  id: string;
  name: string;
}

export interface IMoveProjectFolderProps {
  currentLegalFolder: { id: string; name: string };
  onSelectLegalFolderChange?: (selectedItem: IItem) => void;
  selectedLegalFolder?: IItem | null;
  [key: string]: any;
  cancelHandler: () => void;
  confirmHandler: () => void;
}

export const MoveProjectFolder: FC<IMoveProjectFolderProps> = ({
  currentLegalFolder,
  onSelectLegalFolderChange,
  selectedLegalFolder,
  cancelHandler,
  confirmHandler,
}) => {
  const disabledSubmit = useMemo(
    () =>
      !selectedLegalFolder ||
      (!!currentLegalFolder && currentLegalFolder.id === selectedLegalFolder?.id),
    [selectedLegalFolder, currentLegalFolder]
  );

  return (
    <>
      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={12}>
          <p>
            The Project Folder will be moved, along with all documents, to the selected Legal
            Folder.
          </p>
          <p>All contents of the Project Folder will remain unchanged.</p>
        </Grid>
      </Grid>

      <Box m={2} />
      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={4} md={3}>
          Current Legal Folder:
        </Grid>
        <Grid item xs={8} md={9}>
          {currentLegalFolder.name}
        </Grid>
        <Grid item xs={4} md={3}>
          Select target Legal folder:
        </Grid>
        <Grid item xs={8} md={9}>
          <SelectLegalFolder
            placeholder="Select Legal Folder"
            onSelectChange={onSelectLegalFolderChange}
            selectedItem={selectedLegalFolder}
          ></SelectLegalFolder>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center', gap: 20, marginTop: '20px' }}
        >
          <Button
            color={'primary'}
            variant="contained"
            size="medium"
            onClick={debounce((): void => {
              confirmHandler();
            }, DEBOUNCE_TIMEOUT)}
            style={{
              fontSize: '0.85rem',
              ...(disabledSubmit ? {} : { background: 'red', color: 'white' }),
            }}
            disabled={disabledSubmit}
          >
            Move Project Folder
          </Button>

          <Button
            color={'secondary'}
            variant="contained"
            size="medium"
            onClick={debounce((): void => {
              cancelHandler();
            }, DEBOUNCE_TIMEOUT)}
            style={{ fontSize: '0.85rem' }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
