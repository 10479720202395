import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TreeTable } from 'template/TreeTable/TreeTable';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';

interface IUrlParams {
  id: string;
}

export const TreeTableNewProjectFolder: FC = () => {
  const { initPage } = useComponentContext();
  const { id } = useParams<IUrlParams>();
  useEffect(() => {
    initPage!({
      wellcomeProp: false,
      initTabProp: 'PF',
      projectFoldersTabProp: 'new',
      selectedFolderProp: { legalFolderId: id },
    });
  }, [initPage, id]);
  return <TreeTable></TreeTable>;
};
