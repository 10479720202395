import { FC, useCallback, useMemo, useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Box,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@mui/material';

// API
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import { parties } from 'graphql/legalFolders/types/parties';
import { partyCount } from 'graphql/legalFolders/types/partyCount';
import { partyDesignations } from 'graphql/legalFolders/types/partyDesignations';

// Icons
import { ClearAllOutlined as ClearAllOutlinedIcon, Place as PlaceIcon } from '@mui/icons-material';

// Custom Components
import ConfirmationDialog from '../../components/ConfirmationDialog';
import AlertDialog from '../../components/AlertDialog';
import { paths } from '../../constants';

// Utilities
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';

// Specific styles
import './index.scss';

import { GET_PARTIES, GET_PARTY_COUNT, GET_PARTY_DESIGNATIONS } from 'graphql/legalFolders/parties';
import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { demacPartiesTableHead } from 'constants/partiesTable';
import { PartyFilterInput, PartyTypeEnum } from 'graphql/legalFolders/types/graphql-types';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { pick } from 'lodash';
import { GET_COUNTRIES } from 'graphql/legalFolders/countries';
import { countries } from 'graphql/legalFolders/types/countries';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useUI } from 'contexts/UiContext';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { usePartyDivisions } from 'hooks/partyDivisionsHook';
import { ExportToExcel } from './components/ExportToExcel/ExportToExcel';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { PartiesGeoMap } from './components/PartiesGeoMap/PartiesGeoMap';

interface IPageLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: PartyFilterInput;
  filterValues?: any;
}

const defaultFilterValues = {
  divisionOwnership: { id: 'All', name: 'All' },
  partyType: { id: 'All', name: 'All' },
  clientType: { id: 'All', name: 'All' },
  country: { id: 'All', name: 'All' },
};

export const PartiesTemplate: FC = () => {
  const history = useHistory();
  const { addSnackbar } = useUI();
  const { divisionPairs } = usePartyDivisions();

  const { setItem, getItem } = useTableStorage({
    key: 'PartiesTable',
  });

  const [parties, setParties] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  const [showLocation, setShowLocation] = useState(false);

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    filter: {},
    ...getItem(),
  });

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const { data: designationsData } = useQuery<partyDesignations>(GET_PARTY_DESIGNATIONS);

  const { data: countriesData } = useQuery<countries>(GET_COUNTRIES);

  const [
    loadPartyCount,
    {
      data: dataPartyCount,
      loading: loadingPartyCount,
      refetch: refetchPartyCount,
      called: calledPartyCount,
    },
  ] = useLazyQuery<partyCount>(GET_PARTY_COUNT);

  const [loadParties, { called, data, loading, refetch, error }] = useLazyQuery<parties>(
    GET_PARTIES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const deleteItemTitle = '';
  const deleteItemMessage = '';
  const deleteItemIsOpen = false;

  const [deleteItemErrorOpen, setDeleteItemErrorOpen] = useState(false);
  const deleteItemErrorMessage = '';

  // #region Event handlers
  const deleteItemDialogCloseHandler = (ok: boolean): void => {};
  // #endregion

  useEffect(() => {
    if (!loadingPartyCount && dataPartyCount) {
      setTotalItems(dataPartyCount.partyCount);
    }
  }, [loadingPartyCount, dataPartyCount]);

  useEffect(() => {
    if (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
  }, [loading, error, addSnackbar]);

  useEffect(() => {
    if (!loading && data) {
      setParties(
        data.parties?.map((party) => {
          const {
            id,
            name,
            alias,
            streetAddress,
            streetAddress2,
            addressCity,
            addressState,
            addressZip,
            country,
            partyType,
            partyDesignation,
            projectSetupClientCode,
            divisionOwnership,
            focalPointUser,
          } = party;
          return {
            id,
            name,
            alias,
            streetAddress:
              (streetAddress || '') +
              (streetAddress2 && streetAddress2?.trim().length ? `, ${streetAddress2}` : ''),
            // addressCity:
            //   (!!addressCity ? `${addressCity}` : '') +
            //   (!!addressState || !!addressZip ? ',' : '') +
            //   (!!addressState ? ` ${addressState}` : '') +
            //   (!!addressZip ? ` ${addressZip}` : ''),
            addressCity,
            // addressState:
            //   (!!addressState ? ` ${addressState}` : '') +
            //   (!!addressState && !!addressZip ? ` - ` : '') +
            //   (!!addressZip ? ` ${addressZip}` : ''),
            addressState,
            addressZip,
            country: country ? `${country?.name} (${country?.code})` : '',
            partyType: PartyTypeEnum.CLIENT === partyType?.name ? 'Client' : 'Non-Client',
            clientType: partyDesignation?.name,
            projectSetupClientCode,
            divisionOwnership: divisionOwnership?.name,
            focalPointUser: focalPointUser,
          };
        })
      );
    }
  }, [data, loading]);

  // #region On filter change refetch data
  useEffect(() => {
    const { rowsPerPage, page, orderBy, order, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter,
    };

    if (called) {
      refetch!(variables);
    } else {
      loadParties({ variables });
    }
  }, [loadParties, refetch, called, pageLoadParams]);

  useEffect(() => {
    const { filter } = pageLoadParams;
    const variables = {
      filter,
    };
    if (calledPartyCount) {
      refetchPartyCount!(variables);
    } else {
      loadPartyCount({ variables });
    }
  }, [loadPartyCount, calledPartyCount, refetchPartyCount, pageLoadParams]);

  // #endregion

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onPartieSelect = useCallback(
    (id: string) => {
      history.push(paths.client.CLIENT_DETAILS.replace(':id', id));
      return true;
    },
    [history]
  );

  const convertSelectedToIds = useCallback((item: { id: string } | undefined) => {
    return item && item.id !== 'All' ? [item.id] : undefined;
  }, []);

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const newFilter: PartyFilterInput = {
          nameContains: filterValues.name || undefined,
          aliasContains: filterValues.alias || undefined,
          addressContains: filterValues.streetAddress || undefined,
          cityContains: filterValues.addressCity || undefined,
          stateContains: filterValues.addressState || undefined,
          zipContains: filterValues.addressZip || undefined,
          isActive: undefined,
          partyType: filterValues.partyType.id !== 'All' ? filterValues.partyType.id : undefined,
          partyDesignationId:
            filterValues?.clientType?.id && filterValues.clientType.id !== 'All'
              ? filterValues.clientType.id
              : undefined,
          countryId:
            filterValues?.country?.id && filterValues.country.id !== 'All'
              ? filterValues.country.id
              : undefined,
          projectSetupClientCodeContains: filterValues.projectSetupClientCode || undefined,
          divisionOwnershipIds: convertSelectedToIds(filterValues.divisionOwnership),
          focalPointUserNameContains: filterValues.focalPointUser || undefined,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
            filterValues,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams, convertSelectedToIds]
  );

  const filterApplied = useMemo(() => {
    const { filter } = pageLoadParams;
    return JSON.stringify(filter) !== '{}';
  }, [pageLoadParams]);

  const filterOptions = useMemo(() => {
    const getDesignationPairs = () => {
      return (
        designationsData?.partyDesignations.map((designation) => {
          return pick(designation, ['id', 'name']);
        }) || []
      );
    };

    const getCountryPairs = () => {
      return (
        countriesData?.countries.map((country) => {
          const { id, name, code } = country;
          return {
            id,
            name: `${name} (${code})`,
          };
        }) || []
      );
    };

    return {
      divisionOwnership: [{ id: 'All', name: 'All' }, ...divisionPairs],
      partyType: [
        { id: 'All', name: 'All' },
        { id: 'CLIENT', name: 'Client' },
        { id: 'NONCLIENT', name: 'Non-Client' },
      ],
      clientType: [{ id: 'All', name: 'All' }, ...getDesignationPairs()],
      country: [{ id: 'All', name: 'All' }, ...getCountryPairs()],
    };
  }, [designationsData, countriesData, divisionPairs]);

  const clearAllFilters = () => {
    onFilterChange(defaultFilterValues);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px', minWidth: '100%' }
          : undefined
      }
    >
      {showLocation ? (
        <PartiesGeoMap
          onClose={() => {
            setShowLocation(false);
          }}
        ></PartiesGeoMap>
      ) : undefined}

      <ConfirmationDialog
        title={deleteItemTitle}
        message={deleteItemMessage}
        open={deleteItemIsOpen}
        onClose={deleteItemDialogCloseHandler}
      />

      <AlertDialog
        title="Error"
        message={deleteItemErrorMessage}
        open={deleteItemErrorOpen}
        onClose={() => {
          setDeleteItemErrorOpen(false);
        }}
      />

      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '4px 4px 16px 4px' }
            : { padding: '4px 4px 16px 4px', marginTop: '6px' }
        }
        id="main-paper"
      >
        <Box marginBottom={2} marginTop={1} style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }}>
          <Grid container spacing={0} alignContent="center" alignItems="center">
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={debounce((): void => {
                  history.push(paths.client.NEW_CLIENT);
                }, DEBOUNCE_TIMEOUT)}
              >
                ADD PARTY
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                {filterApplied ? (
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                ) : undefined}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Show on Map">
                  <IconButton
                    style={{ padding: '0' }}
                    size="small"
                    onClick={debounce((): void => {
                      setShowLocation(true);
                    }, DEBOUNCE_TIMEOUT)}
                  >
                    <PlaceIcon fontSize="large" style={{ color: '#006ad4' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          style={{
            marginTop: '15px',
            borderRadius: '1px',
            boxShadow: '0px 2px 5px #00000030',
            border: '1px solid #00000030',
          }}
        >
          <LoadingOverlay spinner active={loading} text="Loading your content...">
            <StickyHeaderTable
              totalItems={totalItems}
              dataCells={parties}
              headCells={demacPartiesTableHead}
              loadPage={loadPage}
              handleSelect={onPartieSelect}
              filterOptions={filterOptions}
              filterValues={pageLoadParams.filterValues || defaultFilterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
              paginationSideComponent={
                <ExportToExcel
                  pageLoadParams={pageLoadParams}
                  totalItems={totalItems}
                ></ExportToExcel>
              }
            />
          </LoadingOverlay>
        </Box>
      </Paper>
    </Container>
  );
};
