import { Grid, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Delete } from '@mui/icons-material';
import React, { FC } from 'react';
import { IStockExchangeData } from '../../interface';
import { SelectOneExchange } from '../SelectOneExchange/SelectOneExchange';
import s from './style.module.scss';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export interface ISelectExchangeTileProps {
  exchange: IStockExchangeData | null;
  onSelectChange: (value: Partial<IStockExchangeData>) => void;
  onRemoveExchange: () => void;
  showValidator?: boolean;
  error?: boolean;
}

export const SelectExchangeTile: FC<ISelectExchangeTileProps> = ({
  exchange,
  onSelectChange,
  onRemoveExchange,
  error,
  showValidator,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const updateExchange =
    (getValue: (props: any) => Partial<IStockExchangeData>) => (props: any) => {
      const value = getValue(props);
      onSelectChange(value);
    };

  return (
    <Grid item xs={12} className={s.componentBox}>
      <Grid container spacing={2} alignContent="center" alignItems="flex-start">
        <Grid item xs={11}>
          <Grid container spacing={matches ? 3 : 1}>
            <Grid item xs={12} md={7}>
              <Grid container spacing={1} alignContent="center" alignItems="center">
                <Grid xs={12} sm={6} item>
                  Stock Exchange Market:
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectOneExchange
                    exchange={exchange}
                    onSelectChange={onSelectChange}
                  ></SelectOneExchange>
                </Grid>
                {exchange?.stockMarket?.id ? (
                  <>
                    <Grid xs={12} sm={12} item style={{ opacity: '0.6' }}>
                      Acronym: {exchange.stockMarket?.acronym || 'n/a'}
                    </Grid>
                  </>
                ) : undefined}
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={1} alignContent="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={s.tableLabel}
                  style={matches ? { textAlign: 'center' } : undefined}
                >
                  Exchange Symbol:
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={exchange?.stockExchangeSymbol || ''}
                    onChange={updateExchange((e: React.ChangeEvent<HTMLInputElement>) => ({
                      stockExchangeSymbol: e.target.value,
                    }))}
                    error={false}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title="Remove Other Exchange">
            <IconButton
              size="small"
              onClick={debounce(onRemoveExchange, DEBOUNCE_TIMEOUT)}
              className={classes.hoverWarning}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
