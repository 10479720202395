import { gql } from '@apollo/client';

const userQueries = {
  GET_USERS: gql`
    query GetUsers($take: Int) {
      users(take: $take) {
        id
        name
        email
        discipline
        division {
          id
          name
        }
      }
    }
  `,
};

export default userQueries;
