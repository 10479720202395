import { useLazyQuery } from '@apollo/client';
import { GET_USERS, GET_USERS_COUNT } from 'graphql/legalFolders/users';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usersList } from 'graphql/legalFolders/types/usersList';
import { usersCount } from 'graphql/legalFolders/types/usersCount';

export interface IUser {
  id: string;
  name: string;
  email?: string | null;
}

export interface IUseOwnerState {
  users: IUser[];
  totalItems: number;
  maxPage: number;
  loadPage: (page: number) => void;
  loadPageCount: () => void;
}

const rowsPerPage = 20;
const order = [{ column: 'NAME', order: 'ASC' }];

interface IUseOwnerProps {
  search: string;
}
export const useOwner = ({ search }: IUseOwnerProps): IUseOwnerState => {
  const [localSearch, setLocalSearch] = useState(search);
  const [totalItems, setTotalItems] = useState(0);
  const [users, setUsers] = useState<IUser[]>([]);

  const [
    loadUsers,
    {
      data: dataUsers,
      loading: loadingUsers,
      refetch: refetchUsers,
      called: calledUsers,
      error: errorUsers,
    },
  ] = useLazyQuery<usersList>(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    loadUsersCount,
    {
      data: dataUsersCount,
      loading: loadingUsersCount,
      refetch: refetchUsersCount,
      called: calledUsersCount,
    },
  ] = useLazyQuery<usersCount>(GET_USERS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const maxPage = useMemo(() => Math.ceil(Number(totalItems) / Number(rowsPerPage)), [totalItems]);

  const loadPage = useCallback(
    (page: number) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: order && order.length ? order : undefined,
        filter: {
          nameContains: search,
          isActive: true,
        },
      };

      if (calledUsers) {
        refetchUsers!(variables);
      } else {
        loadUsers({ variables });
      }
    },
    [loadUsers, refetchUsers, calledUsers, search]
  );

  const loadPageCount = useCallback(() => {
    const variables = {
      filter: {
        nameContains: search,
        isActive: true,
      },
    };

    if (calledUsersCount) {
      refetchUsersCount!(variables);
    } else {
      loadUsersCount({ variables });
    }
  }, [loadUsersCount, refetchUsersCount, calledUsersCount, search]);

  useEffect(() => {
    if (!loadingUsersCount && dataUsersCount) {
      setTotalItems(dataUsersCount.userCount);
    }
  }, [loadingUsersCount, dataUsersCount]);

  useEffect(() => {
    if (!errorUsers && dataUsers && !loadingUsers) {
      setUsers((users) => {
        return [...users, ...dataUsers.users];
      });
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  useEffect(() => {
    if (localSearch !== search) {
      setUsers([]);
      setLocalSearch(search);
      loadPage(0);
      loadPageCount();
    }
  }, [loadPage, loadPageCount, search, localSearch]);

  return {
    users,
    totalItems,
    maxPage,
    loadPage,
    loadPageCount,
  };
};
