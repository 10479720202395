import React, { createContext, FC, useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { paths } from 'constants/index';

export interface IContextState {
  projectFolderId?: string;
  legalFolderId?: string;
  projectDocumentId?: string;
  currentView: DocumentViewTab;
}

export type DocumentViewTab = 'list' | 'new' | 'edit-info' | 'edit-fm';

export interface IContextActions {
  changeView: (view: DocumentViewTab) => void;
}

const initialState: IContextState = {
  currentView: 'list',
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  projectFolderId?: string;
  legalFolderId?: string;
  projectDocumentId?: string;
  newProjectDocumentTs?: number;
  setDocumentView?: (view: DocumentViewTab) => void;
  activeDocumentViewTab: DocumentViewTab;
  children: any;
}

export const Provider: FC<IProviderProps> = ({
  children,
  legalFolderId,
  projectFolderId,
  projectDocumentId,
  newProjectDocumentTs,
  setDocumentView,
  activeDocumentViewTab,
}) => {
  const history = useHistory();

  const [currentView, setView] = useState<DocumentViewTab>(activeDocumentViewTab);

  useEffect(() => {
    setView((currentView) => {
      if (currentView !== activeDocumentViewTab) {
        return activeDocumentViewTab;
      }
      return currentView;
    });
  }, [activeDocumentViewTab]);

  useEffect(() => {
    setDocumentView && setDocumentView(currentView);
  }, [currentView, setDocumentView]);

  useEffect(() => {
    if (legalFolderId && projectFolderId) {
      if (projectDocumentId) {
        setView((old) => (old === 'edit-info' ? old : 'edit-fm'));
      } else {
        setView('list');
      }
    }
  }, [projectDocumentId, projectFolderId, legalFolderId]);

  useEffect(() => {
    if (newProjectDocumentTs) {
      setView('new');
    }
  }, [newProjectDocumentTs]);

  const changeView = useCallback(
    (view: DocumentViewTab) => {
      switch (view) {
        case 'edit-fm': {
          projectDocumentId &&
            history.push(
              paths.treeViewClient.TREEVIEW_DOCUMENT_FM.replace(':id', projectDocumentId)
            );
          break;
        }
        case 'edit-info': {
          projectDocumentId &&
            history.push(paths.treeViewClient.TREEVIEW_DOCUMENT.replace(':id', projectDocumentId));
          break;
        }
        default: {
          projectFolderId &&
            history.push(
              paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB.replace(
                ':id',
                projectFolderId
              ).replace(':tab', 'd')
            );
        }
      }
    },
    [projectDocumentId, projectFolderId, history]
  );

  return (
    <ComponentContext.Provider
      value={{
        legalFolderId,
        projectFolderId,
        projectDocumentId,
        currentView,
        changeView,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
