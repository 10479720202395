import { useComponentContext } from '../../TableSectionContext';
import { LegalFolderForm } from 'template/LegalFolder/components/LegalFolderForm/LegalFolderForm';
import { Provider as LegalFolderContext } from 'template/LegalFolder/LegalFolderContext';
import { useQuery } from '@apollo/client';
import { GET_LEGAL_FOLDER } from 'graphql/legalFolders/legalFolders';
import { legalFolder } from 'graphql/legalFolders/types/legalFolder';
import { useEffect, useState } from 'react';

export const LegalFolderView = () => {
  const { legalFolderId } = useComponentContext();
  const { data, loading, refetch, error } = useQuery<legalFolder>(GET_LEGAL_FOLDER, {
    variables: {
      id: legalFolderId,
    },
    errorPolicy: 'all',
  });

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (error) {
      setInvalid(true);
    }
  }, [error]);

  useEffect(() => {
    if (!data && loading) {
      setInvalid(false);
    }
  }, [data, loading]);

  return (
    <LegalFolderContext
      loadedLegalFolder={data?.contract_legalFolder}
      refetch={refetch}
      loading={loading || invalid}
    >
      {/* <NewLegalFolderButton></NewLegalFolderButton> */}
      <LegalFolderForm></LegalFolderForm>
    </LegalFolderContext>
  );
};
