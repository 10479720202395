/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Button,
  Box,
  Divider,
  TextareaAutosize,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { Alert } from '@mui/lab';

import { useUpload } from './hooks';
import { DocumentApprovalUploadSectionProps } from './interfaces';

import { ConfirmationDialog, Upload } from 'components';

import { DocumentApprovalReviewer } from '../DocumentApprovalReviewer';

import './index.scss';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const defaultDialogConfirmation = {
  title: 'DOCUMENT UPLOAD CONFIRMATION',
  message: 'Please confirm new document upload and new comment.',
  style: { width: 380 },
};

const DocumentApprovalUploadSection: FC<DocumentApprovalUploadSectionProps> = ({
  title = '',
  isDocumentDecline = true,
  handleDocumentApproval,
  onChangeComment,
  onChangeReviewers,
  handleFiles,
  files,
  handleDocumentDecline,
  account,
  projectFolderDocument,
  refetchDocument,
  forApproval,
  open,
}: DocumentApprovalUploadSectionProps) => {
  const [dialogContent, setDialogContent] = useState(defaultDialogConfirmation);
  const {
    isUploading,
    percentage,
    onDrop,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    openConfirmDialog,
    removeFile,
    disableTitlebar,
  } = useUpload({ handleDocumentApproval, handleFiles });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [selectNextReviewerVisible, setSelectNextReviewerVisible] = useState(true);

  useEffect(() => {
    if (isDocumentDecline) {
      setDialogContent(defaultDialogConfirmation);
    } else {
      setDialogContent({
        title: 'FEEDBACK CONFIRMATION',
        message: 'Please confirm your feedback.',
        style: { width: 380 },
      });
    }
  }, [isDocumentDecline]);

  const reviewersChanged = (reviewers: any) => {
    onChangeReviewers(reviewers);
  };

  const checkNextReviewerVisible = useCallback(() => {
    // By default, enable combobox to select next reviewer
    let canSelectNext = true;

    let myEmail = account.userName;
    if (myEmail) {
      myEmail = myEmail.toLocaleLowerCase();
    }

    // Now iterate through list of all reviewers and see if there is at least
    // one reviewer that has been requested to approve/decline
    // if that reviewer is not logged in user, than do not display combobox
    for (const r of projectFolderDocument?.reviewers) {
      if (
        r.status === 'REVIEW_REQUESTED' &&
        !r.disabled &&
        r.employeeEmail?.toLocaleLowerCase() !== myEmail // r.user.email.toLocaleLowerCase() !== myEmail
      ) {
        canSelectNext = false;
        break;
      }
    }

    setSelectNextReviewerVisible(canSelectNext);
  }, [account.userName, projectFolderDocument?.reviewers]);

  useEffect(() => {
    setDialogOpen(open);
    if (open === true) {
      setReminderDialogOpen(true);
      checkNextReviewerVisible();
    }
  }, [open, checkNextReviewerVisible]);

  return (
    <Dialog fullWidth maxWidth="sm" open={dialogOpen}>
      <ConfirmationDialog
        open={openConfirmDialog}
        title={dialogContent.title}
        message={dialogContent.message}
        style={dialogContent.style}
        onClose={handleCloseConfirmDialog}
      />

      <DialogContent dividers>
        <Box mb={2}>
          <Alert severity={forApproval ? 'success' : 'error'}>
            <strong>You are {forApproval ? 'approving' : 'declining'} the document.</strong>
          </Alert>
        </Box>

        {selectNextReviewerVisible && (
          <Box my={2}>
            <Alert severity="warning">
              Reminder: Please assign the document to the next reviewer. If unsure leave the option
              blank.
            </Alert>
          </Box>
        )}
        {!selectNextReviewerVisible && (
          <Box my={2}>
            <Alert severity="info">There are still pending reviews on this document</Alert>
          </Box>
        )}

        <Divider />
        <Box mt={2} marginBottom={4} className="document-approval-upload-section-upload">
          <span className="document-approval-upload-section-upload-component">
            <Upload
              onDrop={onDrop}
              removeFile={removeFile}
              isUploading={isUploading}
              percentage={percentage}
              files={files}
              hint={isDocumentDecline ? 'Upload if the document is marked up.' : ''}
            />
          </span>
        </Box>

        {selectNextReviewerVisible && (
          <Box my={1}>
            <DocumentApprovalReviewer
              projectFolderDocument={projectFolderDocument}
              refetchDocument={refetchDocument}
              enableSubmit={false}
              reviewersChanged={reviewersChanged}
            />
          </Box>
        )}

        <Box marginBottom={1}>
          <Box marginBottom={1}>
            <strong>Comment (optional):</strong>
          </Box>

          <TextareaAutosize
            color="grey"
            style={{
              width: '95%',
              maxWidth: '95%',
              minWidth: '95%',
              minHeight: '1rem',
              padding: 10,
              borderRadius: 4,
              overflow: 'auto',
              border: '1px #ccc solid',
            }}
            aria-label="minimum height"
            minRows={1}
            placeholder="Write comment..."
            className="MuiInputBase-input"
            onChange={onChangeComment}
          />
        </Box>

        <Box marginTop={1} className="document-approval-upload-section-submit" />

        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <span>You can submit the review feedback without uploading a file.</span>
          </Grid>
        </Grid>

        <Box marginBottom={5} className="document-approval-upload-section-submit" />
      </DialogContent>
      <DialogActions>
        <Grid style={{ margin: 'auto' }}>
          <Box pb={1}>
            <Button
              variant="contained"
              size="medium"
              onClick={debounce((): void => {
                setDialogOpen(false);
                handleDocumentApproval(false);
              }, DEBOUNCE_TIMEOUT)}
              style={{ fontSize: '0.85rem', backgroundColor: '#ddd', width: 135 }}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={debounce(handleOpenConfirmDialog, DEBOUNCE_TIMEOUT)}
              style={{ width: 135 }}
            >
              Submit
            </Button>{' '}
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentApprovalUploadSection;
