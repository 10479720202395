import { gql } from '@apollo/client';

export const CONTRACT_DOCUSIGN_INFO = gql`
  query contractDocuSignInfo {
    contract_docuSignInfo {
      sender {
        name
        email
      }
      senderGranted
      requestScopeConsentUrl
    }
  }
`;

export const CONTRACT_DOCUSIGN_CREATE = gql`
  mutation documentDocusignCreate(
    $documentId: ID!
    $documentVersionId: ID!
    $documentFileId: ID!
    $signers: [DocumentDocusignSigner!]
    $ccUsers: [DocumentDocusignCCUser!]
    $ccUserIds: [ID!]
    $emailSubject: String
    $emailBody: String
    $comment: String
  ) {
    contract_documentDocusignCreate(
      documentId: $documentId
      documentVersionId: $documentVersionId
      documentFileId: $documentFileId
      signers: $signers
      ccUsers: $ccUsers
      ccUserIds: $ccUserIds
      emailSubject: $emailSubject
      emailBody: $emailBody
      comment: $comment
    ) {
      envelopeId
    }
  }
`;

export const CONTRACT_DOCUSIGN_SEND = gql`
  mutation documentDocusignSend($documentId: ID!, $envelopeId: String!) {
    contract_documentDocusignSend(documentId: $documentId, envelopeId: $envelopeId) {
      envelopeId
    }
  }
`;

export const CONTRACT_DOCUSIGN_ENVELOPE_STATUS_UPDATE = gql`
  mutation documentDocusignEnvelopeStatusUpdate(
    $documentId: ID!
    $envelopeId: String!
    $status: String!
    $statusReason: String!
  ) {
    contract_documentDocusignEnvelopeStatusUpdate(
      documentId: $documentId
      envelopeId: $envelopeId
      status: $status
      statusReason: $statusReason
    )
  }
`;

export const CONTRACT_DOCUSIGN_DOWNLOAD_ENVELOPE = gql`
  query contractDocuSignEnvelopeDownload($envelopeId: String!, $documentType: String!) {
    contract_documentDocusignEnvelopeDownload(
      envelopeId: $envelopeId
      documentType: $documentType
    ) {
      fileBase64
    }
  }
`;

export const CONTRACT_DOCUSIGN_CREATE_ENVELOPE_VIEW = gql`
  query documentDocusignCreateView($envelopeId: String!, $returnUrl: String) {
    contract_documentDocusignCreateView(envelopeId: $envelopeId, returnUrl: $returnUrl) {
      url
      status
    }
  }
`;
