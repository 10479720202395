import React, { FC, useMemo, useEffect, useState } from 'react';

import LoadingOverlay from 'react-loading-overlay-ts';

import { Typography, Button, Box, Grid, TextareaAutosize, LinearProgress } from '@mui/material';

import { AlertDialog, ConfirmationDialog, Upload } from 'components';
import { useNewDocumentVersion } from './hooks';
import { NewDocumentVersionProps } from './interfaces';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const NewDocumentVersion: FC<NewDocumentVersionProps> = ({ document, refetchDocument }) => {
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const uploadStart = () => {
    setUploadingLoading(true);
  };

  const uploadEnd = (uploadResult: any) => {
    setUploadingLoading(false);

    if (uploadResult.hasErrors === true) {
      setAlertMessage(uploadResult.errorMessage);
      setAlertOpen(true);
    }
  };

  const {
    isUploading,
    percentage,
    onDrop,
    files,
    requireReapprovalFromAllStakeHolders,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleRequireReapprovalFromAllStakeHolders,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    openConfirmDialog,
    removeFile,
    comment,
    handleComment,
    newDocument,
    handleNewDocument,
  } = useNewDocumentVersion({
    document,
    refetchDocument,
    onUploadStart: uploadStart,
    onUploadEnd: uploadEnd,
  });

  const canSubmit = useMemo(() => false, []);

  useEffect(() => {
    const isNewdocument = (): void => {
      const status = document?.status?.toLowerCase();
      if (status?.includes('review pending approval') || status?.includes('review approved')) {
        handleNewDocument(true);
      } else {
        handleNewDocument(false);
      }
    };

    isNewdocument();
  }, [document, handleNewDocument]);

  const dialogVariant = (): JSX.Element => {
    // const hasFile = files.length > 0;
    let dialog = { title: '', message: '', style: { width: 380 } };

    if (comment && requireReapprovalFromAllStakeHolders) {
      dialog = {
        title: 'DOCUMENT UPLOAD CONFIRMATION',
        message: 'Please confirm new document version upload, new comment and re-approval process.',
        style: { width: 380 },
      };
    } else if (requireReapprovalFromAllStakeHolders) {
      dialog = {
        title: 'DOCUMENT UPLOAD CONFIRMATION',
        message: 'Please confirm new document version upload and re-approval process.',
        style: { width: 380 },
      };
    } else if (comment) {
      dialog = {
        title: 'DOCUMENT UPLOAD CONFIRMATION',
        message: 'Please confirm new document version upload and new comment.',
        style: { width: 280 },
      };
    } else {
      dialog = {
        title: 'DOCUMENT UPLOAD CONFIRMATION',
        message: 'Please confirm new document version upload.',
        style: { width: 380 },
      };
    }

    return (
      <ConfirmationDialog
        open={openConfirmDialog}
        title={dialog?.title}
        message={dialog?.message}
        style={dialog?.style}
        onClose={handleCloseConfirmDialog}
      />
    );
  };

  return (
    <LoadingOverlay active={uploadingLoading} spinner text="Please wait, upload in progress...">
      <AlertDialog
        title="Error"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />

      <div className="document-section">
        {dialogVariant()}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2" className="label-title">
              {newDocument ? 'Upload new document' : 'Upload new document version'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={4}>
              <Upload
                onDrop={onDrop}
                removeFile={removeFile}
                isUploading={isUploading}
                percentage={percentage}
                files={files}
                currentDocumentFileVersion={null}
              />
            </Box>
          </Grid>
          {!newDocument && files && files.length > 0 && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box marginBottom={1}>
                    <Box marginBottom={1}>
                      <strong>Comment (optional):</strong>
                    </Box>

                    <TextareaAutosize
                      color="grey"
                      style={{
                        width: '95%',
                        minHeight: '1rem',
                        padding: 10,
                        borderRadius: 4,
                        overflow: 'auto',
                        border: '1px #ccc solid',
                      }}
                      aria-label="minimum height"
                      minRows={4}
                      placeholder="Write comment..."
                      onChange={handleComment}
                      value={comment}
                      className="MuiInputBase-input"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box marginBottom={2} marginTop={1} />

              <Box marginBottom={5} marginTop={1}>
                <Button
                  className="btn-responsive"
                  variant="contained"
                  color="primary"
                  onClick={debounce(handleOpenConfirmDialog, DEBOUNCE_TIMEOUT)}
                  disabled={canSubmit || isUploading}
                  style={{ width: 135 }}
                >
                  {isUploading && 'Uploading'}
                  {!isUploading && 'Submit'}
                </Button>
              </Box>
            </Grid>
          )}
          {isUploading ? (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          ) : undefined}
        </Grid>
      </div>
    </LoadingOverlay>
  );
};
