import { useLazyQuery } from '@apollo/client';
import {
  GET_PROJECT_FOLDERS_COUNT,
  GET_TREE_PROJECT_FOLDERS,
} from 'graphql/legalFolders/legalFolders';
import { getProjectFolders } from 'graphql/legalFolders/types/getProjectFolders';
import { getProjectFoldersCount } from 'graphql/legalFolders/types/getProjectFoldersCount';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface IProjectFolder {
  id: string;
  name: string;
  email?: string | null;
}

export interface IUseProjectFoldersState {
  projectFolders: IProjectFolder[];
  totalItems: number;
  maxPage: number;
  loadPage: (page: number) => void;
  loadPageCount: () => void;
}

const rowsPerPage = 20;
const order = [{ column: 'NAME', order: 'ASC' }];

interface IUseProjectFoldersProps {
  search: string;
  legalFolderId?: string;
}
export const useProjectFolders = ({
  search,
  legalFolderId,
}: IUseProjectFoldersProps): IUseProjectFoldersState => {
  useEffect(() => {
    console.log('useProjectFolders LOADED');
    return () => console.log('useProjectFolders UNLOADED');
  }, []);

  const [localSearch, setLocalSearch] = useState(search);

  const [totalItems, setTotalItems] = useState(0);
  const [projectFolders, setProjectFolders] = useState<IProjectFolder[]>([]);

  const [
    loadProjectFolders,
    {
      data: dataProjectFolders,
      loading: loadingProjectFolders,
      refetch: refetchProjectFolders,
      called: calledProjectFolders,
      error: errorProjectFolders,
    },
  ] = useLazyQuery<getProjectFolders>(GET_TREE_PROJECT_FOLDERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    loadProjectFoldersCount,
    {
      data: dataProjectFoldersCount,
      loading: loadingProjectFoldersCount,
      refetch: refetchProjectFoldersCount,
      called: calledProjectFoldersCount,
    },
  ] = useLazyQuery<getProjectFoldersCount>(GET_PROJECT_FOLDERS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const maxPage = useMemo(() => Math.ceil(Number(totalItems) / Number(rowsPerPage)), [totalItems]);

  const loadPage = useCallback(
    (page: number) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: order && order.length ? order : undefined,
        filter: {
          nameContains: search,
          legalFolderIds: [legalFolderId],
        },
      };

      if (calledProjectFolders) {
        refetchProjectFolders!(variables);
      } else {
        loadProjectFolders({ variables });
      }
    },
    [loadProjectFolders, refetchProjectFolders, calledProjectFolders, search, legalFolderId]
  );

  const loadPageCount = useCallback(() => {
    const variables = {
      filter: {
        nameContains: search,
      },
    };

    if (calledProjectFoldersCount) {
      refetchProjectFoldersCount!(variables);
    } else {
      loadProjectFoldersCount({ variables });
    }
  }, [loadProjectFoldersCount, refetchProjectFoldersCount, calledProjectFoldersCount, search]);

  useEffect(() => {
    if (!loadingProjectFoldersCount && dataProjectFoldersCount) {
      setTotalItems(dataProjectFoldersCount.contract_containerCount);
    }
  }, [loadingProjectFoldersCount, dataProjectFoldersCount]);

  useEffect(() => {
    if (!errorProjectFolders && dataProjectFolders && !loadingProjectFolders) {
      setProjectFolders((projectFolders) => {
        return [...projectFolders, ...dataProjectFolders.contract_containers];
      });
    }
  }, [dataProjectFolders, loadingProjectFolders, errorProjectFolders]);

  const refreshData = useCallback(() => {
    setProjectFolders([]);
    setLocalSearch(search);
    loadPage(0);
    loadPageCount();
  }, [loadPage, loadPageCount, search]);

  const refreshDataRef = useRef<() => void>();

  useEffect(() => {
    refreshDataRef.current = refreshData;
  }, [refreshData, search]);

  useEffect(() => {
    if (localSearch !== search) {
      refreshData();
    }
  }, [refreshData, search, localSearch]);

  useEffect(() => {
    refreshDataRef.current && refreshDataRef.current();
  }, [legalFolderId]);

  return {
    projectFolders,
    totalItems,
    maxPage,
    loadPage,
    loadPageCount,
  };
};
