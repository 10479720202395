import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      oid
      email
      name
      isActive
      isMoscow
      discipline
      isReportsAdmin
      isContractsAdmin
      isProposalsAdmin
      division {
        id
        name
        manager {
          id
          oid
          email
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query usersList($sort: [UserSort!] = [], $filter: UserFilter, $take: Int, $skip: Int = 0) {
    users(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
      email
    }
  }
`;

export const GET_USERS_COUNT = gql`
  query usersCount($filter: UserFilter) {
    userCount(filter: $filter)
  }
`;

export const GET_DOCUMENTS_USERS = gql`
  query GetDocumentsUsers($take: Int, $userFilter: UserFilter) {
    users(take: $take, filter: $userFilter) {
      id
      name
      email
      discipline
      division {
        id
        name
      }
    }

    personas {
      name
      isUsersFiltered
    }
  }
`;
