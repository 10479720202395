import { ProjectFoldersTemplate } from 'template/ProjectFolders/ProjectFolders';
import { NewProjectFolderView } from '../ProjectFolderView/NewProjectFolderView';
import { ProjectFolderView } from '../ProjectFolderView/ProjectFolderView';
import { useComponentContext } from './ProjectFoldersContext';

export interface IDocumentViewProps {}

export const ProjectFoldersView = () => {
  const { currentView, projectFolderId, legalFolderId } = useComponentContext();

  switch (currentView) {
    case 'new':
      return <NewProjectFolderView key="newLegalFolder"></NewProjectFolderView>;
    case 'edit-info':
      return <ProjectFolderView key={projectFolderId}></ProjectFolderView>;
  }
  return <ProjectFoldersTemplate legalFolderId={legalFolderId!}></ProjectFoldersTemplate>;
};
