import HomeIcon from 'images/icons/homeIcon';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import s from './style.module.scss';
import cn from 'classnames';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface ILinkItem {
  title: string;
  url?: string;
  cb?: () => void;
  category?: string;
}

export interface IBreadcrumbsProps {
  links: ILinkItem[];
  pageTitle?: string;
  onClickHome?: () => void;
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ links, pageTitle, onClickHome }) => {
  const { confirmRequest } = useFormChangedDialogContext();
  const history = useHistory();
  let index = 0;
  return (
    <div className={s.breadcrumbs} key="Breadcrumbs">
      <div
        className={s.breadcrumbItem}
        onClick={debounce(() => {
          confirmRequest &&
            confirmRequest((confirmed) => {
              if (confirmed) {
                onClickHome ? onClickHome() : history.push('/');
              }
            });
        }, DEBOUNCE_TIMEOUT)}
        style={{ cursor: 'pointer' }}
        title="Home"
        key="Home"
      >
        <HomeIcon className={s.homeIcon}></HomeIcon>
      </div>
      {links.map(({ url, title, category, cb }) => {
        index++;
        return (
          <div
            key={'link_' + index.toString() + '_' + title}
            onClick={debounce(() => {
              confirmRequest &&
                confirmRequest((confirmed) => {
                  if (confirmed) {
                    if (url) {
                      history.push(url);
                    }
                    cb && cb();
                  }
                });
            }, DEBOUNCE_TIMEOUT)}
            className={cn(s.breadcrumbItem, s.separator, s.link)}
            style={{ cursor: 'pointer' }}
            title={'View ' + title + (category ? ' ' + category : '')}
          >
            {title}
          </div>
        );
      })}
      {pageTitle ? (
        <div className={cn(s.breadcrumbItem, s.separator)} key="CurrentPageTitle">
          {pageTitle}
        </div>
      ) : undefined}
    </div>
  );
};
