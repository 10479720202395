import { gql } from '@apollo/client';
import { reportEvent, reportVersion, reportUser } from '../fragments';

const reportQueries = {
  ADD_REPORT: gql`
    mutation addReport(
      $name: String!
      $clientApprovalNeeded: Boolean!
      $notifyMainStakeholders: Boolean!
      $nonReportDocument: Boolean
      $projectId: ID!
      $reviewers: [ReportUserInput!]
      $info: ReportInfoInput
      $reserves: ReportReservesInput
      $comment: String
    ) {
      reportCreate(
        name: $name
        projectId: $projectId
        clientApprovalNeeded: $clientApprovalNeeded
        notifyMainStakeholders: $notifyMainStakeholders
        nonReportDocument: $nonReportDocument
        info: $info
        reserves: $reserves
        reviewers: $reviewers
        comment: $comment
      ) {
        id
        name
      }
    }
  `,

  REPORT_UPDATE: gql`
    mutation ReportUpdate(
      $clientApprovalNeeded: Boolean!
      $notifyMainStakeholders: Boolean!
      $nonReportDocument: Boolean
      $reviewers: [ReportUserInput!]
      $ownerUserId: ID
      $info: ReportInfoInput
      $reserves: ReportReservesInput
      $name: String
      $id: ID!
    ) {
      reportUpdate(
        clientApprovalNeeded: $clientApprovalNeeded
        notifyMainStakeholders: $notifyMainStakeholders
        nonReportDocument: $nonReportDocument
        reviewers: $reviewers
        ownerUserId: $ownerUserId
        info: $info
        reserves: $reserves
        name: $name
        id: $id
      ) {
        id
        name
      }
    }
  `,

  REPORT_VERSION_CREATE: gql`
  mutation ReportVersionCreate($comment: String, $isMajorVersion: Boolean!, $reportId: ID!, $uploadedFile: UploadedFileInput!) {
    reportVersionCreate(comment: $comment, isMajorVersion: $isMajorVersion, reportId: $reportId, uploadedFile: $uploadedFile) {
      ${reportVersion}
    }
  }
`,

  REPORT_COMMENT: gql`
  mutation ReportComment($comment: String!, $reportId: ID!) {
    reportComment(comment: $comment, reportId: $reportId) {
      reportVersion {
        ${reportVersion}
      }
      author {
        ${reportUser}
      }
      eventType
      comment
      reviewer {
        ${reportUser}
      }
      createdAt
    }
  }
`,

  REPORT_REQUEST_REVIEW: gql`
  mutation ReportRequestReview($reportUserId: ID!, $reportId: ID!) {
    reportRequestReview(reportUserId: $reportUserId, reportId: $reportId) {
      ${reportEvent}
    }
  }
`,

  REPORT_REVIEW_APPROVE: gql`
  mutation ReportReviewApprove($comment: String!, $reportId: ID!, $uploadedFile: UploadedFileInput) {
    reportReviewApprove(comment: $comment, reportId: $reportId, uploadedFile: $uploadedFile) {
      ${reportEvent}
    }
  }
  `,

  REPORT_REVIEW_DISAPPROVE: gql`
  mutation ReportReviewDisapprove($comment: String!, $reportId: ID!, $uploadedFile: UploadedFileInput) {
    reportReviewDisapprove(comment: $comment, reportId: $reportId, uploadedFile: $uploadedFile) {
      ${reportEvent}
    }
  }
  `,

  REPORT_REVIEW_POSTPONE: gql`
  mutation ReportReviewPostpone($reportId: ID!) {
    reportReviewPostpone(reportId: $reportId) {
      ${reportEvent}
    }
  }
  `,

  REPORT_CLIENT_APPROVAL_SET_STATUS: gql`
  mutation ReportClientApprovalSetStatus($status: ClientApprovalStatus!, $type: ClientApprovalType!, $reportId: ID!) {
    reportClientApprovalSetStatus(status: $status, type: $type, reportId: $reportId) {
      ${reportEvent}
    }
  }
  `,

  REPORT_ARCHIVE: gql`
  mutation ReportArchive($customArchiveFileName: String, $overwriteExistingFile:Boolean, $skipArchiveUpload:Boolean, $reportId: ID!) {
    reportArchive(customArchiveFileName:$customArchiveFileName, overwriteExistingFile:$overwriteExistingFile, skipArchiveUpload:$skipArchiveUpload, reportId: $reportId) {
      ${reportEvent}
    }
  }
  `,

  REPORT_ARCHIVE_NONREPORTDOC: gql`
  mutation ReportArchive($reportId: ID!) {
    reportArchive(reportId: $reportId) {
      ${reportEvent}
    }
  }
  `,

  REPORT_CANCEL: gql`
  mutation ReportCancel($reportId: ID!) {
    reportCancel(reportId: $reportId) {
      ${reportEvent}
    }
  }
  `,

  REPORT_SOFT_DELETE: gql`
    mutation report_softDeleteCanceledReport($reportId: ID!) {
      report_softDeleteCanceledReport(reportId: $reportId)
    }
  `,

  REPORT_ADD_SUPPORT_FILE: gql`
    mutation ReportFileUpload($reportId: ID!, $uploadedFile: UploadedFileInput!) {
      reportFileUpload(reportId: $reportId, uploadedFile: $uploadedFile) {
        originalFilename
        downloadUrl
      }
    }
  `,

  REPORT_DELETE_SUPPORT_FILE: gql`
    mutation ReportFileDelete($reportFileId: ID!, $reportId: ID!) {
      reportFileDelete(reportFileId: $reportFileId, reportId: $reportId) {
        blobName
      }
    }
  `,

  REPORT_USER_SEND_REVIEW_REQUEST_REMINDER: gql`
    mutation ReportUserSendReviewRequestReminder($reportUserId: ID!, $reportId: ID!) {
      report_sendReviewRequestReminder(reportUserId: $reportUserId, reportId: $reportId)
    }
  `,

  REPORT_USER_RECALL_REVIEW_REQUEST_REMINDER: gql`
    mutation ReportUserRecallReviewRequestReminder($reportUserId: ID!, $reportId: ID!) {
      report_recallReviewRequest(reportUserId: $reportUserId, reportId: $reportId) {
        ${reportEvent}
      }
    }
  `,
};

export default reportQueries;
