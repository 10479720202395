import { gql } from '@apollo/client';
import { IContainersListContainer, IProjectFolder, ILegalFolder } from './fragments';

export const GET_PROJECT_FOLDER = gql`
  query projectFolder($id: ID!) {
    contract_container(id: $id) {
      ...IProjectFolder
    }
  }
  ${IProjectFolder}
`;

export const GET_PROJECT_FOLDER_NAME = gql`
  query projectFolderName($id: ID!) {
    contract_container(id: $id) {
      name
    }
  }
`;

export const GET_PROJECT_FOLDER_INFO = gql`
  query projectFolderInfo($id: ID!) {
    contract_container(id: $id) {
      id
      name
    }
  }
`;

export const GET_PROJECT_FOLDER_PATH = gql`
  query projectFolderPath($id: ID!) {
    contract_container(id: $id) {
      id
      name
      legalFolder {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_FOLDERS = gql`
  query getProjectFolders(
    $sort: [ContainerSort!] = []
    $filter: ContainerFilter
    $take: Int
    $skip: Int = 0
  ) {
    contract_containers(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IContainersListContainer
    }
  }
  ${IContainersListContainer}
`;

export const GET_PROJECT_FOLDERS_COUNT = gql`
  query getProjectFoldersCount($filter: ContainerFilter) {
    contract_containerCount(filter: $filter)
  }
`;

export const GET_PROJECT_FOLDERS_TOTAL_COUNTERS = gql`
  query getProjectFoldersTotalCounters(
    $totalFilter: ContainerFilter
    $userOnlyFilter: ContainerFilter
    $inaccessibleFilter: ContainerFilter
  ) {
    total: contract_containerCount(filter: $totalFilter, includeInaccessible: false)
    userOnly: contract_containerCount(filter: $userOnlyFilter, includeInaccessible: false)
    inaccessible: contract_containerCount(filter: $inaccessibleFilter, includeInaccessible: true)
  }
`;

export const PROJECT_FOLDER_CREATE_MUTATION = gql`
  mutation projectFolderCreate(
    $legalFolderId: ID!
    $name: String!
    $startDate: DateTime
    $endDate: DateTime
    $notes: String
    $contactIds: [ID!]
    $projectIds: [ID!]
    $proposalIds: [ID!]
    $memberUserIds: [ID!]!
    $notifyOwners: Boolean = true
    $notifyMembers: Boolean = true
  ) {
    contract_containerCreate(
      legalFolderId: $legalFolderId
      name: $name
      startDate: $startDate
      endDate: $endDate
      notes: $notes
      contactIds: $contactIds
      projectIds: $projectIds
      proposalIds: $proposalIds
      memberUserIds: $memberUserIds
      notifyOwners: $notifyOwners
      notifyMembers: $notifyMembers
    ) {
      id
    }
  }
`;

export const PROJECT_FOLDER_UPDATE_MUTATION = gql`
  mutation projectFolderUpdate(
    $id: ID!
    $name: String
    $startDate: DateTime
    $endDate: DateTime
    $notes: String
    $contactIdsToAdd: [ID!]
    $contactIdsToRemove: [ID!]
    $projectIdsToAdd: [ID!]
    $projectIdsToRemove: [ID!]
    $proposalIdsToAdd: [ID!]
    $proposalIdsToRemove: [ID!]
    $ownerUserIdsToAdd: [ID!]
    $ownerUserIdsToRemove: [ID!]
    $memberUserIdsToAdd: [ID!]
    $memberUserIdsToRemove: [ID!]
    $notifyOwners: Boolean
    $notifyMembers: Boolean
  ) {
    contract_containerUpdate(
      id: $id
      name: $name
      startDate: $startDate
      endDate: $endDate
      notes: $notes
      contactIdsToAdd: $contactIdsToAdd
      contactIdsToRemove: $contactIdsToRemove
      projectIdsToAdd: $projectIdsToAdd
      projectIdsToRemove: $projectIdsToRemove
      proposalIdsToAdd: $proposalIdsToAdd
      proposalIdsToRemove: $proposalIdsToRemove
      ownerUserIdsToAdd: $ownerUserIdsToAdd
      ownerUserIdsToRemove: $ownerUserIdsToRemove
      memberUserIdsToAdd: $memberUserIdsToAdd
      memberUserIdsToRemove: $memberUserIdsToRemove
      notifyOwners: $notifyOwners
      notifyMembers: $notifyMembers
    ) {
      id
    }
  }
`;

export const GET_LEGAL_FOLDER = gql`
  query legalFolder($id: ID!) {
    contract_legalFolder(id: $id) {
      ...ILegalFolder
    }
  }
  ${ILegalFolder}
`;

export const LEGAL_FOLDER_CREATE_MUTATION = gql`
  mutation legalFolderCreate(
    $partyIds: [ID!]!
    $name: String!
    $notes: String
    $memberUserIds: [ID!]!
    $notifyOwners: Boolean = true
    $notifyMembers: Boolean = true
  ) {
    contract_legalFolderCreate(
      partyIds: $partyIds
      name: $name
      notes: $notes
      memberUserIds: $memberUserIds
      notifyOwners: $notifyOwners
      notifyMembers: $notifyMembers
    ) {
      id
    }
  }
`;

export const LEGAL_FOLDER_UPDATE_MUTATION = gql`
  mutation legalFolderUpdate(
    $id: ID!
    $name: String
    $notes: String
    $partyIdsToAdd: [ID!]
    $partyIdsToRemove: [ID!]
    $ownerUserIdsToAdd: [ID!]
    $ownerUserIdsToRemove: [ID!]
    $memberUserIdsToAdd: [ID!]
    $memberUserIdsToRemove: [ID!]
    $notifyOwners: Boolean
    $notifyMembers: Boolean
  ) {
    contract_legalFolderUpdate(
      id: $id
      name: $name
      notes: $notes
      partyIdsToAdd: $partyIdsToAdd
      partyIdsToRemove: $partyIdsToRemove
      ownerUserIdsToAdd: $ownerUserIdsToAdd
      ownerUserIdsToRemove: $ownerUserIdsToRemove
      memberUserIdsToAdd: $memberUserIdsToAdd
      memberUserIdsToRemove: $memberUserIdsToRemove
      notifyOwners: $notifyOwners
      notifyMembers: $notifyMembers
    ) {
      id
    }
  }
`;

export const GET_LEGAL_FOLDER_ATTRIBUTES = gql`
  query legalFolderAttributes($id: ID!) {
    contract_legalFolder(id: $id) {
      id
      name
      currentUserHasActionPending
      documentsMonetaryTransactionType
      parties {
        id
      }
    }
  }
`;

export const GET_LEGAL_FOLDERS_NAMES = gql`
  query getLegalFolders(
    $sort: [LegalFolderSort!] = []
    $filter: LegalFolderFilter
    $take: Int
    $skip: Int = 0
    $withLimitedAccess: Boolean = false
  ) {
    contract_legalFolders(
      sort: $sort
      filter: $filter
      take: $take
      skip: $skip
      withLimitedAccess: $withLimitedAccess
    ) {
      id
      name
      currentUserHasActionPending
      currentUserHasAccessRights
    }
  }
`;

export const GET_PROJECT_FOLDERS_NAMES = gql`
  query getProjectFoldersNames(
    $sort: [ContainerSort!] = []
    $filter: ContainerFilter
    $take: Int
    $skip: Int = 0
  ) {
    contract_containers(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
    }
  }
`;

export const GET_LEGAL_FOLDERS_DETAILS = gql`
  query getLegalFoldersDetails(
    $sort: [LegalFolderSort!] = []
    $filter: LegalFolderFilter
    $take: Int
    $skip: Int = 0
    $withLimitedAccess: Boolean = false
  ) {
    contract_legalFolders(
      sort: $sort
      filter: $filter
      take: $take
      skip: $skip
      withLimitedAccess: $withLimitedAccess
    ) {
      id
      name
      owners {
        user {
          id
          name
          email
        }
      }
      members {
        user {
          id
          name
          email
        }
      }
      parties {
        id
        name
      }
      notifyOwners
      notifyMembers
      currentUserHasAccessRights
      documentsMonetaryTransactionType
    }
  }
`;

export const GET_LEGAL_FOLDERS_COUNT = gql`
  query getLegalFoldersCount($filter: LegalFolderFilter, $withLimitedAccess: Boolean = false) {
    contract_legalFolderCount(filter: $filter, withLimitedAccess: $withLimitedAccess)
  }
`;

export const GET_TREE_PROJECT_FOLDERS = gql`
  query getTreeProjectFolders(
    $sort: [ContainerSort!] = []
    $filter: ContainerFilter
    $take: Int
    $skip: Int = 0
  ) {
    contract_containers(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
      documentCount
      currentUserHasActionPending
    }
  }
`;

export const PROJECT_FOLDER_SOFT_DELETE = gql`
  mutation containerSoftDelete($containerId: ID!) {
    contract_containerSoftDelete(containerId: $containerId)
  }
`;

export const LEGAL_FOLDER_SOFT_DELETE = gql`
  mutation legalFolderSoftDelete($legalFolderId: ID!) {
    contract_legalFolderSoftDelete(legalFolderId: $legalFolderId)
  }
`;

// export const CONTAINER_OWNERS_CHANGE_MUTATION = gql`
//   mutation containerOwnersChange(
//     $containerId: ID!
//     $ownersToAdd: [ContainerOwnerAddInput!]
//     $ownersToRemove: [ContainerOwnerRemoveInput!]
//   ) {
//     contract_containerOwnersChange(
//       containerId: $containerId
//       ownersToAdd: $ownersToAdd
//       ownersToRemove: $ownersToRemove
//     )
//   }
// `;

// export const LEGAL_FOLDER_OWNERS_CHANGE_MUTATION = gql`
//   mutation legalFolderOwnersChange(
//     $legalFolderId: ID!
//     $ownersToAdd: [LegalFolderOwnerAddInput!]
//     $ownersToRemove: [LegalFolderOwnerRemoveInput!]
//   ) {
//     contract_legalFolderOwnersChange(
//       legalFolderId: $legalFolderId
//       ownersToAdd: $ownersToAdd
//       ownersToRemove: $ownersToRemove
//     )
//   }
// `;

// contract_containerMove returns boolean
export const CONTAINER_MOVE = gql`
  mutation containerMove($containerId: ID!, $destinationLegalFolderId: ID!) {
    contract_containerMove(
      containerId: $containerId
      destinationLegalFolderId: $destinationLegalFolderId
    )
  }
`;
