import { Box, Button, Grid } from '@mui/material';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DocusignRecipientSelection } from '../DocusignRecipientSelection/DocusignRecipientSelection';

export interface IDocuSignSectionRecipient {
  userId?: string | null;
  name?: string | null;
  email?: string | null;
}

export interface IDocusignRecipientsAnyUserSelectionProps {
  handleRecipientSelection?: (selected: IDocuSignSectionRecipient[]) => void;
  disabled?: boolean;
  label: string;
  recipientTypeLabel: string;
  value: IDocuSignSectionRecipient[];
}

export const DocusignRecipientsAnyUserSelection: FC<IDocusignRecipientsAnyUserSelectionProps> = ({
  value,
  handleRecipientSelection,
  disabled,
  label,
  recipientTypeLabel,
}) => {
  const [selected, setSelected] = useState<IDocuSignSectionRecipient[]>([{}]);

  const selectedRef = useRef<IDocuSignSectionRecipient[]>(selected);
  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (value?.length && JSON.stringify(value) !== JSON.stringify(selectedRef.current)) {
      setSelected(value);
    }
  }, [value]);

  const handleRecipientSelectionRef = useRef<any>();
  useEffect(() => {
    handleRecipientSelectionRef.current = handleRecipientSelection;
  }, [handleRecipientSelection]);

  useEffect(() => {
    handleRecipientSelectionRef.current && handleRecipientSelectionRef.current(selected);
  }, [selected]);

  const onSetSelected = useCallback(
    (no: number) => (recipient: IDocuSignSectionRecipient) =>
      setSelected((old) => {
        old[no] = { ...recipient };
        return [...old];
      }),
    []
  );

  const onRemoveItem = useCallback(
    (no: number) => () =>
      setSelected((old) => {
        const retVal = [...old];
        retVal.splice(no, 1);
        return retVal;
      }),
    []
  );

  return (
    <>
      {selected.length ? (
        <Grid container spacing={2}>
          {selected.map((recipient, no) => {
            return (
              <Grid item xs={12}>
                <DocusignRecipientSelection
                  recipient={recipient}
                  setRecipient={onSetSelected(no)}
                  removeItem={onRemoveItem(no)}
                  recipientTypeLabel={recipientTypeLabel}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : undefined}
      <Box mt={2} />
      <Button
        className="docusign-section-btn btn-responsive"
        variant="contained"
        color="primary"
        onClick={debounce(() => {
          setSelected((old) => [...old, {}]);
        }, DEBOUNCE_TIMEOUT)}
        disabled={disabled}
      >
        Add {label}
      </Button>
    </>
  );
};
