import { NAME_LENGTH_LIMIT } from './config';

export const validators = {
  simpleText: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 1,
    },
  },
  required: {
    presence: true,
  },
  nameText: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 1,
      maximum: NAME_LENGTH_LIMIT,
    },
  },
};
