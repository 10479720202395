import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import './index.scss';

import { useHistory } from 'react-router-dom';

import { paths } from 'constants/index';

import { useComponentContext } from './ProjectFolderContext';

import { useCallback } from 'react';
import { NavigationTabs, projectFolderTabs } from './components/NavigationTabs';

import { ProjectFolderForm } from './components/ProjectFolderForm/ProjectFolderForm';

export const LegalFolderTemplate = () => {
  const history = useHistory();

  const { projectFolder } = useComponentContext();

  const { id } = projectFolder;

  const handleSetPage = useCallback(
    (newPage: projectFolderTabs) => {
      if (newPage === projectFolderTabs.VIEW_DOCUMENTS) {
        const viewDocumentsPath = paths.client.PROJECT_FOLDER_ID_DOCUMENTS.replace(':id', id);
        history.push(viewDocumentsPath);
      }
    },
    [history, id]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="md" style={!matches ? { padding: '0px' } : undefined}>
      <div>
        <Box m={4} />
        <NavigationTabs
          page={projectFolderTabs.PROJECT_FOLDER}
          handleSetPage={handleSetPage}
          newDocument={!parseInt(id)}
        />
      </div>
      <Paper elevation={3} style={{ padding: '1rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <ProjectFolderForm></ProjectFolderForm>
      </Paper>
    </Container>
  );
};
