import { useCallback, useState } from 'react';
import { useApolloClient, ApolloError } from '@apollo/client';

import {
  GET_DOCUMENTS_EXPORT_LIST,
  GET_PROJECT_FOLDER_DOCUMENTS_COUNT,
} from 'graphql/legalFolders/documents';
import { getDocumentsExport } from 'graphql/legalFolders/types/getDocumentsExport';
import { getLegalFolderDocumentsCount } from 'graphql/legalFolders/types/getLegalFolderDocumentsCount';

export interface IProcess {
  status: 'stop' | 'run' | 'fin';
  stages: Array<{ key: string; name: string }>;
  progress: { [key: string]: { steps: number; current: number } };
}

const getInitialLoadDocumentsStatus: () => IProcess = () => ({
  status: 'stop',
  stages: [
    { key: 'calculate', name: 'Collecting Info' },
    { key: 'getDocuments', name: 'Loading Pages' },
  ],
  progress: {
    calculate: { steps: 2, current: 0 },
    getDocuments: { steps: 1, current: 0 },
  },
});

export const useLoadDocuments = () => {
  const client = useApolloClient();
  const [loadDocumentsStatus, setLoadDocumentsStatus] = useState<IProcess>(
    getInitialLoadDocumentsStatus()
  );

  const setLoadDocumentStepCount = useCallback((step: string, count: number) => {
    setLoadDocumentsStatus((old) => ({
      ...old,
      progress: { ...old.progress, [step]: { steps: count, current: 0 } },
    }));
  }, []);

  const incLoadDocumentStepProgress = useCallback((step: string) => {
    setLoadDocumentsStatus((old) => {
      if (old.progress[step].current + 1 < old.progress[step].steps) {
        return {
          ...old,
          progress: {
            ...old.progress,
            [step]: { ...old.progress[step], current: old.progress[step].current + 1 },
          },
        };
      }
      return {
        ...old,
        progress: {
          ...old.progress,
          [step]: {
            ...old.progress[step],
            steps: old.progress[step].steps + 1,
            current: old.progress[step].current + 1,
          },
        },
      };
    });
  }, []);

  const finLoadDocumentStepProgress = useCallback((step: string) => {
    setLoadDocumentsStatus((old) => ({
      ...old,
      progress: {
        ...old.progress,
        [step]: { ...old.progress[step], current: old.progress[step].steps },
      },
    }));
  }, []);

  const loadDocuments = useCallback(
    async ({
      allPages,
      rowsPerPage,
      variables,
    }: {
      variables: any;
      allPages: boolean;
      rowsPerPage: number;
    }) => {
      setLoadDocumentsStatus(() => getInitialLoadDocumentsStatus());
      setLoadDocumentsStatus((old) => ({ ...old, status: 'run' }));

      let result: { data: any; error: ApolloError | undefined };

      if (!allPages) {
        finLoadDocumentStepProgress('calculate');

        const { data, error } = await client.query<getDocumentsExport>({
          query: GET_DOCUMENTS_EXPORT_LIST,
          variables: { ...variables },
          fetchPolicy: 'network-only',
        });
        finLoadDocumentStepProgress('getDocuments');

        if (data.contract_documents) {
          result = { data: data?.contract_documents, error };
        } else {
          result = { data: [], error };
        }
      } else {
        incLoadDocumentStepProgress('calculate');
        const { data: documentCount, error: documentCountError } =
          await client.query<getLegalFolderDocumentsCount>({
            query: GET_PROJECT_FOLDER_DOCUMENTS_COUNT,
            variables: { ...variables },
          });
        finLoadDocumentStepProgress('calculate');

        if (documentCount && !documentCountError) {
          setLoadDocumentStepCount(
            'getDocuments',
            Math.ceil(documentCount.contract_documentCount / rowsPerPage)
          );
        }

        result = { data: [], error: undefined };
        let lastPage = false;

        for (var pageNo = 0; !lastPage; pageNo++) {
          const { data, error } = await client.query<getDocumentsExport>({
            query: GET_DOCUMENTS_EXPORT_LIST,
            variables: { ...variables, take: rowsPerPage, skip: rowsPerPage * pageNo },
          });

          if (error) {
            result.data.error = error;
            break;
          }

          lastPage = !data?.contract_documents?.length;
          if (!lastPage) {
            incLoadDocumentStepProgress('getDocuments');
            result.data.push(...data.contract_documents);
          }
        }
        finLoadDocumentStepProgress('getDocuments');
      }

      const updatedResult = {
        ...result,
        data: result.data.map((data: any) => ({
          ...data,
        })),
      };

      setLoadDocumentsStatus((old) => ({ ...old, status: 'fin' }));
      return updatedResult;
    },
    [client, incLoadDocumentStepProgress, setLoadDocumentStepCount, finLoadDocumentStepProgress]
  );

  return {
    loadDocuments,
    loadDocumentsStatus,
  };
};
