import { FC, useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';

// API
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import { getLegalFolderDocuments } from 'graphql/legalFolders/types/getLegalFolderDocuments';
import { getLegalFolderDocumentsCount } from 'graphql/legalFolders/types/getLegalFolderDocumentsCount';

// Utilities
import LoadingOverlay from 'react-loading-overlay-ts';

// Specific styles
import './index.scss';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';

import { IDocumentsTableLoadParams } from './interface';
import {
  GET_DOCUMENT_TYPES,
  GET_PROJECT_FOLDER_DOCUMENTS,
  GET_PROJECT_FOLDER_DOCUMENTS_COUNT,
  GET_PROJECT_FOLDER_DOCUMENTS_TOTAL_COUNTERS,
} from 'graphql/legalFolders/documents';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { DocumentFilter, DocumentStatusFolder } from 'graphql/legalFolders/types/graphql-types';
import { documentTypes } from 'graphql/legalFolders/types/documentTypes';

import { Table } from 'components/ui/Table/Table';
import { demacProjectFolderDocumentsTableHead } from 'constants/projectFolderDocumentsTable';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useUI } from 'contexts/UiContext';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { buildInitials } from 'utils/user';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { getLegalFolderDocumentsTotalCounters } from 'graphql/legalFolders/types/getLegalFolderDocumentsTotalCounters';

interface ILegalFolderUrlProps {
  projectFolderId: string;
  statusFolders: DocumentStatusFolder[];
  onRecords?: (count: number) => void;
  onTotals?: (totals: getLegalFolderDocumentsTotalCounters) => void;
  initDocumentsTableLoadParams?: Partial<IDocumentsTableLoadParams>;
}

export const DocumentsTable: FC<ILegalFolderUrlProps> = ({
  projectFolderId,
  statusFolders,
  onRecords,
  onTotals,
  initDocumentsTableLoadParams,
}) => {
  const { setItem, getItem } = useTableStorage({
    key: 'status_folder_documents_globals:' + statusFolders.join(','),
  });

  const { addSnackbar } = useUI();
  const { onSelectProjectDocument, myTask } = useComponentContext();

  const [projectFolderDocuments, setLegalFolderDocuments] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  const [pageLoadParams, setPageLoadParams] = useState<IDocumentsTableLoadParams>({
    page: 0,
    rowsPerPage: getItem().rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filter: {
      containerIds: [projectFolderId],
      statusFolders,
    },
    ...initDocumentsTableLoadParams,
  });

  useEffect(() => {
    const oldValue = getItem().rowsPerPage;
    if (pageLoadParams.rowsPerPage !== oldValue) {
      setItem({ rowsPerPage: pageLoadParams.rowsPerPage });
    }
  }, [pageLoadParams.rowsPerPage, setItem, getItem]);

  const [
    loadLegalFolderDocumentsCount,
    {
      data: dataLegalFolderDocumentsCount,
      loading: loadingLegalFolderDocumentsCount,
      refetch: refetchLegalFolderDocumentsCount,
      called: calledLegalFolderDocumentsCount,
      error: errorLegalFolderDocumentsCount,
    },
  ] = useLazyQuery<getLegalFolderDocumentsCount>(GET_PROJECT_FOLDER_DOCUMENTS_COUNT);

  const [
    loadLegalFolderDocumentsTotalCounters,
    {
      data: dataLegalFolderDocumentsTotalCounters,
      loading: loadingLegalFolderDocumentsTotalCounters,
      refetch: refetchLegalFolderDocumentsTotalCounters,
      called: calledLegalFolderDocumentsTotalCounters,
      error: errorLegalFolderDocumentsTotalCounters,
    },
  ] = useLazyQuery<getLegalFolderDocumentsTotalCounters>(
    GET_PROJECT_FOLDER_DOCUMENTS_TOTAL_COUNTERS
  );

  const [loadLegalFolderDocuments, { called, data, loading, refetch, error }] =
    useLazyQuery<getLegalFolderDocuments>(GET_PROJECT_FOLDER_DOCUMENTS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const { data: documentTypesData } = useQuery<documentTypes>(GET_DOCUMENT_TYPES);

  useEffect(() => {
    if (errorLegalFolderDocumentsCount) {
      apolloErrorHandler(addSnackbar!)(errorLegalFolderDocumentsCount);
    }
  }, [loadingLegalFolderDocumentsCount, errorLegalFolderDocumentsCount, addSnackbar]);

  useEffect(() => {
    if (errorLegalFolderDocumentsTotalCounters) {
      apolloErrorHandler(addSnackbar!)(errorLegalFolderDocumentsTotalCounters);
    }
  }, [
    loadingLegalFolderDocumentsTotalCounters,
    errorLegalFolderDocumentsTotalCounters,
    addSnackbar,
  ]);

  useEffect(() => {
    if (!loadingLegalFolderDocumentsCount && dataLegalFolderDocumentsCount) {
      setTotalItems(dataLegalFolderDocumentsCount.contract_documentCount);
      onRecords && onRecords(dataLegalFolderDocumentsCount.contract_documentCount);
    }
  }, [loadingLegalFolderDocumentsCount, dataLegalFolderDocumentsCount, onRecords]);

  useEffect(() => {
    if (!loadingLegalFolderDocumentsTotalCounters && dataLegalFolderDocumentsTotalCounters) {
      onTotals && onTotals(dataLegalFolderDocumentsTotalCounters);
    }
  }, [
    loadingLegalFolderDocumentsTotalCounters,
    dataLegalFolderDocumentsTotalCounters,
    onRecords,
    onTotals,
  ]);

  useEffect(() => {
    if (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
  }, [loading, error, addSnackbar]);

  useEffect(() => {
    if (!loading && data) {
      setLegalFolderDocuments(
        data.contract_documents?.map((projectFolderDocument) => {
          const {
            id,
            name,
            documentType,
            startDate,
            updatedAt,
            createdAt,
            status,
            endDate: expirationDate,
            owners,
            lastAction,
            pendingReviewers,
            currentUserHasActionPending,
          } = projectFolderDocument;
          return {
            id,
            name,
            documentType: documentType?.name || '',
            startDate,
            expirationDate,
            lastModifiedDate: updatedAt,
            createdAt,
            currentlyReviewing: {
              currentUserHasActionPending,
              reviewers: pendingReviewers?.map((reviewer) => reviewer.user?.name),
            },
            lastActionBy: lastAction?.author?.user?.name.length
              ? buildInitials(lastAction?.author?.user?.name)
              : '-',
            ownerNames: owners?.filter((owner) => owner.isEnabled).map((owner) => owner.user?.name),
            status,
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    const { rowsPerPage, page, orderBy, order, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter: { ...filter, onlyCurrentUserDocuments: myTask },
    };

    if (called) {
      refetch!(variables);
    } else {
      loadLegalFolderDocuments({ variables });
    }
  }, [loadLegalFolderDocuments, refetch, called, pageLoadParams, myTask]);

  useEffect(() => {
    const { filter } = pageLoadParams;
    const variables = {
      filter: { ...filter, onlyCurrentUserDocuments: myTask },
    };
    if (calledLegalFolderDocumentsCount) {
      refetchLegalFolderDocumentsCount!(variables);
    } else {
      loadLegalFolderDocumentsCount({ variables });
    }
  }, [
    loadLegalFolderDocumentsCount,
    calledLegalFolderDocumentsCount,
    refetchLegalFolderDocumentsCount,
    pageLoadParams,
    myTask,
  ]);

  useEffect(() => {
    const { filter } = pageLoadParams;
    const variables = {
      totalFilter: { ...filter, onlyCurrentUserDocuments: false },
      userOnlyFilter: { ...filter, onlyCurrentUserDocuments: true },
      inaccessibleFilter: { ...filter, onlyCurrentUserDocuments: false },
    };
    if (calledLegalFolderDocumentsTotalCounters) {
      refetchLegalFolderDocumentsTotalCounters!(variables);
    } else {
      loadLegalFolderDocumentsTotalCounters({ variables });
    }
  }, [
    loadLegalFolderDocumentsTotalCounters,
    calledLegalFolderDocumentsTotalCounters,
    refetchLegalFolderDocumentsTotalCounters,
    pageLoadParams,
    myTask,
  ]);

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onLegalFolderDocumentSelect = useCallback(
    (id: string) => {
      // history.push(`/project-folder-document/${id}`);
      onSelectProjectDocument!(id);
      return true;
    },
    [onSelectProjectDocument]
  );

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const newFilter: DocumentFilter = {
          containerIds: [projectFolderId],
          statusFolders,
          nameContains: filterValues.name || undefined,
          statuses: filterValues.statuses,
          indemnities: filterValues.indemnities,
          startDate: filterValues.startDate || undefined,
          endDate: filterValues.expirationDate || undefined,
          documentTypeIds:
            filterValues.documentType.id !== 'All' ? [filterValues.documentType.id] : undefined,
          updatedAt: filterValues.lastModifiedDate,
          createdAt: filterValues.createdAt,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams, projectFolderId, statusFolders]
  );

  const onFilterChangeRef = useRef(onFilterChange);

  const filterOptions = useMemo(() => {
    const getDocumentTypesPairs = () => {
      return (
        documentTypesData?.contract_documentTypes.map(({ id, name }) => {
          return {
            id,
            name,
          };
        }) || []
      );
    };

    return {
      documentType: [{ id: 'All', name: 'All' }, ...getDocumentTypesPairs()],
    };
  }, [documentTypesData]);

  const filterValues = {
    documentType: { id: 'All', name: 'All' },
  };

  return (
    <div style={{ paddingTop: '24px', minWidth: '100%' }}>
      <Box
        style={{
          borderRadius: '1px',
          boxShadow: '0px 2px 5px #00000030',
          border: '1px solid #00000030',
        }}
      >
        <LoadingOverlay spinner active={loading} text="Loading your content...">
          <Table
            totalItems={totalItems}
            dataCells={projectFolderDocuments}
            headCells={demacProjectFolderDocumentsTableHead}
            loadPage={loadPage}
            handleSelect={onLegalFolderDocumentSelect}
            filterOptions={filterOptions}
            filterValues={filterValues}
            onFilterChange={onFilterChangeRef.current}
            initRowsPerPage={pageLoadParams.rowsPerPage}
            hideFilters
            initOrder={pageLoadParams.order || SortOrder.ASC}
            initOrderBy={pageLoadParams.orderBy}
            initPage={pageLoadParams.page}
          ></Table>
        </LoadingOverlay>
        <Box marginBottom={1} />
      </Box>
    </div>
  );
};
