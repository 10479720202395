import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { join, startCase, toLower, uniqueId } from 'lodash';
import { FC, useCallback, useMemo } from 'react';

import { EventCommentGanttLogItemProps } from './interfaces';

import { DocumentEventType } from 'constants/enums';
import { documentStatusColor } from 'utils/colors';
import { capitalizeFirstLetter } from 'utils/formats';
import { formatTime } from 'utils/time';
import s from './style.module.scss';

import { UserInitials } from 'components';
import { IEvent } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { getVersion } from '../EventCommentLogs';

const changeReportStatusName = (status: string): string => {
  return status
    .replace(/Archived/gi, 'Retired')
    .replace(/Archive/gi, 'Retire')
    .replace(/Report/gi, 'Document')
    .replace(/Party/gi, 'Other Party');
};

export const EventCommentGanttLogItem: FC<EventCommentGanttLogItemProps> = ({
  event,
  usersMap,
  showCommentRef,
}) => {
  const docusignEventInfo = useCallback((val: IEvent): JSX.Element => {
    let payload;
    if (val?.payload) {
      try {
        payload = JSON.parse(val?.payload);
      } catch {
        console.log('Payload is not in JSON format');
      }
    }
    return (
      <>
        {payload.status ? (
          <>
            <br />
            Status: {capitalizeFirstLetter(payload.status)}
          </>
        ) : undefined}
        {/* {payload.oldStatus ? (
          <>
            <br />
            Previous Status: {capitalizeFirstLetter(payload.oldStatus)}
          </>
        ) : undefined} */}
        {/* {payload.sender ? (
          <>
            <br />
            Sender: {payload.sender.name} {'<'}
            {payload.sender.email}
            {'>'}
          </>
        ) : undefined} */}
        {payload.comment ? (
          <>
            <br />
            <div
              style={{
                display: 'inline-block',
                border: '1px solid #ccc',
                padding: '11px 10px',
                borderRadius: '3px',
                width: '100%',
              }}
            >
              {payload.comment}
            </div>
          </>
        ) : undefined}
        {payload.envelopeId ? (
          <>
            <br />
            id: {payload.envelopeId}
          </>
        ) : undefined}
        {payload.signers ? (
          <>
            <br />
            {payload.signers
              .slice()
              .sort(
                (a: any, b: any) => +parseInt(a.routingOrder || 0) - +parseInt(b.routingOrder || 0)
              )
              .map((signer: any) => {
                return (
                  <>
                    <Tooltip
                      title={
                        <>
                          {signer.routingOrder ? (
                            <>
                              Sign Order: #{signer.routingOrder}
                              <br />
                            </>
                          ) : undefined}
                          {`${signer.name} (${signer.email})`}
                        </>
                      }
                    >
                      <div style={{ display: 'inline-block', transform: 'scale(0.7)' }}>
                        <UserInitials {...signer} />
                      </div>
                    </Tooltip>
                  </>
                );
              })}
          </>
        ) : undefined}
      </>
    );
  }, []);

  const commentByEvent = useCallback((val: IEvent): JSX.Element => {
    let text;
    if (val?.payload) {
      try {
        text = JSON.parse(val?.payload).comment;
      } catch {
        console.log('Payload is not in JSON format');
      }
    }

    return (
      <>
        {text && '.'}
        {text && (
          <>
            <br />
            {text}
          </>
        )}
      </>
    );
  }, []);

  const eventType = useCallback(
    (val: IEvent): JSX.Element => (
      <span style={{ color: documentStatusColor(val) }}>
        {changeReportStatusName(startCase(toLower(val?.eventType)))}
      </span>
    ),
    []
  );

  // const content = (val: IEvent): JSX.Element => {
  //   return <div>{val.payload}</div>;
  // };

  const documentCreated = useCallback(
    (val: IEvent): JSX.Element => (
      <div className={s.eventLogItem}>
        {eventType(val)}
        {commentByEvent(val)}
      </div>
    ),
    [commentByEvent, eventType]
  );

  const documentUpdated = (): JSX.Element => <div>Document Information Updated</div>;

  const documentReviewersUpdated = (): JSX.Element => <div>Document Reviewers Updated</div>;

  const documentReviewRequested = useCallback(
    (val: IEvent): JSX.Element => (
      <div>
        {eventType(val)} for {val?.subject?.user?.name || 'Unknown'}
      </div>
    ),
    [eventType]
  );

  const documentVersionDeleted = useCallback(
    (val: IEvent): JSX.Element => {
      let jsonPayload;
      if (val?.payload) {
        try {
          jsonPayload = JSON.parse(val?.payload);
        } catch {}
      }
      const version = jsonPayload?.version;

      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {version ? (
            <>
              <br />
              Version: {getVersion({ version: { version } })}
            </>
          ) : undefined}
          {commentByEvent(val)}
          {val.version?.file?.originalFilename ? (
            <>
              <br />
              Filename: {val.version?.file?.originalFilename}
            </>
          ) : undefined}
          {jsonPayload?.reviewReversalReviewerUserIds?.length &&
          jsonPayload?.reviewReversalToStatus &&
          !!usersMap ? (
            <>
              <br />
              Review status reverted to "
              {capitalizeFirstLetter(
                (jsonPayload.reviewReversalToStatus as string).toLowerCase().replace('_', ' ')
              )}
              " for{' '}
            </>
          ) : undefined}
          {jsonPayload?.reviewReversalReviewerUserIds?.length && !!usersMap
            ? join(
                jsonPayload?.reviewReversalReviewerUserIds.map((id: string) => {
                  return usersMap[id] ? usersMap[id].name || usersMap[id].email : 'User ' + id;
                }),
                ', '
              )
            : undefined}
        </div>
      );
    },
    [commentByEvent, eventType, usersMap]
  );

  const documentVersionCreated = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {val.version?.file?.originalFilename ? (
            <>
              <br />
              Filename: {val.version?.file?.originalFilename}
            </>
          ) : undefined}
          {commentByEvent(val)}
        </div>
      );
    },
    [commentByEvent, eventType]
  );

  const documentUserEvent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {commentByEvent(val)}
        </div>
      );
    },
    [commentByEvent, eventType]
  );

  const documentClientApprovalStatusChanged = useCallback(
    (val: IEvent): JSX.Element => {
      let jsonPayload;
      if (val?.payload) {
        try {
          jsonPayload = JSON.parse(val?.payload);
        } catch {}
      }
      const status =
        jsonPayload && jsonPayload.status
          ? 'status : ' + startCase(toLower(jsonPayload.status))
          : undefined;
      const isRequired =
        jsonPayload && jsonPayload.isRequired !== undefined
          ? 'Required set to ' + jsonPayload.isRequired.toString()
          : undefined;
      return (
        <div>
          {eventType(val)} <br />
          {status ? (
            <>
              {status} <br />{' '}
            </>
          ) : undefined}
          {isRequired ? (
            <>
              {isRequired} <br />{' '}
            </>
          ) : undefined}
        </div>
      );
    },
    [eventType]
  );

  const documentOwnerChanged = useCallback(
    (val: IEvent): JSX.Element => {
      // val.payload is like {"ownerUserId":"294"}
      return <div>{eventType(val)}</div>;
    },
    [eventType]
  );

  const documentMonetaryTtransactionBreakdownChanged = useCallback(
    (val: IEvent): JSX.Element => {
      // val.payload is like {"monetaryTransactionBreakdownsToAdd":[{"value":1111111,"startTimestamp":"2020-01-31T23:00:00.000Z"}]}
      return <div>{eventType(val)}</div>;
    },
    [eventType]
  );

  const documentReviewRecalled = useCallback(
    (val: IEvent): JSX.Element => (
      <div>
        {eventType(val)} for {val?.author?.user?.name}
      </div>
    ),
    [eventType]
  );

  const documentReviewResent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div>
          {eventType(val)} for {val?.subject?.user?.name}
        </div>
      );
    },
    [eventType]
  );

  const documentCommented: (val: IEvent) => JSX.Element = useCallback(
    (val: IEvent): JSX.Element => {
      let text;
      let quoteEventId;
      if (val.payload) {
        try {
          text = JSON.parse(val.payload).comment;
          quoteEventId = JSON.parse(val.payload).refEventId;
        } catch {}
      }
      return showCommentRef.current && showCommentRef.current(val, text, quoteEventId);
    },
    [showCommentRef]
  );

  // -------------------------------------------------
  const content = useCallback(
    (val: IEvent): JSX.Element => {
      switch (val.eventType) {
        case DocumentEventType.DOCUMENT_CREATED:
          return documentCreated(val);

        case DocumentEventType.DOCUMENT_VERSION_DELETED:
          return documentVersionDeleted(val);

        case DocumentEventType.DOCUMENT_VERSION_CREATED:
          return documentVersionCreated(val);

        case DocumentEventType.DOCUMENT_COMMENTED:
        case DocumentEventType.DOCUMENT_COMMENT_REPLIED:
          return documentCommented(val);

        case DocumentEventType.DOCUMENT_OBSERVER_NOTIFICATION_SENT:
        case DocumentEventType.DOCUMENT_REVIEW_REQUESTED:
          return documentReviewRequested(val);

        case DocumentEventType.DOCUMENT_REVIEW_RESENT:
          return documentReviewResent(val);

        case DocumentEventType.DOCUMENT_PARTY_REVIEW_STATUS_CHANGED:
        case DocumentEventType.DOCUMENT_CLIENT_APPROVAL_STATUS_CHANGED:
          return documentClientApprovalStatusChanged(val);

        case DocumentEventType.DOCUMENT_UPDATED:
          return documentUpdated();

        case DocumentEventType.DOCUMENT_REVIEWERS_UPDATED:
          return documentReviewersUpdated();

        case DocumentEventType.DOCUMENT_REVIEW_RECALLED:
          return documentReviewRecalled(val);

        case DocumentEventType.DOCUMENT_REVIEW_APPROVED:
        case DocumentEventType.DOCUMENT_ALL_REVIEWS_COMPLETED:
        case DocumentEventType.DOCUMENT_REVIEW_DISAPPROVED:
        case DocumentEventType.DOCUMENT_REVIEW_POSTPONED:
        case DocumentEventType.DOCUMENT_ARCHIVED:
        case DocumentEventType.DOCUMENT_ARCHIVE_INITIATED:
        case DocumentEventType.DOCUMENT_ARCHIVE_DOCUMENT_STORED:
        case DocumentEventType.DOCUMENT_CANCELED:
          return documentUserEvent(val);

        case DocumentEventType.DOCUMENT_FILE_DELETED:
          return <div>Support Document Deleted</div>;
        case DocumentEventType.DOCUMENT_FILE_UPLOADED:
          return <div>Support Document Uploaded</div>;

        case DocumentEventType.DOCUMENT_OWNER_CHANGED:
          return documentOwnerChanged(val);

        case DocumentEventType.DOCUMENT_MONETARY_TRANSACTION_BREAKDOWN_CHANGE:
          return documentMonetaryTtransactionBreakdownChanged(val);

        case DocumentEventType.DOCUMENT_DOCUSIGN_ENVELOPE_STATUS_CHANGED:
        case DocumentEventType.DOCUMENT_DOCUSIGN_CREATED:
        case DocumentEventType.DOCUMENT_DOCUSIGN:
          return (
            <div>
              {eventType(val)}
              {docusignEventInfo(val)}
            </div>
          );

        default:
          return <div>{eventType(val)}</div>;
      }
    },
    [
      eventType,
      documentClientApprovalStatusChanged,
      documentCommented,
      documentCreated,
      documentMonetaryTtransactionBreakdownChanged,
      documentOwnerChanged,
      documentReviewRecalled,
      documentReviewRequested,
      documentReviewResent,
      documentUserEvent,
      documentVersionCreated,
      documentVersionDeleted,
      docusignEventInfo,
    ]
  );

  const user = (data: any): string => {
    return data?.author?.user?.name ?? data?.authorUser?.name ?? 'Undefined Author';
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const ganttEventsItem = useMemo(() => {
    return (
      <div key={uniqueId()}>
        <div>
          <div className="event-comment-log-header">
            <span className="event-comment-log-header-title">
              <strong>
                {user(event)}
                {/* &nbsp;-&nbsp;
                {getVersion(data)} */}
              </strong>
            </span>
            <span className="event-comment-log-header-date">
              {isSmall ? <br /> : undefined}
              {formatTime(event.createdAt)}
            </span>
          </div>
          <div />
          <div>{content(event)}</div>
        </div>
      </div>
    );
  }, [content, isSmall, event]);

  return <>{ganttEventsItem}</>;
};
