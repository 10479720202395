import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { keys } from 'lodash';

import './index.scss';
import s from './style.module.scss';

import { useHistory, useLocation } from 'react-router-dom';

import EasyAutoComplete from 'components/EasyAutoComplete';
import { Contacts } from '../Contacts/Contacts';
import { NavigationTabs, documentTabs } from '../NavigationTabs';

import { useComponentContext } from '../../LegalFolderDocumentContext';

import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { GET_DOCUMENT_TYPES, GET_PROJECT_FOLDER_DOCUMENTS } from 'graphql/legalFolders/documents';
import { documentTypes } from 'graphql/legalFolders/types/documentTypes';
import { getLegalFolderDocuments } from 'graphql/legalFolders/types/getLegalFolderDocuments';
import { Clear } from '@mui/icons-material';
import { FileDarkIcon } from 'images/icons';

import { containerIndemnity } from 'constants/indemnities';
import { paths } from 'constants/index';

import { DocumentIndemnity } from 'graphql/legalFolders/types/graphql-types';

import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useUI } from 'contexts/UiContext';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';

// const dateStyle = {
//   backgroundColor: '#fff',
//   borderRadius: 4,
//   border: '1px solid #aaa',
// };
interface IGeneralListItem {
  id: string;
  name: string;
}

export const DocumentView = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { addSnackbar } = useUI();

  const [documentTypes, setDocumentTypes] = useState<Array<IGeneralListItem>>();
  const [parentDocumentName, setParentDocumentName] = useState<string>();
  const [documentNamesList, setDocumentNameList] = useState<IGeneralListItem[]>();

  const { data: documentTypesData, loading: documentTypesLoading } =
    useQuery<documentTypes>(GET_DOCUMENT_TYPES);

  const [
    loadLegalFolderDocuments,
    {
      called: loadLegalFolderDocumentsCalled,
      data: loadLegalFolderDocumentsData,
      loading: loadLegalFolderDocumentsLoading,
      refetch: loadLegalFolderDocumentsRefetch,
      error: loadLegalFolderDocumentsError,
    },
  ] = useLazyQuery<getLegalFolderDocuments>(GET_PROJECT_FOLDER_DOCUMENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (loadLegalFolderDocumentsError) {
      apolloErrorHandler(addSnackbar!)(loadLegalFolderDocumentsError);
    }
  }, [loadLegalFolderDocumentsLoading, loadLegalFolderDocumentsError, addSnackbar]);

  useEffect(() => {
    if (documentTypesData && !documentTypesLoading) {
      setDocumentTypes(
        documentTypesData.contract_documentTypes.map((documentType) => {
          return {
            id: documentType.id,
            name: `${documentType.name}`,
          };
        })
      );
    }
  }, [documentTypesData, documentTypesLoading]);

  const {
    projectFolderDocument,
    onAddNewContact,
    onSetLegalFolderDocument,
    onSubmit,
    setContact,
    deleteContact,
  } = useComponentContext();

  const {
    name,
    showValidator,
    errors,
    startDate,
    endDate,
    notes,
    specialInstructions,
    contacts,
    projectFolderName,
    projectFolderId,
    selectedDocumentType,
    selectedParentDocument,
    isOriginParty,
    indemnity,
    includesIndemnity,
    customIndemnity,
    id,
    childDocuments,
    isPartyReviewRequired,
  } = projectFolderDocument;

  useEffect(() => {
    const variables = {
      take: 20,
      skip: 0,
      sort: [{ column: 'NAME', order: 'ASC' }],
      filter: { containerIds: [projectFolderId] },
    };

    if (loadLegalFolderDocumentsCalled) {
      loadLegalFolderDocumentsRefetch!(variables);
    } else {
      loadLegalFolderDocuments({ variables });
    }
  }, [
    loadLegalFolderDocuments,
    loadLegalFolderDocumentsRefetch,
    loadLegalFolderDocumentsCalled,
    parentDocumentName,
    projectFolderId,
  ]);

  const usedDocuments = useMemo(
    () => [id, ...childDocuments.map((childDocument) => childDocument.id)],
    [id, childDocuments]
  );

  const handleSetPage = useCallback(
    (newPage: documentTabs) => {
      if (newPage === documentTabs.FILE_MANAGEMENT) {
        history.push(`${pathname}/file-management`);
      }
    },
    [history, pathname]
  );

  useEffect(() => {
    if (loadLegalFolderDocumentsData && !loadLegalFolderDocumentsLoading) {
      setDocumentNameList(
        () =>
          loadLegalFolderDocumentsData.contract_documents
            ?.map((document) => ({
              id: document.id,
              name: document.name,
            }))
            .filter((document) => !usedDocuments.includes(document.id)) || []
      );
    }
  }, [loadLegalFolderDocumentsData, loadLegalFolderDocumentsLoading, usedDocuments]);

  return (
    <Container maxWidth="md">
      <div>
        <Box m={4} />
        <NavigationTabs
          page={documentTabs.DOCUMENT_VIEW}
          handleSetPage={handleSetPage}
          newDocument={!parseInt(id)}
        />
      </div>
      <Paper elevation={3} style={{ padding: '1rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box m={5} />

              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Document name:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onSetLegalFolderDocument!((oldLegalFolder) => {
                          return {
                            ...oldLegalFolder,
                            name: e.target.value,
                          };
                        });
                      }}
                      fullWidth
                      error={showValidator && !!errors?.name}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Project folder:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={projectFolderName}
                      onChange={() => {}}
                      fullWidth
                      error={showValidator && !!errors?.projectFolderName}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Owner:
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={owner?.name}
                      onChange={() => {}}
                      fullWidth
                      error={showValidator && !!errors?.ownerName}
                      disabled={true}
                    />
                  </Grid> */}

                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Type:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <EasyAutoComplete
                      key={`owner-${selectedDocumentType?.id}`}
                      items={documentTypes || []}
                      label=""
                      textFieldStyle="outlined"
                      optionsLabel="name"
                      selected={selectedDocumentType}
                      selectedChanged={(value: IGeneralListItem) => {
                        onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                          return {
                            ...oldLegalFolderDocument,
                            selectedDocumentType: value,
                          };
                        });
                      }}
                      error={showValidator && !!errors?.selectedDocumentType}
                      getOptionSelected={(option: any, value: any) => {
                        return option.id === value.id;
                      }}
                    />
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
          <Box m={5} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Parent document:
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <div
                      onClick={debounce(() => {
                        if (selectedParentDocument) {
                          history.push(
                            paths.client.PROJECT_FOLDER_DOCUMENT_ID.replace(
                              ':documentId',
                              selectedParentDocument?.id
                            )
                          );
                        }
                      }, DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      <FileDarkIcon className={s.documentIcon}></FileDarkIcon>
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <EasyAutoComplete
                      key={`owner-${selectedParentDocument?.id}`}
                      items={documentNamesList || []}
                      label=""
                      textFieldStyle="outlined"
                      optionsLabel="name"
                      selected={selectedParentDocument}
                      onInputChange={(event: object, value: string, reason: string) => {
                        setParentDocumentName(value);
                      }}
                      selectedChanged={(value: IGeneralListItem) => {
                        onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                          return {
                            ...oldLegalFolderDocument,
                            selectedParentDocument: value,
                          };
                        });
                      }}
                      error={showValidator && !!errors?.selectedParentDocument}
                      getOptionSelected={(option: any, value: any) => {
                        return option.id === value.id;
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <div
                      onClick={debounce(() => {
                        onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                          return {
                            ...oldLegalFolderDocument,
                            selectedParentDocument: undefined,
                          };
                        });
                      }, DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Clear fontSize="small" />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Child documents:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {!childDocuments?.length ? (
                      'The child documents list is empty. This document can be added as a parent to other documents.'
                    ) : (
                      <Grid container>
                        {childDocuments.map((childDocument) => {
                          return (
                            <div key={childDocument.id} style={{ display: 'contents' }}>
                              <Grid item xs={2}>
                                <div
                                  onClick={debounce(() => {
                                    history.push(
                                      paths.client.PROJECT_FOLDER_DOCUMENT_ID.replace(
                                        ':documentId',
                                        childDocument?.id
                                      )
                                    );
                                  }, DEBOUNCE_TIMEOUT)}
                                >
                                  <FileDarkIcon className={s.documentIcon}></FileDarkIcon>
                                </div>
                              </Grid>
                              <Grid item xs={10}>
                                <EasyAutoComplete
                                  key={`owner-${childDocument?.id}`}
                                  items={documentNamesList || []}
                                  label=""
                                  textFieldStyle="outlined"
                                  optionsLabel="name"
                                  selected={childDocument}
                                  onInputChange={() => {
                                    //todo
                                  }}
                                  selectedChanged={() => {
                                    //todo
                                  }}
                                  getOptionSelected={(option: any, value: any) => {
                                    return option.id === value.id;
                                  }}
                                  disabled
                                />
                              </Grid>
                            </div>
                          );
                        })}
                      </Grid>
                    )}
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Origin:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <RadioGroup
                      row
                      aria-label="isOriginParty"
                      name="position"
                      value={isOriginParty ? 'Party' : 'D&M'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                          return {
                            ...oldLegalFolderDocument,
                            isOriginParty: e.target.value === 'Party',
                          };
                        });
                      }}
                    >
                      <FormControlLabel
                        value="D&M"
                        control={<Radio color="primary" />}
                        label="D&M"
                      />
                      <FormControlLabel
                        value="Party"
                        control={<Radio color="primary" />}
                        label="Party"
                      />
                    </RadioGroup>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Party approval:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <RadioGroup
                      row
                      aria-label="isOriginParty"
                      name="position"
                      value={isPartyReviewRequired ? '1' : '0'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                          return {
                            ...oldLegalFolderDocument,
                            isPartyReviewRequired: e.target.value === '1',
                          };
                        });
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Required"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="Not required"
                      />
                    </RadioGroup>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Indemnity:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormControl variant="outlined">
                      <Select
                        native
                        value={
                          indemnity || includesIndemnity
                            ? ([indemnity || includesIndemnity] as string[])
                            : []
                        }
                        onChange={(event: SelectChangeEvent<string[]>) => {
                          const newValue: DocumentIndemnity | undefined =
                            event.target.value?.length &&
                            event.target.value[0] === includesIndemnity
                              ? undefined
                              : (event.target.value[0] as DocumentIndemnity);

                          onSetLegalFolderDocument!((oldLegalFolder) => {
                            return {
                              ...oldLegalFolder,
                              indemnity: newValue,
                            };
                          });
                        }}
                        inputProps={{
                          name: 'age',
                          id: 'outlined-age-native-simple',
                        }}
                      >
                        {keys(containerIndemnity).map((key) => {
                          return (
                            <option value={key} key={key}>
                              {containerIndemnity[key as keyof typeof containerIndemnity]}
                            </option>
                          );
                        })}
                        {customIndemnity ? (
                          <option value={includesIndemnity} key={includesIndemnity}>
                            {includesIndemnity}
                          </option>
                        ) : undefined}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={3}>
                    <Typography variant="body2" className="label-title">
                      Start date:
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      value={startDate}
                      onChange={(date) => {
                        onSetLegalFolderDocument!((oldLegalFolder) => {
                          return {
                            ...oldLegalFolder,
                            startDate: date,
                          };
                        });
                        // setSelected({ ...selected, [headCell.id]: date });
                      }}
                      componentsProps={{
                        actionBar: { actions: ['clear'] as PickersActionBarAction[] },
                      }}
                      renderInput={(props) => (
                        <TextField variant="outlined" fullWidth size="small" {...props} />
                      )}
                    ></DatePicker>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" className="label-title">
                      End date:
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      value={endDate}
                      onChange={(date) => {
                        onSetLegalFolderDocument!((oldLegalFolder) => {
                          return {
                            ...oldLegalFolder,
                            endDate: date,
                          };
                        });
                        // setSelected({ ...selected, [headCell.id]: date });
                      }}
                      componentsProps={{
                        actionBar: { actions: ['clear'] as PickersActionBarAction[] },
                      }}
                      renderInput={(props) => (
                        <TextField variant="outlined" fullWidth size="small" {...props} />
                      )}
                    ></DatePicker>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Other contacts:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}></Grid>

                  <Grid item xs={12}>
                    <Contacts
                      contacts={contacts}
                      setContact={setContact!}
                      deleteContact={deleteContact!}
                    ></Contacts>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={onAddNewContact && debounce(onAddNewContact, DEBOUNCE_TIMEOUT)}
                      >
                        Add contact
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}></Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="label-title">
                      Reviewers:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}></Grid>

                  <Grid item xs={12}>
                    {/* <Reviewers
                      readOnly={false}
                      editMode
                      onChange={stakeholderListChanged}
                      reviewers={reviewers}
                      projectLeaders={[]}
                    ></Reviewers> */}
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="center" alignItems="center">
                <>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body2" className="label-title">
                          notes:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          size="small"
                          multiline
                          value={notes || ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onSetLegalFolderDocument!((oldLegalFolder) => {
                              return {
                                ...oldLegalFolder,
                                notes: e.target.value,
                              };
                            });
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body2" className="label-title">
                          Special instructions:
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          size="small"
                          multiline
                          value={specialInstructions || ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onSetLegalFolderDocument!((oldLegalFolder) => {
                              return {
                                ...oldLegalFolder,
                                specialInstructions: e.target.value,
                              };
                            });
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>

          <Box m={5} />

          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={debounce(() => {
                  history.push(paths.client.PROJECT_FOLDERS);
                }, DEBOUNCE_TIMEOUT)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit && debounce(onSubmit, DEBOUNCE_TIMEOUT)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
};
