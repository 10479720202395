import React, { FC, useEffect, useMemo, useState } from 'react';
import { EasyAutoComplete } from 'components';
import { useProjectFolders } from './hooks';
import { find } from 'lodash';

interface IItem {
  id: string;
  name: string;
}

export interface ISelectProjectFolderProps {
  legalFolderId?: string;
  onSelectChange?: (selectedItem: IItem) => void;
  selectedItem?: IItem | null;
  [key: string]: any;
}

export const SelectProjectFolder: FC<ISelectProjectFolderProps> = ({
  onSelectChange,
  selectedItem,
  legalFolderId,
  ...props
}) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!selectedItem && search !== '') {
      setSearch('');
    }
  }, [selectedItem, search]);

  const { projectFolders, maxPage, loadPage, loadPageCount } = useProjectFolders({
    search,
    legalFolderId,
  });

  const projectFoldersList = useMemo(() => {
    if (
      !selectedItem ||
      find(projectFolders, (projectFolder) => projectFolder.id === selectedItem.id)
    ) {
      return [...projectFolders];
    }
    return [selectedItem, ...projectFolders];
  }, [projectFolders, selectedItem]);

  return (
    <EasyAutoComplete
      key={selectedItem?.id || '_'}
      items={projectFoldersList || [selectedItem]}
      selected={selectedItem}
      label=""
      textFieldStyle="outlined"
      optionsLabel="name"
      selectedChanged={(value: any): void => {
        onSelectChange && onSelectChange(value);
      }}
      error={false}
      pagination={{
        pages: maxPage,
        loadPage: (page: number) => {
          loadPage(page);
          loadPageCount();
        },
      }}
      onInputChange={(event: object, value: string, reason: string) => {
        setSearch(value);
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.id === value.id || (value.id === '0' && option.email === value.email);
      }}
      fullWidth
      {...props}
      style={{
        ...props.style,
        // minWidth: '9.375rem'
      }}
      disabled={!legalFolderId}
      onOpen={() => {
        setSearch('');
      }}
    />
  );
};
