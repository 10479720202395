/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import {
  Typography,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useMutation, useApolloClient } from '@apollo/client';

import { Delete as DeleteIcon } from '@mui/icons-material';

import { Upload, ConfirmationDialog, AlertDialog } from 'components';
import { id } from 'utils/id';

import { useUpload } from './hooks';
import * as gql from 'graphql';

import { azureStorageUpload } from 'utils/azureStorageUpload';

import './index.scss';
import {
  DOCUMENT_SUPPORT_FILE_CREATE,
  DOCUMENT_SUPPORT_FILE_DELETE,
} from 'graphql/legalFolders/fileManager';
import { IDocumentSupportFile } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface ISupportFilesProps {
  documentId: number;
  supportFiles: IDocumentSupportFile[];
  refetchDocument: any;
}

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export const SupportFiles: FC<ISupportFilesProps> = ({
  documentId,
  supportFiles,
  refetchDocument,
}) => {
  const classes = useStyles();
  const [uploadFile, { loading: uploadingFile }] = useMutation(DOCUMENT_SUPPORT_FILE_CREATE);

  const [deleteFile, { loading: deletingFile }] = useMutation(DOCUMENT_SUPPORT_FILE_DELETE);

  const apolloClient = useApolloClient();

  const { isUploading, percentage, onDrop, removeFile, files } = useUpload();

  const [isRefetching, setIsRefetching] = useState(false);

  // #region Delete File Confirmation states

  const [deleteFileConfirmationMessage, setDeleteFileConfirmationMessage] = useState('');
  const [deleteFileConfirmationOpen, setDeleteFileConfirmationOpen] = useState(false);
  const [deleteFileConfirmationFileId, setDeleteFileConfirmationFileId] = useState(0);

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [mutationErrorOpen, setMutationErrorOpen] = useState(false);
  // #endregion

  const submitFileHandler = async () => {
    if (!files || files.length === 0) return;

    setIsRefetching(true);

    const file = files[0];
    const uploadResult = await azureStorageUpload(
      apolloClient,
      'SUPPORT_DOCUMENT',
      documentId,
      file.name,
      file
    );

    // TODO, how to handle this?
    if (uploadResult.hasErrors) {
      setIsRefetching(false);

      setMutationErrorMessage(uploadResult.errorMessage);
      setMutationErrorOpen(true);
      return;
    }

    const uploadedFile = {
      blobContainerName: uploadResult.containerName,
      blobName: uploadResult.blobName,
      fileFilename: uploadResult.fileFilename,
      fileMimeType: uploadResult.fileMimeType,
    };

    uploadFile({ variables: { documentId: documentId, uploadedFile } })
      .then(() => {
        removeFile();
        refetchDocument({ variables: { id: id() } }).then(() => {
          setIsRefetching(false);
        });
      })
      .catch((e) => {
        setMutationErrorMessage(e?.message);
        setMutationErrorOpen(true);
      });
  };

  const deleteFileConfirmationCloseHandler = (ok: boolean): void => {
    setDeleteFileConfirmationOpen(false);

    if (ok) {
      setIsRefetching(true);

      deleteFile({
        variables: { documentFileId: deleteFileConfirmationFileId, documentId: documentId },
      })
        .then(() => {
          refetchDocument({ variables: { id: id() } }).then(() => {
            setIsRefetching(false);
          });
        })
        .catch((e) => {
          setMutationErrorMessage(e?.message);
          setMutationErrorOpen(true);
        });
    }
  };

  const deleteFileHandler = (fileId: any, fileName: string) => {
    if (!document) return;

    setDeleteFileConfirmationFileId(fileId);
    setDeleteFileConfirmationMessage(`Are you sure you want to delete file ${fileName}?`);
    setDeleteFileConfirmationOpen(true);
  };

  const somethingIsLoading = (): boolean => {
    return uploadingFile || deletingFile || isRefetching;
  };

  const noFilesInfo = () => {
    if (somethingIsLoading()) return <></>;
    if (document && supportFiles.length > 0) return <></>;

    return (
      <>
        <Typography>
          <u>No support documents</u>
        </Typography>

        <Box marginBottom={2} />
        <Divider />
      </>
    );
  };

  const getTimeStamp = (file: any): string => {
    try {
      const dt = new Date(file.createdAt);

      return `${dt.toLocaleString()}`;
    } catch {
      return '';
    }
  };

  return (
    <>
      <AlertDialog
        title="Error"
        message={mutationErrorMessage}
        open={mutationErrorOpen}
        onClose={() => {
          setMutationErrorOpen(false);
        }}
      />

      <ConfirmationDialog
        title="Delete file"
        message={deleteFileConfirmationMessage}
        open={deleteFileConfirmationOpen}
        onClose={deleteFileConfirmationCloseHandler}
      />

      {noFilesInfo()}

      {!somethingIsLoading() && supportFiles.length > 0 && (
        <>
          <List>
            {supportFiles.map((file: any) => (
              <ListItem
                key={`li_${file.originalFilename}`}
                dense
                button
                onClick={debounce(() => {
                  window.open(file?.downloadUrl, '_blank');
                }, DEBOUNCE_TIMEOUT)}
              >
                <ListItemText primary={file.originalFilename} secondary={getTimeStamp(file)} />

                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={debounce(() => {
                      deleteFileHandler(file.id, file.originalFilename);
                    }, DEBOUNCE_TIMEOUT)}
                    style={{ padding: '12px' }}
                    className={classes.hoverWarning}
                  >
                    <Tooltip
                      title={`Filename: ${file.originalFilename}`}
                      placement="bottom-start"
                      arrow
                    >
                      <DeleteIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

          <Box marginBottom={2} />
          <Divider />
        </>
      )}

      <Box marginBottom={3} />

      {!somethingIsLoading() && (
        <>
          <Upload
            onDrop={onDrop}
            removeFile={removeFile}
            // isUploading={isUploading}
            percentage={percentage}
            files={files}
            currentDocumentFileVersion={null}
            btnSize="small"
            smallLayout
            formId="supportfiles_upload"
          />

          {files && files.length > 0 && (
            <>
              <Box marginBottom={3} />

              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={debounce(submitFileHandler, DEBOUNCE_TIMEOUT)}
              >
                Submit file
              </Button>
            </>
          )}
        </>
      )}

      {somethingIsLoading() && <Skeleton variant="rectangular" animation="wave" height={200} />}

      <Box marginBottom={3} />
    </>
  );
};
