import { useLazyQuery } from '@apollo/client';
import { EasyAutoComplete } from 'components';
import { GET_PARTY_COUNT, GET_PARTY_NAMES } from 'graphql/legalFolders/parties';
import { partyCount } from 'graphql/legalFolders/types/partyCount';
import { partyNames } from 'graphql/legalFolders/types/partyNames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';

const rowsPerPage = 20;
const orderBy = 'NAME';
const order = 'ASC';

interface IPartiesListItem {
  key: string;
  name: string;
  clientCode: string | null;
  ddLabel: string;
}

export interface ISelectOnePartyProps {
  onSelectChange: (id: string) => void;
  error?: boolean;
  usedPartyIds: string[];
}

export const SelectOneParty: FC<ISelectOnePartyProps> = ({
  onSelectChange,
  error,
  usedPartyIds,
}) => {
  const [search, setSearch] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [parties, setParties] = useState<IPartiesListItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<IPartiesListItem>();

  const [
    loadPartyCount,
    {
      data: dataPartyCount,
      loading: loadingPartyCount,
      refetch: refetchPartyCount,
      called: calledPartyCount,
    },
  ] = useLazyQuery<partyCount>(GET_PARTY_COUNT);

  const [getPartyNames, { data, loading, refetch, called, error: getPartyNamesError }] =
    useLazyQuery<partyNames>(GET_PARTY_NAMES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadPage = useCallback(
    (page: number, search: string) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
        filter: { projectSetupClientCodeOrNameContains: search },
      };

      if (called) {
        refetch!(variables);
      } else {
        getPartyNames({ variables });
      }
    },
    [getPartyNames, refetch, called]
  );

  const loadPageCount = useCallback(
    (search: string) => {
      const variables = {
        filter: { projectSetupClientCodeOrNameContains: search },
      };

      if (calledPartyCount) {
        refetchPartyCount!(variables);
      } else {
        loadPartyCount({ variables });
      }
    },
    [loadPartyCount, refetchPartyCount, calledPartyCount]
  );

  useEffect(() => {
    if (!loadingPartyCount && dataPartyCount) {
      setTotalItems(dataPartyCount.partyCount);
    }
  }, [loadingPartyCount, dataPartyCount]);

  useEffect(() => {
    if (data && !loading && !getPartyNamesError) {
      const ddList = data.parties
        .filter((party) => !usedPartyIds.includes(party.id))
        .map((party) => {
          return {
            key: party.id,
            name: party.name,
            clientCode: party.projectSetupClientCode,
            ddLabel: (party.projectSetupClientCode || 'Non-Client') + ' - ' + party.name,
          };
        });
      setParties((parties) => {
        return [...parties, ...ddList];
      });
    }
  }, [data, loading, getPartyNamesError, usedPartyIds]);

  useEffect(() => {
    setParties([]);
    loadPage(0, search);
    loadPageCount(search);
  }, [search, loadPage, loadPageCount]);

  const maxPage = useMemo(() => Math.ceil(Number(totalItems) / Number(rowsPerPage)), [totalItems]);

  return (
    <EasyAutoComplete
      items={parties}
      selected={selectedItem}
      label=""
      textFieldStyle="outlined"
      optionsLabel="ddLabel"
      selectedChanged={(value: any): void => {
        onSelectChange(value.key);
        setSelectedItem(value);
      }}
      error={error}
      pagination={{
        pages: maxPage,
        loadPage: (page: number) => {
          loadPage(page, search);
        },
      }}
      onInputChange={(event: object, value: string, reason: string) => {
        setSearch(value);
      }}
    />
  );
};
