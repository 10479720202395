import React, { FC } from 'react';
import { Container, Paper, Box, useTheme, useMediaQuery } from '@mui/material';

interface IFrameProps {
  children: any;
}

export const Frame: FC<IFrameProps> = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '40px', paddingRight: '40px', paddingTop: '24px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '24px 1.5rem 16px 1.5rem', margin: '6px' }
            : { padding: '5px .5rem 5px .5rem', margin: '6px' }
        }
        id="main-paper"
      >
        <Box marginBottom={2} marginTop={1} style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }}>
          {children}
        </Box>
      </Paper>
    </Container>
  );
};
