import { useAutoCompleteDataSource } from './autoCompleteDataSource';

import {
  getLegalFolderDocuments,
  getLegalFolderDocuments_contract_documents,
} from 'graphql/legalFolders/types/getLegalFolderDocuments';
import { getLegalFolderDocumentsCount } from 'graphql/legalFolders/types/getLegalFolderDocumentsCount';
import { DocumentFilter } from 'graphql/legalFolders/types/graphql-types';

import {
  GET_PROJECT_FOLDER_DOCUMENTS_COUNT,
  GET_PROJECT_FOLDER_DOCUMENTS_NAMES,
} from 'graphql/legalFolders/documents';

const getCount = (count: getLegalFolderDocumentsCount) => count.contract_documentCount;
const getItems = (data: getLegalFolderDocuments) => data.contract_documents;
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ nameContains: localSearch });

export interface IDocumentsNamesAutocompleteDataHook {
  search: string;
}

export const useDocumentsNamesAutocompleteDataHook = ({
  search,
}: IDocumentsNamesAutocompleteDataHook) => {
  const resp = useAutoCompleteDataSource<
    getLegalFolderDocuments,
    getLegalFolderDocumentsCount,
    getLegalFolderDocuments_contract_documents,
    DocumentFilter
  >({
    GET_QUERY: GET_PROJECT_FOLDER_DOCUMENTS_NAMES,
    GET_COUNT_QUERY: GET_PROJECT_FOLDER_DOCUMENTS_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};
