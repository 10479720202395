import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ActiveTab } from 'template/TreeTable/components/TableSection/TableSectionContext';
import { TreeTable } from 'template/TreeTable/TreeTable';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { useProjectFolderPath } from './hooks';

interface IUrlParams {
  id: string;
  tab?: string;
}

export const TreeTableProjectFolder: FC = () => {
  const { initPage } = useComponentContext();
  const { id: projectFolderId, tab } = useParams<IUrlParams>();
  const { legalFolderId, error } = useProjectFolderPath({ projectFolderId });

  useEffect(() => {
    if (projectFolderId && legalFolderId) {
      initPage!({
        wellcomeProp: false,
        initTabProp: (tab?.toUpperCase() || 'PF') as ActiveTab,
        selectedFolderProp: { legalFolderId, projectFolderId },
        documentTabProp: 'list',
      });
    }
  }, [initPage, projectFolderId, legalFolderId, tab]);

  return <TreeTable projectFolderInvalid={error}></TreeTable>;
};
