import React, { FC, useCallback, useMemo } from 'react';
import { filter } from 'lodash';

export type snackbarSeverityType = 'error' | 'warning' | 'info' | 'success';
type snackbarText = string;

export type SnackbarType = {
  id?: string;
  severity: snackbarSeverityType;
  text: snackbarText;
  open?: boolean;
};
export interface UIContextState {
  snackbars: Array<SnackbarType>;
}

export interface UIContextActions {
  closeSnackbar: (id?: string) => void;
  addSnackbar: (snackbar: SnackbarType) => void;
}

const initialState = {
  snackbars: [],
};

type Action =
  | {
      type: 'ADD_SNACKBAR';
      snackbar: SnackbarType;
    }
  | {
      type: 'CLOSE_SNACKBAR';
      id?: string;
    };

export const UIContext = React.createContext<UIContextState & Partial<UIContextActions>>(
  initialState
);

UIContext.displayName = 'UIContext';

function uiReducer(state: UIContextState, action: Action) {
  switch (action.type) {
    case 'ADD_SNACKBAR': {
      return {
        ...state,
        snackbars: [...state.snackbars, action.snackbar],
      };
    }
    case 'CLOSE_SNACKBAR': {
      let newStateSnackbars: Array<SnackbarType> = [];
      if (action.id) {
        newStateSnackbars = filter(state.snackbars, (curent) => curent.id !== action.id);
      }
      return {
        ...state,
        snackbars: newStateSnackbars,
      };
    }
  }
}

export const UIProvider: FC<{ children: any; [x: string]: any }> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);

  const addSnackbar = useCallback((snackbar: SnackbarType) => {
    if (!snackbar.id) {
      snackbar.id = snackbar.text + '_' + snackbar.severity;
    }
    dispatch({ type: 'ADD_SNACKBAR', snackbar: snackbar });
  }, []);

  const closeSnackbar = useCallback(
    (id?: string) => dispatch({ type: 'CLOSE_SNACKBAR', id: id }),
    []
  );

  const value = useMemo(
    () => ({
      ...state,
      addSnackbar,
      closeSnackbar,
    }),
    [state, addSnackbar, closeSnackbar]
  );

  return <UIContext.Provider value={value} {...props} />;
};

export const useUI = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export const ManagedUIContext: FC<{ children: any }> = ({ children }) => (
  <UIProvider>{children}</UIProvider>
);
