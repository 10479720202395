import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  GET_PROJECT_FOLDERS_COUNT,
  GET_TREE_PROJECT_FOLDERS,
} from 'graphql/legalFolders/legalFolders';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { getProjectFolders } from 'graphql/legalFolders/types/getProjectFolders';
import { getProjectFoldersCount } from 'graphql/legalFolders/types/getProjectFoldersCount';
import { pick } from 'lodash';
import { IProjectFoldersList } from '../interface';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
export interface ILegalFolderTreeProps {
  search?: string;
  legalFolderId: string;
  expanded: boolean;
  myTask: boolean;
}

export const useProjectFolders = ({
  search,
  legalFolderId,
  expanded,
  myTask,
}: ILegalFolderTreeProps) => {
  const client = useApolloClient();

  const reloadTreeRef = useRef<any>(null);
  const stateRef = useRef<any>(null);

  const { treeProjectFolders, setTreeProjectFolders } = useComponentContext();

  const state = useMemo(() => {
    return treeProjectFolders ? treeProjectFolders[legalFolderId] : undefined;
  }, [treeProjectFolders, legalFolderId]);

  const setState = useCallback(
    (cb: (old: IProjectFoldersList | undefined) => IProjectFoldersList) => {
      setTreeProjectFolders &&
        setTreeProjectFolders((treeState) => {
          const newProjectFoldersList = cb(treeState ? treeState[legalFolderId] : undefined);
          return { ...treeState, [legalFolderId]: newProjectFoldersList };
        });
    },
    [legalFolderId, setTreeProjectFolders]
  );

  const [
    getProjectFolders,
    {
      data: getProjectFoldersData,
      loading: getProjectFoldersLoading,
      refetch: getProjectFoldersRefetch,
      called: getProjectFoldersCalled,
    },
  ] = useLazyQuery<getProjectFolders>(GET_TREE_PROJECT_FOLDERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getProjectFoldersCount,
    {
      data: getProjectFoldersCountData,
      loading: getProjectFoldersCountLoading,
      refetch: getProjectFoldersCountRefetch,
      called: getProjectFoldersCountCalled,
    },
  ] = useLazyQuery<getProjectFoldersCount>(GET_PROJECT_FOLDERS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadProjectFoldersPage = useCallback(
    ({ skip, all }: { skip?: number; all?: boolean }) => {
      const variables = {
        filter: {
          // nameContains: search,
          legalFolderIds: [legalFolderId],
          onlyCurrentUserDocuments: myTask,
        },
        // sort: { column: 'START_DATE', order: 'DESC' },
        sort: [
          { column: 'CURRENT_USER_ACTION_PENDING', order: 'DESC' },
          { column: 'UPDATED_AT', order: 'DESC' },
        ],
        take: all && stateRef.current?.length ? stateRef.current.length : DEFAULT_ROWS_PER_PAGE,
        skip: all ? 0 : skip,
      };
      if (getProjectFoldersCalled) {
        getProjectFoldersRefetch!(variables);
      } else {
        getProjectFolders({ variables });
      }
    },
    [getProjectFoldersCalled, getProjectFoldersRefetch, getProjectFolders, legalFolderId, myTask]
  );

  const loadProjectFoldersCount = useCallback(() => {
    const variables = {
      filter: {
        // nameContains: search,
        legalFolderIds: [legalFolderId],
        onlyCurrentUserDocuments: myTask,
      },
    };
    if (getProjectFoldersCountCalled) {
      getProjectFoldersCountRefetch!(variables);
    } else {
      getProjectFoldersCount({ variables });
    }
  }, [
    getProjectFoldersCountCalled,
    getProjectFoldersCountRefetch,
    getProjectFoldersCount,
    // search,
    legalFolderId,
    myTask,
  ]);

  const refToReloadOnExpanded = useRef<any>(null);

  const reloadOnExpanded = useCallback(() => {
    setState(() => ({ totalItems: 0, items: [] }));
    loadProjectFoldersPage({ skip: 0 });
    loadProjectFoldersCount();
  }, [loadProjectFoldersPage, loadProjectFoldersCount, setState]);

  useEffect(() => {
    refToReloadOnExpanded.current = reloadOnExpanded;
  }, [reloadOnExpanded]);

  useEffect(() => {
    if (expanded) {
      refToReloadOnExpanded.current && refToReloadOnExpanded.current();
    }
  }, [expanded]);

  useEffect(() => {
    if (getProjectFoldersCountData && !getProjectFoldersCountLoading) {
      setState((oldState: IProjectFoldersList | undefined) => {
        const newState = oldState || { totalItems: 0, items: [] };
        newState.totalItems = getProjectFoldersCountData.contract_containerCount;
        return { ...newState };
      });
    }
  }, [getProjectFoldersCountData, getProjectFoldersCountLoading, setState]);

  useEffect(() => {
    if (getProjectFoldersData && !getProjectFoldersLoading) {
      setState((oldState) => {
        const newState = oldState || { totalItems: 0, items: [] };
        getProjectFoldersData.contract_containers.forEach((projectFolder) => {
          const { items } = newState;
          const index = items.findIndex((item) => item.id === projectFolder.id);
          if (index >= 0) {
            items[index] = {
              ...items[index],
              ...pick(projectFolder, ['id', 'name', 'documentCount']),
              currentUserHasActionPending: !!projectFolder.currentUserHasActionPending,
            };
          } else {
            items.push({
              ...pick(projectFolder, ['id', 'name', 'documentCount']),
              id: projectFolder.id,
              currentUserHasActionPending: !!projectFolder.currentUserHasActionPending,
            });
          }
        });
        return { ...newState };
      });
    }
  }, [getProjectFoldersData, getProjectFoldersLoading, setState]);

  const loadMore = useCallback(() => {
    if (state && state?.totalItems > state?.items.length) {
      loadProjectFoldersPage({
        skip: state?.items.length,
      });
    }
  }, [state, loadProjectFoldersPage]);

  const reloadTree = useCallback(async () => {
    if (expanded && legalFolderId) {
      setState(() => ({ totalItems: 0, items: [] }));
      loadProjectFoldersPage({ all: true });
      loadProjectFoldersCount();
    }
  }, [legalFolderId, loadProjectFoldersCount, loadProjectFoldersPage, expanded, setState]);

  useEffect(() => {
    reloadTreeRef.current = reloadTree;
  }, [reloadTree]);

  useEffect(() => {
    client.onResetStore(reloadTreeRef.current);
  }, [client]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  return {
    projects: state,
    loadMore,
    loading: !!getProjectFoldersLoading || !!getProjectFoldersCountLoading,
  };
};
