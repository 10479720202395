import { useApolloClient } from '@apollo/client';
import { DOCUSIGN_RETURN_URL } from 'constants/config';
import { useUI } from 'contexts/UiContext';
import { CONTRACT_DOCUSIGN_CREATE_ENVELOPE_VIEW } from 'graphql/legalFolders/docusign';
import { documentDocusignCreateView } from 'graphql/legalFolders/types/documentDocusignCreateView';
import { useCallback, useState } from 'react';

export interface IDocusignCreateViewState {
  error?: any;
  loading: boolean;
  status?: string;
  url?: string;
  envelopeId?: string;
}

export const useDocusignCreateView = () => {
  const client = useApolloClient();
  const { addSnackbar } = useUI();

  const [state, setState] = useState<IDocusignCreateViewState>({ loading: false });

  const getEnvelopeViewUrl = useCallback(
    async ({ envelopeId }: { envelopeId: string }) => {
      if (envelopeId) {
        setState({ loading: true, envelopeId });
        try {
          const { data, error } = await client.query<documentDocusignCreateView>({
            query: CONTRACT_DOCUSIGN_CREATE_ENVELOPE_VIEW,
            variables: {
              envelopeId,
              returnUrl: DOCUSIGN_RETURN_URL,
            },
            fetchPolicy: 'network-only',
          });
          if (error) {
            addSnackbar!({ text: 'Error', severity: 'error' });
            setState({ loading: false, error, envelopeId });
          } else {
            const { url, status } = data?.contract_documentDocusignCreateView;
            if (!status) {
              addSnackbar!({ text: 'Error reading result', severity: 'error' });
              setState({ loading: false, error: 'Error reading result', envelopeId });
            }
            if (!url) {
              addSnackbar!({ text: 'Document view is not allowed', severity: 'info' });
              setState({ loading: false, error: 'Document view is not allowed', envelopeId });
            }
            if (url && status) setState({ loading: false, url, status, envelopeId });
          }
        } catch (e: any) {
          addSnackbar!({ text: 'Error', severity: 'error' });
          setState({ loading: false, error: 'Error', envelopeId });
        }
      }
    },
    [addSnackbar, client]
  );

  const cleanEnvelopeView = useCallback(() => {
    setState({ loading: false });
  }, []);

  return { state, cleanEnvelopeView, getEnvelopeViewUrl };
};
