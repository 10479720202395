import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC } from 'react';
import {
  IDocuSignSectionSigner,
  IDocuSignSectionSigners,
} from '../DocuSignSection/DocuSignSection';
import { DocusignRecipientsAnyUserSelection } from '../DocusignRecipientsAnyUserSelection/DocusignRecipientsAnyUserSelection';

export interface IDocusignSignersAnyUserSelectionProps {
  handleSignerSelection: (selected: IDocuSignSectionSigner[]) => void;
  disabled?: boolean;
  no: number;
  onRemoveSection?: () => void;
  value: IDocuSignSectionSigners;
}

export const DocusignSignersAnyUserSelection: FC<IDocusignSignersAnyUserSelectionProps> = ({
  value,
  no,
  handleSignerSelection,
  disabled,
  onRemoveSection,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={3}
        style={{ display: 'flex', alignItems: 'start', paddingTop: '19px' }}
      >
        <Typography variant="body2" className="label-title">
          Signer Order #{no}:
        </Typography>
      </Grid>
      <Grid item xs={11} md={8}>
        <DocusignRecipientsAnyUserSelection
          value={value.signers}
          handleRecipientSelection={handleSignerSelection}
          disabled={disabled}
          label="Signer"
          recipientTypeLabel="signer"
        />
      </Grid>
      {onRemoveSection ? (
        <Grid
          item
          xs={1}
          md={1}
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <div style={{ cursor: 'pointer', textAlign: 'end' }}>
            <Tooltip title={`Remove #${no} signers group`}>
              <IconButton
                size="small"
                onClick={
                  !!onRemoveSection ? debounce(onRemoveSection!, DEBOUNCE_TIMEOUT) : undefined
                }
                disabled={!onRemoveSection}
              >
                <Clear fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      ) : undefined}
    </Grid>
  );
};
