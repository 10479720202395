export const markSearchString = (str: string, search: string | undefined) => {
  if (search) {
    const reg = RegExp(search, 'i');
    const match = str.match(reg);
    if (match?.input) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: str.replace(
              match[0],
              '<span style="border-bottom: 1px solid">' + match[0] + '</span>'
            ),
          }}
        />
      );
    }
  }
  return str;
};
