import React, { createContext, FC, useContext } from 'react';
import {
  IDocumentsTableHookLoadParams,
  IDocumentsTableHookValue,
  useDocumentsTableHook,
} from './documentsTableHook';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import paths from 'constants/paths';
import { useDivisions } from './divisionsHook';
import { useDocumentTypes } from './documentTypesHook';
import { useDocumentStatus } from './documentStatusesHook';
import { useStatusFolder } from './statusFolderHook';
import { useComponentContext as useMyTask } from 'contexts/MyTask';

export interface IContextState extends IDocumentsTableHookValue {
  myTask: boolean;
  filterOptions?: any;
  filterValues?: any;
}

export interface IContextActions {
  clearAllFilters: any;
  setMyTask: (myTasks: boolean) => void;
  onDocumentSelect: (id: string) => boolean;
}

const initialState: IContextState = {
  loading: false,
  documents: [],
  filterApplied: {},
  loadPage: () => {},
  onFilterChange: () => true,
  pageLoadParams: { rowsPerPage: 0 },
  totalItems: 0,
  myTask: true,
  filterOptions: {},
  error: undefined,
};

const defaultFilterValues = {
  legalFolder: { id: 'All', name: 'All' },
  projectFolder: { id: 'All', name: 'All' },
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IDocumentsTableHookLoadParams>;
  tableStorageKey?: string;
  children: any;
}

export const Provider: FC<IProviderProps> = ({
  children,
  overridePageLoadParams,
  initPageLoadParams,
  tableStorageKey,
}) => {
  const history = useHistory();

  const { myTask, setMyTask } = useMyTask();

  const { divisionPairs } = useDivisions();
  const { documentTypePairs } = useDocumentTypes();
  const { pairs: documentStatusPairs } = useDocumentStatus();
  const { pairs: statusFolderPairs } = useStatusFolder();

  const {
    totalItems,
    documents,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    error,
  } = useDocumentsTableHook({
    myTask,
    initPageLoadParams,
    overridePageLoadParams,
    tableStorageKey,
  });

  const onDocumentSelect = useCallback(
    (id: string) => {
      history.push(paths.treeViewClient.TREEVIEW_DOCUMENT.replace(':id', id));
      return true;
    },
    [history]
  );

  const filterOptions = useMemo(() => {
    return {
      divisions: [...divisionPairs],
      documentType: [...documentTypePairs],
      status: [...documentStatusPairs],
      statusFolder: [...statusFolderPairs],
    };
  }, [divisionPairs, documentTypePairs, documentStatusPairs, statusFolderPairs]);

  const clearAllFilters = () => {
    onFilterChange({ ...overridePageLoadParams?.filterValues, ...defaultFilterValues });
  };

  return (
    <ComponentContext.Provider
      value={{
        totalItems,
        documents,
        loadPage,
        pageLoadParams,
        loading,
        onFilterChange,
        filterApplied,
        clearAllFilters,
        myTask,
        setMyTask,
        onDocumentSelect,
        filterOptions,
        filterValues: pageLoadParams.filterValues || defaultFilterValues,
        error,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
