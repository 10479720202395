import Grid from '@mui/material/Unstable_Grid2';
import React, { FC, useRef, useState } from 'react';
import { LegalFolders } from '../LegalFolders/LegalFolders';
import { SearchField } from '../SearchField/SearchField';
import s from './style.module.scss';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import { useEffect } from 'react';
import { TYPING_TIMEOUT } from 'constants/config';

export const TreeSection: FC = () => {
  const { setSearch, search } = useComponentContext();

  const [searchText, setSearchText] = useState(search);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (setSearch) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      timer.current = setTimeout(() => {
        setSearch(searchText);
      }, TYPING_TIMEOUT);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [searchText, setSearch]);

  useEffect(() => {
    setSearchText(search);
  }, [search]);

  return (
    <Grid container padding={'0 0 20px 0'} spacing={1} className={s.treeSection}>
      <Grid xs={12}>
        <SearchField
          onSearch={(search: any) => {
            setSearchText(search.target.value);
          }}
          onChange={(search: any) => {
            setSearchText(search.target.value);
          }}
          placeholder="Search legal folder or project folder name"
          value={searchText}
        ></SearchField>
      </Grid>
      <Grid xs={12}>
        <LegalFolders></LegalFolders>
      </Grid>
    </Grid>
  );
};
