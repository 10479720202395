import { Button } from '@mui/material';
import { Grid } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import StakeHolder from '../UserBasedStakeHolderObserver';
import { IUsersArrayItem } from '../StakeHolder/interfaces';
import './index.scss';
import { IObserver } from './interfaces';
import { forOwn } from 'lodash';
import { IProjectLeader } from '../hooks/projectLeadersHook';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface IObserversProps {
  readOnly: boolean;
  editMode: boolean;
  onChange: any;
  observers: IObserver[];
  projectLeaders: IProjectLeader[];
  users: IUsersArrayItem[];
  hideDisabled: boolean;
}

export const Observers: FC<IObserversProps> = ({
  readOnly,
  editMode,
  onChange,
  observers,
  projectLeaders,
  users,
  hideDisabled,
}) => {
  const [draggingEnabled, setEnabledDragging] = useState<{ [id: string]: boolean }>({});

  const [draggableParentClass, setdraggableParentClass] = useState('');
  // const [stakeHolders, setStakeHolders] = useState<IObserver[]>(observers);
  const [isReadOnly, setReadOnly] = useState(false);

  const [dragId, setDragId] = useState<number | null>(null);
  let dragTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();
  let dragOffTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const holder: any = document.querySelector('#draggable-parent');

    setReadOnly(readOnly);
    if (!readOnly) {
      window.onmouseup = (): void => {
        if (dragTimeoutHandle.current) {
          clearTimeout(dragTimeoutHandle.current!);
        }

        setdraggableParentClass('');
      };

      holder.onmousedown = (): void => {
        dragTimeoutHandle.current = setTimeout(() => {
          setdraggableParentClass('stakeholder-dragging');
        }, 250);
      };
    }
  }, [readOnly]);

  const onReorderStakeHolders = useCallback(
    (event: unknown, fromId: number, toId: number): void => {
      const indexFrom = observers.findIndex((item) => item.id === fromId);
      const indexTo = observers.findIndex((item) => item.id === toId);

      const newArray = [...observers];
      newArray.splice(indexTo, 0, newArray.splice(indexFrom, 1)[0]);

      for (let i = Math.min(indexFrom, indexTo); i < Math.max(indexFrom, indexTo); i++) {
        newArray[i].changed = true;
      }

      onChange(newArray);
    },
    [onChange, observers]
  );

  const stakeHolderDeleteHandler = useCallback(
    (id: any): void => {
      let _temp = observers;

      _temp = _temp.filter((item: any) => item.id !== id);
      onChange(_temp);
    },
    [onChange, observers]
  );

  const observerChanged = useCallback(
    (
      id: any,
      observer: Pick<IUsersArrayItem, 'name' | 'id' | 'discipline'> | null,
      disabled: boolean
    ): void => {
      const _temp = observers;
      const tmp = id;

      for (const t of _temp) {
        if (t.id === tmp) {
          if (
            t.employeeName !== observer?.name ||
            t.employeeName !== observer?.name ||
            t.disabled !== disabled
          ) {
            t.employeeName = observer?.name;
            t.employeeId = observer?.id;
            t.disabled = disabled;
            // t.type = observer?.discipline;
            t.type = observer?.discipline || '';
            t.changed = true;
            onChange(_temp);
          }
          break;
        }
      }
    },
    [observers, onChange]
  );

  // check if this observer has already been selected
  // do not allow duplicate values
  const notAlreadySelected = useCallback(
    (item: Pick<IUsersArrayItem, 'name'>): boolean => {
      for (const o of observers) {
        if (o.employeeName === item.name) {
          return false;
        }
      }
      return true;
    },
    [observers]
  );

  const addNewPersonaClick = useCallback((): void => {
    const _temp: IObserver[] = observers;

    let newId = 1;

    if (_temp.length > 0) {
      const biggestStakeHolder = _temp.reduce((prev: any, current: any) =>
        prev.id > current.id ? prev : current
      );
      newId = biggestStakeHolder.id + 1;
    }

    const observerEmployeeIds = observers.map((observer) => observer.employeeId);

    const emp = users.find((e: any) => !observerEmployeeIds.includes(e.id));

    let empId = 0;
    let empName = '';
    let empEmail = '';
    let empTemp = '';

    if (emp) {
      empId = parseInt(emp.id, 10);
      empName = emp.name;
      empEmail = emp.email;
      empTemp = emp.discipline;
    }

    if (notAlreadySelected({ name: empName })) {
      const newItem = {
        id: newId,
        type: empTemp,
        status: 'idle',
        employeeName: empName,
        employeeId: empId.toString(),
        employeeEmail: empEmail,
        disabled: false,
        userFromDatabase: false,
        deleted: false,
        changed: true,
        dbOrder: -1,
      };
      _temp.push(newItem);
    } else {
      _temp.push({
        id: newId,
        type: empTemp,
        disabled: false,
        userFromDatabase: false,
        deleted: false,
        changed: true,
        dbOrder: -1,
      });
    }

    onChange(_temp);
  }, [notAlreadySelected, onChange, observers, users]);

  const draggingIsDisabled = useMemo(() => {
    let foundPopup = false;
    forOwn(draggingEnabled, (value) => {
      if (value === true) foundPopup = true;
    });
    return isReadOnly || foundPopup;
  }, [draggingEnabled, isReadOnly]);

  const handleDrag = useCallback(
    (ev: any) => {
      setDragId(parseInt(ev.currentTarget.id));
    },
    [setDragId]
  );

  // const showObservers = useMemo(() => {
  //   if (!hideDisabled) {
  //     return observers;
  //   } else {
  //     return observers.filter((observer) => !observer.disabled);
  //   }
  // }, [observers, hideDisabled]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} id="draggable-parent" className={draggableParentClass}>
        <div>
          {observers.map((observer: IObserver) => {
            const {
              id,
              type,
              employeeName,
              employeeId,
              employeeEmail,
              disabled,
              userFromDatabase,
              originIsEnabled,
            } = observer;
            return (
              <div
                key={`shholder_${id}`}
                draggable={!draggingIsDisabled}
                onDragStart={handleDrag}
                style={{ display: disabled && !!hideDisabled ? 'none' : undefined }}
                onDrop={(ev) => {
                  const targetId = parseInt(ev.currentTarget.id);
                  if (!!dragId && dragId !== targetId) {
                    onReorderStakeHolders(null, dragId, targetId);
                  }
                  setDragId(null);
                  setdraggableParentClass('');
                  if (dragTimeoutHandle.current) {
                    clearTimeout(dragTimeoutHandle.current!);
                  }
                  if (dragOffTimeoutHandle.current) {
                    clearTimeout(dragOffTimeoutHandle.current!);
                  }
                }}
                onDragOver={(ev) => {
                  ev.preventDefault();
                  const targetId = parseInt(ev.currentTarget.id);
                  if (!!dragId && dragId !== targetId) {
                    onReorderStakeHolders(null, dragId, targetId);
                  }
                  if (dragOffTimeoutHandle.current) {
                    clearTimeout(dragOffTimeoutHandle.current);
                  }
                  setdraggableParentClass('stakeholder-dragging');
                }}
                onDragLeave={(ev) => {
                  dragOffTimeoutHandle.current = setTimeout(() => {
                    setdraggableParentClass('');
                  }, 2000);
                }}
                id={`${id}`}
                className={id === dragId ? 'stakeholder-dragged' : undefined}
              >
                <StakeHolder
                  users={users}
                  readOnly={readOnly}
                  editMode={editMode}
                  key={`key_${id}`}
                  type={type}
                  id={id}
                  employeeName={employeeName}
                  employeeId={employeeId}
                  employeeEmail={employeeEmail}
                  disabled={disabled}
                  userFromDatabase={userFromDatabase}
                  onDelete={stakeHolderDeleteHandler}
                  onChange={observerChanged}
                  moving={draggableParentClass !== ''}
                  selectedEmployee={observer}
                  notAlreadySelected={notAlreadySelected}
                  projectLeaders={projectLeaders}
                  originIsEnabled={originIsEnabled}
                  onPopup={(id, open) => {
                    setEnabledDragging((old) => {
                      if (old[id] !== open) {
                        return { ...old, [id]: open };
                      }
                      return old;
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={debounce(addNewPersonaClick, DEBOUNCE_TIMEOUT)}
          >
            Add Observer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
