/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useCallback } from 'react';

export const useUpload = (): any => {
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState<Record<string, any>[]>([]);
  const [percentage, setPercentage] = useState(0);

  const handleComment = (event: any): void => {
    setComment(event.target.value);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles(acceptedFiles);
  }, []);

  const removeFile = useCallback(() => {
    setFiles([]);
  }, []);

  return {
    percentage,
    files,
    onDrop,
    removeFile,
    comment,
    handleComment,
  };
};
