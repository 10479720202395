import { useLazyQuery } from '@apollo/client';
import { GET_PROJECT_DOCUMENT_NAME } from 'graphql/legalFolders/documents';
import { projectDocumentName } from 'graphql/legalFolders/types/projectDocumentName';
import { useCallback, useEffect, useState } from 'react';

export interface IUseProjectDocumentProps {
  id?: string;
}
export const useProjectDocument = ({ id }: IUseProjectDocumentProps) => {
  const [projectDocumentName, setProjectDocumentName] = useState<string>();

  const [getProjectDocumentName, { data, loading, called, refetch }] =
    useLazyQuery<projectDocumentName>(GET_PROJECT_DOCUMENT_NAME, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    });

  const getData = useCallback(
    (id: string) => {
      const variables = {
        id,
      };

      if (called) {
        refetch!(variables);
      } else {
        getProjectDocumentName({ variables });
      }
    },
    [refetch, called, getProjectDocumentName]
  );

  useEffect(() => {
    if (data && !loading) {
      setProjectDocumentName(data.contract_document.name);
    }
  }, [data, loading]);

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [getData, id]);

  return {
    projectDocumentName,
    loading,
  };
};
