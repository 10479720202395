const FileLightIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.81"
      height="13.512"
      viewBox="0 0 10.81 13.512"
      {...props}
    >
      <defs></defs>
      <path
        fill="currentColor"
        d="M2018.345,1743.513a1.35,1.35,0,0,1-1.345-1.351l.007-10.81a1.349,1.349,0,0,1,1.344-1.351h5.479l3.968,4.034v.007l.012.012v8.107a1.355,1.355,0,0,1-1.351,1.351Zm-.161-11.441v9.518a.74.74,0,0,0,.74.74h6.964a.741.741,0,0,0,.74-.74v-6.56h-4.517v-3.7h-3.186A.74.74,0,0,0,2018.184,1732.072Zm7.565,1.775-2.439-2.515h-.018v2.515Z"
        transform="translate(-2017 -1730.001)"
      />
    </svg>
  );
};

export default FileLightIcon;
