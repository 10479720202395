import { FC, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

export interface IDocuSignEnvelopeView {
  envelopeId: string;
  url: string;
  onClose: () => void;
}
export const DocuSignEnvelopeView: FC<IDocuSignEnvelopeView> = ({ envelopeId, url, onClose }) => {
  useEffect(() => {
    const messageListener = (event: any) => {
      if (
        event.origin === 'https://demo.docusign.net' ||
        event.origin === 'https://www.docusign.net'
      ) {
        if (
          event.data === 'signing_complete' ||
          event.data === 'decline' ||
          event.data === 'cancel'
        ) {
          onClose();
        }
      }
      if (event.data === 'docusign_complete' || event.data === 'x30674_end') {
        onClose();
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [onClose]);

  // const [openNewTabDialog, setOpenNewTabDialog] = useState(false);

  // const handleCloseNewTabDialog = useCallback(
  //   (confirm: boolean) => {
  //     if (confirm) {
  //       window.open(url, 'docusign');
  //     }
  //     onClose(false);
  //   },
  //   [onClose, url]
  // );

  // const onError = useCallback(() => {
  //   setOpenNewTabDialog(true);
  // }, []);

  return (
    <>
      {/* <ConfirmationDialog
        open={openNewTabDialog}
        title={'Open DocuSign'}
        message={'DocuSign needs to be open in a new tab'}
        onClose={handleCloseNewTabDialog}
        reverseColors
      /> */}
      <LoadingOverlay active={!url} text="Loading...">
        <div style={{ width: '100%', height: '700px' }}>
          {url ? (
            <iframe
              src={`${url}&enableEmbedded=1`}
              title="DocuSign"
              width="100%"
              height="700px"
              style={{ border: 'none' }}
            />
          ) : undefined}
        </div>
      </LoadingOverlay>
    </>
  );
};
