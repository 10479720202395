import { gql } from '@apollo/client';
import { project, sasBlob } from '../fragments';

const projectQueries = {
  GET_PROJECT: gql`
  query($id: ID!) {
    project(id: $id) {
      ${project}
    }
  }
`,

  GET_PROJECTS: gql`
  query GetProject($filter: ProjectFilter, $take: Int = 100, $skip: Int = 0) {
    projects(filter: $filter, take: $take, skip: $skip) {
      ${project}
    }
  }
`,

  GET_SAS_URL: gql`
  query generateUploadBlobSASUrl($isSupportDocument: Boolean, $filename:String!, $reportId: ID!) {
    generateUploadBlobSASUrl(isSupportDocument:$isSupportDocument, filename: $filename, reportId:$reportId) {
      ${sasBlob}
    }
  }
`,
};

export default projectQueries;
