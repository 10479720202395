import { Alert } from '@mui/material';
import { useMaintenanceMessage } from 'hooks/maintenanceMessage';
import { FC } from 'react';

export const Maintenance: FC = () => {
  const { maintenanceText } = useMaintenanceMessage();

  return maintenanceText ? (
    <div style={{ width: '100%' }}>
      <Alert severity="warning">{maintenanceText}</Alert>
    </div>
  ) : (
    <></>
  );
};
