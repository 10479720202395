import { useMutation } from '@apollo/client';
import { PARTY_STOCK_EXCHANGE_CHANGE } from 'graphql/legalFolders/parties';
import {
  party_party_stockExchanges,
  party_party_stockExchanges_stockMarket,
} from 'graphql/legalFolders/types/party';
import { pick } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { tryUpdateProcedure } from 'utils/apollo';

export interface IStockExchangeStockMarketData
  extends Omit<party_party_stockExchanges_stockMarket, '__typename'> {
  ddLabel: string;
}
export interface IStockExchangeData
  extends Omit<party_party_stockExchanges, '__typename' | 'stockMarket'> {
  changed: boolean;
  stockMarket?: IStockExchangeStockMarketData;
}

const getNewExchange: () => IStockExchangeData = () => ({
  changed: false,
  id: '',
  stockExchangeSymbol: '',
  stockExchangeValue: null,
});

export interface IUsePartyExchangesValue {
  exchanges: IStockExchangeData[] | undefined;
  deleteExchange: (index: number) => void;
  updateExchange: (index: number, value: any) => void;
  addExchange: (exchange: any | undefined) => void;
  submit: (partyId: string) => any;
}

export interface IUsePartyExchangesProps {
  initExchanges: IStockExchangeData[];
}
export const usePartyExchanges = ({
  initExchanges,
}: IUsePartyExchangesProps): IUsePartyExchangesValue => {
  const [exchanges, setExchanges] = useState<IStockExchangeData[]>(initExchanges);

  useEffect(() => {
    setExchanges(initExchanges);
  }, [initExchanges]);

  const [exchangesChangeMutation] = useMutation(PARTY_STOCK_EXCHANGE_CHANGE);

  const deleteExchange = useCallback((index: number) => {
    setExchanges((old) => {
      if (!old?.length) {
        return [];
      }
      if (old.length < index) {
        return old;
      }
      const newList = [...old];
      newList.splice(index, 1);
      return newList;
    });
  }, []);

  const updateExchange = useCallback((index: number, value: Partial<IStockExchangeData>) => {
    setExchanges((old) => {
      if (!old?.length) {
        return [];
      }
      if (old.length < index) {
        return old;
      }
      old[index] = { ...old[index], ...value, changed: true };
      return [...old];
    });
  }, []);

  const addExchange = useCallback((exchange: any | undefined) => {
    setExchanges((old) => {
      if (!old?.length) {
        return [exchange || getNewExchange()];
      }
      return [...old, exchange || getNewExchange()];
    });
  }, []);

  const submit = useCallback(
    async (partyId: string) => {
      const originalExchangeIds = initExchanges.map((exchange) => exchange?.id) || [];

      const partyStockExchangesToAdd = exchanges
        .filter(
          (exchange) =>
            !originalExchangeIds.includes(exchange.id) &&
            (exchange.stockExchangeSymbol || exchange.stockMarket?.id)
        )
        .map((exchange) => ({
          ...pick(exchange, ['stockExchangeValue', 'stockExchangeSymbol']),
          stockExchangeId: exchange.stockMarket?.id
            ? parseInt(exchange.stockMarket?.id)
            : undefined,
        }));

      const keepExchangeIds = exchanges
        .filter(
          (exchange) => !!exchange.id && (exchange.stockExchangeSymbol || exchange.stockMarket?.id)
        )
        .map((exchange) => exchange.id);

      const partyStockExchangeIdsToRemove = originalExchangeIds.filter(
        (id) => !keepExchangeIds.includes(id)
      );

      const partyStockExchangesToModify = exchanges
        .filter(
          (exchange) =>
            keepExchangeIds.includes(exchange.id) &&
            exchange.changed &&
            (exchange.stockExchangeSymbol || exchange.stockMarket?.id)
        )
        .map((exchange) => ({
          ...pick(exchange, ['stockExchangeValue', 'stockExchangeSymbol']),
          partyStockExchangeId: exchange.id,
          stockExchangeId: exchange.stockMarket?.id ? parseInt(exchange.stockMarket?.id) : null,
        }));

      if (
        partyStockExchangesToModify.length ||
        partyStockExchangeIdsToRemove.length ||
        partyStockExchangesToAdd.length
      ) {
        const exchangesSaveData = {
          partyId: partyId,
          partyStockExchangesToAdd: partyStockExchangesToAdd.length
            ? partyStockExchangesToAdd
            : undefined,
          partyStockExchangeIdsToRemove: partyStockExchangeIdsToRemove.length
            ? partyStockExchangeIdsToRemove
            : undefined,
          partyStockExchangesToModify: partyStockExchangesToModify.length
            ? partyStockExchangesToModify
            : undefined,
        };

        const { result, isError, errors } = await tryUpdateProcedure({
          mutation: () =>
            exchangesChangeMutation({
              variables: {
                ...exchangesSaveData,
              },
            }),
          parseResult: (data: any) => {
            return data;
          },
        });

        return { result, isError, errors };
      }
    },
    [exchanges, exchangesChangeMutation, initExchanges]
  );

  return {
    exchanges,
    deleteExchange,
    updateExchange,
    addExchange,
    submit,
  };
};
