/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useMemo } from 'react';
import { useComponentContext } from '../../LegalFolderDocumentContext';

import { Paper, Grid, Typography, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import LoadingOverlay from 'react-loading-overlay-ts';

import {
  DragIndicator as DragIndicatorIcon,
  Delete as DeleteIcon,
  PersonAddDisabled as PersonAddDisabledIcon,
  PersonAdd as PersonAddIcon,
  Email as EmailIcon,
  VisibilityOff,
} from '@mui/icons-material';

import { ConfirmationDialog, EasyAutoComplete, AlertDialog } from 'components';

import './index.scss';
import { IUsersArrayItem, StakeHolderProps } from './interfaces';

import { useDivisionManagers } from '../UserBasedStakeHolder/divisionManagersHook';
import { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { DOCUMENT_SEND_OBSERVER_NOTIFICATION } from 'graphql/legalFolders/documents';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { Person, PersonCardInteraction } from '@microsoft/mgt-react';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

const StakeHolder: FC<StakeHolderProps> = ({
  type,
  id,
  readOnly,
  editMode,
  employeeId,
  disabled,
  userFromDatabase,
  onDelete,
  onChange,
  moving,
  users,
  selectedEmployee,
  notAlreadySelected,
  projectLeaders,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  originIsEnabled,
  onPopup,
  ...props
}) => {
  const classes = useStyles();

  const refOnChange = useRef(onChange);
  useEffect(() => {
    refOnChange.current = onChange;
  }, [onChange]);

  const {
    projectFolderDocument: { id: documentId },
  } = useComponentContext();

  const [sendObserverNotificationReminder, { loading: loadingSendReminder }] = useMutation(
    DOCUMENT_SEND_OBSERVER_NOTIFICATION
  );

  const [isReadOnly, setReadOnly] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [observerDisabled, setObserverDisabled] = useState(disabled);
  const { divisionManagers } = useDivisionManagers();

  const selectedEmployeeUser = useMemo(() => {
    const found = users?.find((user: any) => user.id === selectedEmployee.employeeId);
    if (!found) {
      const observer: IUsersArrayItem = {
        discipline: type,
        division: null,
        email: selectedEmployee.employeeEmail || '',
        name: selectedEmployee.employeeName || '',
        id: selectedEmployee.employeeId!,
      };
      return observer;
    }
    return found;
  }, [
    users,
    selectedEmployee.employeeId,
    selectedEmployee.employeeEmail,
    selectedEmployee.employeeName,
    type,
  ]);

  const isFromDatabase = useMemo(() => {
    return !!userFromDatabase;
  }, [userFromDatabase]);

  // const [allUsers, setAllUsers] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<{
    list: IUsersArrayItem[];
    stakeholder: string;
  } | null>(null);

  // if set it will automatically set this user as selected user (selectedEmployee)
  // used after filteredUsers has been set (during reading list of observers from DB)
  const [userAutoSelect, setUserAutoSelect] = useState<any>(null);

  const [confirmationDeleteIsOpen, setConfirmationDeleteOpen] = useState(false);
  const [confirmationDisableIsOpen, setConfirmationDisableOpen] = useState(false);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);

  const [observerMessageTitle, setObserverMessageTitle] = useState('');
  const [observerMessageContent, setObserverMessageContent] = useState('');
  const [observerMessageOpen, setObserverMessageOpen] = useState(false);

  useEffect(() => {
    onPopup &&
      onPopup(
        id.toString(),
        confirmationDeleteIsOpen || confirmationDisableIsOpen || confirmationIsOpen || alertOpen
      );
  }, [
    id,
    onPopup,
    confirmationDeleteIsOpen,
    confirmationDisableIsOpen,
    confirmationIsOpen,
    alertOpen,
  ]);

  useEffect((): void => {
    for (const u of users) {
      if (u.id === employeeId) {
        setUserAutoSelect(u);
        break;
      }
    }
  }, [employeeId, users]);

  useEffect(() => {
    if (userFromDatabase) {
      setFilteredUsers({
        stakeholder: selectedEmployeeUser.discipline,
        list: [
          {
            ...selectedEmployeeUser!,
          },
        ],
      });
      return;
    }

    setFilteredUsers({ list: users, stakeholder: '' }); // --> filteredUsers
  }, [
    projectLeaders,
    divisionManagers,
    users,
    isFromDatabase,
    selectedEmployeeUser,
    userFromDatabase,
  ]);

  const componentLoaded = (): void => {
    setReadOnly(!!readOnly);
    setEditMode(!!editMode);
  };

  const deleteStakeholderClick = (): void => {
    setConfirmationDeleteOpen(true);
  };

  const toggleStakeholderDisabledClick = (disable: boolean): void => {
    if (disable) {
      setConfirmationDisableOpen(true);
    } else {
      setObserverDisabled(false);

      onChange &&
        onChange(
          id,
          {
            id: selectedEmployee.employeeId!,
            name: selectedEmployee.employeeName!,
            discipline: selectedEmployee.type,
          },
          false
        );
    }
  };

  const sendObserverNotificationClick = (): void => {
    setConfirmationIsOpen(true);
  };

  const dialogDeleteUserCloseHandler = (ok: boolean): void => {
    setConfirmationDeleteOpen(false);

    if (ok) {
      onDelete && onDelete(id);
    }
  };

  const dialogDisableUserCloseHandler = (ok: boolean): void => {
    setConfirmationDisableOpen(false);

    if (ok) {
      setObserverDisabled(true);
      onChange &&
        onChange(
          id,
          {
            id: selectedEmployee.employeeId!,
            name: selectedEmployee.employeeName!,
            discipline: selectedEmployee.type,
          },
          true
        );
    }
  };

  const setObserverMessage = (title: string, content: string): void => {
    setObserverMessageTitle(title);
    setObserverMessageContent(content);

    setObserverMessageOpen(true);
  };

  const dialogSendNotificationCloseHandler = (ok: boolean): void => {
    setConfirmationIsOpen(false);

    if (!ok) return;

    sendObserverNotificationReminder({
      variables: { userId: selectedEmployeeUser.id, documentId },
    })
      .then(() => {
        // TODO alert success

        setObserverMessage('Success', 'Observer notification reminder successfully sent');
      })
      .catch((e: any) => {
        // TODO alert failure
        let errorMessage = e?.message?.replace('[ERROR]', '').trim();
        if (!errorMessage)
          errorMessage = 'Unknown error while trying to send the notification to a observer.';

        setObserverMessage('Error', errorMessage);
      });
  };

  useEffect(componentLoaded, [readOnly, editMode]);

  useEffect((): void => {
    if (userAutoSelect !== null) {
      if (!moving) {
        refOnChange.current && refOnChange.current(id, userAutoSelect, observerDisabled);
      }
      setUserAutoSelect(null);
    }
  }, [userAutoSelect, id, refOnChange, observerDisabled, moving]);

  useEffect((): void => {
    setObserverDisabled(disabled);
  }, [disabled]);

  var CustomPersonCard: any = (props: any) => {
    return (
      <Tooltip title="Data not available">
        <VisibilityOff fontSize="small" />
      </Tooltip>
    );
  };

  return (
    <LoadingOverlay active={loadingSendReminder} spinner text="Please wait...">
      <Paper
        className="stakeholder-draggable"
        elevation={0}
        variant="outlined"
        style={{ padding: '0.2rem', marginBottom: '0.4rem' }}
      >
        <AlertDialog
          title="Message"
          message="This observer has already been set"
          open={alertOpen}
          onClose={(): void => {
            setAlertOpen(false);
          }}
        />

        <AlertDialog
          title={observerMessageTitle}
          message={observerMessageContent}
          open={observerMessageOpen}
          onClose={(): void => {
            setObserverMessageOpen(false);
          }}
        />

        <ConfirmationDialog
          title="Delete observer"
          message="Are you sure you want to delete observer?"
          open={confirmationDeleteIsOpen}
          onClose={dialogDeleteUserCloseHandler}
        />

        <ConfirmationDialog
          title="Disable observer"
          message="Are you sure you want to disable observer?"
          open={confirmationDisableIsOpen}
          onClose={dialogDisableUserCloseHandler}
        />

        <ConfirmationDialog
          title="Observer notification"
          message="Are you sure you want to send the notification to a observer?"
          open={confirmationIsOpen}
          onClose={dialogSendNotificationCloseHandler}
        />

        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid item xs={5}>
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <Grid item xs={1} style={{ opacity: observerDisabled ? '0.3' : '' }}>
                <Typography variant="body2" className="label-title">
                  <DragIndicatorIcon fontSize="small" className="stakeholder-drag-icon" />
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                style={{ opacity: observerDisabled ? '0.3' : '', display: 'flex' }}
              >
                <div
                  style={{
                    paddingRight: '10px',
                  }}
                >
                  <Person
                    key={selectedEmployeeUser.email}
                    personQuery={selectedEmployeeUser.email}
                    showPresence
                    personCardInteraction={
                      observerDisabled ? PersonCardInteraction.none : PersonCardInteraction.hover
                    }
                  >
                    <CustomPersonCard template="no-data"></CustomPersonCard>
                  </Person>
                </div>
                <EasyAutoComplete
                  key={`user-${selectedEmployeeUser?.id}`}
                  items={
                    isReadOnly || observerDisabled || isFromDatabase
                      ? [selectedEmployeeUser]
                      : filteredUsers?.list || []
                  }
                  selected={selectedEmployeeUser}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selectedChanged={(value: any): void => {
                    if (notAlreadySelected(value)) {
                      onChange && onChange(id, value, observerDisabled);
                    } else {
                      onChange &&
                        onChange(
                          id,
                          {
                            id: selectedEmployee.employeeId!,
                            name: selectedEmployee.employeeName!,
                            discipline: selectedEmployee.type,
                          },
                          observerDisabled
                        );

                      setAlertOpen(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container spacing={0} alignContent="center" alignItems="center">
              <Grid item xs={8} style={{ opacity: '0.7' }}>
                <Typography variant="body2" className="label-title-nobold">
                  {selectedEmployeeUser?.discipline?.replace(/_/g, ' ') || 'UNCONVENTIONAL'}
                </Typography>
              </Grid>
              {/* 
            if component is in readonly mode, display nothing
            if it's not in readonly mode then display delete button
            */}
              {!isReadOnly && (
                <Grid item xs={3} style={{ textAlign: 'right' }}>
                  {isEditMode && !observerDisabled && isFromDatabase && !!originIsEnabled && (
                    <>
                      <div style={{ display: 'inline-block' }}>
                        <Tooltip title="Send document notification to the observer.">
                          <IconButton
                            size="small"
                            onClick={debounce((): void => {
                              sendObserverNotificationClick();
                            }, DEBOUNCE_TIMEOUT)}
                          >
                            <EmailIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>

                      {/* <span style={{ marginRight: '1rem' }} /> */}
                    </>
                  )}

                  {!isReadOnly && !isFromDatabase && (
                    <Tooltip title="Delete observer">
                      <IconButton
                        size="small"
                        onClick={debounce((): void => {
                          deleteStakeholderClick();
                        }, DEBOUNCE_TIMEOUT)}
                        className={classes.hoverWarning}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}

                  {isEditMode && !observerDisabled && isFromDatabase && (
                    <Tooltip title="Disable observer">
                      <IconButton
                        size="small"
                        onClick={debounce((): void => {
                          toggleStakeholderDisabledClick(true);
                        }, DEBOUNCE_TIMEOUT)}
                      >
                        <PersonAddDisabledIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}

                  {isEditMode && observerDisabled && isFromDatabase && (
                    <Tooltip title="Enable observer">
                      <IconButton
                        size="small"
                        onClick={debounce((): void => {
                          toggleStakeholderDisabledClick(false);
                        }, DEBOUNCE_TIMEOUT)}
                      >
                        <PersonAddIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </LoadingOverlay>
  );
};

export default StakeHolder;
