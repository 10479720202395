import { useCallback, useState } from 'react';
import { useApolloClient, ApolloError } from '@apollo/client';

import { GET_PARTIES, GET_PARTY_COUNT } from 'graphql/legalFolders/parties';
import { parties } from 'graphql/legalFolders/types/parties';
import { partyCount } from 'graphql/legalFolders/types/partyCount';

import { GET_PARTY_CONTACTS } from 'graphql/legalFolders/partyContact';
import { partyContacts } from 'graphql/legalFolders/types/partyContacts';
import { groupBy } from 'lodash';

export interface IProcess {
  status: 'stop' | 'run' | 'fin';
  stages: Array<{ key: string; name: string }>;
  progress: { [key: string]: { steps: number; current: number } };
}

const getInitialLoadPartiesStatus: () => IProcess = () => ({
  status: 'stop',
  stages: [
    { key: 'calculate', name: 'Collecting Info' },
    { key: 'getParties', name: 'Loading Pages' },
    { key: 'getContacts', name: 'Loading Contacts' },
  ],
  progress: {
    calculate: { steps: 2, current: 0 },
    getParties: { steps: 1, current: 0 },
    getContacts: { steps: 5, current: 0 },
  },
});

export const useLoadParties = () => {
  const client = useApolloClient();
  const [loadPartiesStatus, setLoadPartiesStatus] = useState<IProcess>(
    getInitialLoadPartiesStatus()
  );

  const setLoadPartyStepCount = useCallback((step: string, count: number) => {
    setLoadPartiesStatus((old) => ({
      ...old,
      progress: { ...old.progress, [step]: { steps: count, current: 0 } },
    }));
  }, []);

  const incLoadPartyStepProgress = useCallback((step: string) => {
    setLoadPartiesStatus((old) => {
      if (old.progress[step].current + 1 < old.progress[step].steps) {
        return {
          ...old,
          progress: {
            ...old.progress,
            [step]: { ...old.progress[step], current: old.progress[step].current + 1 },
          },
        };
      }
      return {
        ...old,
        progress: {
          ...old.progress,
          [step]: {
            ...old.progress[step],
            steps: old.progress[step].steps + 1,
            current: old.progress[step].current + 1,
          },
        },
      };
    });
  }, []);

  const finLoadPartyStepProgress = useCallback((step: string) => {
    setLoadPartiesStatus((old) => ({
      ...old,
      progress: {
        ...old.progress,
        [step]: { ...old.progress[step], current: old.progress[step].steps },
      },
    }));
  }, []);

  const loadContacts = useCallback(
    async (partyIds: Array<string>) => {
      const rowsPerPage = 100;
      const variables = { filter: { partyIds } };
      let result: { data: any; error: ApolloError | undefined } = { data: [], error: undefined };
      let lastPage = false;
      for (var pageNo = 0; !lastPage; pageNo++) {
        const { data, error } = await client.query<partyContacts>({
          query: GET_PARTY_CONTACTS,
          variables: { ...variables, take: rowsPerPage, skip: rowsPerPage * pageNo },
        });
        if (error) {
          result.data.error = error;
          break;
        }
        lastPage = !data?.partyContacts?.length;
        if (!lastPage) {
          incLoadPartyStepProgress('getContacts');
          result.data.push(...data.partyContacts);
        }
      }
      finLoadPartyStepProgress('getContacts');
      return result;
    },
    [client, incLoadPartyStepProgress, finLoadPartyStepProgress]
  );

  const loadParties = useCallback(
    async ({
      allPages,
      rowsPerPage,
      variables,
    }: {
      variables: any;
      allPages: boolean;
      rowsPerPage: number;
    }) => {
      setLoadPartiesStatus(() => getInitialLoadPartiesStatus());
      setLoadPartiesStatus((old) => ({ ...old, status: 'run' }));

      let result: { data: any; error: ApolloError | undefined };

      if (!allPages) {
        finLoadPartyStepProgress('calculate');

        const { data, error } = await client.query<parties>({
          query: GET_PARTIES,
          variables: { ...variables },
          fetchPolicy: 'network-only',
        });
        finLoadPartyStepProgress('getParties');

        if (data.parties) {
          result = { data: data?.parties, error };
        } else {
          result = { data: [], error };
        }
      } else {
        incLoadPartyStepProgress('calculate');
        const { data: partyCount, error: partyCountError } = await client.query<partyCount>({
          query: GET_PARTY_COUNT,
          variables: { ...variables },
        });
        finLoadPartyStepProgress('calculate');

        if (partyCount && !partyCountError) {
          setLoadPartyStepCount('getParties', Math.ceil(partyCount.partyCount / rowsPerPage));
        }

        result = { data: [], error: undefined };
        let lastPage = false;

        for (var pageNo = 0; !lastPage; pageNo++) {
          const { data, error } = await client.query<parties>({
            query: GET_PARTIES,
            variables: { ...variables, take: rowsPerPage, skip: rowsPerPage * pageNo },
          });

          if (error) {
            result.data.error = error;
            break;
          }

          lastPage = !data?.parties?.length;
          if (!lastPage) {
            incLoadPartyStepProgress('getParties');
            result.data.push(...data.parties);
          }
        }
        finLoadPartyStepProgress('getParties');
      }

      const partieIds = result.data.map((data: { id: string }) => data.id);
      const contacts = await loadContacts(partieIds);
      const partiesContacts = groupBy(contacts.data, 'partyId');

      const updatedResult = {
        ...result,
        data: result.data.map((data: any) => ({
          ...data,
          contacts: partiesContacts[data.id] || [],
        })),
      };

      setLoadPartiesStatus((old) => ({ ...old, status: 'fin' }));
      return updatedResult;
    },
    [
      client,
      incLoadPartyStepProgress,
      setLoadPartyStepCount,
      finLoadPartyStepProgress,
      loadContacts,
    ]
  );

  return {
    loadParties,
    loadPartiesStatus,
  };
};
