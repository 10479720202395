import { useQuery } from '@apollo/client';
import { GET_PROJECT_FOLDER_PATH } from 'graphql/legalFolders/legalFolders';
import { useEffect, useState } from 'react';
import { projectFolderPath } from 'graphql/legalFolders/types/projectFolderPath';

export interface IHook {
  legalFolderId?: string;
}

export interface IUseProjectFolderPathProps {
  projectFolderId: string;
}
export const useProjectFolderPath = ({ projectFolderId }: IUseProjectFolderPathProps): IHook => {
  const [legalFolderId, setLegalFolderId] = useState<string>();

  const { data, loading } = useQuery<projectFolderPath>(GET_PROJECT_FOLDER_PATH, {
    variables: { id: projectFolderId },
  });

  useEffect(() => {
    if (data && !loading) {
      setLegalFolderId(data.contract_container.legalFolder.id);
    }
  }, [data, loading]);
  return {
    legalFolderId,
  };
};
