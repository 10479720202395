import { Grid, Tooltip } from '@mui/material';

import { UserInitials } from 'components';

import { carbonCopiesByOrder, signersByOrder } from 'utils/docusign';
import { capitalizeFirstLetter } from 'utils/formats';
import { formatTime } from 'utils/time';
import { IDocumentDocusign } from 'graphql/legalFolders/types/IDocumentDocusign';
import { FC, useCallback } from 'react';

import { IDocumentVersion } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { useApolloClient } from '@apollo/client';
import { useUI } from 'contexts/UiContext';
import { CONTRACT_DOCUSIGN_DOWNLOAD_ENVELOPE } from 'graphql/legalFolders/docusign';
import { contractDocuSignEnvelopeDownload } from 'graphql/legalFolders/types/contractDocuSignEnvelopeDownload';

import { saveToFile } from 'utils/saveToFile';

export interface IDocusignInfoProps {
  docusign: IDocumentDocusign;
  versions: IDocumentVersion[];
}

export const DocusignInfo: FC<IDocusignInfoProps> = ({ docusign, versions }) => {
  const client = useApolloClient();
  const { addSnackbar } = useUI();

  const signersByRoutingOrder = signersByOrder(docusign);
  const ccByRoutingOrder = carbonCopiesByOrder(docusign);

  const version = versions.find((v) => v.id === docusign.documentVersionId);

  const handleDownloadDocusign = useCallback(
    (docusign: any, version: any, documentType: any) => {
      const envelopeId = docusign.envelopeId;
      const fileName = version?.blobName + '_' + envelopeId;

      return async () => {
        try {
          const { data, error } = await client.query<contractDocuSignEnvelopeDownload>({
            query: CONTRACT_DOCUSIGN_DOWNLOAD_ENVELOPE,
            variables: {
              envelopeId,
              documentType,
            },
            fetchPolicy: 'network-only',
          });
          if (error) {
            addSnackbar!({ text: 'Error', severity: 'error' });
            console.log('Error', error);
          } else {
            if (!data?.contract_documentDocusignEnvelopeDownload?.fileBase64) {
              addSnackbar!({ text: 'Error', severity: 'error' });
            } else {
              const fileBase64 = data.contract_documentDocusignEnvelopeDownload.fileBase64;
              const buffer = Buffer.from(fileBase64, 'base64');

              // const blob = new Blob([new Uint8Array(buffer)]);
              const blob = new Blob([buffer], { type: 'application/octet-stream' });
              saveToFile(blob, fileName + '_' + documentType, '.pdf');
            }
          }
        } catch (e: any) {
          addSnackbar!({ text: 'Error', severity: 'error' });
          console.log('Error', e);
        }
      };
    },
    [client, addSnackbar]
  );

  if (!version) return <></>;
  return (
    <Grid container spacing={1}>
      <>
        <Grid item xs={6}>
          <div
            className={'file-archive-item'}
            style={{
              overflow: 'hidden',
              // wordBreak: 'break-all',
              // wordWrap: 'break-word',
            }}
            onClick={handleDownloadDocusign(docusign, version, 'combined')}
          >
            Download DocuSign Document
          </div>

          {docusign?.envelope?.status === 'completed' ? (
            <div
              className={'file-archive-item'}
              style={{
                overflow: 'hidden',
                // wordBreak: 'break-all',
                // wordWrap: 'break-word',
              }}
              onClick={handleDownloadDocusign(docusign, version, 'certificate')}
            >
              Download Certificate
            </div>
          ) : undefined}
          <div style={{ padding: '1rem 0' }}>
            DocuSign Status:{' '}
            <div
              style={{
                display: 'inline-block',
                color: ['voided', 'deleted'].includes(docusign?.envelope?.status ?? '')
                  ? 'red'
                  : ['complited', 'signed'].includes(docusign?.envelope?.status ?? '')
                  ? 'green'
                  : undefined,
              }}
            >
              {capitalizeFirstLetter(docusign?.envelope?.status || 'unknown')}
            </div>
            {docusign?.envelope?.statusChangedDateTime ? (
              <>
                <br />
                Status Changed: {formatTime(docusign?.envelope?.statusChangedDateTime)}
              </>
            ) : undefined}
            {docusign?.envelope?.voidedReason ? (
              <>
                <br />
                <div
                  style={{
                    display: 'inline-block',
                    border: '1px solid #ccc',
                    padding: '11px 10px',
                    borderRadius: '3px',
                    width: '85%',
                  }}
                >
                  {docusign?.envelope?.voidedReason}
                </div>
              </>
            ) : undefined}
            {/* <br />
                  Request initiated: {formatTime(docusign?.createdAt)} */}
            {docusign?.envelope?.createdDateTime ? (
              <>
                <br />
                Created: {formatTime(docusign?.envelope?.createdDateTime)}
              </>
            ) : undefined}
            {docusign?.envelope?.lastModifiedDateTime ? (
              <>
                <br />
                Last Modified: {formatTime(docusign?.envelope?.lastModifiedDateTime)}
              </>
            ) : undefined}
            {docusign?.envelope?.completedDateTime ? (
              <>
                <br />
                Completed: {formatTime(docusign?.envelope?.completedDateTime)}
              </>
            ) : undefined}
            {docusign?.envelope?.expireEnabled &&
            docusign?.envelope?.expireDateTime &&
            !docusign?.envelope?.completedDateTime &&
            docusign?.envelope?.status !== 'voided' ? (
              <>
                <br />
                Expire Date: {formatTime(docusign?.envelope?.expireDateTime)}
              </>
            ) : undefined}
            <br />
            <br />
            File Name:{' '}
            <a
              href={version?.downloadUrl || undefined}
              style={{ color: '#000000de' }}
              target="_blank"
              rel="noreferrer"
            >
              {version?.blobName}
            </a>
            <br />
            Original File Name: {version?.originalFilename}
            <br />
            Version v1.{version.version}
            <br />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            {signersByRoutingOrder.length ? (
              <>
                <br />
                <strong>Signers:</strong>
                <br />
              </>
            ) : undefined}
            {signersByRoutingOrder.map(
              ({ routingOrder, signers }: { routingOrder: number; signers: any[] }) => {
                return signers?.map((signer: any) => {
                  return (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          margin: '10px 0',
                          gap: '10px',
                        }}
                      >
                        <Tooltip
                          title={
                            <>
                              {`${signer.name} (${signer.email})`}
                              <br />
                              Status: {capitalizeFirstLetter(signer.status)}
                              <br />
                              Sign Order: #{routingOrder}
                            </>
                          }
                        >
                          <div
                            style={{
                              transform: 'scale(0.7)',
                            }}
                          >
                            <UserInitials
                              {...signer}
                              divStyle={
                                signer.status === 'sent'
                                  ? { backgroundColor: 'yellow', color: 'black' }
                                  : signer.status === 'completed'
                                  ? { backgroundColor: 'green' }
                                  : { backgroundColor: 'black', color: 'white' }
                              }
                            />
                          </div>
                        </Tooltip>

                        <div>
                          {`${signer.name}`}
                          <br />
                          {signer.email}
                        </div>
                      </div>
                    </>
                  );
                });
              }
            )}
            <br />
            {ccByRoutingOrder.length ? (
              <>
                <strong>Receives a copy:</strong>
                <br />
              </>
            ) : undefined}
            {ccByRoutingOrder.map(({ routingOrder, cc }: { routingOrder: number; cc: any[] }) => {
              return cc?.map((cc: any) => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'start',
                        margin: '10px 0',
                        gap: '10px',
                      }}
                    >
                      <Tooltip
                        title={
                          <>
                            {`${cc.name} (${cc.email})`}
                            <br />
                            DocuSign Status: {capitalizeFirstLetter(cc.status)}
                            <br />
                            Routing Order: #{routingOrder}
                          </>
                        }
                      >
                        <div
                          style={{
                            transform: 'scale(0.7)',
                          }}
                        >
                          <UserInitials
                            {...cc}
                            divStyle={
                              cc.status === 'sent'
                                ? { backgroundColor: 'yellow', color: 'black' }
                                : cc.status === 'completed'
                                ? { backgroundColor: 'green' }
                                : { backgroundColor: 'black', color: 'white' }
                            }
                          />
                        </div>
                      </Tooltip>

                      <div>
                        {`${cc.name}`}
                        <br />
                        {cc.email}
                      </div>
                    </div>
                  </>
                );
              });
            })}
          </div>
        </Grid>
      </>
    </Grid>
  );
};
