import React, { FC, useMemo } from 'react';
import { IProposal } from './interface';
import { ProposalTile } from './components/ProposalTile/ProposalTile';
import { SelectProposalTile } from './components/SelectProposalTile/SelectProposalTile';

export interface IProposalTilesProps {
  proposals: Array<IProposal | null>;
  onAddProposal: (id: string, index: number) => void;
  onRemoveProposal: (index: number) => void;
  onViewProposal: (index: number) => void;
  showValidator?: boolean;
  legalFolderPartyIds?: string[];
}

export const ProposalTiles: FC<IProposalTilesProps> = ({
  proposals,
  onAddProposal,
  onRemoveProposal,
  onViewProposal,
  showValidator,
  legalFolderPartyIds,
}) => {
  let index = -1;
  const onRemove = (index: number) => () => onRemoveProposal(index);
  const onAdd = (index: number) => (id: string) => onAddProposal(id, index);
  const onView = (index: number) => () => onViewProposal(index);

  const usedProposalIds = useMemo(
    () => proposals.filter((proposal) => !!proposal?.id).map((proposal) => proposal!.id),
    [proposals]
  );

  return (
    <>
      {proposals.map((proposal) => {
        index++;
        return (
          <>
            {proposal ? (
              <ProposalTile
                proposal={proposal}
                index={index}
                onRemoveProposal={onRemove(index)}
                onViewProposal={onView(index)}
              ></ProposalTile>
            ) : (
              <SelectProposalTile
                onAddProposal={onAdd(index)}
                onRemoveProposal={onRemove(index)}
                error={!!showValidator}
                usedProposalIds={usedProposalIds}
                legalFolderPartyIds={legalFolderPartyIds}
              ></SelectProposalTile>
            )}
          </>
        );
      })}
    </>
  );
};
