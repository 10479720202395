import { Button, TextField, Typography, Tooltip, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import EasyAutoComplete from 'components/EasyAutoComplete';
import React, { FC, LegacyRef, useMemo, useCallback } from 'react';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FirstPage, LastPage } from '@mui/icons-material';
import { Alert } from '@mui/lab';

const localization = {
  firstTooltip: 'First Page',
  firstAriaLabel: 'First Page',
  previousTooltip: 'Previous Page',
  previousAriaLabel: 'Previous Page',
  nextTooltip: 'Next Page',
  nextAriaLabel: 'Next Page',
  lastTooltip: 'Last Page',
  lastAriaLabel: 'Last Page',
};
export interface IPagination {
  rowsPerPageOptions: number[];
  count: number;
  totalCount?: number;
  rowsPerPage: number;
  page: number;
  onPageChanged: any;
  onChangeRowsPerPage: any;
  showFirstLastPageButtons?: boolean;
  leftSideComponent?: any;
  ref?: any;
}

export const Pagination: FC<IPagination> = React.forwardRef(
  (
    {
      rowsPerPageOptions,
      count,
      totalCount,
      rowsPerPage,
      page,
      onPageChanged,
      onChangeRowsPerPage,
      showFirstLastPageButtons = true,
      leftSideComponent,
    },
    ref
  ) => {
    const maxPage = useMemo(
      () => Math.ceil(Number(count) / Number(rowsPerPage)),
      [count, rowsPerPage]
    );

    const onNextPage = useCallback(() => {
      onPageChanged(null, page ? page + 1 : 1);
    }, [page, onPageChanged]);

    const onPreviousPage = useCallback(() => {
      let pageNum;
      if (page >= maxPage) {
        pageNum = maxPage - 1;
      } else {
        pageNum = page > 0 ? page - 1 : 0;
      }
      onPageChanged(null, pageNum);
    }, [page, onPageChanged, maxPage]);

    const handleManualPageSet = (event: any): void => {
      const val = event.target.value;

      if (!val || val === '') return;

      let pageNum = Number(val);

      if (pageNum < 1) return;

      if (pageNum > maxPage) return;

      pageNum -= 1;

      onPageChanged(event, pageNum);
    };

    const pageOptions = rowsPerPageOptions?.map((rpp: any): any => ({ pageSize: String(rpp) }));

    const getSelectedPageSize = useCallback(() => {
      for (const p of pageOptions) {
        if (p.pageSize.toString() === rowsPerPage.toString()) {
          return p;
        }
      }

      return null;
    }, [pageOptions, rowsPerPage]);

    const handleFirstPageButtonClick = useCallback(
      (event: any): void => {
        onPageChanged(event, 0);
      },
      [onPageChanged]
    );

    const handleLastPageButtonClick = useCallback(
      (event: any): void => {
        const pageNum = Math.ceil(Number(count) / Number(rowsPerPage)) - 1;
        onPageChanged(event, pageNum);
      },
      [count, onPageChanged, rowsPerPage]
    );

    const getCountRecordsText = useCallback(() => {
      if ((totalCount || count) === 1) return 'record';
      return 'records';
    }, [count, totalCount]);

    return (
      <div style={{ padding: '20px 0 10px 0' }} ref={ref as LegacyRef<HTMLDivElement>}>
        <Grid container padding={0} spacing={2}>
          <Grid
            xs={12}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {leftSideComponent}
          </Grid>
          <Grid xs={12} md={8}>
            <Grid container padding={0} justifyContent="center" spacing={3}>
              <Grid style={{ margin: 'auto 0' }}>
                {showFirstLastPageButtons ? (
                  <Tooltip title={localization.firstTooltip}>
                    <div>
                      <IconButton
                        disabled={!page}
                        onClick={debounce(handleFirstPageButtonClick, DEBOUNCE_TIMEOUT)}
                        aria-label={localization.firstAriaLabel}
                      >
                        <FirstPage />
                      </IconButton>
                    </div>
                  </Tooltip>
                ) : undefined}
              </Grid>
              <Grid style={{ margin: 'auto 0' }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(onPreviousPage, DEBOUNCE_TIMEOUT)}
                  disabled={!page}
                >
                  Previous
                </Button>
              </Grid>
              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <Typography variant="body2" style={{ padding: '0.5rem' }}>
                    Page{' '}
                  </Typography>
                </div>
                <div>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={page + 1}
                    style={{
                      width: '5rem',
                      background: '#fff',
                      border: '0.5px solid #aaa',
                      borderRadius: 5,
                    }}
                    inputProps={{
                      style: { margin: '0.1rem', paddingTop: '8.9px', paddingBottom: '8.9px' },
                    }}
                    onChange={handleManualPageSet}
                  />
                </div>
                <div>
                  <Typography variant="body2" style={{ padding: '0.5rem' }}>
                    of {Math.ceil(count / rowsPerPage)}
                  </Typography>
                </div>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  width: '135px',
                  alignItems: 'center',
                }}
              >
                <EasyAutoComplete
                  items={pageOptions}
                  selected={getSelectedPageSize()}
                  label=""
                  optionsLabel="pageSize"
                  optionsLabelSuffix=" rows"
                  textFieldStyle="outlined"
                  textFieldStyling={{
                    background: '#fff',
                    border: '0.5px solid #aaa',
                    borderRadius: 5,
                    margin: '0px',
                  }}
                  selectedChanged={(event: any): void => {
                    onChangeRowsPerPage({ target: { value: Number(event?.pageSize) } });
                  }}
                />
              </Grid>
              <Grid style={{ margin: 'auto 0' }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={page + 1 >= maxPage}
                  onClick={debounce(onNextPage, DEBOUNCE_TIMEOUT)}
                >
                  Next
                </Button>
              </Grid>
              <Grid style={{ margin: 'auto 0' }}>
                {!showFirstLastPageButtons ? undefined : (
                  <Tooltip title={localization.lastTooltip}>
                    <div>
                      <IconButton
                        disabled={page + 1 >= maxPage}
                        onClick={debounce(handleLastPageButtonClick, DEBOUNCE_TIMEOUT)}
                        aria-label={localization.lastAriaLabel}
                      >
                        <LastPage />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            md={2}
            style={{
              display: 'flex',
              // width: '135px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Alert
              severity="info"
              style={{
                padding: '2px 8px',
                marginRight: '1rem',
                width: '80%',
                whiteSpace: 'nowrap',
              }}
            >
              {totalCount || count} {getCountRecordsText()}
            </Alert>
          </Grid>
        </Grid>
      </div>
    );
  }
);
